import React from "react";
import googleIcon from "../../assets/google-play.svg";
import appleStoreIcon from "../../assets/ios.svg"
import banner from "../../assets/landing screen/banner-svg.svg";

function HomeBanner() {
  return (
    <main className="bg-[#FAF8F4] relative left-0 top-0 w-[99.5vw] px-1 md:px-0">
      <div className="flex flex-col items-center justify-center pt-[150px]">
        <div className="text-base text-[#675732] bg-[#D7CAAC] rounded-3xl inline-block py-2 px-4">
          <p className="font-circular-450">Agriculture. Fish Farming. Animal Farming.</p>
        </div>
        <div className="text-[xl] md:text-7xl text-center font-medium text-[#212121] max-w-[41rem] m-6">
          <p className="">Delivering Growth Fresh Produce</p>
        </div>
        <div className="text-[#6B7280] text-xl font-normal text-center max-w-[35rem]">
          <p className="font-circular-450">
            Buying and Selling Online Platform for the Fish Farmers and Traders
            based in Nigeria
          </p>
        </div>
        <div className="flex gap-3 my-10">
          <button type="button" onClick={() => {}}>
            <img
              src={appleStoreIcon}
              className="w-[132px] h-11"
              alt="appleStore"
            />
          </button>
          <button type="button" onClick={() => {}}>
            <img src={googleIcon} className="w-[148.5px] h-11" alt="playStore" />
          </button>
        </div>
        <div className="w-[100%]">
          <img src={banner} alt="banner" className="w-full" />
        </div>
      </div>
    </main>
  );
}

export default HomeBanner;
