import React from "react";
import ProductCard from "../../../../components/ProductCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { sliderSettings } from "../../../../shared/constant";

function Cards({ visibleCards, sliderRef, setStatusMessage, setShowAddToCartSuccessText }) {
  return (
    visibleCards.length > 0 &&  
     <div>
      <Slider {...sliderSettings} ref={sliderRef}>
        {visibleCards?.length > 0 ? (
          visibleCards?.map((product) => (
            <div className="px-2">
              <ProductCard key={product.id} product={product} setStatusMessage={setStatusMessage} setShowAddToCartSuccessText={setShowAddToCartSuccessText} />
            </div>
          ))
        ) : (
          <p>No Data Available right now</p>
        )}
      </Slider>
    </div>
  );
}

export default Cards;
