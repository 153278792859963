import * as actionTypes from "./types";
import axios from "../../axios-api";
import { arrayBufferToBase64, getErrors, getResData } from "../../shared/utility";

// updatePassword
export const updatePasswordStart = () => {
    return {
        type: actionTypes.PROFILE_UPDATE_PASSWORD_START,
    };
};

export const updatePasswordSuccess = (updatePasswordRes) => {
    return {
        type: actionTypes.PROFILE_UPDATE_PASSWORD_SUCCESS,
        updatePasswordRes,
    };
};

export const updatePasswordFailed = (error) => {
    return {
        type: actionTypes.PROFILE_UPDATE_PASSWORD_FAIL,
        error,
    };
};

export const updatePassword = (data) => {
    return (dispatch) => {
        dispatch(updatePasswordStart());

        return axios
            .put(`/user/password`, data)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(updatePasswordSuccess(data.data));
            })
            .catch((err) => dispatch(updatePasswordFailed(getErrors(err))));
    };
};

// getProfile
export const getProfileStart = () => {
    return {
        type: actionTypes.PROFILE_GET_USER_PROFILE_START,
    };
};

export const getProfileSuccess = (profile) => {
    return {
        type: actionTypes.PROFILE_GET_USER_PROFILE_SUCCESS,
        profile,
    };
};

export const getProfileFailed = (error) => {
    return {
        type: actionTypes.PROFILE_GET_USER_PROFILE_FAIL,
        error,
        profile: null
    };
};

export const getProfile = () => {
    return (dispatch) => {
        dispatch(getProfileStart());

        if(localStorage.getItem("m-farm-user-token")){
            return axios
            .get(`/user/profile`)
            .then((response) => {
                if (!response) throw new Error("No Response");
                if(response.data.errorCode === 129 ){
                    const data = {
                        subscribed: false,
                        message: 'No Subscription',
                    }
                    return dispatch(getProfileSuccess(data));
                }
                else {
                    const data = getResData(response.data);
                    if(!data.success) throw new Error(getErrors(data));
                    return dispatch(getProfileSuccess(data.data));
                }
            })
            .catch((err) => dispatch(getProfileFailed(getErrors(err))));
        }else{
            dispatch(getProfileFailed(getErrors('Something went wrong. Please try later')));
        }
    };
};

// updateProfile
export const updateProfileStart = () => {
    return {
        type: actionTypes.PROFILE_UPDATE_USER_PROFILE_START,
    };
};

export const updateProfileSuccess = (updateProfileRes) => {
    return {
        type: actionTypes.PROFILE_UPDATE_USER_PROFILE_SUCCESS,
        updateProfileRes,
    };
};

export const updateProfileFailed = (error) => {
    return {
        type: actionTypes.PROFILE_UPDATE_USER_PROFILE_FAIL,
        error,
    };
};

export const updateProfile = (data) => {
    return (dispatch) => {
        dispatch(updateProfileStart());

        return axios
            .put(`/user/profile`, data)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                dispatch(updateProfileSuccess(data.data));
                dispatch(getProfile())
            })
            .catch((err) => dispatch(updateProfileFailed(getErrors(err))));
    };
};

// getProfileImage
export const getProfileImageStart = () => {
    return {
        type: actionTypes.PROFILE_GET_PROFILE_IMAGE_START,
    };
};

export const getProfileImageSuccess = (profileImage) => {
    return {
        type: actionTypes.PROFILE_GET_PROFILE_IMAGE_SUCCESS,
        profileImage,
    };
};

export const getProfileImageFailed = (error) => {
    return {
        type: actionTypes.PROFILE_GET_PROFILE_IMAGE_FAIL,
        error,
    };
};

export const getProfileImage = () => {
    return (dispatch) => {
        dispatch(getProfileImageStart());

        return axios
            .get(`/user/profile/image`, {
                responseType: 'arraybuffer',// Tell Axios to treat the response as an array buffer
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = response.data;
                const base64String = arrayBufferToBase64(data);

                return dispatch(getProfileImageSuccess(base64String));
            })
            .catch((err) => dispatch(getProfileImageFailed(getErrors(err))));
    };
};

// updateProfileImage
export const updateProfileImageStart = () => {
    return {
        type: actionTypes.PROFILE_UPDATE_PROFILE_IMAGE_START,
    };
};

export const updateProfileImageSuccess = (updateProfileImageRes) => {
    return {
        type: actionTypes.PROFILE_UPDATE_PROFILE_IMAGE_SUCCESS,
        updateProfileImageRes,
    };
};

export const updateProfileImageFailed = (error) => {
    return {
        type: actionTypes.PROFILE_UPDATE_PROFILE_IMAGE_FAIL,
        error,
    };
};

export const updateProfileImage = (data) => {
    return (dispatch) => {
        dispatch(updateProfileImageStart());

        return axios
            .put(`/user/profile/image`, data)
            .then(async (response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                await dispatch(updateProfileImageSuccess(data.data));

                return await dispatch(getProfileImage())
            })
            .catch((err) => dispatch(updateProfileImageFailed(getErrors(err))));
    };
};