import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FormikInput from "../FormikInput";
import { getPricePeriods } from "../../store/actions/rentLandRequest";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from "../ImageUploader";
import { getRentRequestImage } from "../../store/actions/rentLorriesRequest";
import ImageUploaderEdit from "../ImageUploaderEdit";
import Spinner from "../../shared/Spinner";

const selectPriceStyles = {
  control: (provided, state) => ({
    ...provided,
    display: "flex",
    width: "100%",
    borderRadius: "0.375rem", // Equivalent to 6px (rounded-md)
    background: "#50A060",
    padding: "8px 17px", // Equivalent to 13px 25px (py-[13px] px-[25px])
    border: "none",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)", // Equivalent to shadow-sm
    outline: "none", // Equivalent to outline-none,
    color: "#fff",
    minWidth: "180px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#50A060"
      : state.isFocused
      ? "#f0f0f0"
      : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: state.isSelected ? "#50A060" : "#f0f0f0",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    padding: "0px",
    color: "#fff",
  }),
  input: (provided) => ({
    ...provided,
    padding: "0px",
    margin: "0px", // Remove the padding from the input
    color: "#fff",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0", // Remove the padding from the value container
    color: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  indicatorSeparator: () => null,
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#fff",
    "&:hover": {
      color: "#fff",
    },
  }),
};

const validationLorrySchema = Yup.object().shape({
  location: Yup.string().required("Location is required").trim().min(1, "Location size must be between 1 and 255").max(255, "Location size must be between 1 and 255"),
  name: Yup.string().required("Name is required").trim().min(1, "Name size must be between 1 and 255").max(255, "Name size must be between 1 and 255"),
  price: Yup.number().required("Price is required").min(1, "Price is not valid").max(9999999999, "Price is not valid"),
  pricePeriod: Yup.string().required("Price Period is required"),
  brandName: Yup.string().required("Brand Name is required"),
  horsePower: Yup.string().required("Horse Power is required").trim(),
  yearOfManufacture: Yup.string().required("Year of Manufacture is required"),
  imageBase64: Yup.array()
    .min(3, "At least 3 images are required")
    .max(10, "Maximum of 10 images allowed")
    .required("Images are required")
    .test('fileSize', 'One of the File size is too large, it should be less than 1mb',  (imageArray) =>  imageArray.reduce((acc, image) => ((image.base64 ?  ((( image.base64.length * 3) / 4) <= (1024 * 1024 )) : (((image.length * 3) / 4) <= (1024 * 1024 )) ? acc : false)), true)),
});

const RentRequestLorriesForm = ({ onSubmit, rentRequest, onClose, loading }) => {
  const dispatch = useDispatch();
  let setFieldV;

  const { pricePeriods } = useSelector((state) => ({
    pricePeriods: state?.rentLandRequests?.pricePeriods,
  }));

  const [images, setImages] = useState("");

  const fetchImages = async () => {
    const imageIdList = rentRequest?.imageIdList || []; // Ensure imageIdList is an array

    const imagePromises = imageIdList.map(async (imageId) => {
      const res = await getRentRequestImage(imageId);
      return { id: imageId, base64: res };
    });

    const imageArray = await Promise.all(imagePromises);
    setImages(imageArray);
  };

  useEffect(() => {
    if (setFieldV) {
      setFieldV("imageBase64", images);
    }
  }, [images, setFieldV]);

  useEffect(() => {
    if (rentRequest?.imageIdList) {
      fetchImages();
    }
  }, [rentRequest?.imageIdList]);

  useEffect(() => {
    dispatch(getPricePeriods());
  }, [dispatch]);

  const pricePeriodsOptions = pricePeriods?.data?.map((pricePeriod) => {
    return { value: pricePeriod, label: pricePeriod };
  });

  return (
    <div>
      <Formik
        initialValues={{
          location: rentRequest?.location ? rentRequest?.location : "",
          name: rentRequest?.name ? rentRequest?.name : "",
          price: rentRequest?.price ? rentRequest?.price : "",
          pricePeriod: rentRequest?.pricePeriod ? rentRequest?.pricePeriod : "",
          brandName: rentRequest?.brandName ? rentRequest?.brandName : "",
          horsePower: rentRequest?.horsePower ? rentRequest?.horsePower : "",
          yearOfManufacture: rentRequest?.yearOfManufacture
            ? new Date(new Date().setFullYear(rentRequest?.yearOfManufacture))
            : "",
          imageBase64: rentRequest?.imageBase64
            ? rentRequest?.imageBase64.map((item) => item.base64)
            : "",
        }}
        validationSchema={validationLorrySchema}
        onSubmit={(values) =>
          onSubmit({
            ...values,
            yearOfManufacture: values?.yearOfManufacture?.getFullYear(),
          })
        }
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => {
          setFieldV = setFieldValue;
          return (
            <Form className="w-[98%]">
              <div className="mb-4 grid gap-y-[20px]  w-[100%]">
                <div>
                  {images ? (
                    <ImageUploaderEdit
                      images={values?.imageBase64}
                      setImages={(values) => {
                        setFieldValue("imageBase64", values);
                      }}
                    />
                  ) : (
                    <ImageUploader
                      images={values?.imageBase64}
                      setImages={(values) => {
                        setFieldValue("imageBase64", values);
                      }}
                    />
                  )}
                  {touched.imageBase64 && errors.imageBase64 && (
                    <div className="mt-1 text-sm text-[#FF0000]">
                      {errors.imageBase64}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <FormikInput
                    placeholder="Name"
                    name="name"
                    type="text"
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className="mb-2">
                  <FormikInput
                    placeholder="Brand Name"
                    name="brandName"
                    type="text"
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className="mb-2">
                  <FormikInput
                    placeholder="Horse Power"
                    name="horsePower"
                    type="text"
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className="mb-2 grid">
                  <DatePicker
                    name="yearOfManufacture"
                    selected={values.yearOfManufacture}
                    onChange={(dates) => {
                      setFieldValue("yearOfManufacture", dates);
                    }}
                    placeholderText="Year of Manufacture"
                    showYearPicker
                    maxDate={new Date(new Date().getFullYear(), 11, 31)}
                    dateFormat="yyyy"
                    className={
                      "form-control mt-1 block w-full rounded-md border-gray-300 bg-[#F5F5F5] px-[25px] py-[13px] shadow-sm outline-none" +
                      (errors?.yearOfManufacture && touched?.yearOfManufacture
                        ? "border-red-500"
                        : "")
                    }
                  />
                  {touched.yearOfManufacture && errors.yearOfManufacture && (
                    <div className="mt-1 text-sm text-[#FF0000]">
                      {errors.yearOfManufacture}
                    </div>
                  )}
                </div>
                <div className="flex justify-evenly sm:flex-row flex-col gap-3">
                  <div>
                    <FormikInput
                      placeholder="Price"
                      name="price"
                      type="number"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div>
                    <Select
                      name="pricePeriod"
                      options={pricePeriodsOptions}
                      value={pricePeriodsOptions?.find(
                        (option) => option.value === values.pricePeriod
                      )}
                      placeholder="Price Period"
                      onChange={(selectedOption) => {
                        setFieldValue("pricePeriod", selectedOption.value);
                      }}
                      styles={selectPriceStyles}
                      handleBlur={handleBlur}
                    />
                    {touched.pricePeriod && errors.pricePeriod && (
                      <div className="mt-1 text-sm text-[#FF0000]">
                        {errors.pricePeriod}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-2">
                  <FormikInput
                    placeholder="Location"
                    name="location"
                    type="text"
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              <div className="flex justify-evenly gap-3">
                <button
                  disabled={loading}
                  type="submit"
                  className={`flex gap-1 justify-center items-center rounded-[10px] bg-main w-44 h-12 font-medium text-white ${loading ? "opacity-60" : "opacity-100"}`}
                >
                  {loading && <Spinner />}
                  Save
                </button>
                <button
                  type="button"
                  className="rounded-[10px] px-[66px] py-[12px] text-[#969696]"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RentRequestLorriesForm;
