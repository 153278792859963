import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";

const initialState = {
  error: null,
  loading: false,
  rentLorriesRequests: null,
  createRes: null,
  editRes: null,
  deleteRes: null,
  pricePeriods: null,
};

// get price periods requests
const getPricePeriodsStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getPricePeriodsSuccess = (state, action) => {
  return updateObject(state, {
    pricePeriods: action.pricePeriods,
    error: null,
    loading: false,
  });
};

const getPricePeriodsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

// get rentLorries requests
const getRentLorriesRequestsStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getRentLorriesRequestsSuccess = (state, action) => {
  return updateObject(state, {
    rentLorriesRequests: action.rentLorriesRequests,
    error: null,
    loading: false,
  });
};

const getRentLorriesRequestsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    rentLorriesRequests: null,
  });
};

// add rentLorry request image
const createRentLorryRequestImageStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const createRentLorryRequestImageSuccess = (state, action) => {
  return updateObject(state, {
    addImageRes: action.addImageRes,
    error: null,
    loading: false,
  });
};

const createRentLorryRequestImageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    addImageRes: null,
  });
};

// delete rentLorry request image
const deleteRentLorryRequestImageStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const deleteRentLorryRequestImageSuccess = (state, action) => {
  return updateObject(state, {
    deleteImageRes: action.deleteImageRes,
    error: null,
    loading: false,
  });
};

const deleteRentLorryRequestImageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    deleteImageRes: null,
  });
};

// create rentLorries request
const createRentLorriesRequestStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const createRentLorriesRequestSuccess = (state, action) => {
  return updateObject(state, {
    createRes: action.createRes,
    error: null,
    loading: false,
  });
};

const createRentLorriesRequestFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    createRes: null,
  });
};

// edit rentLorries request
const editRentLorriesRequestStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const editRentLorriesRequestSuccess = (state, action) => {
  return updateObject(state, {
    editRes: action.editRes,
    error: null,
    loading: false,
  });
};

const editRentLorriesRequestFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    editRes: null,
  });
};

// delete rentLorries request
const deleteRentLorriesRequestStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const deleteRentLorriesRequestSuccess = (state, action) => {
  return updateObject(state, {
    deleteRes: action.deleteRes,
    error: null,
    loading: false,
  });
};

const deleteRentLorriesRequestFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    deleteRes: null,
  });
};

const rentLorriesRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    // get rentLorries requests actions
    case actionTypes.GET_RENT_LORRY_REQUESTS_START:
      return getRentLorriesRequestsStart(state, action);
    case actionTypes.GET_RENT_LORRY_REQUESTS_SUCCESS:
      return getRentLorriesRequestsSuccess(state, action);
    case actionTypes.GET_RENT_LORRY_REQUESTS_FAIL:
      return getRentLorriesRequestsFail(state, action);
    // create rentLorries request actions
    case actionTypes.CREATE_RENT_LORRY_REQUEST_START:
      return createRentLorriesRequestStart(state, action);
    case actionTypes.CREATE_RENT_LORRY_REQUEST_SUCCESS:
      return createRentLorriesRequestSuccess(state, action);
    case actionTypes.CREATE_RENT_LORRY_REQUEST_FAIL:
      return createRentLorriesRequestFail(state, action);
    // edit rentLorries request actions
    case actionTypes.EDIT_RENT_LORRY_REQUEST_START:
      return editRentLorriesRequestStart(state, action);
    case actionTypes.EDIT_RENT_LORRY_REQUEST_SUCCESS:
      return editRentLorriesRequestSuccess(state, action);
    case actionTypes.EDIT_RENT_LORRY_REQUEST_FAIL:
      return editRentLorriesRequestFail(state, action);
    // delete rentLorries request actions
    case actionTypes.DELETE_RENT_LORRY_REQUEST_START:
      return deleteRentLorriesRequestStart(state, action);
    case actionTypes.DELETE_RENT_LORRY_REQUEST_SUCCESS:
      return deleteRentLorriesRequestSuccess(state, action);
    case actionTypes.DELETE_RENT_LORRY_REQUEST_FAIL:
      return deleteRentLorriesRequestFail(state, action);
    case actionTypes.GET_PRICE_PERIODS_START:
      return getPricePeriodsStart(state, action);
    case actionTypes.GET_PRICE_PERIODS_SUCCESS:
      return getPricePeriodsSuccess(state, action);
    case actionTypes.GET_PRICE_PERIODS_FAIL:
      return getPricePeriodsFail(state, action);
    // create rentLorry Image
    case actionTypes.CREATE_RENT_LORRY_REQUEST_IMAGE_START:
      return createRentLorryRequestImageStart(state, action);
    case actionTypes.CREATE_RENT_LORRY_REQUEST_IMAGE_SUCCESS:
      return createRentLorryRequestImageSuccess(state, action);
    case actionTypes.CREATE_RENT_LORRY_REQUEST_IMAGE_FAIL:
      return createRentLorryRequestImageFail(state, action);
    // DELETE rentLorry Image
    case actionTypes.DELETE_RENT_LORRY_REQUEST_IMAGE_START:
      return deleteRentLorryRequestImageStart(state, action);
    case actionTypes.DELETE_RENT_LORRY_REQUEST_IMAGE_SUCCESS:
      return deleteRentLorryRequestImageSuccess(state, action);
    case actionTypes.DELETE_RENT_LORRY_REQUEST_IMAGE_FAIL:
      return deleteRentLorryRequestImageFail(state, action);
    default:
      return state;
  }
};

export default rentLorriesRequestReducer;
