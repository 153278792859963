import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/Layout";
import Table from "../../../components/Tables/Table";
import SearchForm from "../../../components/Forms/SearchForm";
import Img from "../../../assets/image-placeholder.png";
import { getProfile } from "../../../store/actions/profile";
import { getOrderHistorySell,getOrderHistoryImage, getOrderStatus, changeOrderSellStatus, changeOrderSellStatusFailed } from "../../../store/actions/orderHistorySell";
import Select from "react-select";
import Modal from '../../../components/Modal/Modal';
import MoreInfo from "../../../components/MoreInfo";
import authCheck from "../../../shared/authCheck"

const OrderHistorySell = () => {
  const dispatch = useDispatch();

  const [selectedOrder, setSelectedOrder] = useState(null);
  // const [selectedStatus, setSelectedStatus] = useState(null);
  const [updateList, setUpdateList] = useState(true);
  const [firstItemIndex, setFirstItemIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState(false);
  const [sellAcceptanceStatus, setsellAcceptanceStatus] = useState({});
  const [searchValue, setSearchValue] = useState(null);
  const [orderHistoryImages, setOrderHistoryImages] = useState({});

  const { profile, orderHistorySell, orderHistorySellStatus } = useSelector(state => ({
    profile: state?.profile?.profile,
    orderHistorySell: state?.orderHistorySell?.orderHistorySell,
    orderHistorySellStatus: state?.orderHistorySell?.orderHistorySellStatus,
  }));

  useEffect(() => {
    if (updateList && profile) {
      if(searchValue){
        dispatch(getOrderHistorySell(firstItemIndex, 5, profile?.type, searchValue));
      }else{
        dispatch(getOrderHistorySell(firstItemIndex, 5, profile?.type));
      }
      if(profile.type==='SELLER'){
        dispatch(getOrderStatus())
      }
      setUpdateList(false);
    }

    if (!profile) {
      dispatch(getProfile());
    }

    if (orderHistorySell) {
      getImages(orderHistorySell.data);
    }
  }, [dispatch, firstItemIndex, updateList, profile ,orderHistorySell, searchValue]);

  // Get Images
  const getImages = async data => {

    data.map(async e => {
      if (orderHistoryImages[e.id] == undefined && e.hasImage) {
        const res = await getOrderHistoryImage(e.sellRequestId);
        
        if (res?.response?.status === 404) {
          setOrderHistoryImages(prevState => ({...prevState, [e.id]: null}))
        } else {
          const base64Sting = 'data:image/jpeg;base64,'+ res;
          setOrderHistoryImages(prevState => ({...prevState, [e.id]: base64Sting}))
        }
      }
    });
  };

   // handle status change
   const handleStatusSubmit = async id => {
    
    for(let statusId in sellAcceptanceStatus){
      if(statusId == id){
        const res = await dispatch(changeOrderSellStatus(statusId, sellAcceptanceStatus[statusId].label));
        if (res.type === "CHANGE_ORDER_SELL_STATUS_SUCCESS") {
          setUpdateList(true);
          // setSelectedStatus(null);
        } else if (res.type === "CHANGE_ORDER_SELL_STATUS_FAIL") {
          setTimeout(() => {
            dispatch(changeOrderSellStatusFailed(null));
            setUpdateList(true);
          }, 5000);
        }
      }
    }
  };

  //Handle Search
  const handleSearchSubmit = values => {
    dispatch(getOrderHistorySell(0, 5, profile?.type, values.search));
    setFirstItemIndex(0)
    setCurrentPage(1)
  };
  
  // Handle More Info
  const handleMoreInfo = (info) => {
    setSelectedOrder(info);
    setIsMoreInfoModalOpen(true); 
  }

  const handleDeliveryStatus = (delivery) => {
    let status = orderHistorySellStatus?.data;
    if(delivery==='Order Conformed'){
      status = status?.slice(status?.indexOf(delivery)+1, status.length-1);
      return status?.map((e,i) => { return { value: i+1, label: e } });
    }else if(delivery==='Delivery Process'){
      status = status?.slice(status?.indexOf(delivery)+1, status.length-1);
      return status?.map((e,i) => { return { value: i+1, label: e } });
    }else{
      return status?.map((e,i) => { return { value: i+1, label: e } });
    }
  }

  const formatDate = (dateString) => {
    if (dateString) {
      
      const dateAndTime = dateString.split(" ");
      const getDate = dateAndTime[0].split("-");
      const day = parseInt(getDate[0]);
      const month = parseInt(getDate[1]) - 1  ;
      const year = parseInt(getDate[2]);

      const getTime = dateAndTime[1].split(":")
      const hours = parseInt(getTime[0]);
      const minutes = parseInt(getTime[1]);
      const date = new Date(year, month, day, hours, minutes);
      return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })},${date.getFullYear()}`;
    }
  }

  const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        display: 'flex',
        width: '10rem',
        height:"3rem",
        borderRadius: '0.375rem', // Equivalent to 6px (rounded-md)
        background: '#F5F5F5',
        padding: '11px 17px', // Equivalent to 13px 25px (py-[13px] px-[25px])
        border: 'none',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', // Equivalent to shadow-sm
        outline: 'none', // Equivalent to outline-none
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
          ? '#50A060'
          : state.isFocused
              ? '#f0f0f0'
              : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
          backgroundColor: state.isSelected ? '#50A060' : '#f0f0f0',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: '0px',
      color: '#333',
    }),
    input: (provided) => ({
      ...provided,
      padding: '0px',
      margin: '0px', // Remove the padding from the input
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0', // Remove the padding from the value container
    }),
  };

  const tableData = {
    header: [],
    body:
      orderHistorySell &&
      orderHistorySell?.data?.map((row, index) => {
        const updateStatus = (selectedOption) => {
          const newObj = {};
          newObj[row.id] = selectedOption;
          setsellAcceptanceStatus((prev) => ({...prev, ...newObj }))
        }
      return (
        <React.Fragment key={index}>
          <td className="py-[20px] px-[20px]">
            <div className="flex justify-between gap-2 overflow-x-auto overflow-y-hidden">
              <div className={`flex ${profile.type==='BUYER' ? "min-w-[30rem]" : "min-w-[15rem]"}`}>
                <img 
                  src= {
                    orderHistoryImages[row.id] ? 
                    orderHistoryImages[row.id] : 
                    Img
                  }
                  alt="order" 
                  className="w-16 h-16 rounded" 
                />
                <div className={`text-left min-w-[8rem] truncate ${profile.type==='BUYER' ? "md:min-w-[10rem] w-[25rem]" : "w-[15rem]"} mx-2`}> 
                  <p className="font-medium px-3 py-2 truncate">{row.name}</p>
                  <p className="text-[#898989] font-semibold pb-2 px-3 text-xs truncate">
                    {row.quantity} Unit
                  </p>
                </div>
              </div>
              
              <div className={`flex items-start justify-between gap-5 text-sm ${profile.type==='BUYER' ? "min-w-[5rem] md:min-w-[15rem]" : "min-w-[10rem]"} mr-5 pr-5`}>
                  <div>
                    <p className="text-[#898989] font-semibold">Order Id</p>
                    <p className={`font-semibold ${profile.type==='BUYER' ? 'pointer-events-none' : 'cursor-pointer hover:text-main'}`} onClick={()=> handleMoreInfo(row)}>{row.orderNumber}</p>
                  </div>
                  <div className="whitespace-nowrap">
                    <p className="text-[#898989] font-semibold">Date Placed</p>
                    <p className="font-semibold">{formatDate(row.date)}</p>
                  </div>
                  <div>
                    <p className="text-[#898989] font-semibold">Status</p>
                    <p className="font-semibold w-6">{row.deliveryStatus}</p>
                  </div>
              </div>
              <div className="text-right min-w-[5rem] mx-2">
              <p className="text-[#898989]  font-semibold pb-1 whitespace-nowrap">Total Amount</p>
                <p className='text-main pb-2 text-xl font-semibold'>&#8358; {row.amount}</p>
              </div>
              <div className={`flex gap-2 justify-between sm:gap-[1rem] ${profile.type==='BUYER' && 'hidden'}`}>              
              {/* <div className={profile.type==='BUYER' && 'hidden'}> */}
                <Select
                  onChange={updateStatus}
                  isDisabled={row.deliveryStatus==='Delivered' || row.deliveryStatus==='Reject'}
                  styles={selectStyles}
                  options={ handleDeliveryStatus(row.deliveryStatus) }
                  menuPosition="fixed"
                />
              {/* </div>
              <div className={profile.type==='BUYER' && 'hidden'}> */}
                <button 
                  type="submit"
                  disabled={row.deliveryStatus==='Delivered' || row.deliveryStatus==='Reject'}
                  className={`w-[10rem] rounded-[10px] h-12 font-medium text-white max-h-[4rem]  ${(row.deliveryStatus==='Delivered' || row.deliveryStatus==='Reject') ? 'bg-slate-300' : 'bg-main'}`}
                  onClick={()=>handleStatusSubmit(row.id)}
                  >Update</button>
              {/* </div> */}
            </div>
            </div>
          </td>
        </React.Fragment>
      )
      }),
    className: "grid grid-cols[1fr] items-start",
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <>
      <Modal
        isOpen={selectedOrder && isMoreInfoModalOpen }
        onClose={() => { 
          setIsMoreInfoModalOpen(false)
          setSelectedOrder(null) 
        }}
        title="More Info"
      >
        <MoreInfo 
          data={selectedOrder}
          type = "SELL"
          sellImage = {
            orderHistoryImages[selectedOrder?.id] ?
            orderHistoryImages[selectedOrder?.id] : Img
          }
        />
      </Modal>
      <Layout>
        <main className="py-[110px]">
          <div className="mb-[30px] flex flex-col sm:flex-row items-center justify-between gap-3 px-[10px] sm:px-[40px]">
            <h1 className="text-[24px] font-semibold">Order History Sell</h1>
            <SearchForm setSearchValue={setSearchValue} onSubmit={values => handleSearchSubmit(values)} setUpdateList={setUpdateList} />
          </div>
          {orderHistorySell?.data?.length > 0 ? (
            <Table
              data={orderHistorySell?.data}
              total={orderHistorySell?.total}
              setFirstIndex={setFirstItemIndex}
              onUpdateList={value => setUpdateList(value)}
              tableData={tableData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            <h2 className="px-[40px]">No Order History found</h2>
          )}
        </main>
      </Layout>
    </>
  );
};

export default authCheck(OrderHistorySell);
