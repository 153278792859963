import React from "react";
import appleStoreIcon from "../../assets/ios.svg";
import googleIcon from "../../assets/google-play.svg";
import mobileAppImage from "../../assets/landing screen/mobile-app-img.svg"
function SubFooter() {
  return (
    <main className="mt-24 mb-24 px-5 sm:px-20">
      <div className="flex flex-col sm:flex-row items-center justify-between gap-8">
        <div className="flex flex-col gap-6 max-w-md">
          <p className="font-circular-500 text-3xl sm:text-5xl">Download MFarm mobile app</p>
          <p className="font-circular-450 text-base sm:text-xl text-[#6B7280]">
            MFarm is a Nigerian app for farmers, connecting them to a
            marketplace for agricultural products.
          </p>
          <div className="flex gap-3 mt-6 justify-evenly sm:justify-normal">
              <img 
                src={googleIcon} 
                className="rounded-[10px] cursor-pointer"
                onClick={() => {}} 
                alt="playStore" />
              <img
                src={appleStoreIcon}
                className="rounded-[10px] cursor-pointer"
                onClick={() => {}}
                alt="appleStore" />            
          </div>
        </div>
        <div>
            <img src={mobileAppImage} alt="mobile-app" />
        </div>
      </div>
    </main>
  );
}

export default SubFooter;
