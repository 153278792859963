import * as actionTypes from '../actions/types';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
    landBookedDays: null,
    lorryBookedDays: null,
    landOrder: null,
    lorryOrder: null,
    order: null,
    landTotalPrice: null,
    totalPrice: null,
    shippingDetails: null,
    termsAndConditions: null,
};

// Get Land Booked Days
const getLandBookedDaysStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getLandBookedDaysSuccess = (state, action) => {
    return updateObject(state, {
        landBookedDays: action.landBookedDays,
        error: null,
        loading: false,
    });
};

const getLandBookedDaysFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        landBookedDays: null,
    });
};

// Make Land Order
const makeLandOrderStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const makeLandOrderSuccess = (state, action) => {
    return updateObject(state, {
        landOrder: action.landOrder,
        error: null,
        loading: false,
    });
};

const makeLandOrderFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        landOrder: null,
    });
};

// Get Land Total Price
const getLandTotalPriceStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getLandTotalPriceSuccess = (state, action) => {
    return updateObject(state, {
        landTotalPrice: action.landTotalPrice,
        error: null,
        loading: false,
    });
};

const getLandTotalPriceFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        landTotalPrice: null,
    });
};

// Get Lorry Booked Days
const getLorryBookedDaysStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getLorryBookedDaysSuccess = (state, action) => {
    return updateObject(state, {
        lorryBookedDays: action.lorryBookedDays,
        error: null,
        loading: false,
    });
};

const getLorryBookedDaysFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        lorryBookedDays: null,
    });
};

// Make Lorry Order
const makeLorryOrderStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const makeLorryOrderSuccess = (state, action) => {
    return updateObject(state, {
        lorryOrder: action.lorryOrder,
        error: null,
        loading: false,
    });
};

const makeLorryOrderFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        lorryOrder: null,
    });
};

// Make Product Order
const makeProductOrderStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const makeProductOrderSuccess = (state, action) => {
    return updateObject(state, {
        order: action.order,
        error: null,
        loading: false,
    });
};

const makeProductOrderFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        order: null,
    });
};

// Get Lorry Total Price
const getLorryTotalPriceStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getLorryTotalPriceSuccess = (state, action) => {
    return updateObject(state, {
        lorryTotalPrice: action.lorryTotalPrice,
        error: null,
        loading: false,
    });
};

const getLorryTotalPriceFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        lorryTotalPrice: null,
    });
};

// Get Lorry Total Price
const getShippingDetailsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getShippingDetailsSuccess = (state, action) => {
    return updateObject(state, {
        shippingDetails: action.shippingDetails,
        error: null,
        loading: false,
    });
};

const getShippingDetailsFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        shippingDetails: null,
    });
};

// Set terms and conditions in store
const setTermsAndConditionsStart = (state) => {
    return updateObject(state, {
        loading: true,
    });
}

const setTermsAndConditionsSuccess =(state, action) => {
    return updateObject(state, {
        termsAndConditions: action.payload,
        loading: false,
    });
}

const setTermsAndConditionsFailed = (state, action) => {
    return updateObject(state, {
        error: action.payload,
        loading: false,
    })
}

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        // Get Land Booked Days
        case actionTypes.GET_LAND_BOOKED_DAYS_START:
            return getLandBookedDaysStart(state, action);
        case actionTypes.GET_LAND_BOOKED_DAYS_SUCCESS:
            return getLandBookedDaysSuccess(state, action);
        case actionTypes.GET_LAND_BOOKED_DAYS_FAIL:
            return getLandBookedDaysFail(state, action);

        // Make Land Order
        case actionTypes.MAKE_LAND_ORDER_START:
            return makeLandOrderStart(state, action);
        case actionTypes.MAKE_LAND_ORDER_SUCCESS:
            return makeLandOrderSuccess(state, action);
        case actionTypes.MAKE_LAND_ORDER_FAIL:
            return makeLandOrderFail(state, action);

        // Get Land Total Price
        case actionTypes.GET_LAND_TOTAL_PRICE_START:
            return getLandTotalPriceStart(state, action);
        case actionTypes.GET_LAND_TOTAL_PRICE_SUCCESS:
            return getLandTotalPriceSuccess(state, action);
        case actionTypes.GET_LAND_TOTAL_PRICE_FAIL:
            return getLandTotalPriceFail(state, action);

        // Get Lorry Booked Days
        case actionTypes.GET_LORRY_BOOKED_DAYS_START:
            return getLorryBookedDaysStart(state, action);
        case actionTypes.GET_LORRY_BOOKED_DAYS_SUCCESS:
            return getLorryBookedDaysSuccess(state, action);
        case actionTypes.GET_LORRY_BOOKED_DAYS_FAIL:
            return getLorryBookedDaysFail(state, action);

        // Make Lorry Order
        case actionTypes.MAKE_LORRY_ORDER_START:
            return makeLorryOrderStart(state, action);
        case actionTypes.MAKE_LORRY_ORDER_SUCCESS:
            return makeLorryOrderSuccess(state, action);
        case actionTypes.MAKE_LORRY_ORDER_FAIL:
            return makeLorryOrderFail(state, action);

        // Make Product Order
        case actionTypes.MAKE_PRODUCT_ORDER_START:
            return makeProductOrderStart(state, action);
        case actionTypes.MAKE_PRODUCT_ORDER_SUCCESS:
            return makeProductOrderSuccess(state, action);
        case actionTypes.MAKE_PRODUCT_ORDER_FAIL:
            return makeProductOrderFail(state, action);

        // Get Lorry Total Price
        case actionTypes.GET_LORRY_TOTAL_PRICE_START:
            return getLorryTotalPriceStart(state, action);
        case actionTypes.GET_LORRY_TOTAL_PRICE_SUCCESS:
            return getLorryTotalPriceSuccess(state, action);
        case actionTypes.GET_LORRY_TOTAL_PRICE_FAIL:
            return getLorryTotalPriceFail(state, action);

        // Get Lorry Total Price
        case actionTypes.GET_SHIPPING_DETAILS_START:
            return getShippingDetailsStart(state, action);
        case actionTypes.GET_SHIPPING_DETAILS_SUCCESS:
            return getShippingDetailsSuccess(state, action);
        case actionTypes.GET_SHIPPING_DETAILS_FAIL:
            return getShippingDetailsFailed(state, action);

        // Set Terms and conditions
        case actionTypes.SET_TERMS_AND_CONDITIONS_START:
            return setTermsAndConditionsStart(state);
        case actionTypes.SET_TERMS_AND_CONDITIONS_SUCCESS:
            return setTermsAndConditionsSuccess(state, action);
        case actionTypes.SET_TERMS_AND_CONDITIONS_FAILED:
            return setTermsAndConditionsFailed(state, action);

        default:
            return state;
    }
};

export default orderReducer;
