import React from "react";
import RentCard from "../../../../components/RentCard";
import { sliderSettings } from "../../../../shared/constant";
import Slider from "react-slick";

function RentCardContainer({ visibleCards, sliderRef, type}) {

  return (
     visibleCards.length > 0 &&  
    <div>
      <Slider {...sliderSettings} ref={sliderRef}>
        {visibleCards?.map((rent) => (
          <div className="px-2">
            <RentCard key={rent.id} rentItem={rent} type={type} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default RentCardContainer;
