import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom/cjs/react-router-dom";
import jwtDecode from "jwt-decode";

import "./Auth.scss";
import Logo from "../../assets/LogoLogin.svg";
import { getProfile } from "../../store/actions/profile";
import ButtonSpinner from "../../components/ButtonSpinner";
import { logout } from "../../store/actions/auth";
import { getSubscriptionDetails, setUserSubscription } from "../../store/actions/subscription";

const Subscription = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [subscriptionAmount, setSubscriptionAmount] = useState(null);
  const [userType, setUserType] = useState(null);
  const [showError, setShowError] = useState(false);

  const { profile, isSubscribed, subscription, loading } = useSelector((state) => ({
    profile: state?.profile?.profile,
    isSubscribed: state?.subscription.isSubscribed ,
    subscription: state?.subscription,
    loading: state?.subscription?.loading,
  }));

  const handleBackButton = async() => {
    await dispatch(logout());
    history.push(`${process.env.PUBLIC_URL}/`)
  }

  async function getSubscriptionDetailsCall() {
    await dispatch(getSubscriptionDetails(userType))
  }

 // fetch one time payment charges and user subscription status
  useEffect(()=>{
    try{
      const token = localStorage.getItem("m-farm-user-token");
      if(token){
        const jwtDecoded = jwtDecode(token);
        if(jwtDecoded){
          setUserType(jwtDecoded.aud);
        }
      }
    }catch{
      throw new Error("Error while decoding JWT token!")
    }    
  },[])

  useEffect(()=>{
      if(userType){
        getSubscriptionDetailsCall();
      }
    }, [userType])

  useEffect(() => {
    if(!profile) {
      dispatch(getProfile());
    }
  }, [dispatch]);

  // check is user is subscibed then redirect to next route
  useEffect(()=>{
    if(isSubscribed){
      if (profile.type === "SELLER" || userType === "SELLER") {
        history.push(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        history.push(`${process.env.PUBLIC_URL}/`);
      }
    }
    
    if(subscription?.subscriptionDetails) setSubscriptionAmount(subscription?.subscriptionDetails);

  },[isSubscribed, subscription])

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <div className="min-h-screen bg-cover bg-center flex flex-col items-center justify-center auth-bg-image">
      <Link to={process.env.PUBLIC_URL}>
        <img className="mx-auto mb-[40px]" src={Logo} alt="Logo" />
      </Link>
      <div className="w-[90%] max-w-md py-[50px] sm:px-[45px] px-[10px] bg-white p-8 rounded-[10px] shadow">
        <h2 className="font-semibold text-[24px] text-center">
          Welcome to mFarm <br />
          Please subscribe to the service with  &#8358; {subscriptionAmount} payment via MTN MOMO  
        </h2>
        <Formik
          initialValues={{ msisdn: "", password: "" }}
          onSubmit={async (values, { setErrors, resetForm }) => {
            setShowError(false)
            // api call for set subscriptions confirm
            const res = await dispatch(setUserSubscription(userType));

            // check if API does not responsed and set showError tr
            dispatch(getProfile())
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div
                role="group"
                aria-labelledby="type"
                className={`grid gap-4 items-center my-[30px]`}
              >

              </div>
              <div className="flex justify-center items-center gap-3">
                <button
                  onClick={handleBackButton}
                  className="rounded-[10px] w-[20rem] h-12 uppercase text-[#969696] focus:outline-none flex items-center justify-center"
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className={`flex items-center justify-center gap-2 rounded-[10px] bg-main w-[20rem] h-12 uppercase text-white focus:outline-none ${loading ? "opacity-60": "opacity-100"}`}
                >
                  {loading && <ButtonSpinner />}
                  Subscribe
                </button>
              </div>
              {showError && 
                <div className="text-xs text-[#FF0000] text-center pt-3">
                  <p>Error Occured while Subscribing user, please try again later! </p>
                </div>
              }
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Subscription;
