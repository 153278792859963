import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authCheck from "../../shared/authCheck";

import Layout from "../../components/Layout";
import Modal from "../../components/Modal/Modal";

import EditIcon from "../../assets/edit.svg";
import DeleteIcon from "../../assets/delete.svg";

import ImagePlaceholderIcon from "../../assets/image-placeholder.png";
import Pagination from "../../components/Pagination";
import RentRequestLorriesForm from "../../components/Forms/RentRequestLorriesForm";
import {
  createRentLorriesRequest,
  createRentLorryRequestImage,
  deleteRentLorriesRequest,
  deleteRentLorryRequestImage,
  editRentLorriesRequest,
  getRentLorriesRequests,
  getRentLorriesRequestsFailed,
  getRentRequestImage,
} from "../../store/actions/rentLorriesRequest";
import SearchForm from "../../components/Forms/SearchForm";

const TableRow = ({
  rentRequest,
  handleEditSellRequest,
  handleDeleteSellRequest,
  lorryImages,
  setLorryImages
}) => {
  const { name, location, description, price, imageIdList, status, pricePeriod, horsePower, yearOfManufacture, brandName, rejectReason } =
    rentRequest;

  const fetchImage = async () => {
    const res = await getRentRequestImage(imageIdList[0]);
    setLorryImages(prevState =>({...prevState, [imageIdList[0]]: res}))
  };

  useEffect(() => {
    if (imageIdList?.length > 0 && lorryImages[imageIdList[0]] === undefined) {
      fetchImage();
    }
  }, [imageIdList]);

  return (
    <div className="grid grid-cols-[150px_25rem_5rem_100px] md:grid-cols-[150px_6fr_1fr_100px] gap-x-[25px] p-[20px] items-center">
      {lorryImages[imageIdList[0]] ? (
        <div
          className="w-[150px] h-[150px] rounded-[10px] block cursor-pointer"
          style={{
            backgroundImage: `url(data:image/jpeg;base64,${lorryImages[imageIdList[0]]})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      ) : (
        <img src={ImagePlaceholderIcon} alt="" />
      )}

      <div className="flex justify-center items-start flex-col overflow-hidden gap-2">
        <p className="text-[22px] font-semibold  w-full truncate">{name}</p>
        <p className="text-main w-full truncate mt-[6px]">{price} / per {pricePeriod}</p>
        <div className="flex sm:flex-row flex-col mt-[6px] gap-1 w-full truncate">
          <b className="text-[#000]">Brand:</b>
          <p className="text-[#565656] w-full truncate"> {brandName} </p>
        </div>
        <div className="flex sm:flex-row flex-col mt-[6px] gap-1 w-full truncate">
          <b className="text-[#000]">HorsePower:</b>
          <p className="text-[#565656] w-full truncate"> {horsePower} hp</p>
        </div>
        <div className="flex sm:flex-row flex-col mt-[6px] gap-1 w-full truncate">
          <b className="text-[#000]">MFG Year:</b>
          <p className="text-[#565656] w-full truncate"> {yearOfManufacture}</p>
        </div>
        <div className="flex sm:flex-row flex-col mt-[6px] gap-1 w-full truncate">
          <b className="text-[#000]">Location:</b>
          <p className="text-[#565656] w-full truncate"> {location}</p>
        </div>
      </div>
      <div className="relative group">
        <div className={`flex items-center absolute p-2 -top-[0] right-[104px] text-center text-[#FF0000] bg-white w-[30vw] h-fit rounded shadow-lg sibling-element opacity-0 invisible transition duration-300 ${ rejectReason ? "group-hover:opacity-100 group-hover:visible" : ""}`}>
          <p>
            {rejectReason}
          </p>
        </div>
        <p
        className={`text-center py-3 
                            ${status === "PENDING" && "text-[#285C9A]"} 
                            ${status === "REJECTED" && "text-[#FF0000] cursor-pointer"}
                            ${status === "APPROVED" && "text-main"}`}
      >
        {status}
      </p>
      </div>
      <div className="flex items-center flex-col">
        <button
          type="button"
          onClick={() => handleEditSellRequest(rentRequest)}
          className="rounded-[5px] bg-[#285C9A] p-[8px]"
        >
          <img src={EditIcon} alt="edit" />
        </button>
        <button
          type="button"
          onClick={handleDeleteSellRequest}
          className="rounded-[5px] bg-[#FF0000] p-[8px] mt-[22px]"
        >
          <img src={DeleteIcon} alt="delete" />
        </button>
      </div>
    </div>
  );
};

const RentRequestsLorriesPage = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateList, setUpdateList] = useState(true);
  const [searchValue, setSearchValue] = useState(null);
  const [rentRequest, setRentRequest] = useState();
  const [loading, setLoading] = useState(false);
  const [lorryImages, setLorryImages] = useState({});

  const dispatch = useDispatch();

  const { rentLorriesRequests } = useSelector((state) => ({
    rentLorriesRequests: state?.rentLorriesRequests?.rentLorriesRequests,
  }));

  const itemsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(rentLorriesRequests?.total / itemsPerPage);

  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setUpdateList(true);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (updateList) {
      if(searchValue){
        dispatch(getRentLorriesRequests(firstItemIndex, 5, searchValue));
      }else{
        dispatch(getRentLorriesRequests(firstItemIndex, 5));
      }
      setUpdateList(false);
    }
  }, [dispatch, firstItemIndex, updateList]);

  const handleEditRentRequest = (rentRequest) => {
    setRentRequest(rentRequest);
    setIsEditModalOpen(true);
  };

  const handleDeleteSellRequest = (id) => {
    const [rentRequest] = rentLorriesRequests?.data?.filter(
      (rentRequest) => rentRequest.id === id
    );
    setRentRequest(rentRequest);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteSubmit = async () => {
    await dispatch(deleteRentLorriesRequest(rentRequest.id));
    setUpdateList(true);
    setIsDeleteModalOpen(false);
  };

  const handleCreateLorrySubmit = async (values) => {
    setLoading(true)
    const res = await dispatch(createRentLorriesRequest(values));
    if (res.type === "CREATE_RENT_LORRY_REQUEST_SUCCESS") {
      setUpdateList(true);
      setIsAddModalOpen(false);
      setLoading(false)
    } else if (res.type === "CREATE_RENT_LORRY_REQUEST_FAIL") {
      setTimeout(() => {
        dispatch(getRentLorriesRequestsFailed(null));
        setUpdateList(true);
        setIsAddModalOpen(false);
        setLoading(false)
      }, 5000);
    }
  };

  const handleEditLorrySubmit = async (values) => {
    setLoading(true)
    const initialIdArray = rentRequest?.imageIdList;
    const changedArray = values?.imageBase64;

    const deleteArray = initialIdArray.filter(
      (id) => !changedArray.some((item) => item.id === id)
    );

    const addArray = changedArray.filter((item) => !item.id);

    const res = await dispatch(editRentLorriesRequest(rentRequest.id, values));

    // An array to hold all the deletion and addition promises
    const promises = [];

    deleteArray.forEach(async (id) => {
      promises.push(dispatch(deleteRentLorryRequestImage(id)));
    });

    addArray.forEach(async (addItem) => {
      promises.push(
        dispatch(createRentLorryRequestImage(rentRequest?.id, addItem.base64))
      );
    });

    // Wait for all the promises to resolve
    await Promise.all(promises);
    if (res.type === "EDIT_RENT_LORRY_REQUEST_SUCCESS") {
      setUpdateList(true);
      setIsEditModalOpen(false);
      setRentRequest(null);
      setLoading(false);
    } else if (res.type === "EDIT_RENT_LORRY_REQUEST_FAIL") {
      setTimeout(() => {
        dispatch(getRentLorriesRequestsFailed(null));
        setUpdateList(true);
        setIsEditModalOpen(false);
        setRentRequest(null);
        setLoading(false);
      }, 5000);
    }
  };

  const handleSearchSubmit = values => {
    setCurrentPage(1)
  }

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <>
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        title="Create Rent Lorry Request"
      >
        <RentRequestLorriesForm
          onSubmit={handleCreateLorrySubmit}
          onClose={() => {
            setIsAddModalOpen(false);
          }}
          loading = {loading}
        />
      </Modal>
      <Modal
        isOpen={isEditModalOpen && rentRequest}
        onClose={() => {
          setIsEditModalOpen(false);
          setRentRequest(null);
        }}
        title="Edit Rent Lorry Request"
      >
        <RentRequestLorriesForm
          rentRequest={rentRequest}
          onSubmit={(rentRequest) => handleEditLorrySubmit(rentRequest)}
          onClose={() => {
            setIsEditModalOpen(false);
            setRentRequest(null);
          }}
          loading = {loading}
        />
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen && rentRequest}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setRentRequest(null);
        }}
        title={`Are you sure you want to delete this Rent Request?`}
      >
        <div className="flex justify-evenly sm:flex-row flex-col gap-1">
          <button
            type="button"
            onClick={handleDeleteSubmit}
            className="rounded-[10px] bg-[#ff0000] px-[66px] py-[12px] font-medium text-white"
          >
            Delete
          </button>
          <button
            type="button"
            onClick={() => {
              setIsDeleteModalOpen(false);
              setRentRequest(null);
            }}
            className="rounded-[10px] px-[66px] py-[12px] text-[#969696]"
          >
            Cancel
          </button>
        </div>
      </Modal>
      <Layout>
        <main className="py-[110px]">
          <div className="flex justify-between items-center sm:px-[40px] px-[10px] pb-[30px] flex-col sm:flex-row">
            <div className="flex justify-between items-center gap-2  bb-[30px]">
              <h1 className="text-[24px] font-semibold">Rent Request Lorries</h1>
              <button
                type="button"
                onClick={() => {
                  setIsAddModalOpen(true);
                }}
                className="rounded-[10px] bg-main w-[8.6rem] h-[3.2rem] text-white uppercase whitespace-nowrap block sm:hidden"
              >
                + Create
              </button>
            </div>
            <div className="flex items-center gap-3">
            <div className="mt-4">
                <SearchForm setSearchValue={setSearchValue} onSubmit={values => handleSearchSubmit(values)} setUpdateList={setUpdateList} />
              </div>
              <button
                type="button"
                onClick={() => {
                  setIsAddModalOpen(true);
                }}
                className="rounded-[10px] bg-main w-[8.6rem] h-[3.2rem] text-white uppercase whitespace-nowrap hidden sm:block"
              >
                + Create
              </button>
            </div>
          </div>
          {rentLorriesRequests &&
          rentLorriesRequests?.data &&
          rentLorriesRequests?.data?.length > 0 ? (
            <div className="px-[40px]">
              <div className=" bg-white shadow-table rounded-[10px] overflow-auto">
                {rentLorriesRequests?.data?.map((rentRequestItem, idx) => {
                  return (
                    <>
                      {idx !== 0 && <hr />}
                      <TableRow
                        key={rentRequestItem?.id}
                        rentRequest={rentRequestItem}
                        handleEditSellRequest={(rentRequest) =>
                          handleEditRentRequest(rentRequest)
                        }
                        handleDeleteSellRequest={() =>
                          handleDeleteSellRequest(rentRequestItem?.id)
                        }
                        setLorryImages={setLorryImages}
                        lorryImages={lorryImages}
                      />
                    </>
                  );
                })}
              </div>
            </div>
          ) : (
            <h2 className="px-[40px]">No Rent Requests found</h2>
          )}
          {rentLorriesRequests?.data && rentLorriesRequests?.data?.length > 0 ? (
            <div className={`mb-2 mt-[20px] px-[40px] flex justify-between ${rentLorriesRequests?.total && rentLorriesRequests?.total <= 0 && 'hidden'}`}>
            <span className="text-sm text-[#A6A6A6] ml-5">
              {firstItemIndex + 1} -{' '}
              {lastItemIndex + 1 > rentLorriesRequests?.total ? rentLorriesRequests?.total : lastItemIndex} of {rentLorriesRequests?.total}
            </span>
            <div className="flex justify-end mr-5">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                startIndex={firstItemIndex}
                lastIndex={lastItemIndex}
                onPageChange={handlePageChange}
              />
            </div>
            </div>
          ) : null}
        </main>
      </Layout>
    </>
  );
};

export default authCheck(RentRequestsLorriesPage);
