import React from "react";
import tractor from "../../../assets/landing screen/tractor.png";
import tickMark from "../../../assets/landing screen/tick-mark.svg";

const SellerRightsContent = () => {
  return (
    <div className="flex flex-col sm:flex-row gap-16">
      <img src={tractor} alt="tractor" className="flex-1 w-[1115.022px] rounded-3xl object-cover" />
      <div className="flex flex-col gap-12 items-center justify-center">
        {
            list.map(( item, index) => (
                <div className="flex gap-4">
                    <img src={item.icon} alt="tick" className="w-12 h-12" />
                    <div className="flex flex-col gap-2"> 
                        <p className="font-circular-500 text-xl text-[#212121]">{item.title}</p>
                        <p className="font-circular-450 text-base text-[#6B7280]">{item.description}</p>
                    </div>
                </div>
            ))
        }
      </div>
    </div>
  );
};

const list = [
  {
    icon: tickMark,
    title: "Access wide range of market",
    description:
      "Selling on our platform, you can reach consumers, businesses, and markets that you may not have been able to access otherwise.",
  },
  {
    icon: tickMark,
    title: "Fair Pricing",
    description:
      "We are committed to ensuring that farmers receive a fair and competitive price for their agricultural produce.",
  },
  {
    icon: tickMark,
    title: "Transparent Prices",
    description:
      "We clearly mark the prices for farm produce, both for the benefit of farmers and customers.",
  },
];
export default SellerRightsContent;
