import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';

import ShowPassIcon from "../assets/show-pass.svg"
import HidePassIcon from "../assets/hide-pass.svg"

const ProfilePageInput = ({ label, name, placeholder, type = "text", hideEdit, error, onCancel, disabled, setDisabled }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <label htmlFor={name} className="text-[#848484] mb-[8px]">
                {label}
            </label>
            <div className={`flex items-center justify-between border-b ${error ? "border-red-500" : "border-[#E7E7E7]"} ${disabled ? "bg-transparent" : "bg-[#F5F5F5]"} pb-[16px]`}>
                {type === "password" ? (
                    <div className="relative w-full">
                        <Field
                            type={showPassword ? "text" : "password"}
                            id={name}
                            name={name}
                            placeholder={placeholder}
                            className="w-full outline-none bg-transparent text-[20px] font-semibold"
                            disabled={disabled}
                        />
                        <button
                            type="button"
                            onClick={handleTogglePassword}
                            className="absolute top-1/2 right-4 transform -translate-y-1/2"
                        >
                            {showPassword ? (
                                <img src={ShowPassIcon} alt="Hide" />
                            ) : (
                                <img src={HidePassIcon} alt="Show" />
                            )}
                        </button>
                    </div>
                ) : (
                    <>
                        <Field
                            type={type}
                            id={name}
                            name={name}
                            placeholder={placeholder}
                            className="w-full outline-none bg-transparent text-[20px] font-semibold"
                            disabled={disabled}
                        />
                        {!hideEdit && disabled && (
                            <button
                                type="button"
                                onClick={() => {
                                    setDisabled(false);
                                }}
                                className="ml-2 text-main"
                            >
                                Edit
                            </button>
                        )}
                        {!hideEdit && !disabled && (
                            <>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setDisabled(true);
                                        onCancel();
                                    }}
                                    className="ml-2 text-main"
                                >
                                    Cancel
                                </button>
                            </>
                        )}
                    </>
                )}
            </div>
            <ErrorMessage name={name} component="div" className="text-[#FF0000] mt-1" />
        </div>
    );
};

export default ProfilePageInput;
