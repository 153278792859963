import React, { useEffect, useRef, useState } from "react";
import AboutCard from "../AboutCard";
import Cards from "./Cards";
import { useDispatch, useSelector } from "react-redux";
import { getHomeSellRequests } from "../../../../store/actions/homeRentSell";
import ArrowButtonContainer from "./ArrowButtonContainer";

function ProductCardContainer(props) {
  const { cardData, setStatusMessage, setShowAddToCartSuccessText  } = props;

  const [visibleCards, setVisibleCards] = useState(5);
  const [totalCards, setTotalCards] = useState(null);
  const sliderRef = useRef(null);

  const { sellRequests } = useSelector((state) => ({
    sellRequests: state?.homeRentSell?.sellRequests,
  }));

  const dispatch = useDispatch();

  function getSellRequest (start, length) {
    dispatch(getHomeSellRequests(start, length))
  }

  const handleLeftArrowButton = () => {
    sliderRef.current.slickPrev()
  };

  const handleRightArrowButton = () => {
    sliderRef.current.slickNext()

    if(sellRequests?.data?.length + 5 <= totalCards ){
      getSellRequest(sellRequests.data.length, sellRequests.data.length + 5)
    }
  };

  useEffect(()=>{
    if(sellRequests?.total) setTotalCards(sellRequests?.total)
    if(sellRequests?.data) setVisibleCards(sellRequests.data)

  },[sellRequests]);

  useEffect(() => {
    getSellRequest(0,10);
  }, []);

  return (
    <section className="py-24">
      <AboutCard cardData={cardData} />
      <Cards visibleCards={visibleCards} sliderRef={sliderRef}  setStatusMessage={setStatusMessage} setShowAddToCartSuccessText={setShowAddToCartSuccessText}/>
      <ArrowButtonContainer
        handleLeft={handleLeftArrowButton}
        handleRight={handleRightArrowButton}
      />
    </section>
  );
}

export default ProductCardContainer;
