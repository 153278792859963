import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import store from "./storage";
import { authCheckState } from "./store/actions/auth";


/* think about place to move */
// eslint-disable-next-line no-extend-native
/* String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

// eslint-disable-next-line no-extend-native
String.prototype.uncapitalize = function () {
  return this.charAt(0).toLowerCase() + this.slice(1);
}

if (localStorage.getItem('access_token')) {
  store.dispatch(authCheckState());
} */

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));