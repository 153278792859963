import React from "react";
import registrationMockScreen from "../../../../assets/landing screen/registration-mock-screen.png";

const Steps = () => {
  return (
    <div className="flex items-start flex-col sm:flex-row gap-16 px-8">
      <div className="flex flex-1 flex-col gap-12">
        {list1.map((item, index) => (
          <div className="flex flex-col gap-5" key={index}>
            <div className="w-14 py-[15px] px-[14px] border border-solid border-1 border-gray-300 rounded-[10px]">
              <span className="font-circular-500 text-xl text-[#344054]">
                {item.number}
              </span>
            </div>
            <div className="flex flex-col">
              <p className="font-circular-500 text-xl text-[#212121]">
                {item.title}
              </p>
              <p className="font-circular-450 text-base text-[#6B7280]]">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="inline-block">
        <img src={registrationMockScreen} alt="mock-screen-register" className="w-[450px] object-cover" />
      </div>
      <div className="flex flex-1 flex-col gap-12">
        {list2.map((item, index) => (
          <div className="flex flex-col gap-5" key={index}>
            <div className="w-14 py-[15px] px-[14px] border border-solid border-1 border-gray-300 rounded-[10px]">
              <p className="font-circular-500 text-xl text-[#344054]">
                {item.number}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-circular-500 text-xl text-[#212121]">
                {item.title}
              </p>
              <p className="font-circular-450 text-base text-[#6B7280]]">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const list1 = [
  {
    number: "01",
    title: "Registration",
    description:
      'Click on the "Register" or "Sign Up" button to begin the registration process.',
  },
  {
    number: "02",
    title: "Agree to Terms and Conditions",
    description:
      "Before proceeding, make sure to review and accept the mFarm terms and conditions.",
  },
];

const list2 = [
  {
    number: "03",
    title: "Log In",
    description:
      "Once your account is verified, go to the mFarm login page to log in.",
  },
  {
    number: "04",
    title: "Start Shopping",
    description:
      "Congratulations! You are now ready to explore the fresh produce available on mFarm. Simply browse, select your items, and enjoy the convenience of farm-fresh products delivered to your doorstep",
  },
];

export default Steps;
