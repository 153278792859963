import * as actionTypes from './types';
import axios from '../../axios-api';
import { getErrors, getResData } from '../../shared/utility';

// get diagnosis
export const getDiagnosisRequestStart = () => {
  return {
    type: actionTypes.GET_DIAGNOSIS_REQUEST_START,
  };
};

export const getDiagnosisRequestSuccess = (diagnosisRequests) => {
  return {
    type: actionTypes.GET_DIAGNOSIS_REQUEST_SUCCESS,
    diagnosisRequests,
  };
};

export const getDiagnosisRequestFailed = (error) => {
  return {
    type: actionTypes.GET_DIAGNOSIS_REQUEST_FAIL,
    error,
  };
};

export const getDiagnosisRequest = (start, length, question) => {
  return (dispatch) => {
    dispatch(getDiagnosisRequestStart());

    let url = '/user/diagnosis/request';

    const params = {};

    if (length !== undefined) {
      params.length = length;
    }

    if (question !== undefined) {
      params.question = question;
    }

    if (start !== undefined) {
      params.start = start;
    }

    if (Object.keys(params).length > 0) {
      url += '?' + new URLSearchParams(params).toString();
    }

    return axios
      .get(url)
      .then((response) => {
        if (!response) throw new Error('No Response');

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(getDiagnosisRequestSuccess(data));
      })
      .catch((err) =>
        dispatch(getDiagnosisRequestFailed(getErrors(err?.response?.data || err)))
      );
  };
};


const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

// get diagnosis Image
export const getDiagnosisImageRequestStart = () => {
  return {
    type: actionTypes.GET_DIAGNOSIS_IMAGE_REQUEST_START,
  };
};

export const getDiagnosisImageRequestSuccess = (diagnosisImage) => {
  return {
    type: actionTypes.GET_DIAGNOSIS_IMAGE_REQUEST_SUCCESS,
    diagnosisImage,
  };
};

export const getDiagnosisImageRequestFailed = (error) => {
  return {
    type: actionTypes.GET_DIAGNOSIS_IMAGE_REQUEST_FAIL,
    error,
  };
};

export const getDiagnosisImageRequest = (id) => {
    
    return axios
      .get(`/user/diagnosis/request/image/${id}`, {responseType: 'arraybuffer'})
      .then((response) => {
        if (!response) throw new Error('No Response');

        const data = getResData(response.data);
        const base64String = arrayBufferToBase64(response.data);

        if (!data) throw new Error(getErrors(data));

        return base64String;
      })
      .catch((err) => {}
     );
};

// Create Diagnosis Request 
export const createDiagnosisRequestStart = () => {
  return {
    type: actionTypes.CREATE_DIAGNOSIS_REQUEST_START,
  };
};

export const createDiagnosisRequestSuccess = (createRes) => {
  return {
    type: actionTypes.CREATE_DIAGNOSIS_REQUEST_SUCCESS,
    createRes,
  };
};

export const createDiagnosisRequestFailed = (error) => {
  return {
    type: actionTypes.CREATE_DIAGNOSIS_REQUEST_FAIL,
    error,
  };
};

export const createDiagnosisRequest = (val) => {
  return (dispatch) => {
    dispatch(createDiagnosisRequestStart());

    return axios
      .post(`/user/diagnosis/request`, val)
      .then((response) => {
        if (!response) throw new Error('No Response');

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(createDiagnosisRequestSuccess(data));
      })
      .catch((err) =>
        dispatch(createDiagnosisRequestFailed(getErrors(err?.response?.data || err)))
      );
  };
};

// updateImage
export const updateImageStart = () => {
    return {
        type: actionTypes.UPDATE_IMAGE_START,
    };
};

export const updateImageSuccess = (diagnosisImage) => {
    return {
        type: actionTypes.UPDATE_IMAGE_SUCCESS,
        diagnosisImage,
    };
};

export const updateImageFailed = (error) => {
    return {
        type: actionTypes.UPDATE_IMAGE_FAIL,
        error,
    };
};

export const updateImage = (data) => {
    return (dispatch) => {
        dispatch(updateImageStart());
        return dispatch(updateImageSuccess(data));
    };
};