import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikInput from "../FormikInput";
import UploadImage from "../UploadFileInput/UploadImage";
import Spinner from '../../shared/Spinner'

const validationSchema = Yup.object().shape({
  question: Yup.string()
                .required()
                .trim()
                .min(1, "Question must be between 1 and 400 chars")
                .max(400, "Question must be between 1 and 400 chars"),
  imageBase64: Yup.string()
                  .required("Image is required")
                  .test('fileSize', 'File size is too large, it should be less than 1mb', 
                        (value) => (((value.length * 3) / 4) <= (1024 * 1024))),
});

const DiagnosisForm = ({ onClose, tip, onSubmit, loading }) => {

  //   const { error } = useSelector((state) => ({
  //     error: state.categories.error,
  //   }));

  return (
    <div>
      <Formik
        initialValues={{
          question: "",
          imageBase64: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, handleBlur, values, setFieldValue }) => {
          return (
            <Form>
              <div className="mb-4 w-[98%]">
                <div className="rounded-[10px]">
                  <div className="mb-2">
                    <UploadImage
                      placeholder="Choose Image"
                      name="imageBase64"
                      onChange={imageBase64 => {
                        setFieldValue("imageBase64", imageBase64);
                      }}
                      variant="image"
                    />
                  </div>
                  <div className="mb-2">
                    <FormikInput
                      name="question"
                      type="textarea"
                      placeholder="Type Question here..."
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-evenly gap-3">
                <button
                  disabled ={loading}
                  type="submit"
                  className={`flex gap-1 justify-center items-center rounded-[10px] bg-main w-44 h-12 font-medium text-white ${loading ? "opacity-60" : "opacity-100"}`}
                >
                  {loading && <Spinner />}
                  Save
                </button>
                <button
                  type="button"
                  onClick={onClose}
                  className="rounded-[10px] px-[50px] py-[12px] text-[#969696]"
                >
                  Cancel
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DiagnosisForm;
