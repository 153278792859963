import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Logo from "../assets/green-logo.svg";
import ArrowDown from "../assets/arrow-down.svg";
import BellIcon from "../assets/navigation/bell.svg";
import CartIcon from "../assets/cartIcon.svg";
import MenuIcon from "../assets/navigation/menu.svg";
import CloseIcon from "../assets/close.svg";
import defaultProfileImg  from "../assets/default_profile_icon.png";

import { logout } from "../store/actions/auth";
import { getProfile, getProfileImage } from "../store/actions/profile";
import { useWindowWidth } from "../shared/hooks";
import { buyerLinks, notAuthorisedLinks, sellerLinks } from "../shared/links";

const Menu = ({ profile, onClose, onLogout, active }) => {
  return (
    <div className="fixed w-full min-h-screen bg-white inset-0 overflow-y-auto z-40">
      <button
        type="button"
        onClick={onClose}
        className="rounded-[10px] focus:outline-none relative left-[90%] top-[10px] mb-[18px]"
      >
        <img
          src={CloseIcon}
          alt="Close Menu"
          className={`w-8 h-8 transform transition-transform`}
        />
      </button>
      <div className="grid h-full-60px items-start content-start	">
        <div className="self-start">
          {!profile
            ? notAuthorisedLinks?.map((link, idx) => (
                <React.Fragment key={idx}>
                  {idx !== 0 && <hr />}
                  <Link
                    to={`${process.env.PUBLIC_URL}${link.path}`}
                    className={`block px-6 py-3 font-semibold ${
                      active === `${process.env.PUBLIC_URL}${link?.path}`
                        ? "text-white bg-main"
                        : "text-gray-800 hover:bg-gray-200"
                    }`}
                  >
                    {link.label}
                  </Link>
                </React.Fragment>
              ))
            : profile?.type === "BUYER"
            ? buyerLinks?.map((link, idx) => (
                <React.Fragment key={idx}>
                  {idx !== 0 && <hr />}
                  <Link
                    to={`${process.env.PUBLIC_URL}${link.path}`}
                    className={`block px-6 py-3 font-semibold ${
                      active === `${process.env.PUBLIC_URL}${link?.path}`
                        ? "text-white bg-main"
                        : "text-gray-800 hover:bg-gray-200"
                    }`}
                  >
                    {link.label}
                  </Link>
                </React.Fragment>
              ))
            : profile?.type === "SELLER"
            ? sellerLinks?.map((link, idx) => (
                <React.Fragment key={idx}>
                  {idx !== 0 && <hr />}
                  <Link
                    to={`${process.env.PUBLIC_URL}${link.path}`}
                    className={`block px-6 py-3 font-semibold ${
                      active === `${process.env.PUBLIC_URL}${link?.path}`
                        ? "text-white bg-main"
                        : "text-gray-800 hover:bg-gray-200"
                    }`}
                  >
                    {link.label}
                  </Link>
                </React.Fragment>
              ))
            : null}
        </div>
        {profile ? (
          <>
            <hr className="self-start" />
            <Link
              to={`${process.env.PUBLIC_URL}/profile`}
              className={`block px-6 py-3 font-semibold self-start ${
                active === `${process.env.PUBLIC_URL}/profile`
                  ? "text-white bg-main"
                  : "text-gray-800 hover:bg-gray-200"
              }`}
            >
              Profile
            </Link>
            {profile?.type === "BUYER" ? (
              <>
                <Link
                  to={`${process.env.PUBLIC_URL}/order-history-land`}
                  className={`block px-6 py-3 font-semibold self-start ${
                    active === `${process.env.PUBLIC_URL}/profile`
                      ? "text-white bg-main"
                      : "text-gray-800 hover:bg-gray-200"
                  }`}
                >
                  Order History Land
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/order-history-lorry`}
                  className={`block px-6 py-3 font-semibold self-start ${
                    active === `${process.env.PUBLIC_URL}/profile`
                      ? "text-white bg-main"
                      : "text-gray-800 hover:bg-gray-200"
                  }`}
                >
                  Order History Lorry
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/order-history-sell`}
                  className={`block px-6 py-3 font-semibold self-start ${
                    active === `${process.env.PUBLIC_URL}/profile`
                      ? "text-white bg-main"
                      : "text-gray-800 hover:bg-gray-200"
                  }`}
                >
                  Order History Sell
                </Link>
              </>
            ) : null}
            <hr className="self-start" />
            <div className="px-6 mt-5 pb-3">
              <button
                type="button"
                onClick={onLogout}
                className="w-full block px-[62px] py-[16px] text-white rounded-[10px] bg-main text-center"
              >
                Logout
              </button>
            </div>
          </>
        ) : (
          <div className="px-6 mt-5 pb-3">
            <Link
              to={`${process.env.PUBLIC_URL}/auth`}
              className="block px-[62px] py-[16px] text-white rounded-[10px] bg-main w-full text-center"
            >
              LOGIN
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const HeaderNotAuth = ({ profile, active, windowWidth }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className='fixed w-[100vw] flex items-center justify-center py-[10px] z-[2] bg-white'>
      <div className="container flex items-center justify-between mx-[40px]">
        <div className="flex items-center gap-10">
          <Link to={`${process.env.PUBLIC_URL}/`} className="flex items-end gap-[9.357px]">
            <img src={Logo} alt="Logo" className="w-6 h-6 m-auto" />
            <p className="font-circular-500 text-[#212121] text-2xl">mFarm</p>
          </Link>
          {windowWidth > 768 && (
            <div className="grid grid-flow-col gap-8">
              {notAuthorisedLinks?.map((link, idx) => {
                return (
                  <Link
                    key={idx}
                    to={`${process.env.PUBLIC_URL}${link?.path}`}
                    className={
                      active === `${process.env.PUBLIC_URL}${link?.path}`
                        ? "text-main border-b-[2px] border-main"
                        : ""
                    }
                  >
                    {link?.label}
                  </Link>
                );
              })}
            </div>
        )}
        </div>
        {windowWidth > 768 ? (
          <div className="flex items-center gap-4">
            <Link
              to={{
                pathname: `${process.env.PUBLIC_URL}/auth`,
                state: "login",
              }}
              className="font-circular-450 block px-4 py-[10px] text-base text-[#212121] rounded-lg bg-transparent"
            >
              Log in
            </Link>
            <Link
              to={{
                pathname: `${process.env.PUBLIC_URL}/auth`,
                state: "register",
              }}
              className="font-circular-450 block px-4 py-[10px] text-base text-white rounded-lg bg-[#3DB83A]"
            >
              Sign up
            </Link>
          </div>
        ) : (
          <>
            <button
              type="button"
              onClick={() => setMenuOpen(true)}
              className="flex items-center focus:outline-none"
            >
              <img
                src={MenuIcon}
                alt="Menu"
                className={`w-8 h-8 transform transition-transform`}
              />
            </button>
            {menuOpen && (
              <Menu
                profile={profile}
                active={active}
                onClose={() => setMenuOpen(false)}
              />
            )}
          </>
        )}
      </div>
    </header>
  );
};

const Header = ({ showLinks }) => {
  const windowWidth = useWindowWidth();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [cartCount, setCartCount] =  useState(0);

  const { profile, profileImage,CartDetails } = useSelector((state) => ({
    profile: state?.profile?.profile,
    profileImage: state?.profile?.profileImage,
    CartDetails: state?.cart?.CartDetails,
  }));

  useEffect(() => {
    if (!profile) {
      dispatch(getProfile());
    }
  }, [profile, dispatch]);

  useEffect(() => {
    if (profile?.hasImage) {
      dispatch(getProfileImage());
    }
  }, [profile, dispatch]);
  
  useEffect(() => {
      const productCount = CartDetails?.product.length;
      const landCount = CartDetails?.land.length;
      const lorryCount = CartDetails?.lorry.length;

      setCartCount((productCount + landCount + lorryCount))
  },[CartDetails])

  const handleLogout = async () => {
    await dispatch(logout());
    history.push(`${process.env.PUBLIC_URL}/`);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const openConfirmationModal = () => {
    setShowConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  const active = window?.location?.pathname;

  const showSellerMenu =
    profile?.type === "SELLER" && active === `${process.env.PUBLIC_URL}/`;

  return profile ? (
    <header className="fixed w-[100vw] flex items-center justify-between py-[10px] px-3 sm:px-[40px] z-[2] bg-white">
      <div>
        <Link to={`${process.env.PUBLIC_URL}/`}>
          <img src={Logo} alt="Logo" className="w-10 h-10" />
        </Link>
      </div>
      <div className="relative">
        <div className="flex items-center">
          {windowWidth > 768 && profile?.type === "BUYER" && (
            <div className="grid grid-flow-col gap-x-[53px] sm:mr-[67px]">
              {buyerLinks?.map((link) => {
                return (
                  <Link
                    to={`${process.env.PUBLIC_URL}${link?.path}`}
                    className={
                      active === `${process.env.PUBLIC_URL}${link?.path}`
                        ? "text-main border-b-[2px] border-main"
                        : " border-b-[2px] border-transparent"
                    }
                  >
                    {link?.label}
                  </Link>
                );
              })}
            </div>
          )}
          <Link
            to={`${process.env.PUBLIC_URL}/notifications`}
            className="bg-light-green rounded-[100%] w-[38px] h-[38px] relative flex items-center justify-center mr-3 sm:mr-[30px]"
          >
            <div className="grid">
              {profile?.unreadCount > 0 && (
                <div className="p-[1px]  absolute top-[-5px] right-[0px] rounded-[100%] bg-main text-[12px] font-semibold text-[white] justify-self-end min-w-[16px] h-[16px] text-center flex items-center justify-center mr-[-4px] mb-[-9px] z-[1]">
                  {profile?.unreadCount}
                </div>
              )}
              <img src={BellIcon} alt="" className={``} />
            </div>
          </Link>
          <Link
            to={`${process.env.PUBLIC_URL}/cart`}
            className={`bg-light-green rounded-[100%] min-w-[38px] min-h-[38px] relative flex items-center justify-center mr-3 sm:mr-[30px] ${
              profile?.type !== "BUYER" && "hidden"
            }`}
          >
            <div className="grid">
              <div className="p-[1px] absolute top-[-3px] right-[0px] rounded-[100%] bg-main text-[12px] font-semibold text-[white] justify-self-end min-w-[16px] h-[16px] text-center flex items-center justify-center z-[1]">
                {cartCount}
              </div>
              <img src={CartIcon} alt="" className={``} />
            </div>
          </Link>
          {profileImage ? (
            <div
              className="w-[38px] h-[38px] mr-1 sm:mr-[12px] rounded-[100%] border"
              style={{
                backgroundImage: `url(data:image/jpeg;base64,${profileImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
          ) : (
            <div className="w-[38px] h-[38px] mr-1 sm:mr-[12px] bg-[#aaaaaa] rounded-[100%]" >
              <img src={defaultProfileImg} />
            </div>
          )}
          <span className="mr-1 sm:mr-[16px] text-[18px]">
            {profile?.firstName} {profile?.lastName}
          </span>
          {windowWidth > 768 ? (
            <button
              type="button"
              onClick={toggleDropdown}
              className="flex items-center focus:outline-none"
            >
              <img
                src={ArrowDown}
                alt="Arrow Down"
                className={`w-4 h-4 transform transition-transform ${
                  showDropdown ? "rotate-180" : ""
                }`}
              />
            </button>
          ) : (
            <button
              type="button"
              onClick={() => setMenuOpen(true)}
              className="flex items-center focus:outline-none"
            >
              <img
                src={MenuIcon}
                alt="Menu"
                className={`w-8 h-8 transform transition-transform ${
                  showDropdown ? "rotate-180" : ""
                }`}
              />
            </button>
          )}
        </div>

        {showDropdown && (
          <div className="absolute right-0 mt-[14px] py-2 bg-white rounded shadow-lg w-[167px]">
            {showSellerMenu &&
              sellerLinks?.map((link, idx) => {
                return (
                  <Link
                    key={idx}
                    to={`${process.env.PUBLIC_URL}${link?.path}`}
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {link?.label}
                  </Link>
                );
              })}
            {profile?.type && 
              <Link
                to={`${process.env.PUBLIC_URL}/profile`}
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Profile
              </Link>
            }
            {profile?.type === "BUYER" && (
              <>
                <Link
                  to={`${process.env.PUBLIC_URL}/order-history-land`}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Order History Land
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/order-history-lorry`}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Order History Lorry
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/order-history-sell`}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Order History Sell
                </Link>
              </>
            )}
            <button
              type="button"
              onClick={openConfirmationModal}
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-start"
            >
              Logout
            </button>
          </div>
        )}
      </div>
      {menuOpen && (
        <Menu
          profile={profile}
          active={active}
          onClose={() => setMenuOpen(false)}
          onLogout={openConfirmationModal}
        />
      )}
      {showConfirmation && (
        <div className="fixed w-full min-h-screen h-full inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded">
            <p className="text-center">Are you sure you want to log out?</p>
            <div className="flex justify-around mt-6">
              <button
                type="button"
                onClick={closeConfirmationModal}
                className="rounded-[10px] py-[12px] px-[62px] uppercase text-white focus:outline-none bg-gray-500 mr-4"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleLogout}
                className="rounded-[10px] py-[12px] px-[62px] uppercase text-white focus:outline-none bg-red-500"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </header>
  ) : (
    <HeaderNotAuth
      profile={profile}
      active={active}
      windowWidth={windowWidth}
    />
  );
};

export default Header;
