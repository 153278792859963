import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function AboutCard(props) {
  const { title, subTitle, description, buttonText } = props.cardData;

  const { profile } = useSelector((state) => ({
    profile: state?.profile?.profile,
  }));

  return (
    <main className="mb-10">
      <div className="flex items-start sm:items-center justify-between gap-[6px] flex-col sm:flex-row">
        <div className="w-full sm:w-[65%] flex flex-col gap-2">
          <p className="font-circular-500 text-[#3DB83A] text-base">Products</p>
          <div className="font-circular-500 text-4xl mb-5">
            <p>{subTitle}</p>
          </div>
          <div className="font-circular-450 text-xl text-[#6B7280]">
            <p> {description} </p>
          </div>
        </div>
        <div className="block justify-items-start h-full">
          <Link
            className="inline-block py-3 px-5 rounded-lg font-circular-450 text-base text-white bg-[#3DB83A] hover:bg-[#7F56D9] ease-linear duration-300"
            to={{
              pathname:
                profile?.type === "BUYER"
                  ? `${process.env.PUBLIC_URL}/${
                      title === "Products"
                        ? "products"
                        : title === "Lorry on Rent"
                        ? "lorries"
                        : "land"
                    }`
                  : profile?.type === "SELLER"
                  ? `${process.env.PUBLIC_URL}/${
                      title === "Products"
                        ? "sell-request"
                        : title === "Lorry on Rent"
                        ? "rent-request-lorries"
                        : "rent-request-land"
                    }`
                  : `${process.env.PUBLIC_URL}/auth`,
              state: "login",
            }}
          >
            {buttonText}
          </Link>
          <button type="button"></button>
        </div>
      </div>
    </main>
  );
}

export default AboutCard;
