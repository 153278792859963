import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import FormikInput from "../../components/FormikInput";
import UploadImage from "../../components/UploadFileInput/UploadImage";

import { auth, signUp } from "../../store/actions/auth";
import { transformErrorsFromAPI } from "../../shared/utility";

import "./RegisterForm.scss";
import { getProfile } from "../../store/actions/profile";
import ButtonSpinner from "../ButtonSpinner";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("User type is required"),
  firstName: Yup.string()
    .required("First Name is required")
    .matches(/^[a-zA-Z ]*$/, "First Name must contain only alphabet characters")
    .min(1, "First Name size must be between 1 and 100")
    .max(100, "First Name size must be between 1 and 100"),
  lastName: Yup.string()
    .required("Last Name is required")
    .matches(/^[A-Za-z ]*$/, "Last Name must contain only alphabet characters")
    .min(1, "Last Name size must be between 1 and 100")
    .max(100, "Last Name size must be between 1 and 100"),
  msisdn: Yup.string().required("Phone Number is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password size must be between 6 and 20")
    .max(20, "Password size must be between 6 and 20"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  imageBase64: Yup.string(),
});

const RegisterForm = ({ setLoggedMsisdn }) => {
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dispatch = useDispatch();

  const { error, loading, profile } = useSelector((state) => ({
    error: state?.auth?.error,
    loading: state?.auth?.loading,
    profile: state?.profile?.profile,
  }));

  const history = useHistory();
  return (
    <Formik
      initialValues={{
        type: "",
        firstName: "",
        lastName: "",
        msisdn: "",
        password: "",
        confirmPassword: "",
        imageBase64: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, resetForm }) => {
        const data = {
          type: values.type,
          firstName: values.firstName,
          lastName: values.lastName,
          msisdn: values.msisdn,
          password: values.password,
          imageBase64: values.imageBase64,
        };
        const res = await dispatch(signUp(data));
        if (!res) {
          alert("Something went wrong");
        }
        if (res && res.error) {
          setErrors(transformErrorsFromAPI(res.error));
          return;
        }
        if (res.type === "AUTH_REGISTER_SUCCESS" && !profile) {
          dispatch(getProfile());
          setLoggedMsisdn(values.msisdn);
        }
        return;
      }}
    >
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <Form>
            <div className="mb-[30px]">
              <div>
                <div
                  role="group"
                  aria-labelledby="type"
                  className={`grid gap-4 justify-center items-center grid-cols-2 pl-4`}
                >
                  <label className="radio-btn-container flex items-center">
                    <Field
                      type="radio"
                      name="type"
                      value="SELLER"
                      class="absolute opacity-0 h-0 w-0 cursor-pointer"
                    />
                    <span className="checkmark"></span>
                    <span>Seller / Renter</span>
                  </label>
                  <label className="radio-btn-container flex items-center">
                    <Field
                      type="radio"
                      name="type"
                      value="BUYER"
                      class="w-4 h-4 border rounded-full border-gray-300 transition-colors duration-300 focus:outline-none focus:border-green-500 focus:ring focus:ring-green-200"
                    />
                    <span className="checkmark"></span>
                    <span>Buyer</span>
                  </label>
                </div>
                {errors.type && touched.type && (
                  <div className="text-[#FF0000]">{errors.error}</div>
                )}
              </div>
            </div>
            <div className="mb-[30px]">
              <FormikInput
                name="firstName"
                placeholder="First Name"
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="mb-[30px]">
              <FormikInput
                name="lastName"
                placeholder="Last Name"
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="mb-[30px]">
              <FormikInput
                name="msisdn"
                placeholder="Phone Number"
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="mb-[30px]">
              <FormikInput
                name="password"
                type="password"
                placeholder="Password"
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="mb-[30px]">
              <FormikInput
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="mb-[30px]">
              <UploadImage
                name="imageBase64"
                type="file"
                placeholder="Upload Profile Image"
                errors={errors}
                touched={touched}
                onChange={(value) => setFieldValue("imageBase64", value)}
              />
              {values.imageBase64 && (
                <img
                  src={`data:image/jpeg;base64,${values.imageBase64}`}
                  className="w-full mt-[20px]"
                  alt=""
                />
              )}
            </div>
            {error && <p className="text-center text-[#FF0000]">{error}</p>}
            <div className="grid items-center justify-center">
              <button
                type="submit"
                disabled={loading}
                className={`flex items-center justify-center gap-2 rounded-[10px] bg-main w-[16rem] h-[3rem] uppercase text-white focus:outline-none ${loading ? "opacity-60": "opacity-100"}`}
              >
                {loading && 
                  <ButtonSpinner />
                } 
                Register NOW
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
