import { useCallback, useEffect, useState } from "react";
import { getHomeSellRequestImage } from "../store/actions/homeRentSell";
import ImagePlaceholder from "../assets/image-placeholder.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToCart } from "../store/actions/cart";
import Star from "../assets/Star.svg";
import cartIcon from "../assets/cart-icon.svg";
import { formatPrice } from "../shared/utility";
const ProductCard = ({ product, setStatusMessage, setShowAddToCartSuccessText }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [price, setPrice] = useState(null);
  const [itemsNotAvailableList, setItemsNotAvailableList] = useState(null);
  const [isPeriodAvailableError, setIsPeriodAvailableError] = useState(false);

  const getImage = useCallback(async () => {
    const res = await getHomeSellRequestImage(product?.id);
    setImage(res);
  }, [product?.id]);

  const { profile, CartDetails } = useSelector((state) => ({
    profile: state?.profile?.profile,
    CartDetails: state?.cart?.CartDetails,
  }));

  useEffect(() => {
    if (product?.hasImage) {
      getImage();
    }
  }, [product, getImage]);

  const handleAddToCard = () => {
    if (profile) {
      if (product?.id) {
        const req = { type: "product", id: product.id, quantity: 1 };
        // check is item is already present in cart or not if item is present in cart dont add again
        const isItemAlreadyAvailableInCart = CartDetails?.product?.reduce(
          (acc, item) => {
            return item.data.sellRequestId === product.id || acc;
          },
          false
        );
        if (isItemAlreadyAvailableInCart) {
          setStatusMessage("Item already present in cart");
          setShowAddToCartSuccessText(true);
        } else {
          dispatch(addToCart(req, setIsPeriodAvailableError));
          setStatusMessage("Item added to cart");
          setShowAddToCartSuccessText(true);
        }

        setTimeout(() => {
          setStatusMessage(null);
          setShowAddToCartSuccessText(false);
        }, 3000);
      } else {
        throw new Error("Product ID not found Try Again later");
      }
    } else {
      history.push(`${process.env.PUBLIC_URL}/auth`);
    }
  };

  const handleOnclickOnImage = () =>{
    history.push({
      pathname: `${process.env.PUBLIC_URL}/product-details/${product?.id}`,
      data: { product },
    });
  }

  useEffect(()=>{
    const formattedPrice = formatPrice(product?.price)
    setPrice(formattedPrice);
  },[]);

  return (
      <div
        className="rounded-[10px]"
      >
        {image ? (
          <img
          src={`data:image/jpeg;base64,${image}`}
          alt=""
          className="w-[384px] h-[289.285px] rounded-[10px] cursor-pointer object-cover"
          onClick={handleOnclickOnImage}
        />
        ) : (
          <img
            src={ImagePlaceholder}
            alt=""
            className="w-[384px] h-[289.285px] rounded-[10px] cursor-pointer object-cover"
            onClick={handleOnclickOnImage}
          />
        )}
        <div className="grid mt-6 gap-3">
          <div className="font-circular-500 text-sm text-[#3DB83A] bg-green-400 bg-opacity-10 rounded-xl w-fit">
            <p className="max-w-[16rem] px-2 py-1 truncate">{product?.location}</p>
          </div>
          <div className="flex items-center justify-between gap-2 flex-wrap">
            <p className="font-circular-500 text-2xl max-w-[16rem] text-left truncate">
              {product?.name}
            </p>
            <p className="font-circular-900 text-xl text-right text-[#3DB83A]">
              &#8358; {price}
            </p>
          </div>
          <div className="font-circular-450 text-[#475467] text-base">
            <p className="h-11 text-ellipsis overflow-hidden">{product?.description}</p>
          </div>
          <div className="flex items-start gap-1">
            <img src={Star} alt="rating" />
            <img src={Star} alt="rating" />
            <img src={Star} alt="rating" />
            <img src={Star} alt="rating" />
            <img src={Star} alt="rating" />
            <p className="font-circular-450 text-sm">{"(91)"}</p>
          </div>
          {profile?.type !== "SELLER" && 
          <div>
            <button
              className="flex items-center justify-center gap-1 px-3 py-2 text-sm border-2 border-solid border-gray rounded-3xl"
              onClick={handleAddToCard}
            >
              <img src={cartIcon} alt="cart" className="w-5 h-5" />
               Add to Cart
            </button>
          </div>}
        </div>
      </div>
  );
};

export default ProductCard;
