const Footer = ( ) => {
    return (
    <section className="flex items-baseline justify-between flex-col gap-6 pt-8 pb-10 sm:flex-row border-t-2 border-solid border-[#333741] bg-[#212121] text-[#94969C] ">
        <p className="font-circular-450 text-base">
            © 2023 MFarm. All rights reserved. A product of Innovii LTD.
        </p>
        <div className="flex gap-4 w-[100%] sm:w-auto justify-evenly items-stretch font-circular-450 text-base">
            <p>Terms</p>
            <p>Privacy</p>
            <p>Cookies</p>
        </div>
    </section>);
}

export default Footer