import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getLandBookedDays, getLorryBookedDays } from '../../store/actions/order';
import { getProfile } from '../../store/actions/profile';

const validationLorrySchema = Yup.object().shape({
    period: Yup.mixed(),
    date: Yup.string(),
});

const RentLandForm = ({ onSubmit, landId, onClose, type, itemDbId }) => {
    const dispatch = useDispatch()

    const { profile, landBookedDays, lorryBookedDays } = useSelector((state) => ({
        profile: state?.profile?.profile,
        landBookedDays: state?.orders?.landBookedDays,
        lorryBookedDays: state?.orders?.lorryBookedDays
    }));

    const [month, setMonth] = useState(new Date().getMonth() + 1)
    const [year, setYear] = useState(new Date().getFullYear())
    const handleMonthChange = (date) => {
        setMonth(date.getMonth() + 1);
        setYear(date?.getFullYear())
    };

    useEffect(() => {
        if(!profile) dispatch(getProfile());
    }, [dispatch])

    useEffect(() => {
        if(type==='LAND'){
            dispatch(getLandBookedDays(year, month, landId))
        }else if(type==='LORRY'){
            dispatch(getLorryBookedDays(year, month, landId))
        }
    }, [dispatch, year, month])


    return (
        <div>
            <Formik
                initialValues={{
                    type: "period",
                    period: "",
                    date: ""
                }}
                validationSchema={validationLorrySchema}
                onSubmit={(values, state) => {
                    if (values.type === "period") {
                        // Check if both startDate and endDate are present
                        if (!values.period.startDate || !values.period.endDate) {
                            state.setErrors({ period: "Both start date and end date are required." });
                          return;
                        }
                      } else if (values.type === "date") {
                          // Check if date is empty
                          if (!values.date) {
                            state.setErrors({ date: "Date is required." });
                            return;
                        }
                      }
                    onSubmit({ ...profile, ...values, ...{id:itemDbId} })
            }}
            >
                {({ errors, touched, setFieldValue, values,fir }) => (
                    <Form className='grid w-full'>
                        <div className="mb-[30px]">
                            <div>
                                <div role="group" aria-labelledby="type" className='grid grid-cols-2'>
                                    <label className="radio-btn-container flex items-center">
                                        <Field
                                            type="radio"
                                            name="type"
                                            value="period"
                                            class="absolute opacity-0 h-0 w-0 cursor-pointer"
                                        />
                                        <span className="checkmark"></span>
                                        <span>Durations Date</span>
                                    </label>
                                    <label className="radio-btn-container flex items-center">
                                        <Field
                                            type="radio"
                                            name="type"
                                            value="date"
                                            class="w-4 h-4 border rounded-full border-gray-300 transition-colors duration-300 focus:outline-none focus:border-green-500 focus:ring focus:ring-green-200"
                                        />
                                        <span className="checkmark"></span>
                                        <span>Single Day</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 grid">
                            {values.type === 'period' ? (
                                <DatePicker
                                    name="period"
                                    selected={values.period.startDate}
                                    startDate={values.period.startDate}
                                    endDate={values.period.endDate}
                                    selectsRange
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    onChange={(dateRange) => {
                                        setFieldValue('period', { startDate: dateRange[0], endDate: dateRange[1] });
                                    }}
                                    excludeDates={type==='LAND' 
                                    ? landBookedDays?.data?.map(day => new Date(year, month-1, day))
                                    : lorryBookedDays?.data?.map(day => new Date(year, month-1, day))
                                    }
                                    className="form-control my-1 block w-full rounded-md border-gray-300 bg-[#F5F5F5] px-[25px] py-[13px] shadow-sm outline-none"
                                    onMonthChange={handleMonthChange}
                                />
                            ) : (
                                <DatePicker
                                    name="date"
                                    selected={values.date}
                                    onChange={(date) => {
                                        setFieldValue('date', date);
                                    }}
                                    placeholderText="Select Date"
                                    minDate={new Date()}
                                    excludeDates={type==='LAND' 
                                    ? landBookedDays?.data?.map(day => new Date(year, month-1, day))
                                    : lorryBookedDays?.data?.map(day => new Date(year, month-1, day))
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    onMonthChange={handleMonthChange}
                                    className={
                                        'form-control my-1 block w-full rounded-md border-gray-300 bg-[#F5F5F5] px-[25px] py-[13px] shadow-sm outline-none' +
                                        (errors?.period && touched?.period ? ' border-red-500' : '')
                                    }
                                />
                            )}
                            {touched.period && errors.period && (
                                <div className="mt-1 text-sm text-[#FF0000]">
                                    {errors.period}
                                </div>
                            )}
                            {touched.date && errors.date && (
                                <div className="mt-1 text-sm text-[#FF0000]">
                                    {errors.date}
                                </div>
                            )}
                        </div>
                        <div className="flex justify-evenly">
                            <button
                                type="submit"
                                className="rounded-[10px] bg-main px-[66px] py-[12px] font-medium text-white"
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="rounded-[10px] px-[66px] py-[12px] text-[#969696]"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default RentLandForm;
