import React from 'react'
import AboutBuyer from './AboutBuyer'
import handArrow from "../../../assets/landing screen/hand-drawn-arrow.svg";
import mockScreen from "../../../assets/landing screen/screen-mockup.svg";
import MfarmQualities from './MfarmQualities';

const LandingPageBuyerSubBanner = () => {
  return (
    <main className={Style.mainBox}>
        <AboutBuyer />
        <div className={Style.contentBox}>
            <MfarmQualities />
            <div className='flex-1 relative'>
                <img src={handArrow} alt='arrow' className='absolute top-[-160px] left-16 invisible xl:visible' />
                <img src={mockScreen} alt='mock-screen' />
            </div>
        </div>
    </main>
  )
}

const Style = {
  mainBox: "py-16 px-5 md:pl-[112px] flex flex-col items-center sm:items-start justify-center bg-[#F9FAFB]",
  contentBox:'flex flex-col xl:flex-row gap-8 mt-16',
}
export default LandingPageBuyerSubBanner
