import axios from "../../axios-api";
import * as actionTypes from "./types";
import { arrayBufferToBase64, getErrors, getResData } from "../../shared/utility";

export const getHomeSellRequestsStart = () => {
    return {
        type: actionTypes.HOME_GET_SELL_REQUESTS_START,
    };
};

export const getHomeSellRequestsSuccess = (sellRequests) => {
    return {
        type: actionTypes.HOME_GET_SELL_REQUESTS_SUCCESS,
        sellRequests,
    };
};

export const getHomeSellRequestsFailed = (error) => {
    return {
        type: actionTypes.HOME_GET_SELL_REQUESTS_FAIL,
        error,
    };
};

export const getHomeSellRequests = (start, length, categoryId, name) => {
    return (dispatch) => {
        dispatch(getHomeSellRequestsStart());

        let url = "/sell/request";

        const params = {};

        if (start !== undefined) {
            params.start = start;
        }

        if (length !== undefined) {
            params.length = length;
        }

        if (categoryId !== undefined) {
            params.categoryId = categoryId;
        }

        if (name !== undefined) {
            params.name = name;
        }

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getHomeSellRequestsSuccess(data));
            })
            .catch((err) =>
                dispatch(getHomeSellRequestsFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const getHomeSellRequestImage = (id) => {
    return axios
        .get(`/sell/request/image/${id}`, {
            responseType: 'arraybuffer',// Tell Axios to treat the response as an array buffer
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if (!response) throw new Error("No Response");

            const data = response.data;
            const base64String = arrayBufferToBase64(data);

            return base64String;
        })
        .catch((err) => getErrors(err));
};

export const getHomeRentLandRequestsStart = () => {
    return {
        type: actionTypes.HOME_GET_RENT_REQUESTS_LAND_START,
    };
};

export const getHomeRentLandRequestsSuccess = (rentLandRequests) => {
    return {
        type: actionTypes.HOME_GET_RENT_REQUESTS_LAND_SUCCESS,
        rentLandRequests,
    };
};

export const getHomeRentLandRequestsFailed = (error) => {
    return {
        type: actionTypes.HOME_GET_RENT_REQUESTS_LAND_FAIL,
        error,
    };
};

export const getHomeRentLandRequests = (start, length) => {
    return (dispatch) => {
        dispatch(getHomeRentLandRequestsStart());

        let url = "/rent/request/land";

        const params = {};

        if (start !== undefined) {
            params.start = start;
        }

        if (length !== undefined) {
            params.length = length;
        }

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getHomeRentLandRequestsSuccess(data));
            })
            .catch((err) =>
                dispatch(getHomeRentLandRequestsFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const getHomeRentLandRequestImage = (id) => {
    return axios
        .get(`rent/request/land/image/${id}`, {
            responseType: 'arraybuffer',// Tell Axios to treat the response as an array buffer
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if (!response) throw new Error("No Response");

            const data = response.data;
            const base64String = arrayBufferToBase64(data);

            return base64String;
        })
        .catch((err) => getErrors(err));
};

export const getHomeRentLorriesRequestsStart = () => {
    return {
        type: actionTypes.HOME_GET_RENT_REQUESTS_LORRY_START,
    };
};

export const getHomeRentLorriesRequestsSuccess = (rentLorriesRequests) => {
    return {
        type: actionTypes.HOME_GET_RENT_REQUESTS_LORRY_SUCCESS,
        rentLorriesRequests,
    };
};

export const getHomeRentLorriesRequestsFailed = (error) => {
    return {
        type: actionTypes.HOME_GET_RENT_REQUESTS_LORRY_FAIL,
        error,
    };
};

export const getHomeRentLorriesRequests = (start, length) => {
    return (dispatch) => {
        dispatch(getHomeRentLorriesRequestsStart());

        let url = "/rent/request/lorry";

        const params = {};

        if (start !== undefined) {
            params.start = start;
        }

        if (length !== undefined) {
            params.length = length;
        }

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getHomeRentLorriesRequestsSuccess(data));
            })
            .catch((err) =>
                dispatch(getHomeRentLorriesRequestsFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const getHomeRentLorriesRequestImage = (id) => {
    return axios
        .get(`/rent/request/lorry/image/${id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if (!response) throw new Error("No Response");

            const data = response.data;
            const base64String = arrayBufferToBase64(data);

            return base64String;
        })
        .catch((err) => getErrors(err));
};

const setSelectedProductStart = () => {
    return {
        type: actionTypes.SET_SELECTED_PRODUCT_START,
    };
}

const setSelectedProductSuccess = (selectedProduct) => {
    return {
        type: actionTypes.SET_SELECTED_PRODUCT,
        selectedProduct,
    };
};

const setSelectedProductFail = (error) => {
    return {
        type: actionTypes.SET_SELECTED_PRODUCT_START,
        error,
    };
}

export const getSelectedtItemById = ( id, type ) => {
    return (dispatch) => {
        dispatch(setSelectedProductStart());
        let url = null;
        switch (type) {
            case "PRODUCT":
                url = `/sell/request/${id}`;
                break;
            case "LORRY":
                url = `/rent/request/lorry/${id}`;
                break;
            case "LAND":
                url = `/rent/request/land/${id}`;
                break;
            default:
                break;
        }
        

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(setSelectedProductSuccess(data?.data));
            })
            .catch((err) =>
                dispatch(setSelectedProductFail(getErrors(err?.response?.data || err)))
            );
    }

}