import React, { useState } from 'react';

import Pagination from '../Pagination';

const Table = ({ total, setFirstIndex, onUpdateList, tableData , noPagination, currentPage, setCurrentPage }) => {
  const itemsPerPage = 5;

  const totalPages = Math.ceil(total / itemsPerPage);

  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setFirstIndex(pageNumber * itemsPerPage - itemsPerPage);
    onUpdateList(true);
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-[98%] sm:pl-[40px]">
      <div className="w-[98%] overflow-x-auto rounded-[10px] shadow-table mx-[0.5rem]">
        <table className="w-full rounded-[10px]">
          <thead className="bg-[#F0F0F0]">
            <tr
              className={`text-left text-[16px] ${
                tableData?.className ? tableData?.className : ''
              }`}
            >
              {tableData.header.map((label, idx) => (
                <th
                  className={`font-semibold ${idx===0 && 'w-[12.5rem]'} ${
                    tableData?.className ? '' : 'px-[40px]'
                  } py-[20px] ${idx === 0 ? 'rounded-tl-[10px]' : ''} ${
                    idx === tableData.header.length - 1
                      ? 'rounded-tr-[10px]'
                      : ''
                  }`}
                >
                  {label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="flex  flex-col  ">
            {tableData?.body?.map((row, index) => (
              <tr
                key={index}
                className={`border-t border-[#E7E7E7] ${
                  tableData?.className ? tableData?.className : ''
                }`}
              >
                {row}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={`mb-2 px-[20px] mt-[20px] flex justify-between ${noPagination && 'hidden'}`}>
        <span className="text-sm text-[#A6A6A6]">
          {firstItemIndex + 1} -{' '}
          {lastItemIndex + 1 > total ? total : lastItemIndex} of {total}
        </span>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          startIndex={firstItemIndex}
          lastIndex={lastItemIndex}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Table;