import React from 'react';
import AboutSeller from './AboutSeller';
import MfarmSellerQualities from './MfarmSellerQualities';
import screenImage from '../../../assets/landing screen/seller-page-lady.svg';

const LandingPageSellerSubBanner = () => {
  return (
    <main className={Style.mainBox}>
        <AboutSeller />
        <div className={Style.contentBox}>
            <MfarmSellerQualities />
            <div className='flex-1'>
                <img src={screenImage} alt='mock-screen' className={Style.image} />
            </div>
        </div>
    </main>
  )
}
const Style = {
  mainBox: "py-16 px-5 md:pl-[112px] flex flex-col items-center sm:items-start justify-center bg-[#F9FAFB]",
  contentBox:"flex flex-col xl:flex-row gap-8 mt-16",
  image:"rounded-3xl"
}

export default LandingPageSellerSubBanner



