import { Form, Formik } from "formik";
import SearchForm from "../../components/Forms/SearchForm";
import Layout from "../../components/Layout"
import { getHomeSellRequestImage, getHomeSellRequests } from "../../store/actions/homeRentSell";
import FormikInput from "../../components/FormikInput";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useEffect, useState } from "react";
import { getCategories } from "../../store/actions/categories";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";
import ProductCard from "../../components/ProductCard";
import CloseIcon from "../../assets/close.svg";

const validationSchema = Yup.object().shape({
    search: Yup.string(),
    categoryId: Yup.mixed()
});

const AgricultureProductsPage = () => {
    const dispatch = useDispatch()

    const handleSearchSubmit = values => {
        dispatch(getHomeSellRequests(0, 5, values.categoryId, values.search));
    };

    const [step, setStep] = useState(6)
    const [isSearchFocused,setIsSearchFocused] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [statusMessage, setStatusMessage] = useState(null);
    const [showAddToCartSuccessText, setShowAddToCartSuccessText] = useState(false);

    const { categories, sellRequests } = useSelector((state) => ({
        categories: state?.categories?.categories,
        sellRequests: state?.homeRentSell?.sellRequests,
    }));

    useEffect(() => {
        dispatch(getCategories())
    }, [dispatch])

    useEffect(() => {
        if(searchValue){
            dispatch(getHomeSellRequests(0, step, searchValue?.categoryId, searchValue?.search))
        }else{
            dispatch(getHomeSellRequests(0, step))
        }
    }, [dispatch, step, searchValue])

    const categoryOptions = categories?.data?.map((category) => { return { value: category?.id, label: category?.name } })

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            width: '100%',
            borderRadius: '0.375rem', // Equivalent to 6px (rounded-md)
            background: '#F5F5F5',
            padding: '8px 17px', // Equivalent to 13px 25px (py-[13px] px-[25px])
            border: 'none',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', // Equivalent to shadow-sm
            outline: 'none', // Equivalent to outline-none
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#50A060'
                : state.isFocused
                    ? '#f0f0f0'
                    : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: state.isSelected ? '#50A060' : '#f0f0f0',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            padding: '0px',
            color: '#333',
        }),
        input: (provided) => ({
            ...provided,
            padding: '0px',
            margin: '0px', // Remove the padding from the input
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0', // Remove the padding from the value container
        }),
    };

    const clearIconStyle = {
        position : "absolute",
        top: "0.8rem",
        right:"5%",
        cursor:"pointer"
    }
    const handleClearSearch = (setFieldValue) => () => {
        setIsSearchFocused(false);
        setFieldValue('search', '');
        setFieldValue('categoryId', '')
        dispatch(getHomeSellRequests(0, step))
        setSearchValue(null);
    }

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])

    return (
        <Layout>
                  {showAddToCartSuccessText && (
          <div className="fixed top-20 right-14 bg-slate-100 p-4 rounded z-50">
            <p
              className={`${
                statusMessage === "Item added to cart"
                  ? "text-green-500"
                  : "text-[#FF0000]"
              } ${showAddToCartSuccessText ? "visible" : "invisible"}`}
            >
              {statusMessage}
            </p>
          </div>
        )}
            <div className="container mx-[auto] mb-[90px]">
                <div className="md:flex justify-between items-center pb-[50px] mt-[100px]">
                    <h1 className="text-[24px] font-semibold">Products</h1>
                    <Formik
                        initialValues={{
                            search: '',
                            categoryId: ""
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values)=>{
                            setSearchValue(state => ({...state , search : values.search}));
                        }}
                    >
                        {({ errors, touched, values, setFieldValue, handleBlur }) => {
                            return (
                                <Form>
                                    <div className="grid md:flex gap-[30px] mt-[30px] md:my-[0px]">
                                        <div className="md:max-w-[350px]">
                                            <Select
                                                name="categoryId"
                                                options={categoryOptions}
                                                value={categoryOptions?.find((option) => option?.value === searchValue?.categoryId) || null}
                                                placeholder="Category"
                                                onChange={(selectedOption) => {
                                                    setFieldValue("categoryId", selectedOption.value)
                                                    setSearchValue(state => ({...state , categoryId : selectedOption.value}));
                                                }}
                                                handleBlur={handleBlur}
                                                styles={selectStyles}
                                                menuPosition="fixed"
                                            />
                                        </div>
                                        <div className="relative">
                                            <FormikInput
                                                name="search"
                                                type="text"
                                                placeholder="Search here"
                                                errors={errors}
                                                touched={touched}
                                                setIsSearchFocused={setIsSearchFocused}
                                            />
                                            { isSearchFocused && 
                                                <img src={CloseIcon} alt="clear" style={clearIconStyle} onClick={handleClearSearch(setFieldValue)}  />
                                            }
                                        </div>
                                        <button type="submit" className="bg-main px-[16px] py-[12px] text-white rounded-[10px]">Search</button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
                {sellRequests?.data?.length > 0 ? ( 
                    <>
                    <div className="grid md:grid-cols-3 gap-[50px]">
                        {sellRequests?.data && sellRequests?.data?.map(sellRequest => {
                            return <ProductCard  key={sellRequest.id} product={sellRequest} setStatusMessage={setStatusMessage} setShowAddToCartSuccessText={setShowAddToCartSuccessText} />
                        })}
                    </div>
                    {sellRequests?.data?.length < sellRequests?.total &&
                        <div className="flex justify-center">
                            <button type="button" onClick={() => setStep(step + 6)} className="uppercase text-main text-[18px] underline mt-[60px] mx-[auto]">View More</button>
                        </div>
                    }
                    </>
                ) : (
                    <div>
                        <p className="px-[40px] text-xl">No results found</p>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default AgricultureProductsPage;