import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";

const initialState = {
  error: null,
  loading: false,
  orderHistoryLand: null,
  orderHistoryLandStatus: null,
};

//get order history
const getOrderHistoryLandStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getOrderHistoryLandSuccess = (state, action) => {
  return updateObject(state, {
    orderHistoryLand: action.orderHistoryLand,
    error: null,
    loading: false,
  });
};

const getOrderHistoryLandFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    orderHistoryLand: null,
  });
};

// Get order image
const getOrderHistoryLandImageStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getOrderHistoryLandImageSuccess = (state, action) => {
  return updateObject(state, {
    orderHistoryLandImage: action.orderHistoryLandImage,
    error: null,
    loading: false,
  });
};

const getOrderHistoryLandImageFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    orderHistoryLandImage: null,
  });
};

// Get order status
const getOrderHistoryLandStatusStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getOrderHistoryLandStatusSuccess = (state, action) => {
  return updateObject(state, {
    orderHistoryLandStatus: action.orderHistoryLandStatus,
    error: null,
    loading: false,
  });
};

const getOrderHistoryLandStatusFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    orderHistoryLandStatus: null,
  });
};

// change order status
const changeOrderLandStatusStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const changeOrderLandStatusSuccess = (state, action) => {
  return updateObject(state, {
    changeOrderStatus: action.changeOrderStatus,
    error: null,
    loading: false,
  });
};

const changeOrderLandStatusFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    changeOrderStatus: null,
  });
};

const orderHistoryLandReducer = (state = initialState, action) => {
  switch (action.type) {
    // get order history
    case actionTypes.GET_ORDER_HISTORY_LAND_START:
      return getOrderHistoryLandStart(state, action);
    case actionTypes.GET_ORDER_HISTORY_LAND_SUCCESS:
      return getOrderHistoryLandSuccess(state, action);
    case actionTypes.GET_ORDER_HISTORY_LAND_FAIL:
      return getOrderHistoryLandFailed(state, action);
    case actionTypes.GET_ORDER_HISTORY_IMAGE_START:
      return getOrderHistoryLandImageStart(state, action);
    case actionTypes.GET_ORDER_HISTORY_IMAGE_SUCCESS:
      return getOrderHistoryLandImageSuccess(state, action);
    case actionTypes.GET_ORDER_HISTORY_IMAGE_FAIL:
      return getOrderHistoryLandImageFailed(state, action);
    case actionTypes.GET_ORDER_HISTORY_LAND_STATUS_START:
      return getOrderHistoryLandStatusStart(state, action);
    case actionTypes.GET_ORDER_HISTORY_LAND_STATUS_SUCCESS:
      return getOrderHistoryLandStatusSuccess(state, action);
    case actionTypes.GET_ORDER_HISTORY_LAND_STATUS_FAIL:
      return getOrderHistoryLandStatusFailed(state, action);
    case actionTypes.CHANGE_ORDER_LAND_STATUS_START:
      return changeOrderLandStatusStart(state, action);
    case actionTypes.CHANGE_ORDER_LAND_STATUS_SUCCESS:
      return changeOrderLandStatusSuccess(state, action);
    case actionTypes.CHANGE_ORDER_LAND_STATUS_FAIL:
      return changeOrderLandStatusFailed(state, action);
    default:
      return state;
  }
};

export default orderHistoryLandReducer;
