// actions.js
import axios from "../../axios-api";
import * as actionTypes from "./types";
import { arrayBufferToBase64, getErrors, getResData } from "../../shared/utility";

export const getSellRequestsStart = () => {
    return {
        type: actionTypes.GET_SELL_REQUESTS_START,
    };
};

export const getSellRequestsSuccess = (sellRequests) => {
    return {
        type: actionTypes.GET_SELL_REQUESTS_SUCCESS,
        sellRequests,
    };
};

export const getSellRequestsFailed = (error) => {
    return {
        type: actionTypes.GET_SELL_REQUESTS_FAIL,
        error,
    };
};

export const getSellRequests = (start, length, name) => {
    return (dispatch) => {
        dispatch(getSellRequestsStart());

        let url = "/seller/sell/request";

        const params = {};

        if (start !== undefined) {
            params.start = start;
        }

        if (length !== undefined) {
            params.length = length;
        }

        if (name !== undefined) {
            params.name = name;
        }

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getSellRequestsSuccess(data));
            })
            .catch((err) =>
                dispatch(getSellRequestsFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const createSellRequestStart = () => {
    return {
        type: actionTypes.CREATE_SELL_REQUEST_START,
    };
};

export const createSellRequestSuccess = (createRes) => {
    return {
        type: actionTypes.CREATE_SELL_REQUEST_SUCCESS,
        createRes,
    };
};

export const createSellRequestFailed = (error) => {
    return {
        type: actionTypes.CREATE_SELL_REQUEST_FAIL,
        error,
    };
};

export const createSellRequest = (data) => {
    return (dispatch) => {
        dispatch(createSellRequestStart());

        return axios
            .post(`/seller/sell/request`, data)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(createSellRequestSuccess(data));
            })
            .catch((err) =>
                dispatch(createSellRequestFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const editSellRequestStart = () => {
    return {
        type: actionTypes.EDIT_SELL_REQUEST_START,
    };
};

export const editSellRequestSuccess = (editRes) => {
    return {
        type: actionTypes.EDIT_SELL_REQUEST_SUCCESS,
        editRes,
    };
};

export const editSellRequestFailed = (error) => {
    return {
        type: actionTypes.EDIT_SELL_REQUEST_FAIL,
        error,
    };
};

export const editSellRequest = (id, data) => {
    return (dispatch) => {
        dispatch(editSellRequestStart());

        return axios
            .put(`/seller/sell/request`, { id: id, ...data })
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(editSellRequestSuccess(data));
            })
            .catch((err) =>
                dispatch(editSellRequestFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const deleteSellRequestStart = () => {
    return {
        type: actionTypes.DELETE_SELL_REQUEST_START,
    };
};

export const deleteSellRequestSuccess = (deleteRes) => {
    return {
        type: actionTypes.DELETE_SELL_REQUEST_SUCCESS,
        deleteRes,
    };
};

export const deleteSellRequestFailed = (error) => {
    return {
        type: actionTypes.DELETE_SELL_REQUEST_FAIL,
        error,
    };
};

export const deleteSellRequest = (id) => {
    return (dispatch) => {
        dispatch(deleteSellRequestStart());

        return axios
            .delete(`/seller/sell/request/${id}`)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(deleteSellRequestSuccess(data));
            })
            .catch((err) =>
                dispatch(deleteSellRequestFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const getSellRequestImage = (id) => {
    return axios
        .get(`/seller/sell/request/image/${id}`, {
            responseType: 'arraybuffer',// Tell Axios to treat the response as an array buffer
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if (!response) throw new Error("No Response");

            const data = response.data;
            const base64String = arrayBufferToBase64(data);

            return base64String;
        })
        .catch((err) => getErrors(err));
};

// updateSellRequestImage
export const updateSellRequestImageStart = () => {
    return {
        type: actionTypes.EDIT_SELL_REQUEST_IMAGE_START,
    };
};

export const updateSellRequestImageSuccess = (updateSellRequestImageRes) => {
    return {
        type: actionTypes.EDIT_SELL_REQUEST_IMAGE_SUCCESS,
        updateSellRequestImageRes,
    };
};

export const updateSellRequestImageFailed = (error) => {
    return {
        type: actionTypes.EDIT_SELL_REQUEST_IMAGE_FAIL,
        error,
    };
};

export const updateSellRequestImage = (data) => {
    return (dispatch) => {
        dispatch(updateSellRequestImageStart());

        return axios
            .put(`/seller/sell/request/image`, data)
            .then(async (response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return await dispatch(updateSellRequestImageSuccess(data.data));
            })
            .catch((err) => dispatch(updateSellRequestImageFailed(getErrors(err))));
    };
};
