import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";

const initialState = {
  error: null,
  loading: false,
  orderHistorySell: null,
};

//get sell history
const getOrderHistorySellStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getOrderHistorySellSuccess = (state, action) => {
  return updateObject(state, {
    orderHistorySell: action.orderHistorySell,
    error: null,
    loading: false,
  });
};

const getOrderHistorySellFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    orderHistorySell: null,
  });
};

// Get order image
const getOrderHistorySellImageStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getOrderHistorySellImageSuccess = (state, action) => {
  return updateObject(state, {
    orderHistorySellImage: action.orderHistorySellImage,
    error: null,
    loading: false,
  });
};

const getOrderHistorySellImageFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    orderHistorySellImage: null,
  });
};

// Get order status
const getOrderHistorySellStatusStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getOrderHistorySellStatusSuccess = (state, action) => {
  return updateObject(state, {
    orderHistorySellStatus: action.orderHistorySellStatus,
    error: null,
    loading: false,
  });
};

const getOrderHistorySellStatusFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    orderHistorySellStatus: null,
  });
};

// change order status
const changeOrderSellStatusStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const changeOrderSellStatusSuccess = (state, action) => {
  return updateObject(state, {
    changeOrderStatus: action.changeOrderStatus,
    error: null,
    loading: false,
  });
};

const changeOrderSellStatusFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    changeOrderStatus: null,
  });
};

const orderHistorySellReducer = (state = initialState, action) => {
  switch (action.type) {
    // get tips actions
    case actionTypes.GET_ORDER_HISTORY_SELL_START:
      return getOrderHistorySellStart(state, action);
    case actionTypes.GET_ORDER_HISTORY_SELL_SUCCESS:
      return getOrderHistorySellSuccess(state, action);
    case actionTypes.GET_ORDER_HISTORY_SELL_FAIL:
      return getOrderHistorySellFailed(state, action);
    case actionTypes.GET_ORDER_HISTORY_SELL_IMAGE_START:
      return getOrderHistorySellImageStart(state, action);
    case actionTypes.GET_ORDER_HISTORY_SELL_IMAGE_SUCCESS:
      return getOrderHistorySellImageSuccess(state, action);
    case actionTypes.GET_ORDER_HISTORY_SELL_IMAGE_FAIL:
      return getOrderHistorySellImageFailed(state, action);
    case actionTypes.GET_ORDER_HISTORY_SELL_STATUS_START:
      return getOrderHistorySellStatusStart(state, action);
    case actionTypes.GET_ORDER_HISTORY_SELL_STATUS_SUCCESS:
      return getOrderHistorySellStatusSuccess(state, action);
    case actionTypes.GET_ORDER_HISTORY_SELL_STATUS_FAIL:
      return getOrderHistorySellStatusFailed(state, action);
    case actionTypes.CHANGE_ORDER_SELL_STATUS_START:
      return changeOrderSellStatusStart(state, action);
    case actionTypes.CHANGE_ORDER_SELL_STATUS_SUCCESS:
      return changeOrderSellStatusSuccess(state, action);
    case actionTypes.CHANGE_ORDER_SELL_STATUS_FAIL:
      return changeOrderSellStatusFailed(state, action);
    default:
      return state;
  }
};

export default orderHistorySellReducer;
