const NavOrderHistoryIcon = ({ color }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3379 0.618105C17.1174 0.236844 16.7069 0 16.2667 0H11.6665V5.44143H20.1276L17.3379 0.618105Z" fill={color} />
            <path d="M10.3769 0H5.7333C5.29227 0 4.88179 0.237273 4.66127 0.619395L1.88184 5.44143H10.3769V0H10.3769Z" fill={color} />
            <path d="M1.42041 6.73145V20.4844C1.42041 21.3205 2.10086 22.0005 2.93691 22.0005H19.0631C19.8991 22.0005 20.5796 21.3205 20.5796 20.4844V6.73145H1.42041ZM13.9268 12.515L10.592 15.8498C10.4661 15.9757 10.301 16.0389 10.136 16.0389C9.97093 16.0389 9.80585 15.9757 9.68033 15.8498L8.117 14.2869C7.86555 14.0354 7.86555 13.627 8.117 13.3751C8.36889 13.1233 8.77726 13.1233 9.02914 13.3751L10.136 14.4824L13.0151 11.6033C13.267 11.3514 13.6754 11.3514 13.9269 11.6033C14.1787 11.8552 14.1787 12.2632 13.9268 12.515Z" fill={color} />
        </svg>
    )
}

export default NavOrderHistoryIcon
