import React, { useEffect, useState } from "react";
import AboutCard from "../AboutCard";
import RentCardContainer from "./RentCardContainer";
import { useDispatch, useSelector } from "react-redux";
import { getHomeRentLorriesRequests } from "../../../../store/actions/homeRentSell";
import ArrowButtonContainer from "../ProductCardContainer/ArrowButtonContainer";
import { useRef } from "react";

function MachineRentContainer(props) {
  const { cardData } = props;

  const [visibleCards, setVisibleCards] = useState(5);
  const [totalCards, setTotalCards] = useState(null);
  const sliderRef = useRef(null);

  const { rentLorriesRequests } = useSelector((state) => ({
    rentLorriesRequests: state?.homeRentSell?.rentLorriesRequests,
  }));
  
  const dispatch = useDispatch();

  function getLorryRequest (start, length) {
    dispatch(getHomeRentLorriesRequests(start, length))
  }

  const handleLeftArrowButton = () => {
    sliderRef.current.slickPrev();
  };

  const handleRightArrowButton = () => {
    sliderRef.current.slickNext();
    if(rentLorriesRequests?.data?.length + 5 <= totalCards ){
      getLorryRequest(rentLorriesRequests.data.length, rentLorriesRequests.data.length + 5)
    }
  };

  useEffect(()=>{
    if(rentLorriesRequests?.total) setTotalCards(rentLorriesRequests?.total)
    if(rentLorriesRequests?.data) setVisibleCards(rentLorriesRequests.data)

  },[rentLorriesRequests]);

  useEffect(() => {
    getLorryRequest(0,10);
  }, []);

  return (
    <section className="py-24">
      <AboutCard cardData={cardData} />
      <RentCardContainer visibleCards={visibleCards} sliderRef={sliderRef} type="lorry" />
      <ArrowButtonContainer handleLeft={handleLeftArrowButton} handleRight={handleRightArrowButton} />
    </section>
  );
}

export default MachineRentContainer;
