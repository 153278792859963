import React, { useState } from "react";
import ImagePlaceholderIcon from "../assets/image-placeholder.png";
import DeleteIcon from "../assets/delete.svg";
import EditIcon from "../assets/edit.svg";

const ImageUploader = ({ images, setImages }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageChange = (event) => {
    const newBase64Images = [...images];
    const file = event.target.files;
    if(file){
      for(let i = 0; i < file.length; i++){
        const isImage = /^image\//.test(file[i].type);
        if(isImage){
          const reader = new FileReader();
          reader.onload = (e) => {
            newBase64Images.push(e.target.result.split(",")[1]); // Extract base64 part
            setImages(newBase64Images);
          };
          reader.readAsDataURL(file[i]);
        }else{
          alert("Selected file is not an image.");
        }
      }
    }
  };

  const handleImageDelete = (index) => {
    const newBase64Images = [...images];
    newBase64Images.splice(index, 1);
    setImages(newBase64Images);
  };

  /* const handleImageEdit = (index) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.addEventListener("change", (event) => {
      const newBase64Images = [...images];
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          newBase64Images[index] = e.target.result.split(',')[1];
          setImages(newBase64Images);
        };
        reader.readAsDataURL(file);
      }
    });
    input.click();
  }; */

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className="grid grid-cols-[80px_1fr] ">
      <input
        id="imageGallery"
        type="file"
        multiple
        accept=".jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp"
        onChange={handleImageChange}
        className="hidden"
      />
      <label htmlFor="imageGallery">
        <img
          src={ImagePlaceholderIcon}
          alt="Choose file"
          className="w-[80px] h-[80px] cursor-pointer rounded-[10px]"
        />
      </label>

      {images.length > 0 && (
        <div className="grid grid-cols-[32px_auto_32px] relative">
          <button
            type="button"
            className={`px-2 py-1 rounded-lg self-center text-[32px] ${
              currentIndex === 0 ? "text-[#aaa]" : "text-main"
            }`}
            onClick={handlePrevious}
            disabled={currentIndex === 0}
          >
            &lt;
          </button>
          <div className="flex justify-start space-x-2">
            {images
              .slice(currentIndex, currentIndex + 3)
              .map((image, index) => (
                <div className="grid" key={index}>
                  <img
                    src={`data:image/jpeg;base64,${image}`}
                    alt={`Image ${currentIndex + index}`}
                    className="max-w-[100px] max-h-[100px]"
                  />
                  <div className="flex justify-center space-x-2 mt-1 self-end">
                    {/* <button
                    type="button"
                    className="bg-blue-500 text-white px-1 py-1 rounded h-[24px] w-[24px]"
                    onClick={() => handleImageEdit(currentIndex + index)}
                  >
                    <img src={EditIcon} className='h-[16px] w-[16px]' />
                  </button> */}
                    <button
                      type="button"
                      className="bg-red-500 text-white px-1 py-1 rounded h-[24px] w-[24px]"
                      onClick={() => handleImageDelete(currentIndex + index)}
                    >
                      <img src={DeleteIcon} className="h-[16px] w-[16px]" />
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <button
            type="button"
            className={`px-2 py-1 rounded-lg self-center text-[32px] ${
              currentIndex >= images.length - 3 ? "text-[#aaa]" : "text-main"
            }`}
            onClick={handleNext}
            disabled={currentIndex >= images.length - 3}
          >
            &gt;
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
