import React from 'react'
import GettingStartedHeader from "../../LandingPageBuyer/GettingStarted/GettingStartedHeader";
import Steps from "./Steps";

const GettingStartedSeller= () => {
  return (
    <main className='flex flex-col gap-16 pt-24 pb-32 px-[30px] bg-[#F9FAFB]'>
        <GettingStartedHeader />
        <Steps />
    </main>
  )
}

export default GettingStartedSeller
