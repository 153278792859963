import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';

import ShowPassIcon from "../assets/show-pass.svg"
import HidePassIcon from "../assets/hide-pass.svg"

const FormikInput = ({ label, placeholder, name, type = "text", errors, touched,setIsSearchFocused }) => {
    const [showPassword, setShowPassword] = useState(false);
    
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    
    const handleOnFocus = ()=>{
        if(name === "search"){
            setIsSearchFocused(true)
        }   
    }   

    return (
        <>
            {label && <label htmlFor={name} className="block text-gray-700">{label}</label>}
            {type === "textarea" ? (
                <Field name={name} as="textarea" placeholder={placeholder} className={`resize-none h-[95px] block w-full rounded-md bg-[#F5F5F5] py-[13px] px-[25px] border-gray-300 shadow-sm outline-none ${errors?.[name] && touched?.[name] ? 'border-red-500' : ''}`} />
            ) : (
                <div className="relative">
                    <Field
                        name={name}
                        type={type === 'password' && showPassword ? 'text' : type}
                        placeholder={placeholder}
                        onClick={handleOnFocus}
                        className={`block w-full rounded-md bg-[#F5F5F5] py-[13px] pl-[25px] pr-[50px] border-gray-300 shadow-sm outline-none ${errors?.[name] && touched?.[name] ? 'border-red-500' : ''}`}
                    />
                    {type === 'password' && (
                        <button type="button" onClick={handleTogglePassword} className="absolute top-1/2 right-4 transform -translate-y-1/2">
                            {showPassword ? <img src={ShowPassIcon} alt="Hide" /> : <img src={HidePassIcon} alt='Show' />}
                        </button>
                    )}                
                </div>
            )}
            <ErrorMessage name={name} component="div" className="text-[#ff0000] text-sm mt-1" />
        </>
    );
};

export default FormikInput;
