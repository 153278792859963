import Layout from "../../components/Layout"
import { getHomeRentLandRequests } from "../../store/actions/homeRentSell";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import RentCard from "../../components/RentCard";
import authCheck from "../../shared/authCheck"

const RentLandListPage = () => {
    const dispatch = useDispatch()

    const [step, setStep] = useState(6)

    const { rentLandRequests } = useSelector((state) => ({
        rentLandRequests: state?.homeRentSell?.rentLandRequests,
    }));

    useEffect(() => {
        dispatch(getHomeRentLandRequests(0, step))
    }, [dispatch, step])

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[]);

    return (
        <Layout>
            <div className="container mx-[auto] mb-[90px]">
                <div className="flex justify-between items-center pb-[50px] mt-[100px]">
                    <h1 className="text-[24px] font-semibold">Agriculture Land Rent</h1>
                </div>
                <div className="grid md:grid-cols-3 gap-[50px]">
                    {rentLandRequests?.data && rentLandRequests?.data?.map(rentItem => {
                        return <RentCard type="land" rentItem={rentItem} />
                    })}
                </div>
                {rentLandRequests?.data?.length < rentLandRequests?.total &&
                    <div className="flex justify-center">
                        <button type="button" onClick={() => setStep(step + 6)} className="uppercase text-main text-[18px] underline mt-[60px] mx-[auto]">View More</button>
                    </div>
                }
            </div>
        </Layout>
    )
}

export default authCheck(RentLandListPage);