import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";

const initialState = {
    dashboardData: null,
    error: null,
    loading: false,
};

const getDashboardDataStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getDashboardDataSuccess = (state, action) => {
    return updateObject(state, {
        dashboardData: action.dashboardData,
        error: null,
        loading: false,
    });
};

const getDashboardDataFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DASHBOARD_GET_DASHBOARD_DATA_START:
            return getDashboardDataStart(state, action);
        case actionTypes.DASHBOARD_GET_DASHBOARD_DATA_SUCCESS:
            return getDashboardDataSuccess(state, action);
        case actionTypes.DASHBOARD_GET_DASHBOARD_DATA_FAIL:
            return getDashboardDataFail(state, action);
        default:
            return state;
    }
};

export default dashboardReducer;
