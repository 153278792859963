// Auth
export const AUTH_LOGIN_START = "AUTH_LOGIN_START";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";

export const AUTH_REGISTER_START = "AUTH_REGISTER_START";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_FAIL = "AUTH_REGISTER_FAIL";

export const AUTH_REGISTER_CONFIRM_START = "AUTH_REGISTER_CONFIRM_START";
export const AUTH_REGISTER_CONFIRM_SUCCESS = "AUTH_REGISTER_CONFIRM_SUCCESS";
export const AUTH_REGISTER_CONFIRM_FAIL = "AUTH_REGISTER_CONFIRM_FAIL";

export const AUTH_RESEND_OTP_START = "AUTH_RESEND_OTP_START";
export const AUTH_RESEND_OTP_SUCCESS = "AUTH_RESEND_OTP_SUCCESS";
export const AUTH_RESEND_OTP_FAIL = "AUTH_RESEND_OTP_FAIL";

export const AUTH_LOGOUT = "AUTH_LOGOUT";

// Profile
export const PROFILE_UPDATE_PASSWORD_START = "PROFILE_UPDATE_PASSWORD_START"
export const PROFILE_UPDATE_PASSWORD_SUCCESS = "PROFILE_UPDATE_PASSWORD_SUCCESS"
export const PROFILE_UPDATE_PASSWORD_FAIL = "PROFILE_UPDATE_PASSWORD_FAIL"

export const PROFILE_GET_USER_PROFILE_START = "PROFILE_GET_USER_PROFILE_START"
export const PROFILE_GET_USER_PROFILE_SUCCESS = "PROFILE_GET_USER_PROFILE_SUCCESS"
export const PROFILE_GET_USER_PROFILE_FAIL = "PROFILE_GET_USER_PROFILE_FAIL"

export const PROFILE_UPDATE_USER_PROFILE_START = "PROFILE_UPDATE_USER_PROFILE_START"
export const PROFILE_UPDATE_USER_PROFILE_SUCCESS = "PROFILE_UPDATE_USER_PROFILE_SUCCESS"
export const PROFILE_UPDATE_USER_PROFILE_FAIL = "PROFILE_UPDATE_USER_PROFILE_FAIL"

export const PROFILE_GET_PROFILE_IMAGE_START = "PROFILE_GET_PROFILE_IMAGE_START"
export const PROFILE_GET_PROFILE_IMAGE_SUCCESS = "PROFILE_GET_PROFILE_IMAGE_SUCCESS"
export const PROFILE_GET_PROFILE_IMAGE_FAIL = "PROFILE_GET_PROFILE_IMAGE_FAIL"

export const PROFILE_UPDATE_PROFILE_IMAGE_START = "PROFILE_UPDATE_PROFILE_IMAGE_START"
export const PROFILE_UPDATE_PROFILE_IMAGE_SUCCESS = "PROFILE_UPDATE_PROFILE_IMAGE_SUCCESS"
export const PROFILE_UPDATE_PROFILE_IMAGE_FAIL = "PROFILE_UPDATE_PROFILE_IMAGE_FAIL"

// Dashboard
export const DASHBOARD_GET_DASHBOARD_DATA_START = "DASHBOARD_GET_DASHBOARD_DATA_START";
export const DASHBOARD_GET_DASHBOARD_DATA_SUCCESS = "DASHBOARD_GET_DASHBOARD_DATA_SUCCESS";
export const DASHBOARD_GET_DASHBOARD_DATA_FAIL = "DASHBOARD_GET_DASHBOARD_DATA_FAIL";

// Tips
export const GET_TIPS_START = "GET_TIPS_START";
export const GET_TIPS_SUCCESS = "GET_TIPS_SUCCESS";
export const GET_TIPS_FAIL = "GET_TIPS_FAIL";

// Diagnosis
export const GET_DIAGNOSIS_REQUEST_START = 'GET_DIAGNOSIS_REQUEST_START';
export const GET_DIAGNOSIS_REQUEST_SUCCESS = 'GET_DIAGNOSIS_REQUEST_SUCCESS';
export const GET_DIAGNOSIS_REQUEST_FAIL = 'GET_DIAGNOSIS_REQUEST_FAIL';

export const CREATE_DIAGNOSIS_REQUEST_START = 'CREATE_DIAGNOSIS_REQUEST_START';
export const CREATE_DIAGNOSIS_REQUEST_SUCCESS = 'CREATE_DIAGNOSIS_REQUEST_SUCCESS';
export const CREATE_DIAGNOSIS_REQUEST_FAIL = 'CREATE_DIAGNOSIS_REQUEST_FAIL';

export const GET_DIAGNOSIS_IMAGE_REQUEST_START = 'GET_DIAGNOSIS_IMAGE_REQUEST_START';
export const GET_DIAGNOSIS_IMAGE_REQUEST_SUCCESS = 'GET_DIAGNOSIS_IMAGE_REQUEST_SUCCESS';
export const GET_DIAGNOSIS_IMAGE_REQUEST_FAIL = 'GET_DIAGNOSIS_IMAGE_REQUEST_FAIL';

export const UPDATE_IMAGE_START = 'UPDATE_IMAGE_START';
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS';
export const UPDATE_IMAGE_FAIL = 'UPDATE_IMAGE_FAIL';

// Categories
export const GET_CATEGORIES_START = "GET_CATEGORIES_START";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

// Sell Requests
export const GET_SELL_REQUESTS_START = "GET_SELL_REQUESTS_START";
export const GET_SELL_REQUESTS_SUCCESS = "GET_SELL_REQUESTS_SUCCESS";
export const GET_SELL_REQUESTS_FAIL = "GET_SELL_REQUESTS_FAIL";

export const CREATE_SELL_REQUEST_START = "CREATE_SELL_REQUEST_START";
export const CREATE_SELL_REQUEST_SUCCESS = "CREATE_SELL_REQUEST_SUCCESS";
export const CREATE_SELL_REQUEST_FAIL = "CREATE_SELL_REQUEST_FAIL";

export const EDIT_SELL_REQUEST_START = "EDIT_SELL_REQUEST_START";
export const EDIT_SELL_REQUEST_SUCCESS = "EDIT_SELL_REQUEST_SUCCESS";
export const EDIT_SELL_REQUEST_FAIL = "EDIT_SELL_REQUEST_FAIL";

export const DELETE_SELL_REQUEST_START = "DELETE_SELL_REQUEST_START";
export const DELETE_SELL_REQUEST_SUCCESS = "DELETE_SELL_REQUEST_SUCCESS";
export const DELETE_SELL_REQUEST_FAIL = "DELETE_SELL_REQUEST_FAIL";

export const GET_SELL_REQUEST_IMAGE_START = "GET_SELL_REQUEST_IMAGE_START";
export const GET_SELL_REQUEST_IMAGE_SUCCESS = "GET_SELL_REQUEST_IMAGE_SUCCESS";
export const GET_SELL_REQUEST_IMAGE_FAIL = "GET_SELL_REQUEST_IMAGE_FAIL";

export const EDIT_SELL_REQUEST_IMAGE_START = "EDIT_SELL_REQUEST_IMAGE_START";
export const EDIT_SELL_REQUEST_IMAGE_SUCCESS = "EDIT_SELL_REQUEST_IMAGE_SUCCESS";
export const EDIT_SELL_REQUEST_IMAGE_FAIL = "EDIT_SELL_REQUEST_IMAGE_FAIL";

// Buy Requests
export const GET_BUY_REQUESTS_START = "GET_BUY_REQUESTS_START";
export const GET_BUY_REQUESTS_SUCCESS = "GET_BUY_REQUESTS_SUCCESS";
export const GET_BUY_REQUESTS_FAIL = "GET_BUY_REQUESTS_FAIL";

export const CREATE_BUY_REQUEST_START = "CREATE_BUY_REQUEST_START";
export const CREATE_BUY_REQUEST_SUCCESS = "CREATE_BUY_REQUEST_SUCCESS";
export const CREATE_BUY_REQUEST_FAIL = "CREATE_BUY_REQUEST_FAIL";

export const EDIT_BUY_REQUEST_START = "EDIT_BUY_REQUEST_START";
export const EDIT_BUY_REQUEST_SUCCESS = "EDIT_BUY_REQUEST_SUCCESS";
export const EDIT_BUY_REQUEST_FAIL = "EDIT_BUY_REQUEST_FAIL";

export const DELETE_BUY_REQUEST_START = "DELETE_BUY_REQUEST_START";
export const DELETE_BUY_REQUEST_SUCCESS = "DELETE_BUY_REQUEST_SUCCESS";
export const DELETE_BUY_REQUEST_FAIL = "DELETE_BUY_REQUEST_FAIL";

export const GET_BUY_REQUEST_IMAGE_START = "GET_BUY_REQUEST_IMAGE_START";
export const GET_BUY_REQUEST_IMAGE_SUCCESS = "GET_BUY_REQUEST_IMAGE_SUCCESS";
export const GET_BUY_REQUEST_IMAGE_FAIL = "GET_BUY_REQUEST_IMAGE_FAIL";

export const EDIT_BUY_REQUEST_IMAGE_START = "EDIT_BUY_REQUEST_IMAGE_START";
export const EDIT_BUY_REQUEST_IMAGE_SUCCESS = "EDIT_BUY_REQUEST_IMAGE_SUCCESS";
export const EDIT_BUY_REQUEST_IMAGE_FAIL = "EDIT_BUY_REQUEST_IMAGE_FAIL";

// Rent Land Requests
export const GET_RENT_LAND_REQUESTS_START = "GET_RENT_LAND_REQUESTS_START";
export const GET_RENT_LAND_REQUESTS_SUCCESS = "GET_RENT_LAND_REQUESTS_SUCCESS";
export const GET_RENT_LAND_REQUESTS_FAIL = "GET_RENT_LAND_REQUESTS_FAIL";

export const CREATE_RENT_LAND_REQUEST_START = "CREATE_RENT_LAND_REQUEST_START";
export const CREATE_RENT_LAND_REQUEST_SUCCESS = "CREATE_RENT_LAND_REQUEST_SUCCESS";
export const CREATE_RENT_LAND_REQUEST_FAIL = "CREATE_RENT_LAND_REQUEST_FAIL";

export const EDIT_RENT_LAND_REQUEST_START = "EDIT_RENT_LAND_REQUEST_START";
export const EDIT_RENT_LAND_REQUEST_SUCCESS = "EDIT_RENT_LAND_REQUEST_SUCCESS";
export const EDIT_RENT_LAND_REQUEST_FAIL = "EDIT_RENT_LAND_REQUEST_FAIL";

export const DELETE_RENT_LAND_REQUEST_START = "DELETE_RENT_LAND_REQUEST_START";
export const DELETE_RENT_LAND_REQUEST_SUCCESS = "DELETE_RENT_LAND_REQUEST_SUCCESS";
export const DELETE_RENT_LAND_REQUEST_FAIL = "DELETE_RENT_LAND_REQUEST_FAIL";

export const CREATE_RENT_LAND_REQUEST_IMAGE_START = "CREATE_RENT_LAND_REQUEST_IMAGE_START";
export const CREATE_RENT_LAND_REQUEST_IMAGE_SUCCESS = "CREATE_RENT_LAND_REQUEST_IMAGE_SUCCESS";
export const CREATE_RENT_LAND_REQUEST_IMAGE_FAIL = "CREATE_RENT_LAND_REQUEST_IMAGE_FAIL";

export const DELETE_RENT_LAND_REQUEST_IMAGE_START = "DELETE_RENT_LAND_REQUEST_IMAGE_START";
export const DELETE_RENT_LAND_REQUEST_IMAGE_SUCCESS = "DELETE_RENT_LAND_REQUEST_IMAGE_SUCCESS";
export const DELETE_RENT_LAND_REQUEST_IMAGE_FAIL = "DELETE_RENT_LAND_REQUEST_IMAGE_FAIL";

// Rent Lorry Requests
export const GET_RENT_LORRY_REQUESTS_START = "GET_RENT_LORRY_REQUESTS_START";
export const GET_RENT_LORRY_REQUESTS_SUCCESS = "GET_RENT_LORRY_REQUESTS_SUCCESS";
export const GET_RENT_LORRY_REQUESTS_FAIL = "GET_RENT_LORRY_REQUESTS_FAIL";

export const CREATE_RENT_LORRY_REQUEST_START = "CREATE_RENT_LORRY_REQUEST_START";
export const CREATE_RENT_LORRY_REQUEST_SUCCESS = "CREATE_RENT_LORRY_REQUEST_SUCCESS";
export const CREATE_RENT_LORRY_REQUEST_FAIL = "CREATE_RENT_LORRY_REQUEST_FAIL";

export const EDIT_RENT_LORRY_REQUEST_START = "EDIT_RENT_LORRY_REQUEST_START";
export const EDIT_RENT_LORRY_REQUEST_SUCCESS = "EDIT_RENT_LORRY_REQUEST_SUCCESS";
export const EDIT_RENT_LORRY_REQUEST_FAIL = "EDIT_RENT_LORRY_REQUEST_FAIL";

export const DELETE_RENT_LORRY_REQUEST_START = "DELETE_RENT_LORRY_REQUEST_START";
export const DELETE_RENT_LORRY_REQUEST_SUCCESS = "DELETE_RENT_LORRY_REQUEST_SUCCESS";
export const DELETE_RENT_LORRY_REQUEST_FAIL = "DELETE_RENT_LORRY_REQUEST_FAIL";

export const CREATE_RENT_LORRY_REQUEST_IMAGE_START = "CREATE_RENT_LORRY_REQUEST_IMAGE_START";
export const CREATE_RENT_LORRY_REQUEST_IMAGE_SUCCESS = "CREATE_RENT_LORRY_REQUEST_IMAGE_SUCCESS";
export const CREATE_RENT_LORRY_REQUEST_IMAGE_FAIL = "CREATE_RENT_LORRY_REQUEST_IMAGE_FAIL";

export const DELETE_RENT_LORRY_REQUEST_IMAGE_START = "DELETE_RENT_LORRY_REQUEST_IMAGE_START";
export const DELETE_RENT_LORRY_REQUEST_IMAGE_SUCCESS = "DELETE_RENT_LORRY_REQUEST_IMAGE_SUCCESS";
export const DELETE_RENT_LORRY_REQUEST_IMAGE_FAIL = "DELETE_RENT_LORRY_REQUEST_IMAGE_FAIL";

// Price Periods
export const GET_PRICE_PERIODS_START = "GET_PRICE_PERIODS_START";
export const GET_PRICE_PERIODS_SUCCESS = "GET_PRICE_PERIODS_SUCCESS";
export const GET_PRICE_PERIODS_FAIL = "GET_PRICE_PERIODS_FAIL";

// Notifications
export const GET_NOTIFICATIONS_START = "GET_NOTIFICATIONS_START";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

export const MARK_ALL_AS_READ_START = "MARK_ALL_AS_READ_START";
export const MARK_ALL_AS_READ_SUCCESS = "MARK_ALL_AS_READ_SUCCESS";
export const MARK_ALL_AS_READ_FAIL = "MARK_ALL_AS_READ_FAIL";

// Order History Land
export const GET_ORDER_HISTORY_LAND_START = "GET_ORDER_HISTORY_LAND_START";
export const GET_ORDER_HISTORY_LAND_SUCCESS = "GET_ORDER_HISTORY_LAND_SUCCESS";
export const GET_ORDER_HISTORY_LAND_FAIL = "GET_ORDER_HISTORY_LAND_FAIL";

export const GET_ORDER_HISTORY_IMAGE_START = "GET_ORDER_HISTORY_IMAGE_START";
export const GET_ORDER_HISTORY_IMAGE_SUCCESS = "GET_ORDER_HISTORY_IMAGE_SUCCESS";
export const GET_ORDER_HISTORY_IMAGE_FAIL = "GET_ORDER_HISTORY_IMAGE_FAIL";

export const GET_ORDER_HISTORY_LAND_STATUS_START = "GET_ORDER_HISTORY_LAND_STATUS_START";
export const GET_ORDER_HISTORY_LAND_STATUS_SUCCESS = "GET_ORDER_HISTORY_LAND_STATUS_SUCCESS";
export const GET_ORDER_HISTORY_LAND_STATUS_FAIL = "GET_ORDER_HISTORY_LAND_STATUS_FAIL";

export const CHANGE_ORDER_LAND_STATUS_START = "CHANGE_ORDER_LAND_STATUS_START";
export const CHANGE_ORDER_LAND_STATUS_SUCCESS = "CHANGE_ORDER_LAND_STATUS_SUCCESS";
export const CHANGE_ORDER_LAND_STATUS_FAIL = "CHANGE_ORDER_LAND_STATUS_FAIL";

// Order History Lorry
export const GET_ORDER_HISTORY_LORRY_START = "GET_ORDER_HISTORY_LORRY_START";
export const GET_ORDER_HISTORY_LORRY_SUCCESS = "GET_ORDER_HISTORY_LORRY_SUCCESS";
export const GET_ORDER_HISTORY_LORRY_FAIL = "GET_ORDER_HISTORY_LORRY_FAIL";

export const GET_ORDER_HISTORY_LORRY_IMAGE_START = "GET_ORDER_HISTORY_LORRY_IMAGE_START";
export const GET_ORDER_HISTORY_LORRY_IMAGE_SUCCESS = "GET_ORDER_HISTORY_LORRY_IMAGE_SUCCESS";
export const GET_ORDER_HISTORY_LORRY_IMAGE_FAIL = "GET_ORDER_HISTORY_LORRY_IMAGE_FAIL";

export const GET_ORDER_HISTORY_LORRY_STATUS_START = "GET_ORDER_HISTORY_LORRY_STATUS_START";
export const GET_ORDER_HISTORY_LORRY_STATUS_SUCCESS = "GET_ORDER_HISTORY_LORRY_STATUS_SUCCESS";
export const GET_ORDER_HISTORY_LORRY_STATUS_FAIL = "GET_ORDER_HISTORY_LORRY_STATUS_FAIL";

export const CHANGE_ORDER_LORRY_STATUS_START = "CHANGE_ORDER_LORRY_STATUS_START";
export const CHANGE_ORDER_LORRY_STATUS_SUCCESS = "CHANGE_ORDER_LORRY_STATUS_SUCCESS";
export const CHANGE_ORDER_LORRY_STATUS_FAIL = "CHANGE_ORDER_LORRY_STATUS_FAIL";

// Order History Sell
export const GET_ORDER_HISTORY_SELL_START = "GET_ORDER_HISTORY_SELL_START";
export const GET_ORDER_HISTORY_SELL_SUCCESS = "GET_ORDER_HISTORY_SELL_SUCCESS";
export const GET_ORDER_HISTORY_SELL_FAIL = "GET_ORDER_HISTORY_SELL_FAIL";

export const GET_ORDER_HISTORY_SELL_IMAGE_START = "GET_ORDER_HISTORY_SELL_IMAGE_START";
export const GET_ORDER_HISTORY_SELL_IMAGE_SUCCESS = "GET_ORDER_HISTORY_SELL_IMAGE_SUCCESS";
export const GET_ORDER_HISTORY_SELL_IMAGE_FAIL = "GET_ORDER_HISTORY_SELL_IMAGE_FAIL";

export const GET_ORDER_HISTORY_SELL_STATUS_START = "GET_ORDER_HISTORY_SELL_STATUS_START";
export const GET_ORDER_HISTORY_SELL_STATUS_SUCCESS = "GET_ORDER_HISTORY_SELL_STATUS_SUCCESS";
export const GET_ORDER_HISTORY_SELL_STATUS_FAIL = "GET_ORDER_HISTORY_SELL_STATUS_FAIL";

export const CHANGE_ORDER_SELL_STATUS_START = "CHANGE_ORDER_SELL_STATUS_START";
export const CHANGE_ORDER_SELL_STATUS_SUCCESS = "CHANGE_ORDER_SELL_STATUS_SUCCESS";
export const CHANGE_ORDER_SELL_STATUS_FAIL = "CHANGE_ORDER_SELL_STATUS_FAIL";

// Home Sell Requests
export const HOME_GET_SELL_REQUESTS_START = "HOME_GET_SELL_REQUESTS_START";
export const HOME_GET_SELL_REQUESTS_SUCCESS = "HOME_GET_SELL_REQUESTS_SUCCESS";
export const HOME_GET_SELL_REQUESTS_FAIL = "HOME_GET_SELL_REQUESTS_FAIL";

// Home Rent Requests Lorry
export const HOME_GET_RENT_REQUESTS_LORRY_START = "HOME_GET_RENT_REQUESTS_LORRY_START";
export const HOME_GET_RENT_REQUESTS_LORRY_SUCCESS = "HOME_GET_RENT_REQUESTS_LORRY_SUCCESS";
export const HOME_GET_RENT_REQUESTS_LORRY_FAIL = "HOME_GET_RENT_REQUESTS_LORRY_FAIL";

// Home Rent Requests Land
export const HOME_GET_RENT_REQUESTS_LAND_START = "HOME_GET_RENT_REQUESTS_LAND_START";
export const HOME_GET_RENT_REQUESTS_LAND_SUCCESS = "HOME_GET_RENT_REQUESTS_LAND_SUCCESS";
export const HOME_GET_RENT_REQUESTS_LAND_FAIL = "HOME_GET_RENT_REQUESTS_LAND_FAIL";

// Selected Product
export const SET_SELECTED_PRODUCT_START = "SET_SELECTED_PRODUCT_START";
export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";
export const SET_SELECTED_PRODUCT_FAIL = "HOME_GET_RENT_REQUESTS_LAND_FAIL";

// ORDERS
// Land Booked Days
export const GET_LAND_BOOKED_DAYS_START = "GET_LAND_BOOKED_DAYS_START";
export const GET_LAND_BOOKED_DAYS_SUCCESS = "GET_LAND_BOOKED_DAYS_SUCCESS";
export const GET_LAND_BOOKED_DAYS_FAIL = "GET_LAND_BOOKED_DAYS_FAIL";

// Land Order
export const MAKE_LAND_ORDER_START = "MAKE_LAND_ORDER_START";
export const MAKE_LAND_ORDER_SUCCESS = "MAKE_LAND_ORDER_SUCCESS";
export const MAKE_LAND_ORDER_FAIL = "MAKE_LAND_ORDER_FAIL";

// Land Total Price
export const GET_LAND_TOTAL_PRICE_START = "GET_LAND_TOTAL_PRICE_START";
export const GET_LAND_TOTAL_PRICE_SUCCESS = "GET_LAND_TOTAL_PRICE_SUCCESS";
export const GET_LAND_TOTAL_PRICE_FAIL = "GET_LAND_TOTAL_PRICE_FAIL";

// Lorry Booked Days
export const GET_LORRY_BOOKED_DAYS_START = "GET_LORRY_BOOKED_DAYS_START";
export const GET_LORRY_BOOKED_DAYS_SUCCESS = "GET_LORRY_BOOKED_DAYS_SUCCESS";
export const GET_LORRY_BOOKED_DAYS_FAIL = "GET_LORRY_BOOKED_DAYS_FAIL";

// Lorry Order
export const MAKE_LORRY_ORDER_START = "MAKE_LORRY_ORDER_START";
export const MAKE_LORRY_ORDER_SUCCESS = "MAKE_LORRY_ORDER_SUCCESS";
export const MAKE_LORRY_ORDER_FAIL = "MAKE_LORRY_ORDER_FAIL";

// Product Order
export const MAKE_PRODUCT_ORDER_START = "MAKE_PRODUCT_ORDER_START";
export const MAKE_PRODUCT_ORDER_SUCCESS = "MAKE_PRODUCT_ORDER_SUCCESS";
export const MAKE_PRODUCT_ORDER_FAIL = "MAKE_PRODUCT_ORDER_FAIL";

// Lorry Total Price
export const GET_LORRY_TOTAL_PRICE_START = "GET_LORRY_TOTAL_PRICE_START";
export const GET_LORRY_TOTAL_PRICE_SUCCESS = "GET_LORRY_TOTAL_PRICE_SUCCESS";
export const GET_LORRY_TOTAL_PRICE_FAIL = "GET_LORRY_TOTAL_PRICE_FAIL";

// Shipping Details
export const GET_SHIPPING_DETAILS_START = "GET_SHIPPING_DETAILS_START";
export const GET_SHIPPING_DETAILS_SUCCESS = "GET_SHIPPING_DETAILS_SUCCESS";
export const GET_SHIPPING_DETAILS_FAIL = "GET_SHIPPING_DETAILS_FAIL";


// Cart Details
export const GET_CART_DETAILS_START = "GET_CART_DETAILS_START";
export const GET_CART_DETAILS_SUCCESS = "GET_CART_DETAILS_SUCCESS";
export const GET_CART_DETAILS_FAIL = "GET_CART_DETAILS_FAIL";

export const ADD_TO_CART_START = "ADD_TO_CART_START";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILED = "ADD_TO_CART_FAILED";

export const EDIT_CART_DETAIL_START = "EDIT_CART_DETAIL_START";
export const EDIT_CART_DETAIL_SUCCESS = "EDIT_CART_DETAIL_SUCCESS";
export const EDIT_CART_DETAIL_FAIL = "EDIT_CART_DETAIL_FAIL";

export const DELETE_CART_ITEM_START = "DELETE_CART_ITEM_START";
export const DELETE_CART_ITEM_SUCCESS = "DELETE_CART_ITEM_SUCCESS";
export const DELETE_CART_ITEM_FAILED = "DELETE_CART_ITEM_FAILED";

// subscription details

export const SET_SUBSCRIPTION_DETAILS_START = "SET_SUBSCRIPTION_DETAILS_START";
export const SET_SUBSCRIPTION_DETAILS_SUCCESS = "SET_SUBSCRIPTION_DETAILS_SUCCESS";
export const SET_SUBSCRIPTION_DETAILS_FAILED = "SET_SUBSCRIPTION_DETAILS_FAILED";
export const SET_USER_SUBSCRIPTION_STATUS = "SET_USER_SUBSCRIPTION_STATUS";
export const SET_SELLER_SUBSCRIPTIONS_STATUS = "SET_SELLER_SUBSCRIPTIONS_STATUS";
export const SET_BUYER_SUBSCRIPTIONS_STATUS = "SET_BUYER_SUBSCRIPTIONS_STATUS";
export const SET_SUBSCRIPTIONS_STATUS_SUCCESS = "SET_SUBSCRIPTIONS_STATUS_SUCCESS";
export const RESET_SUBSCRIPTION_STATE = "RESET_SUBSCRIPTION_STATE";

// Terms and conditions

export const SET_TERMS_AND_CONDITIONS_START = "SET_TERMS_AND_CONDITIONS_START";
export const SET_TERMS_AND_CONDITIONS_SUCCESS = "SET_TERMS_AND_CONDITIONS_SUCCESS";
export const SET_TERMS_AND_CONDITIONS_FAILED = "SET_TERMS_AND_CONDITIONS_FAILED";