import React, { useState, useEffect } from 'react';

import ClearSkyIcon from "../assets/weather-widget/clear-sky.svg"
import FewCloudsIcon from "../assets/weather-widget/few-clouds.svg"
import ScatteredCloudsIcon from "../assets/weather-widget/scattered-clouds.svg"
import BrockenCloudsIcon from "../assets/weather-widget/broken-clouds.svg"
import ShowerRainIcon from "../assets/weather-widget/shower-rain.svg"
import RainIcon from "../assets/weather-widget/rain.svg"
import ThunderstormIcon from "../assets/weather-widget/thunderstorm.svg"
import SnowIcon from "../assets/weather-widget/snow.svg"
import MistIcon from "../assets/weather-widget/mist.svg"

import "./WeatherWidget.scss"

const WeatherWidget = ({ city, setWeather, isDashboard }) => {
    const [weatherData, setWeatherData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiKey = process.env.REACT_APP_WEATHER_API_KEY;

    useEffect(() => {
        if (city?.lat && city?.lon) {
            fetchWeatherData(city?.lat, city?.lon)
        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchWeatherData(latitude, longitude);
                },
                (error) => {
                    console.error(error);
                    setError('You have denied access to your location.');
                    setLoading(false);
                }
            );
        } else {
            setError('Geolocation is not supported in your browser.');
            setLoading(false);
        }
    }, [city?.lat, city?.lon]);

    const fetchWeatherData = async (latitude, longitude) => {
        try {
            const response = await fetch(
                `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`
            );
            if (!response.ok) {
                throw new Error('Weather data not available');
            }
            const data = await response.json();
            const aggregatedData = aggregateWeatherData(data.list);
            setWeatherData && setWeatherData({
                city: data.city.name,
                forecast: aggregatedData,
                currentTemp: Math.round(data.list[0].main.temp),
            });
            setWeather && setWeather(data?.list[0])
            setLoading(false);
        } catch (error) {
            console.error(error);
            setError('Unable to fetch weather data.');
            setLoading(false);
        }
    };

    const aggregateWeatherData = (weatherList) => {
        const aggregatedData = {};
        weatherList.forEach((item) => {
            const date = item.dt_txt.split(' ')[0];
            if (!aggregatedData[date]) {
                aggregatedData[date] = {
                    minTemp: item.main.temp,
                    maxTemp: item.main.temp,
                    weather: item.weather[0].description,
                    icon: item.weather[0].icon,
                };
            } else {
                if (item.main.temp < aggregatedData[date].minTemp) {
                    aggregatedData[date].minTemp = item.main.temp;
                }
                if (item.main.temp > aggregatedData[date].maxTemp) {
                    aggregatedData[date].maxTemp = item.main.temp;
                }
            }
        });
        return aggregatedData;
    };

    const getWeatherIcon = (weather) => {
        switch (weather) {
            case "clear sky":
                return ClearSkyIcon;
            case "few clouds":
                return FewCloudsIcon;
            case "scattered clouds":
                return ScatteredCloudsIcon;
            case "broken clouds":
            case "overcast clouds":
                return BrockenCloudsIcon;
            case "shower rain":
            case "light intensity drizzle":
            case "drizzle":
            case "heavy intensity drizzle":
            case "light intensity drizzle rain":
            case "drizzle rain":
            case "heavy intensity drizzle rain":
            case "shower rain and drizzle":
            case "heavy shower rain and drizzle":
            case "shower drizzle":
            case "light intensity shower rain":
            case "heavy intensity shower rain":
            case "ragged shower rain":
                return ShowerRainIcon;
            case "rain":
            case "light rain":
            case "moderate rain":
            case "heavy intensity rain":
            case "very heavy rain":
            case "extreme rain":
                return RainIcon;
            case "thunderstorm":
            case "thunderstorm with light rain":
            case "thunderstorm with rain":
            case "thunderstorm with heavy rain":
            case "light thunderstorm":
            case "heavy thunderstorm":
            case "ragged thunderstorm":
            case "thunderstorm with light drizzle":
            case "thunderstorm with drizzle":
            case "thunderstorm with heavy drizzle":
                return ThunderstormIcon;
            case "snow":
            case "freezing rain":
            case "light snow":
            case "heavy snow":
            case "sleet":
            case "light shower sleet":
            case "shower sleet":
            case "light rain and snow":
            case "rain and snow":
            case "light shower snow":
            case "shower snow":
            case "heavy shower snow":
                return SnowIcon;
            case "mist":
            case "smoke":
            case "haze":
            case "sand/dust whirls":
            case "fog":
            case "sand":
            case "dust":
            case "volcanic ash":
            case "squalls":
            case "tornado":
                return MistIcon
        }
    }

    return (
        <div className="h-ful-50px">
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : weatherData ? (
                <>
                    <div className="flex h-ful mb-32px">
                        <div
                            className={`rounded-lg w-full pt-[16px] pb-[10px] pl-[27px] pr-[13px] ${!isDashboard ? "xl:px-[60px] weather-widget-main-container" : "dashboard-weather-widget-main-container"} grid justify-between`}
                            style={{
                                background:
                                    'linear-gradient(225deg, rgba(0, 178, 255, 0.20) 0%, rgba(255, 199, 0, 0.20) 100%)',
                                backdropFilter: 'blur(2px)',
                            }}>
                            <div className='main-city-area'>
                                <p className={`main-city-area-city text-[18px] sm:text-[24px] ${!isDashboard && "xl:text-[64px]"} font-medium`}>{weatherData.city}</p>
                                <p className={`text-[#484848] text-[14px] sm:text-[16px] ${!isDashboard && "xl:text-[32px]"}`}>
                                    {new Date(Object.keys(weatherData.forecast)[0]).toLocaleString('en-US', { weekday: 'long' })}
                                </p>
                            </div>
                            <p className={`justify-self-end sm:justify-self-start self-start sm:self-end ${!isDashboard && "xl:self-center xl:grid"} flex gap-x-[15px] main-minmax-area`}>
                                <span className={`flex ${!isDashboard && "xl:grid xl:justify-items-center"}`}>
                                    Max: <span className={`text-[14px] sm:text-[16px] ${!isDashboard && "xl:text-[36px]"} font-semibold`}>{Math.round(weatherData.forecast[Object.keys(weatherData.forecast)[0]].maxTemp)}°</span>
                                </span>
                                <span className={`flex ${!isDashboard && "xl:grid xl:justify-items-center"}`}>
                                    Min: <span className={`text-[14px] sm:text-[16px] ${!isDashboard && "xl:text-[36px]"} font-semibold`}>{Math.round(weatherData.forecast[Object.keys(weatherData.forecast)[0]].minTemp)}°</span>
                                </span>

                            </p>

                            <p className={`text-[32px] sm:text-[48px] ${!isDashboard && "xl:text-[128px]"} font-semibold mt-[7px] main-temperature-area`}>{weatherData.currentTemp}°</p>
                            <div className='grid justify-items-center self-center main-weather-area'>
                                <img src={getWeatherIcon(weatherData.forecast[Object.keys(weatherData.forecast)[0]].weather)} className='h-[75px]' />
                                <p className={`capitalize text-[14px] sm:text-[18px] text-center ${!isDashboard && "xl:text-[32px]"} text-[#484848] text-cente mt-[10px]`}>{weatherData.forecast[Object.keys(weatherData.forecast)[0]].weather}</p>
                            </div>
                        </div>
                    </div>
                    {/* Forecast for Next 6 Days */}
                    <div className="grid grid-cols-5 gap-x-[14px] mt-[32px]">
                        {Object.entries(weatherData.forecast).slice(1, 6).map(([date, data]) => {
                            const day = new Date(date).toLocaleString('en-US', { weekday: 'short' });
                            return (
                                <div
                                    key={date}
                                    className={`rounded-lg px-[7px] py-[15px] ${!isDashboard ? "xl:p-[20px] xl:gap-[16px] weather-widget-day-container" : "dashboard-weather-widget-day-container"} text-center grid justify-items-center`}
                                    style={{
                                        background:
                                            'linear-gradient(225deg, rgba(0, 178, 255, 0.20) 0%, rgba(255, 199, 0, 0.20) 100%)',
                                        backdropFilter: 'blur(2px)',
                                    }}
                                >
                                    <p className={`uppercase font-medium mb-[9px] day-day-area ${!isDashboard && "xl:text-[20px]"}`}>{day}</p>
                                    <img src={getWeatherIcon(data.weather)} className='h-full day-weather-icon-area' />
                                    <div className='day-min-area'>
                                        <p className={`text-[10px] mt-[9px] ${!isDashboard && "xl:text-[16px]"}`}>Min</p>
                                        <p className={`text-[14px] font-semibold mt-[2px] ${!isDashboard && "xl:text-[30px]"}`}>{Math.round(data.minTemp)}°</p>
                                    </div>
                                    <div className='day-max-area'>
                                        <p className={`text-[10px] mt-[9px] ${!isDashboard && "xl:text-[16px]"}`}>Max</p>
                                        <p className={`text-[14px] font-semibold mt-[2px] ${!isDashboard && "xl:text-[30px]"}`}>{Math.round(data.maxTemp)}°</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default WeatherWidget;