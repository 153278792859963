// actions.js
import axios from "../../axios-api";
import * as actionTypes from "./types";
import { arrayBufferToBase64, getErrors, getResData } from "../../shared/utility";

export const getBuyRequestsStart = () => {
    return {
        type: actionTypes.GET_BUY_REQUESTS_START,
    };
};

export const getBuyRequestsSuccess = (buyRequests) => {
    return {
        type: actionTypes.GET_BUY_REQUESTS_SUCCESS,
        buyRequests,
    };
};

export const getBuyRequestsFailed = (error) => {
    return {
        type: actionTypes.GET_BUY_REQUESTS_FAIL,
        error,
    };
};

export const getBuyRequests = (start, length, name) => {
    return (dispatch) => {
        dispatch(getBuyRequestsStart());

        let url = "/seller/buy/request";

        const params = {};

        if (start !== undefined) {
            params.start = start;
        }

        if (length !== undefined) {
            params.length = length;
        }

        if (name !== undefined) {
            params.name = name;
        }

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getBuyRequestsSuccess(data));
            })
            .catch((err) =>
                dispatch(getBuyRequestsFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const createBuyRequestStart = () => {
    return {
        type: actionTypes.CREATE_BUY_REQUEST_START,
    };
};

export const createBuyRequestSuccess = (createRes) => {
    return {
        type: actionTypes.CREATE_BUY_REQUEST_SUCCESS,
        createRes,
    };
};

export const createBuyRequestFailed = (error) => {
    return {
        type: actionTypes.CREATE_BUY_REQUEST_FAIL,
        error,
    };
};

export const createBuyRequest = (data) => {
    return (dispatch) => {
        dispatch(createBuyRequestStart());

        return axios
            .post(`/seller/buy/request`, data)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(createBuyRequestSuccess(data));
            })
            .catch((err) =>
                dispatch(createBuyRequestFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const editBuyRequestStart = () => {
    return {
        type: actionTypes.EDIT_BUY_REQUEST_START,
    };
};

export const editBuyRequestSuccess = (editRes) => {
    return {
        type: actionTypes.EDIT_BUY_REQUEST_SUCCESS,
        editRes,
    };
};

export const editBuyRequestFailed = (error) => {
    return {
        type: actionTypes.EDIT_BUY_REQUEST_FAIL,
        error,
    };
};

export const editBuyRequest = (id, data) => {
    return (dispatch) => {
        dispatch(editBuyRequestStart());

        return axios
            .put(`/seller/buy/request`, { id: id, ...data })
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(editBuyRequestSuccess(data));
            })
            .catch((err) =>
                dispatch(editBuyRequestFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const deleteBuyRequestStart = () => {
    return {
        type: actionTypes.DELETE_BUY_REQUEST_START,
    };
};

export const deleteBuyRequestSuccess = (deleteRes) => {
    return {
        type: actionTypes.DELETE_BUY_REQUEST_SUCCESS,
        deleteRes,
    };
};

export const deleteBuyRequestFailed = (error) => {
    return {
        type: actionTypes.DELETE_BUY_REQUEST_FAIL,
        error,
    };
};

export const deleteBuyRequest = (id) => {
    return (dispatch) => {
        dispatch(deleteBuyRequestStart());

        return axios
            .delete(`/seller/buy/request/${id}`)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(deleteBuyRequestSuccess(data));
            })
            .catch((err) =>
                dispatch(deleteBuyRequestFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const getBuyRequestImage = (id) => {
    return axios
        .get(`/seller/buy/request/image/${id}`, {
            responseType: 'arraybuffer',// Tell Axios to treat the response as an array buffer
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if (!response) throw new Error("No Response");

            const data = response.data;
            const base64String = arrayBufferToBase64(data);

            return base64String;
        })
        .catch((err) => getErrors(err));
};

// updateBuyRequestImage
export const updateBuyRequestImageStart = () => {
    return {
        type: actionTypes.EDIT_BUY_REQUEST_IMAGE_START,
    };
};

export const updateBuyRequestImageSuccess = (updateBuyRequestImageRes) => {
    return {
        type: actionTypes.EDIT_BUY_REQUEST_IMAGE_SUCCESS,
        updateBuyRequestImageRes,
    };
};

export const updateBuyRequestImageFailed = (error) => {
    return {
        type: actionTypes.EDIT_BUY_REQUEST_IMAGE_FAIL,
        error,
    };
};

export const updateBuyRequestImage = (data) => {
    return (dispatch) => {
        dispatch(updateBuyRequestImageStart());

        return axios
            .put(`/seller/buy/request/image`, data)
            .then(async (response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return await dispatch(updateBuyRequestImageSuccess(data.data));
            })
            .catch((err) => dispatch(updateBuyRequestImageFailed(getErrors(err))));
    };
};
