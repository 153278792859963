import * as actionTypes from "./types";
import axios from "../../axios-api";
import { getErrors, getResData } from "../../shared/utility";

// getDashboardData
export const getDashboardDataStart = () => {
    return {
        type: actionTypes.DASHBOARD_GET_DASHBOARD_DATA_START,
    };
};

export const getDashboardDataSuccess = (dashboardData) => {
    return {
        type: actionTypes.DASHBOARD_GET_DASHBOARD_DATA_SUCCESS,
        dashboardData,
    };
};

export const getDashboardDataFailed = (error) => {
    return {
        type: actionTypes.DASHBOARD_GET_DASHBOARD_DATA_FAIL,
        error,
    };
};

export const getDashboardData = () => {
    return (dispatch) => {
        dispatch(getDashboardDataStart());

        return axios
            .get(`/seller/dashboard`) // Assuming this is the correct URL for the dashboard endpoint
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getDashboardDataSuccess(data.data));
            })
            .catch((err) => dispatch(getDashboardDataFailed(getErrors(err))));
    };
};
