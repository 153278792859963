import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";
import store from "../../storage";
import { logout } from "../actions/auth";

const initialState = {
    isSubscribed: null,
    subscriptionDetails: null,
    seller:{
        sellerSubscriptionEnable: null,
        sellerSubscriptionEnableCount: 0,
    },
    buyer: {
        buyerSubscriptionEnable: null,
        buyerSubscriptionEnableCount: 0,
    },
    error: null,
    loading: false,
}

const setSubscriptionStart = (state) => { 
    return updateObject (state, {error: null, loading: true});
}

const setSubscriptionDetailsSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        subscriptionDetails: action.payload,
        loading: false
    });
}

const setSubscriptionFailed = (state, action) => {
    return updateObject ( state,{ 
        error: action.payload,
        loading: false,
        subscriptionDetails : null,
        isSubscribed: null,    
    });
}

const setSubscriptionStatus = (state, action) => {
    return updateObject ( state,{ 
        isSubscribed: action.payload,    
        loading: false,
    });
}

const setSellerSubscriptionEnable = (state, action) => {
    if(state.seller.sellerSubscriptionEnable !== null && state.seller.sellerSubscriptionEnable !== action.payload){
        setTimeout(() => {
            store.dispatch(logout());
        }, 100);
    }

    return updateObject ( state, { 
        seller: {...state.seller ,
                ...{
                        sellerSubscriptionEnable: action.payload,
                        sellerSubscriptionEnableCount : ++state.seller.sellerSubscriptionEnableCount,
                    }
                }, 
        loading: false,
    });
}

const setBuyerSubscriptionEnable = (state, action) => {
    if(state.buyer.buyerSubscriptionEnable !== null && state.buyer.buyerSubscriptionEnable !== action.payload){
        setTimeout(() => {
            store.dispatch(logout());
        }, 100);
    }

    return updateObject ( state, {
        buyer: {...state.buyer ,
                ...{ 
                        buyerSubscriptionEnable: action.payload,
                        buyerSubscriptionEnableCount : ++state.buyer.buyerSubscriptionEnableCount,
                    }
                },
        loading: false,
    });
}

const getSubscriptionsStatusSuccess = (state) => {
    return updateObject ( state, {     
        loading: false,
    });
}

const resetData = (state) => {
    return updateObject ( state, {
        isSubscribed: null,
        subscriptionDetails: null,
        seller:{
            sellerSubscriptionEnable: null,
            sellerSubscriptionEnableCount: 0,
        },
        buyer: {
            buyerSubscriptionEnable: null,
            buyerSubscriptionEnableCount: 0,
        },
        error: null,
        loading: false,
    });
}

// setup reducer
const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SUBSCRIPTION_DETAILS_START:
            return setSubscriptionStart(state);
        case actionTypes.SET_SUBSCRIPTION_DETAILS_SUCCESS:
            return setSubscriptionDetailsSuccess(state, action);
        case actionTypes.SET_SUBSCRIPTION_DETAILS_FAILED: 
            return setSubscriptionFailed(state, action);
        case actionTypes.SET_USER_SUBSCRIPTION_STATUS:
            return setSubscriptionStatus(state, action);
        case actionTypes.SET_SELLER_SUBSCRIPTIONS_STATUS:
            return setSellerSubscriptionEnable(state, action);
        case actionTypes.SET_BUYER_SUBSCRIPTIONS_STATUS:
            return setBuyerSubscriptionEnable(state, action);
        case actionTypes.SET_SUBSCRIPTIONS_STATUS_SUCCESS:
            return getSubscriptionsStatusSuccess(state);
        case actionTypes.RESET_SUBSCRIPTION_STATE:
            return resetData(state);
        default:
            return state;
    }
}

export default subscriptionReducer;