import React from "react";
import ZapFast from "../../assets/zap-fast.svg";
function About() {
  return (
    <main className="my-20 px-2 md:px-20">
      <div className="flex flex-1 gap-16 flex-col sm:flex-row items-start justify-between">
        <div className="flex flex-1 gap-5 flex-col items-start justify-between">
            <div className="w-14 h-14 rounded-[50%] flex items-center justify-center bg-green-400 bg-opacity-20">
                <img src={ZapFast} className="" alt="zap" />
            </div>
          <p className="font-circular-500 text-4xl text-left">
            MFarm, the only best Africa farm company.
          </p>
          <p className="font-circular-450 text-xl text-[#475467] text-left">
            Our mission is to provide sustainable, high-quality agricultural and
            animal products while upholding the highest ethical and
            environmental standards.
          </p>
        </div>
        <div className="flex flex-1 gap-8 flex-col items-center justify-between">
          <div className="flex flex-col items-start justify-between">
            <p className="font-circular-500 text-xl text-left">Sustainability</p>
            <p className="font-circular-450 text-base text-[#475467] text-left">
              Promote sustainable farming practices that protect the environment
              and conserve natural resources.
            </p>
          </div>
          <div className="flex flex-col items-start justify-between">
            <p className="font-circular-500 text-xl text-left">Animal Welfare</p>
            <p className="font-circular-450 text-base text-[#475467] text-left">
              Show a commitment to the humane treatment of animals and their
              well-being in the farming process.
            </p>
          </div>
          <div className="flex flex-col items-start justify-between">
            <p className="font-circular-500 text-xl">
              Environmental Stewardship
            </p>
            <p className="font-circular-450 text-base text-[#475467] text-left">
              Demonstrate a commitment to responsible land and resource
              management, reducing the ecological footprint of farming.
            </p>
          </div>
        </div>
        <div className="flex flex-1 gap-8 flex-col items-start justify-between">
          <div className="flex flex-col items-start justify-between">
            <p className="font-circular-500 text-xl text-left">Quality</p>
            <p className="font-circular-450 text-base text-[#475467] text-left">
              Emphasize the importance of producing high-quality agricultural
              and animal products for the well-being of consumers.
            </p>
          </div>
          <div className="flex flex-col items-start justify-between">
            <p className="font-circular-500 text-xl text-left">Transparency</p>
            <p className="font-circular-450 text-base text-[#475467] text-left">
              Ensure transparency in your operations, from farming practices to
              the quality of products, to build trust with consumers.
            </p>
          </div>
          <div className="flex flex-col items-start justify-between">
            <p className="font-circular-500 text-xl text-left">Integrity</p>
            <p className="font-circular-450 text-base text-[#475467] text-left">
              Uphold the highest standards of integrity and ethics in all your
              farming and business activities.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default About;
