import { useEffect, useState } from "react";
import {
  getHomeRentLandRequestImage,
  getHomeRentLorriesRequestImage,
} from "../store/actions/homeRentSell";

import ImagePlaceholder from "../assets/image-placeholder.png";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Star from "../assets/Star.svg";
import cartIcon from "../assets/cart-icon.svg";
import { formatPrice } from "../shared/utility";

const RentCard = ({ rentItem, type }) => {
  const history = useHistory();
  const [image, setImage] = useState("");
  const [price, setPrice] = useState(null);

  const { profile } = useSelector((state) => ({
    profile: state?.profile?.profile,
  }));

  const getImage = async (id) => {
    const res =
      type === "lorry"
        ? await getHomeRentLorriesRequestImage(id)
        : await getHomeRentLandRequestImage(id);
    setImage(res);
  };

  useEffect(() => {
    if (rentItem?.imageIdList?.length > 0) {
      getImage(rentItem?.imageIdList[0]);
    }
  }, [rentItem]);

  const handleOnclickOnImage = () => {

      const url = `${process.env.PUBLIC_URL}/${
        type === "lorry"
          ? `rent-lorry-details/${rentItem?.id}`
          : `rent-land-details/${rentItem?.id}`
      }`;
      history.push({
        pathname: url,
        data: { rentItem },
      });
  };

  const handleAddToCard = () => {
    if (profile) {
      const url = `${process.env.PUBLIC_URL}/${
        type === "lorry"
          ? `rent-lorry-details/${rentItem?.id}`
          : `rent-land-details/${rentItem?.id}`
      }`;
      history.push({
        pathname: url,
        data: { rentItem },
      });
    } else {
      history.push(`${process.env.PUBLIC_URL}/auth`);
    }
  };

  useEffect(()=>{
    const formattedPrice = formatPrice(rentItem?.price)
    setPrice(formattedPrice);
  },[]);

  return (
    <div 
      className="rounded-[10px] pb-[35px]"
    >
      {image ? (
        <img
        src={`data:image/jpeg;base64,${image}`}
        alt=""
        className="w-[384px] h-[289.285px] rounded-[10px] cursor-pointer object-cover"
        onClick={handleOnclickOnImage}
      />
      ) : (
        <img
          src={ImagePlaceholder}
          alt=""
          className="w-[384px] h-[289.285px] rounded-[10px] cursor-pointer object-cover"
          onClick={handleOnclickOnImage}
        />
      )}

      <div className="grid mt-6 gap-3">
        <div className="text-[#3DB83A] text-sm font-circular-500 bg-green-400 bg-opacity-10 rounded-xl w-fit">
          <p className="max-w-[16rem] px-2 py-1 truncate">{rentItem?.location}</p>
        </div>
        <div className="flex items-center justify-between gap-2 flex-wrap">
          <p className="font-circular-500 text-2xl max-w-[11rem] text-left truncate">
            {rentItem?.name}
          </p>
          <p className="font-circular-900 text-xl leading-5  text-right text-[#3DB83A]">
            &#8358; {price}/{rentItem?.pricePeriod.toString().toLowerCase()} 
          </p>
        </div>
        {type === "land" &&<div className="font-circular-450 text-[#475467] text-base">
            <p className="h-12 text-ellipsis overflow-hidden">{rentItem?.description}</p>
        </div>}
        {type === "lorry" &&<div className="font-circular-450 text-[#475467] text-base">
            <p className="text-ellipsis overflow-hidden"> Brand: {rentItem?.brandName}</p>
            <p className="text-ellipsis overflow-hidden"> Horse Power: {rentItem?.horsePower}</p>
            <p className="text-ellipsis overflow-hidden"> Year of Manufacturing: {rentItem?.yearOfManufacture}</p>
        </div>}
          <div className="flex items-start gap-1">
            <img src={Star} alt="rating" />
            <img src={Star} alt="rating" />
            <img src={Star} alt="rating" />
            <img src={Star} alt="rating" />
            <img src={Star} alt="rating" />
            <p className="pl-2 text-[10px]">{"(91)"}</p>
          </div>
          {profile?.type !== "SELLER" &&<div>
            <button 
              className="flex items-center justify-center gap-1 font-medium border-2 border-solid border-gray w-36 h-10 rounded-3xl"
              onClick={handleAddToCard}
            >
              <img src={cartIcon} alt="cart" /> Add to Cart
            </button>
          </div>}
      </div>
    </div>
  );
};

export default RentCard;
