import React from 'react'
import LeftArrow from "../../../../assets/arrow-left.svg"
import RightArrow from "../../../../assets/arrow-right.svg"
function ArrowButtonContainer({handleLeft, handleRight}) {

  const classes = "w-14 h-14 rounded-[50%] cursor-pointer hover:border-2 hover:border-solid hover:border-[#EAECF0]";
  return (
    <div className='flex items-center justify-between gap-8 w-36 mt-8 '>
      <img src={LeftArrow} alt='left' className={classes} onClick={handleLeft} />
      <img src={RightArrow} alt='right' className={classes} onClick={handleRight} />
    </div>
  )
}

export default ArrowButtonContainer
