import * as actionTypes from "./types";
import axios from "../../axios-api";
import { getErrors, getResData } from "../../shared/utility";

const setSubscriptionStart = () => {
    return{
        type: actionTypes.SET_SUBSCRIPTION_DETAILS_START,
    }
}

const setSubscriptionFailed = (error) => {
    return {
        type: actionTypes.SET_SUBSCRIPTION_DETAILS_FAILED,
        payload: error
    }
}

const setSubscriptionDetailsSuccess = (subscriptionDetails) => {
    return {
        type: actionTypes.SET_SUBSCRIPTION_DETAILS_SUCCESS,
        payload: subscriptionDetails,
    }
    
}

const setSellerSubscriptionEnable = (sellerStatus) => {
    return {
        type: actionTypes.SET_SELLER_SUBSCRIPTIONS_STATUS,
        payload: sellerStatus
    }
}

const setBuyerSubscriptionEnable = (buyerStatus) => {
    return {
        type: actionTypes.SET_BUYER_SUBSCRIPTIONS_STATUS,
        payload: buyerStatus
    }
}

const getSubscriptionsStatusSuccess = () => {
    return {
        type: actionTypes.SET_SUBSCRIPTIONS_STATUS_SUCCESS,
    }
}

// check is user is subscribed or not and set isSubscribed in store
const setSubscriptionStatus = (subscriptionStatus) => {
    return {
        type: actionTypes.SET_USER_SUBSCRIPTION_STATUS,
        payload: subscriptionStatus
    }
}

// is seller subscriptions active
export const getSellerSubscriptionEnableStatus = () => {

    return async (dispatch) => {

        dispatch(setSubscriptionStart());
        const URL =  "/subscription/seller/enabled";

        return axios
        .get(URL)
        .then(response => {
            if(!response) throw new Error ("!No response from get user's subscription status");

                const data = getResData(response.data);

                if(!data.success) throw new Error (getErrors(data));

                return dispatch(setSellerSubscriptionEnable(data?.data));

        })
        .catch(err => { dispatch(setSubscriptionFailed(getErrors(err?.response?.data || err))) });
    }
}

// is buyer subscriptions active
export const getBuyerSubscriptionEnableStatus = () => {

    return async (dispatch) => {
        dispatch(setSubscriptionStart());

        const URL =  "/subscription/buyer/enabled";

        return axios
        .get(URL)
        .then(response => {
            if(!response) throw new Error ("!No response from get user's subscription status");

                const data = getResData(response.data);

                if(!data.success) throw new Error (getErrors(data));

                return dispatch(setBuyerSubscriptionEnable(data?.data));

        })
        .catch(err => { dispatch(setSubscriptionFailed(getErrors(err?.response?.data || err))) });
    }
}

// get user Subscription price 
export const getSubscriptionDetails = (type) => {
    return (dispatch) => {
        dispatch(setSubscriptionStart());

        let URL = null;
        if(type == "SELLER"){
            URL = "/subscription/seller/amount";
        }else{
            URL = "/subscription/buyer/amount";
        }

        return axios
        .get(URL)
        .then(response => {
            if(!response) throw new Error ("!No Response form get Subscritions details API");
            
            const data = getResData(response.data);
            if(!data.success) throw new Error (getErrors(data));
            // check response and set subscription deatils in store
            return dispatch(setSubscriptionDetailsSuccess(data.data))
        })
        .catch(err => {
            dispatch(setSubscriptionFailed(getErrors(err?.response?.data || err)))});
    }
}

// set user's new subscription status
export const setUserSubscription = (type) => {

        return (dispatch) => {
            dispatch(setSubscriptionStart());
    
            let URL = null;
            if(type === "SELLER"){
                URL =  "/seller/subscribe";
            }else if(type === "BUYER"){
                URL = "/buyer/subscribe";
            }

            return axios
            .post(URL)
            .then(response => {

                if(!response) throw new Error ("No Response from setUser Subscription API !")
                
                const data = getResData(response.data);

                if(!data.success) throw new Error (getErrors(data));

                // check response if status is succcess set isSubscribed status in store
                return dispatch(setSubscriptionStatus(data?.data))
            })
            .catch(err => dispatch(setSubscriptionFailed(getErrors(err?.response?.data || err))));  
        }
}

// get User subscription status 
export const getIsSubscriptionExist = () => {
    return (dispatch) => {
        dispatch(setSubscriptionStart());

        let URL = "/user/subscription/exist";

        return axios
        .get(URL)
        .then(response => {
            if(!response) throw new Error ("!No response from get user's subscription status");

            if(response?.data?.errorCode === 129){
                // user is not subscribed 
                return dispatch(setSubscriptionStatus(false))
               
            }
            const data = getResData(response.data);
            // if data success is true user is subscribed
            if(!data.success) throw new Error (getErrors(data));
            return dispatch(setSubscriptionStatus(data))
        })
        .catch(err => {
            dispatch(setSubscriptionFailed(getErrors(err?.response?.data || err)));
            return 
        });
    }
}