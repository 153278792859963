import React from 'react'

const SellerRightsHeader = () => {
    return (
        <div className='flex flex-col max-w-[42rem]'>
          <p className='font-circular-500 text-4xl text-[#212121] mb-5'>Why sell with mFarm?</p>
          <p className='font-circular-450 text-xl text-[#6B7280]'>Open a full-featured account in with virtual cards in less than 5 minutes.</p>
        </div>
      )
}
 
export default SellerRightsHeader
