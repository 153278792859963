import React from 'react'

const GettingStartedHeader = () => {
  return (
    <div className={Style.mainBox}>
        <p className={Style.head}>Welcome to mFarm</p>
        <p className={Style.content}>Getting Started with mFarm</p>
        <p className={Style.foot}>Are you ready to experience the freshest farm produce with mFarm? Getting started is a breeze! Just follow these simple steps.</p>
    </div>
  )
}

const Style = {
    mainBox: "flex flex-col max-w-[768px] self-center text-center",
    head: "font-circular-500 text-base text-[#3DB83A] self-stretch",
    content: "font-circular-500 text-4xl text-[#212121] mt-4 self-stretch",
    foot: "font-circular-450 text-xl text-[#6B7280] mt-5 self-stretch",
}

export default GettingStartedHeader
