import React, { useState } from 'react';
import ImagePlaceholderIcon from "../assets/image-placeholder.png"
import DeleteIcon from "../assets/delete.svg"

const ImageUploaderEdit = ({ images, setImages }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

  const handleImageChange = async (event) => {
    const newBase64Images = [...images];
    const file = event.target.files;

    if(file){
      for(let i = 0; i < file.length; i++){
        const isImage = /^image\//.test(file[i].type);
        if(isImage){
          const base64Image = await toBase64(file[i]);
          const img = base64Image.split(",")[1];

          newBase64Images.push({base64: img});

          setImages(newBase64Images);
        }else{
          alert("Selected file is not an image.");
        }
      }
    }
  };

  const handleImageDelete = (id) => {
    const newBase64Images = images?.filter(item => item?.id !== id)
    setImages(newBase64Images);
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className="grid grid-cols-[80px_1fr] ">
      <input
        id="imageGallery"
        type="file"
        multiple
        accept=".jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp"
        onChange={handleImageChange}
        className='hidden'
      />
      <label htmlFor='imageGallery'>
        <img src={ImagePlaceholderIcon} alt="Choose file" className='w-[80px] h-[80px] cursor-pointer rounded-[10px]' />
      </label>

      {images.length > 0 && (
        <div className="grid grid-cols-[32px_auto_32px] relative">
          <button
            type="button"
            className={`px-2 py-1 rounded-lg self-center text-[32px] ${currentIndex === 0 ? "text-[#aaa]" : "text-main"}`}
            onClick={handlePrevious}
            disabled={currentIndex === 0}
          >
            &lt;
          </button>
          <div className="flex justify-start space-x-2">
            {images.slice(currentIndex, currentIndex + 3).map((image, index) => (
              <div className='grid' key={index}>
                <img
                  src={`data:image/jpeg;base64,${image?.base64}`}
                  alt={`Image ${currentIndex + index}`}
                  className="max-w-[100px] max-h-[100px]"
                />
                <div className="flex justify-center space-x-2 mt-1 self-end">
                  <button
                    type="button"
                    className="bg-red-500 text-white px-1 py-1 rounded h-[24px] w-[24px]"
                    onClick={() => handleImageDelete(image?.id)}
                  >
                    <img src={DeleteIcon} className='h-[16px] w-[16px]' />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <button
            type="button"
            className={`px-2 py-1 rounded-lg self-center text-[32px] ${currentIndex >= images.length - 3 ? "text-[#aaa]" : "text-main"}`}
            onClick={handleNext}
            disabled={currentIndex >= images.length - 3}
          >
            &gt;
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageUploaderEdit;
