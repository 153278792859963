import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";

const initialState = {
  error: null,
  loading: false,
  orderHistoryLorry: null,
};

//get order history
const getOrderHistoryLorryStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getOrderHistoryLorrySuccess = (state, action) => {
  return updateObject(state, {
    orderHistoryLorry: action.orderHistoryLorry,
    error: null,
    loading: false,
  });
};

const getOrderHistoryLorryFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    orderHistoryLorry: null,
  });
};

// Get order image
const getOrderHistoryLorryImageStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getOrderHistoryLorryImageSuccess = (state, action) => {
  return updateObject(state, {
    orderHistoryLorryImage: action.orderHistoryLorryImage,
    error: null,
    loading: false,
  });
};

const getOrderHistoryLorryImageFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    orderHistoryLorryImage: null,
  });
};

// Get order status
const getOrderHistoryLorryStatusStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getOrderHistoryLorryStatusSuccess = (state, action) => {
  return updateObject(state, {
    orderHistoryLorryStatus: action.orderHistoryLorryStatus,
    error: null,
    loading: false,
  });
};

const getOrderHistoryLorryStatusFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    orderHistoryLorryStatus: null,
  });
};

// change order status
const changeOrderLorryStatusStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const changeOrderLorryStatusSuccess = (state, action) => {
  return updateObject(state, {
    changeOrderStatus: action.changeOrderStatus,
    error: null,
    loading: false,
  });
};

const changeOrderLorryStatusFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    changeOrderStatus: null,
  });
};

const orderHistoryLorryReducer = (state = initialState, action) => {
  switch (action.type) {
    // get order history
    case actionTypes.GET_ORDER_HISTORY_LORRY_START:
      return getOrderHistoryLorryStart(state, action);
    case actionTypes.GET_ORDER_HISTORY_LORRY_SUCCESS:
      return getOrderHistoryLorrySuccess(state, action);
    case actionTypes.GET_ORDER_HISTORY_LORRY_FAIL:
      return getOrderHistoryLorryFailed(state, action);
    case actionTypes.GET_ORDER_HISTORY_LORRY_IMAGE_START:
      return getOrderHistoryLorryImageStart(state, action);
    case actionTypes.GET_ORDER_HISTORY_LORRY_IMAGE_SUCCESS:
      return getOrderHistoryLorryImageSuccess(state, action);
    case actionTypes.GET_ORDER_HISTORY_LORRY_IMAGE_FAIL:
      return getOrderHistoryLorryImageFailed(state, action);
    case actionTypes.GET_ORDER_HISTORY_LORRY_STATUS_START:
      return getOrderHistoryLorryStatusStart(state, action);
    case actionTypes.GET_ORDER_HISTORY_LORRY_STATUS_SUCCESS:
      return getOrderHistoryLorryStatusSuccess(state, action);
    case actionTypes.GET_ORDER_HISTORY_LORRY_STATUS_FAIL:
      return getOrderHistoryLorryStatusFailed(state, action);
    case actionTypes.CHANGE_ORDER_LORRY_STATUS_START:
      return changeOrderLorryStatusStart(state, action);
    case actionTypes.CHANGE_ORDER_LORRY_STATUS_SUCCESS:
      return changeOrderLorryStatusSuccess(state, action);
    case actionTypes.CHANGE_ORDER_LORRY_STATUS_FAIL:
      return changeOrderLorryStatusFailed(state, action);
    default:
      return state;
  }
};

export default orderHistoryLorryReducer;
