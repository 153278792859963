import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import ProfilePageInput from "../ProfileFormikInput";
import * as Yup from "yup";
import { updatePassword } from "../../store/actions/profile";
import { useState } from "react";

const passwordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().when("changePassword", {
    is: true,
    then: Yup.string().required("Old Password is required"),
  }),
  newPassword: Yup.string()
    .when("changePassword", {
      is: true,
      then: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("New Password is required")
        .notOneOf([Yup.ref("oldPassword")], "New Password should not be the same as Old Password"),
    })
    .min(6, "Password size must be between 6 and 20")
    .max(20, "Password size must be between 6 and 20"),
});

const ChangePasswordForm = ({ toggleChangePassword, setIsPasswordChange }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
      }}
      validationSchema={passwordValidationSchema}
      onSubmit={async (values) => {
        const res = await dispatch(updatePassword(values));
        if(res.type === "PROFILE_UPDATE_PASSWORD_SUCCESS"){
          setIsPasswordChange(true)
          setTimeout(() => {
            setIsPasswordChange(false)
          },3000)
        }
        if (res?.error) {
          return setError(res.error);
        }
        toggleChangePassword();
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="mt-[50px] grid gap-y-[16px]">
            <ProfilePageInput
              label="Old Password"
              type="password"
              name="oldPassword"
              hideEdit={true}
              placeholder="Enter your old password"
              notDisabled={true}
            />
            <ProfilePageInput
              label="New Password"
              type="password"
              name="newPassword"
              hideEdit={true}
              placeholder="Enter your new password"
              notDisabled={true}
            />
            {error && <p className="text-[#FF0000]">{error}</p>}
            <div>
              <button
                type="button"
                onClick={toggleChangePassword}
                disabled={isSubmitting}
                className="mr-[20px] text-main"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-main text-white px-[30px] py-[16px] rounded-[10px] uppercase mt-[30px]"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
