import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import authReducer from "./store/reducers/auth";
import thunk from "redux-thunk";
import * as actionTypes from "./store/actions/types";
import profileReducer from './store/reducers/profile';
import dashboardReducer from './store/reducers/dashboard';
import tipReducer from './store/reducers/tips';
import diagnosisReducer from './store/reducers/diagnosis';
import categoryReducer from './store/reducers/categories';
import sellRequestReducer from './store/reducers/sellRequest';
import buyRequestReducer from './store/reducers/buyRequest';
import rentLandRequestReducer from './store/reducers/rentLandRequest';
import rentLorriesRequestReducer from './store/reducers/rentLorriesRequest';
import notificationReducer from './store/reducers/notifications';
import orderHistoryLandReducer from './store/reducers/orderHistoryLand';
import orderHistoryLorryReducer from './store/reducers/orderHistoryLorry';
import orderHistorySellReducer from './store/reducers/orderHistorySell';
import homeSellRentRequestReducer from './store/reducers/homeRentSell';
import orderReducer from './store/reducers/order';
import cartReducer from './store/reducers/Cart';
import subscriptionReducer from './store/reducers/subsciption';

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const appReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  dashboard: dashboardReducer,
  tips: tipReducer,
  diagnosisRequest: diagnosisReducer,
  categories: categoryReducer,
  sellRequests: sellRequestReducer,
  buyRequests: buyRequestReducer,
  rentLandRequests: rentLandRequestReducer,
  rentLorriesRequests: rentLorriesRequestReducer,
  notifications: notificationReducer,
  orderHistoryLand: orderHistoryLandReducer,
  orderHistoryLorry: orderHistoryLorryReducer,
  orderHistorySell: orderHistorySellReducer,
  homeRentSell: homeSellRentRequestReducer,
  orders: orderReducer,
  cart: cartReducer,
  subscription: subscriptionReducer,

});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.AUTH_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
}

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

export default store;
