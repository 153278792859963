import React from "react";
import registrationMockScreen from "../../../assets/landing screen/registration-mock-screen.png";

const Steps = () => {
  return (
    <div className="flex items-start flex-col sm:flex-row gap-16 px-8">
      <div className="flex flex-1 flex-col gap-12">
        {list1.map((item, index) => (
          <div className="flex flex-col gap-5" key={index}>
            <div className="w-14 py-[15px] px-[14px] border border-solid border-1 border-gray-300 rounded-[10px]">
              <span className="font-circular-500 text-xl text-[#344054]">
                {item.number}
              </span>
            </div>
            <div className="flex flex-col">
              <p className="font-circular-500 text-xl text-[#212121]">
                {item.title}
              </p>
              <p className="font-circular-450 text-base text-[#6B7280]]">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="inline-block">
        <img
          src={registrationMockScreen}
          alt="mock-screen-register"
          className="w-[450px] object-cover"
        />
      </div>
      <div className="flex flex-1 flex-col gap-12">
        {list2.map((item, index) => (
          <div className="flex flex-col gap-5" key={index}>
            <div className="w-14 py-[15px] px-[14px] border border-solid border-1 border-gray-300 rounded-[10px]">
              <p className="font-circular-500 text-xl text-[#344054]">
                {item.number}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-circular-500 text-xl text-[#212121]">
                {item.title}
              </p>
              <p className="font-circular-450 text-base text-[#6B7280]]">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const list1 = [
  {
    number: "01",
    title: "Personal Details",
    description:
      "Enter your full name and any other necessary personal information.",
  },
  {
    number: "02",
    title: "Username for Log In",
    description:
      "Create a unique username that you'll use to log in to your mFarm account.",
  },
];

const list2 = [
  {
    number: "03",
    title: "MTN Phone Number",
    description:
      "Please provide a valid MTN phone number. We may use this to contact you regarding your orders.",
  },
  {
    number: "04",
    title: "Password for Log In",
    description: "Choose a secure password to safeguard your account",
  },
];

export default Steps;
