import axios from "../../axios-api";
import * as actionTypes from "./types";
import {
  arrayBufferToBase64,
  getErrors,
  getResData,
} from "../../shared/utility";

export const getPricePeriodsStart = () => {
  return {
    type: actionTypes.GET_PRICE_PERIODS_START,
  };
};

export const getPricePeriodsSuccess = (pricePeriods) => {
  return {
    type: actionTypes.GET_PRICE_PERIODS_SUCCESS,
    pricePeriods,
  };
};

export const getPricePeriodsFailed = (error) => {
  return {
    type: actionTypes.GET_PRICE_PERIODS_FAIL,
    error,
  };
};

export const getPricePeriods = (start, length) => {
  return (dispatch) => {
    dispatch(getPricePeriodsStart());

    let url = "/seller/price/period";

    const params = {};

    if (start !== undefined) {
      params.start = start;
    }

    if (length !== undefined) {
      params.length = length;
    }

    if (Object.keys(params).length > 0) {
      url += "?" + new URLSearchParams(params).toString();
    }

    return axios
      .get(url)
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(getPricePeriodsSuccess(data));
      })
      .catch((err) =>
        dispatch(getPricePeriodsFailed(getErrors(err?.response?.data || err)))
      );
  };
};

export const getRentLandRequestsStart = () => {
  return {
    type: actionTypes.GET_RENT_LAND_REQUESTS_START,
  };
};

export const getRentLandRequestsSuccess = (rentLandRequests) => {
  return {
    type: actionTypes.GET_RENT_LAND_REQUESTS_SUCCESS,
    rentLandRequests,
  };
};

export const getRentLandRequestsFailed = (error) => {
  return {
    type: actionTypes.GET_RENT_LAND_REQUESTS_FAIL,
    error,
  };
};

export const getRentLandRequests = (start, length, name) => {
  return (dispatch) => {
    dispatch(getRentLandRequestsStart());

    let url = "/seller/rent/request/land";

    const params = {};

    if (start !== undefined) {
      params.start = start;
    }

    if (length !== undefined) {
      params.length = length;
    }

    if (name !== undefined) {
      params.name = name;
    }

    if (Object.keys(params).length > 0) {
      url += "?" + new URLSearchParams(params).toString();
    }

    return axios
      .get(url)
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(getRentLandRequestsSuccess(data));
      })
      .catch((err) =>
        dispatch(
          getRentLandRequestsFailed(getErrors(err?.response?.data || err))
        )
      );
  };
};

export const createRentLandRequestStart = () => {
  return {
    type: actionTypes.CREATE_RENT_LAND_REQUEST_START,
  };
};

export const createRentLandRequestSuccess = (createRes) => {
  return {
    type: actionTypes.CREATE_RENT_LAND_REQUEST_SUCCESS,
    createRes,
  };
};

export const createRentLandRequestFailed = (error) => {
  return {
    type: actionTypes.CREATE_RENT_LAND_REQUEST_FAIL,
    error,
  };
};

export const createRentLandRequest = (data) => {
  return (dispatch) => {
    dispatch(createRentLandRequestStart());

    return axios
      .post(`/seller/rent/request/land`, data)
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(createRentLandRequestSuccess(data));
      })
      .catch((err) =>
        dispatch(
          createRentLandRequestFailed(getErrors(err?.response?.data || err))
        )
      );
  };
};

export const editRentLandRequestStart = () => {
  return {
    type: actionTypes.EDIT_RENT_LAND_REQUEST_START,
  };
};

export const editRentLandRequestSuccess = (editRes) => {
  return {
    type: actionTypes.EDIT_RENT_LAND_REQUEST_SUCCESS,
    editRes,
  };
};

export const editRentLandRequestFailed = (error) => {
  return {
    type: actionTypes.EDIT_RENT_LAND_REQUEST_FAIL,
    error,
  };
};

export const editRentLandRequest = (id, data) => {
  return (dispatch) => {
    dispatch(editRentLandRequestStart());

    return axios
      .put(`/seller/rent/request/land`, { id: id, ...data })
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(editRentLandRequestSuccess(data));
      })
      .catch((err) =>
        dispatch(
          editRentLandRequestFailed(getErrors(err?.response?.data || err))
        )
      );
  };
};

export const deleteRentLandRequestStart = () => {
  return {
    type: actionTypes.DELETE_RENT_LAND_REQUEST_START,
  };
};

export const deleteRentLandRequestSuccess = (deleteRes) => {
  return {
    type: actionTypes.DELETE_RENT_LAND_REQUEST_SUCCESS,
    deleteRes,
  };
};

export const deleteRentLandRequestFailed = (error) => {
  return {
    type: actionTypes.DELETE_RENT_LAND_REQUEST_FAIL,
    error,
  };
};

export const deleteRentLandRequest = (id) => {
  return (dispatch) => {
    dispatch(deleteRentLandRequestStart());

    return axios
      .delete(`/seller/rent/request/land/${id}`)
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(deleteRentLandRequestSuccess(data));
      })
      .catch((err) =>
        dispatch(
          deleteRentLandRequestFailed(getErrors(err?.response?.data || err))
        )
      );
  };
};

export const getRentRequestImage = (id) => {
  return axios
    .get(`/seller/rent/request/land/image/${id}`, {
      responseType: "arraybuffer", // Tell Axios to treat the response as an array buffer
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (!response) throw new Error("No Response");

      const data = response.data;
      const base64String = arrayBufferToBase64(data);

      return base64String;
    })
    .catch((err) => getErrors(err));
};

export const createRentLandRequestImageStart = () => {
  return {
    type: actionTypes.CREATE_RENT_LAND_REQUEST_IMAGE_START,
  };
};

export const createRentLandRequestImageSuccess = (addImageRes) => {
  return {
    type: actionTypes.CREATE_RENT_LAND_REQUEST_IMAGE_SUCCESS,
    addImageRes,
  };
};

export const createRentLandRequestImageFailed = (error) => {
  return {
    type: actionTypes.CREATE_RENT_LAND_REQUEST_IMAGE_FAIL,
    error,
  };
};

export const createRentLandRequestImage = (id, imageBase64) => {
  return (dispatch) => {
    dispatch(createRentLandRequestImageStart());

    return axios
      .post(`/seller/rent/request/land/image`, {
        id: id,
        imageBase64: imageBase64,
      })
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(createRentLandRequestImageSuccess(data));
      })
      .catch((err) =>
        dispatch(
          createRentLandRequestImageFailed(
            getErrors(err?.response?.data || err)
          )
        )
      );
  };
};

export const deleteRentLandRequestImageStart = () => {
  return {
    type: actionTypes.DELETE_RENT_LAND_REQUEST_IMAGE_START,
  };
};

export const deleteRentLandRequestImageSuccess = (deleteImageRes) => {
  return {
    type: actionTypes.DELETE_RENT_LAND_REQUEST_IMAGE_SUCCESS,
    deleteImageRes,
  };
};

export const deleteRentLandRequestImageFailed = (error) => {
  return {
    type: actionTypes.DELETE_RENT_LAND_REQUEST_IMAGE_FAIL,
    error,
  };
};

export const deleteRentLandRequestImage = (id) => {
  return (dispatch) => {
    dispatch(deleteRentLandRequestImageStart());

    return axios
      .delete(`/seller/rent/request/land/image/${id}`)
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(deleteRentLandRequestImageSuccess(data));
      })
      .catch((err) =>
        dispatch(
          deleteRentLandRequestImageFailed(
            getErrors(err?.response?.data || err)
          )
        )
      );
  };
};
