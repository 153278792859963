import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import "./Home.scss";
import "../../assets/fonts/Circular/CircularStd-MediumItalic.otf";
import HomeBanner from "./HomeBanner";
import ProductAndRentContainer from "./ProductAndRentContainer";
import USSDInfo from "./USSDInfo";
import About from "./About";
import SubFooter from "./SubFooter";
import FooterContainer from "./FooterContainer";
import Modal from '../../components/Modal/Modal';

const Home = () => {

  const [isBuyerSubscriptionEnable, setIsBuyerSubscriptionEnable] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [showAddToCartSuccessText, setShowAddToCartSuccessText] = useState(false);

   const { profile, subscription } =
    useSelector((state) => ({
      profile: state?.profile?.profile,
      subscription: state?.subscription,
    }));


   useEffect(() => {

    if(subscription?.buyer?.buyerSubscriptionEnable !== null  && subscription?.buyer?.buyerSubscriptionEnable === false && subscription?.buyer?.buyerSubscriptionEnableCount === 1 && profile?.type === "BUYER"){
      setIsBuyerSubscriptionEnable(false);
    }
  },[subscription, profile]);

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return(
    <main className="overflow-x-hidden">
      <Modal
        isOpen={isBuyerSubscriptionEnable === false}
        onClose={() => {
          setIsBuyerSubscriptionEnable(true);
        }}
        title="🎉 Free Subscription Alert 🎉"
      >
        <div className="p-5">
          <p>Subscription services for Buyer are now available for FREE!</p>
        </div>
      </Modal>

      {showAddToCartSuccessText && (
          <div className="fixed top-20 right-14 bg-slate-100 p-4 rounded z-50">
            <p
              className={`${
                statusMessage === "Item added to cart"
                  ? "text-green-500"
                  : "text-[#FF0000]"
              } ${showAddToCartSuccessText ? "visible" : "invisible"}`}
            >
              {statusMessage}
            </p>
          </div>
        )}
      <Header />
      <HomeBanner />
      <ProductAndRentContainer setStatusMessage={setStatusMessage} setShowAddToCartSuccessText={setShowAddToCartSuccessText} />
      <USSDInfo />
      <About />
      <SubFooter />
      <FooterContainer />
    </main>
  )  
};

export default Home;
