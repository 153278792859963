import React, { useEffect, useState } from "react";
import { ErrorMessage } from "formik";

import PaperclipIcon from "../../assets/paperclip.svg";
import ImagePlaceholderIcon from "../../assets/image-placeholder.png";

import "./UploadFileInput.scss";

const UploadImage = ({
  placeholder,
  name,
  type = "file",
  onChange,
  variant = "text",
  value,
}) => {
  const [image, setImage] = useState();

  useEffect(() => {
    if (value) {
      setImage(value);
      onChange(value);
    }
  }, [value]);

  return (
    <>
      <div className="relative">
        <input
          id="fileInput"
          name={name}
          type={type}
          placeholder={placeholder}
          className={`hidden`}
          accept=".jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp"
          onChange={(event) => {
            const file = event.target.files[0];
            const isImage = /^image\//.test(file.type);

            if (isImage) {
              if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  const base64String = reader.result.replace(
                    /^data:image\/(.*);base64,/,
                    ""
                  );
                  setImage(base64String);
                  onChange(base64String);
                };
                reader.readAsDataURL(file);
              }
            } else {
              alert("Selected file is not an image.");
            }
          }}
        />
        {variant === "text" && (
          <label htmlFor="fileInput" className="flex">
            <img src={PaperclipIcon} alt="Choose file" />
            <span className="ml-[9px] text-main">{placeholder}</span>
          </label>
        )}
        {variant === "image" && (
          <label htmlFor="fileInput" className="flex w-[80px] h-[80px]">
            {image ? (
              <div
                title="Change Profile Image"
                htmlFor="imageBase64"
                className="w-[80px] h-[80px] rounded-[10px] block cursor-pointer"
                style={{
                  backgroundImage: `url(data:image/jpeg;base64,${image})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            ) : (
              <img
                src={ImagePlaceholderIcon}
                alt="Choose file"
                className="w-[80px] h-[80px] cursor-pointer rounded-[10px]"
              />
            )}
          </label>
        )}
      </div>
      {name && (
        <ErrorMessage
          name={name}
          component="div"
          className="text-[#ff0000] text-sm mt-1"
        />
      )}
    </>
  );
};

export default UploadImage;
