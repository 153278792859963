import * as actionTypes from "./types";
import axios from "../../axios-api";
import { getErrors, getResData } from "../../shared/utility";

// getCategories
export const getCategoriesStart = () => {
    return {
        type: actionTypes.GET_CATEGORIES_START,
    };
};

export const getCategoriesSuccess = (categories) => {
    return {
        type: actionTypes.GET_CATEGORIES_SUCCESS,
        categories,
    };
};

export const getCategoriesFailed = (error) => {
    return {
        type: actionTypes.GET_CATEGORIES_FAIL,
        error,
    };
};

export const getCategories = (start, length, name) => {
    return (dispatch) => {
        dispatch(getCategoriesStart());

        let url = '/user/category';

        const params = {};

        if (start !== undefined) {
            params.start = start;
        }

        if (length !== undefined) {
            params.length = length;
        }

        if (name !== undefined) {
            params.name = name;
        }

        if (Object.keys(params).length > 0) {
            url += '?' + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getCategoriesSuccess(data));
            })
            .catch((err) => dispatch(getCategoriesFailed(getErrors(err?.response?.data || err))));
    };
};