import React from "react";
import ladyImage from "../../assets/landing screen/landing-screen-lady.png";
function USSDInfo() {
  return (
    <main className="px-2 md:px-20 my-10">
      <div className="flex flex-1 items-center justify-between gap-2 flex-col sm:flex-row bg-[#3DB83A] rounded-2xl">
        <div className="flex flex-col gap-2 sm:gap-5 flex-1 p-12 md:px-2 sm:pl-16">
          <p className="text-white text-[30px] md:text-[40px] font-circular-500 leading-10 text-left">
            Join our daily contents
          </p>
          <p className="text-white text-[15px] lg:text-[20px] font-circular-450 text-left">
            Dial *6464# to access daily farming contents
          </p>
        </div>
        <div className="flex-1 text-right">
          <img
            src={ladyImage}
            className="float-right !important rounded-b-2xl sm:rounded-r-2xl object-cover"
            alt="lady"
          />
        </div>
      </div>
    </main>
  );
}

export default USSDInfo;
