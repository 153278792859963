import React from 'react'
import GettingStartedHeader from './GettingStartedHeader'
import Steps from './Steps'

const GettingStarted = () => {
  return (
    <main className='flex flex-col gap-16 pt-24 px-[30px] bg-[#F9FAFB]'>
        <GettingStartedHeader />
        <Steps />
    </main>
  )
}

export default GettingStarted
