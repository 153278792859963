import * as actionTypes from "./types";
import axios from "../../axios-api";
import { getErrors, getResData } from "../../shared/utility";

// get order history
export const getOrderHistoryLorryStart = () => {
    return {
        type: actionTypes.GET_ORDER_HISTORY_LORRY_START,
    };
};

export const getOrderHistoryLorrySuccess = (orderHistoryLorry) => {
    return {
        type: actionTypes.GET_ORDER_HISTORY_LORRY_SUCCESS,
        orderHistoryLorry,
    };
};

export const getOrderHistoryLorryFailed = (error) => {
    return {
        type: actionTypes.GET_ORDER_HISTORY_LORRY_FAIL,
        error,
    };
};

export const getOrderHistoryLorry = (start, length, type, search) => {
    return (dispatch) => {
        dispatch(getOrderHistoryLorryStart());

        let url;

        if(type==='SELLER'){
          url = '/seller/rent/request/lorry/order';
        }else if(type==='BUYER'){
          url = '/buyer/rent/request/lorry/order';
        }

        const params = {};

        if (start !== undefined) {
            params.start = start;
        }

        if (length !== undefined) {
            params.length = length;
        }

        if (search !== undefined) {
          params.name = search;
        }

        if (Object.keys(params).length > 0) {
            url += '?' + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getOrderHistoryLorrySuccess(data));
            })
            .catch((err) => dispatch(getOrderHistoryLorryFailed(getErrors(err?.response?.data || err))));
    };
};

// get Order Image
export const getOrderHistoryImageStart = () => {
    return {
      type: actionTypes.GET_ORDER_HISTORY_LORRY_IMAGE_START,
    };
  };
  
  export const getOrderHistoryImageSuccess = (orderLorryImage) => {
    return {
      type: actionTypes.GET_ORDER_HISTORY_LORRY_IMAGE_SUCCESS,
      orderLorryImage,
    };
  };
  
  export const getOrderHistoryImageFailed = (error) => {
    return {
      type: actionTypes.GET_ORDER_HISTORY_LORRY_IMAGE_FAIL,
      error,
    };
  };
  
  export const getOrderHistoryImage = (id) => { 
    return axios
      .get(`/rent/request/lorry/image/${id}`, {responseType: 'arraybuffer'})
      .then((response) => {
        if (!response) throw new Error('No Response');
  
        const data = getResData(response.data);
        const base64String = arrayBufferToBase64(response.data);
  
        if (!data) throw new Error(getErrors(data));
  
        return base64String;
      })
      .catch((err) => {
        return err;
      }
      );
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };


// get order status
export const getOrderHistoryLorryStatusStart = () => {
  return {
      type: actionTypes.GET_ORDER_HISTORY_LORRY_STATUS_START,
  };
};

export const getOrderHistoryLorryStatusSuccess = (orderHistoryLorryStatus) => {
  return {
      type: actionTypes.GET_ORDER_HISTORY_LORRY_STATUS_SUCCESS,
      orderHistoryLorryStatus,
  };
};

export const getOrderHistoryLorryStatusFailed = (error) => {
  return {
      type: actionTypes.GET_ORDER_HISTORY_LORRY_STATUS_FAIL,
      error,
  };
};

export const getOrderStatus = () => {
  return (dispatch) => {
      dispatch(getOrderHistoryLorryStatusStart());

      let url = '/seller/rent/status';

      return axios
          .get(url)
          .then((response) => {
              if (!response) throw new Error("No Response");

              const data = getResData(response.data);

              if (!data.success) throw new Error(getErrors(data));

              return dispatch(getOrderHistoryLorryStatusSuccess(data));
          })
          .catch((err) => dispatch(getOrderHistoryLorryStatusFailed(getErrors(err?.response?.data || err))));
  };
};

// change order status
export const changeOrderLorryStatusStart = () => {
  return {
      type: actionTypes.CHANGE_ORDER_LORRY_STATUS_START,
  };
};

export const changeOrderLorryStatusSuccess = (changeOrderStatus) => {
  return {
      type: actionTypes.CHANGE_ORDER_LORRY_STATUS_SUCCESS,
      changeOrderStatus,
  };
};

export const changeOrderLorryStatusFailed = (error) => {
  return {
      type: actionTypes.CHANGE_ORDER_LORRY_STATUS_FAIL,
      error,
  };
};

export const changeOrderLorryStatus = (id, status) => {
  return (dispatch) => {
      dispatch(changeOrderLorryStatusStart());

      let url = '/seller/rent/status/lorry';

      return axios
          .put(url, {id: id, status: status})
          .then((response) => {
              if (!response) throw new Error("No Response");

              const data = getResData(response.data);

              if (!data.success) throw new Error(getErrors(data));

              return dispatch(changeOrderLorryStatusSuccess(data));
          })
          .catch((err) => dispatch(changeOrderLorryStatusFailed(getErrors(err?.response?.data || err))));
  };
};
  