import React, { useEffect, useState } from "react";
import { getOrderHistoryImage as landImage } from "../store/actions/orderHistoryLand";
import { getOrderHistoryImage as lorryImage } from "../store/actions/orderHistoryLorry";
import defaultImage from "../assets/image-placeholder.png";
const MoreInfo = ({ data, type, sellImage }) => {
  const [images, setImages] = useState(null);
  const [activeImage, setActiveImage] = useState(null);

  const fetchImages = async () => {
    try {
      let imagePromises = null;
      if (data)
        imagePromises = data?.imageIdList?.map(async (id) => {
          return await (type === "LAND" ? landImage(id) : lorryImage(id) );
        });

      if (imagePromises) {
        const images = await Promise.all(imagePromises);
        setActiveImage(`data:image/jpeg;base64,${images[0]}`);
        setImages(images);
      }
    } catch (error) {
      console.log("Error fetching images:", error);
    }
  };

  useEffect(() => {
    if (type === "SELL") {
      setActiveImage(sellImage);
    } else if (data?.imageIdList?.length > 0) {
      fetchImages();
    }
  }, [data?.imageIdList]);

  return (
    <>
      <div className="flex flex-col sm:flex-row w-[98%] items-start justify-center px-5 sm:p-5 gap-3">
        <div className="h-1/2 w-[98%] sm:w-1/2">
          <img src={activeImage ? activeImage : defaultImage} alt="advert" />
        </div>
        <div className="w-[98%] sm:w-1/2 px-5 leading-8 ">
          <p className="text-2xl font-semibold">{data.name}</p>
          <table>
            {data.msisdn && (
              <tr>
                <td className="font-medium align-top w-1/4">Mobile Number:</td>
                <td className="px-3">{data.msisdn}</td>
              </tr>
            )}
            {data.buyer && (
              <tr>
                <td className="font-medium align-top w-1/4">Mobile Number:</td>
                <td className="px-3">{data.buyer}</td>
              </tr>
            )}
            <tr>
              <td className="font-medium align-top w-1/4">First Name:</td>
              <td className="px-3">{data.firstName}</td>
            </tr>
            <tr>
              <td className="font-medium align-top w-1/4">Last Name:</td>
              <td className="px-3">{data.lastName}</td>
            </tr>
            <tr>
              <td className="font-medium align-top w-1/4">Note:</td>
              <td className="px-3 break-all">{data.notes}</td>
            </tr>
            { (data?.street || data?.city || data?.area) && <tr>
              <td className="font-medium align-top w-1/4">Address:</td>
              <td className="px-3 break-all">{`${data?.street ? `${data.street}, ` : ""}${data?.area ? `${data.area}, ` : ""}${data?.city ? data.city : ""}`}</td>
            </tr>}
          </table>
        </div>
      </div>
      <div className="grid mt-[30px] w-[100%] overflow-x-auto">
        <div className={`flex no-wrap w-max gap-[30px]`}>
          {images?.map((image) => (
            <button
              type="button"
              onClick={() => setActiveImage(`data:image/jpeg;base64,${image}`)}
              className="w-[100px] h-[100px] rounded-[10px]"
              style={{
                backgroundImage: `url(data:image/jpeg;base64,${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default MoreInfo;
