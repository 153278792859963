// actions.js
import axios from "../../axios-api";
import * as actionTypes from "./types";
import { arrayBufferToBase64, getErrors, getResData } from "../../shared/utility";

export const getPricePeriodsStart = () => {
    return {
        type: actionTypes.GET_PRICE_PERIODS_START,
    };
};

export const getPricePeriodsSuccess = (pricePeriods) => {
    return {
        type: actionTypes.GET_PRICE_PERIODS_SUCCESS,
        pricePeriods,
    };
};

export const getPricePeriodsFailed = (error) => {
    return {
        type: actionTypes.GET_PRICE_PERIODS_FAIL,
        error,
    };
};

export const getPricePeriods = (start, length) => {
    return (dispatch) => {
        dispatch(getPricePeriodsStart());

        let url = "/seller/price/period";

        const params = {};

        if (start !== undefined) {
            params.start = start;
        }

        if (length !== undefined) {
            params.length = length;
        }

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getPricePeriodsSuccess(data));
            })
            .catch((err) =>
                dispatch(getPricePeriodsFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const getRentLorriesRequestsStart = () => {
    return {
        type: actionTypes.GET_RENT_LORRY_REQUESTS_START,
    };
};

export const getRentLorriesRequestsSuccess = (rentLorriesRequests) => {
    return {
        type: actionTypes.GET_RENT_LORRY_REQUESTS_SUCCESS,
        rentLorriesRequests,
    };
};

export const getRentLorriesRequestsFailed = (error) => {
    return {
        type: actionTypes.GET_RENT_LORRY_REQUESTS_FAIL,
        error,
    };
};

export const getRentLorriesRequests = (start, length, name) => {
    return (dispatch) => {
        dispatch(getRentLorriesRequestsStart());

        let url = "/seller/rent/request/lorry";

        const params = {};

        if (start !== undefined) {
            params.start = start;
        }

        if (length !== undefined) {
            params.length = length;
        }

        if (name !== undefined) {
            params.name = name;
        }

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getRentLorriesRequestsSuccess(data));
            })
            .catch((err) =>
                dispatch(getRentLorriesRequestsFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const createRentLorriesRequestStart = () => {
    return {
        type: actionTypes.CREATE_RENT_LORRY_REQUEST_START,
    };
};

export const createRentLorriesRequestSuccess = (createRes) => {
    return {
        type: actionTypes.CREATE_RENT_LORRY_REQUEST_SUCCESS,
        createRes,
    };
};

export const createRentLorriesRequestFailed = (error) => {
    return {
        type: actionTypes.CREATE_RENT_LORRY_REQUEST_FAIL,
        error,
    };
};

export const createRentLorriesRequest = (data) => {
    return (dispatch) => {
        dispatch(createRentLorriesRequestStart());

        return axios
            .post(`/seller/rent/request/lorry`, data)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(createRentLorriesRequestSuccess(data));
            })
            .catch((err) =>
                dispatch(createRentLorriesRequestFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const editRentLorriesRequestStart = () => {
    return {
        type: actionTypes.EDIT_RENT_LORRY_REQUEST_START,
    };
};

export const editRentLorriesRequestSuccess = (editRes) => {
    return {
        type: actionTypes.EDIT_RENT_LORRY_REQUEST_SUCCESS,
        editRes,
    };
};

export const editRentLorriesRequestFailed = (error) => {
    return {
        type: actionTypes.EDIT_RENT_LORRY_REQUEST_FAIL,
        error,
    };
};

export const editRentLorriesRequest = (id, data) => {
    return (dispatch) => {
        dispatch(editRentLorriesRequestStart());

        return axios
            .put(`/seller/rent/request/lorry`, { id: id, ...data })
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(editRentLorriesRequestSuccess(data));
            })
            .catch((err) =>
                dispatch(editRentLorriesRequestFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const deleteRentLorriesRequestStart = () => {
    return {
        type: actionTypes.DELETE_RENT_LORRY_REQUEST_START,
    };
};

export const deleteRentLorriesRequestSuccess = (deleteRes) => {
    return {
        type: actionTypes.DELETE_RENT_LORRY_REQUEST_SUCCESS,
        deleteRes,
    };
};

export const deleteRentLorriesRequestFailed = (error) => {
    return {
        type: actionTypes.DELETE_RENT_LORRY_REQUEST_FAIL,
        error,
    };
};

export const deleteRentLorriesRequest = (id) => {
    return (dispatch) => {
        dispatch(deleteRentLorriesRequestStart());

        return axios
            .delete(`/seller/rent/request/lorry/${id}`)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(deleteRentLorriesRequestSuccess(data));
            })
            .catch((err) =>
                dispatch(deleteRentLorriesRequestFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const getRentRequestImage = (id) => {
    return axios
        .get(`/seller/rent/request/lorry/image/${id}`, {
            responseType: 'arraybuffer',// Tell Axios to treat the response as an array buffer
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if (!response) throw new Error("No Response");

            const data = response.data;
            const base64String = arrayBufferToBase64(data);

            return base64String;
        })
        .catch((err) => getErrors(err));
};

export const createRentLorryRequestImageStart = () => {
  return {
    type: actionTypes.CREATE_RENT_LORRY_REQUEST_IMAGE_START,
  };
};

export const createRentLorryRequestImageSuccess = (addImageRes) => {
  return {
    type: actionTypes.CREATE_RENT_LORRY_REQUEST_IMAGE_SUCCESS,
    addImageRes,
  };
};

export const createRentLorryRequestImageFailed = (error) => {
  return {
    type: actionTypes.CREATE_RENT_LORRY_REQUEST_IMAGE_FAIL,
    error,
  };
};

export const createRentLorryRequestImage = (id, imageBase64) => {
  return (dispatch) => {
    dispatch(createRentLorryRequestImageStart());

    return axios
      .post(`/seller/rent/request/lorry/image`, {
        id: id,
        imageBase64: imageBase64,
      })
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(createRentLorryRequestImageSuccess(data));
      })
      .catch((err) =>
        dispatch(
          createRentLorryRequestImageFailed(
            getErrors(err?.response?.data || err)
          )
        )
      );
  };
};

export const deleteRentLorryRequestImageStart = () => {
  return {
    type: actionTypes.DELETE_RENT_LORRY_REQUEST_IMAGE_START,
  };
};

export const deleteRentLorryRequestImageSuccess = (deleteImageRes) => {
  return {
    type: actionTypes.DELETE_RENT_LORRY_REQUEST_IMAGE_SUCCESS,
    deleteImageRes,
  };
};

export const deleteRentLorryRequestImageFailed = (error) => {
  return {
    type: actionTypes.DELETE_RENT_LORRY_REQUEST_IMAGE_FAIL,
    error,
  };
};

export const deleteRentLorryRequestImage = (id) => {
  return (dispatch) => {
    dispatch(deleteRentLorryRequestImageStart());

    return axios
      .delete(`/seller/rent/request/lorry/image/${id}`)
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(deleteRentLorryRequestImageSuccess(data));
      })
      .catch((err) =>
        dispatch(
          deleteRentLorryRequestImageFailed(
            getErrors(err?.response?.data || err)
          )
        )
      );
  };
};
