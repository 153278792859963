import axios from "../../axios-api";
import * as actionTypes from "./types";
import { getResData, getErrors } from "../../shared/utility";

export const getLandBookedDaysStart = () => {
    return {
        type: actionTypes.GET_LAND_BOOKED_DAYS_START,
    };
};

export const getLandBookedDaysSuccess = (landBookedDays) => {
    return {
        type: actionTypes.GET_LAND_BOOKED_DAYS_SUCCESS,
        landBookedDays,
    };
};

export const getLandBookedDaysFailed = (error) => {
    return {
        type: actionTypes.GET_LAND_BOOKED_DAYS_FAIL,
        error,
    };
};

// Get Land Booked Days
export const getLandBookedDays = (year, month, rentRequestId) => {
    return (dispatch) => {
        dispatch(getLandBookedDaysStart());

        let url = `/buyer/rent/request/land/booked/days`;

        const params = {
            year,
            month,
            rentRequestId,
        };

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                dispatch(getLandBookedDaysSuccess(data));
            })
            .catch((err) =>
                dispatch(getLandBookedDaysFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const makeLandOrderStart = () => {
    return {
        type: actionTypes.MAKE_LAND_ORDER_START,
    };
};

export const makeLandOrderSuccess = (landOrder) => {
    return {
        type: actionTypes.MAKE_LAND_ORDER_SUCCESS,
        landOrder,
    };
};

export const makeLandOrderFailed = (error) => {
    return {
        type: actionTypes.MAKE_LAND_ORDER_FAIL,
        error,
    };
};

// Make Land Order
export const makeLandOrder = (data) => {
    return (dispatch) => {
        dispatch(makeLandOrderStart());
  
      return axios
        .post("/buyer/rent/request/land/order", data)
        .then((response) => {
          if (!response) throw new Error('No Response');
  
          const data = getResData(response.data);
  
          if (!data.success) throw new Error(getErrors(data));
  
          return dispatch(makeLandOrderSuccess(data));
        })
        .catch((err) =>
          dispatch(makeLandOrderFailed(getErrors(err?.response?.data || err)))
        );
    };
};

export const getLandTotalPriceStart = () => {
    return {
        type: actionTypes.GET_LAND_TOTAL_PRICE_START,
    };
};

export const getLandTotalPriceSuccess = (landTotalPrice) => {
    return {
        type: actionTypes.GET_LAND_TOTAL_PRICE_SUCCESS,
        landTotalPrice,
    };
};

export const getLandTotalPriceFailed = (error) => {
    return {
        type: actionTypes.GET_LAND_TOTAL_PRICE_FAIL,
        error,
    };
};

// Get Land Total Price
export const getLandTotalPrice = (rentFrom, rentTo, rentRequestId) => {
    return (dispatch) => {
        dispatch(getLandTotalPriceStart());

        let url = `/buyer/rent/request/land/price`;

        const params = {
            rentFrom,
            rentTo,
            rentRequestId,
        };

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getLandTotalPriceSuccess(data));
            })
            .catch((err) =>
                dispatch(getLandTotalPriceFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const getLorryBookedDaysStart = () => {
    return {
        type: actionTypes.GET_LORRY_BOOKED_DAYS_START,
    };
};

export const getLorryBookedDaysSuccess = (lorryBookedDays) => {
    return {
        type: actionTypes.GET_LORRY_BOOKED_DAYS_SUCCESS,
        lorryBookedDays,
    };
};

export const getLorryBookedDaysFailed = (error) => {
    return {
        type: actionTypes.GET_LORRY_BOOKED_DAYS_FAIL,
        error,
    };
};

// Get Lorry Booked Days
export const getLorryBookedDays = (year, month, rentRequestId) => {
    return (dispatch) => {
        dispatch(getLorryBookedDaysStart());

        let url = `/buyer/rent/request/lorry/booked/days`;

        const params = {
            year,
            month,
            rentRequestId,
        };

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                dispatch(getLorryBookedDaysSuccess(data));
            })
            .catch((err) =>
                dispatch(getLorryBookedDaysFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const makeLorryOrderStart = () => {
    return {
        type: actionTypes.MAKE_LORRY_ORDER_START,
    };
};

export const makeLorryOrderSuccess = (lorryOrder) => {
    return {
        type: actionTypes.MAKE_LORRY_ORDER_SUCCESS,
        lorryOrder,
    };
};

export const makeLorryOrderFailed = (error) => {
    return {
        type: actionTypes.MAKE_LORRY_ORDER_FAIL,
        error,
    };
};

// Make Lorry Order
export const makeLorryOrder = (data) => {
    return (dispatch) => {
        dispatch(makeLorryOrderStart());

        const url = "/buyer/rent/request/lorry/order";

        return axios
            .post(url, data)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const responseData = getResData(response.data);

                if (!responseData.success) throw new Error(getErrors(responseData));

                dispatch(makeLorryOrderSuccess());
            })
            .catch((err) =>
                dispatch(makeLorryOrderFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const makeProductOrderStart = () => {
    return {
        type: actionTypes.MAKE_PRODUCT_ORDER_START,
    };
};

export const makeProductOrderSuccess = (order) => {
    return {
        type: actionTypes.MAKE_PRODUCT_ORDER_SUCCESS,
        order,
    };
};

export const makeProductOrderFailed = (error) => {
    return {
        type: actionTypes.MAKE_PRODUCT_ORDER_FAIL,
        error,
    };
};

// Make Cart Order
export const orderCartItems = (data) => {
    return (dispatch) => {
        dispatch(makeProductOrderStart());

        const url = "/buyer/checkout";

        return axios
            .post(url, data)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const responseData = getResData(response.data);

                if (!responseData.success) throw new Error(getErrors(responseData));

                return dispatch(makeProductOrderSuccess(responseData?.data));
            })
            .catch((err) =>
                dispatch(makeProductOrderFailed(getErrors(err?.response?.data || err)))
            );
    };
};

// Make Sell Request Order
export const orderSellRequest = (data) => {
    return (dispatch) => {
        dispatch(makeProductOrderStart());

        const URL = '/buyer/order/sell/request';

        return axios
        .post(URL, data)
        .then(response => {
            if (!response) throw new Error("No Response");

            const responseData = getResData(response.data);

            if (!responseData.success) throw new Error(getErrors(responseData));

            return dispatch(makeProductOrderSuccess(responseData?.data));
        })
        .catch(err => dispatch(makeProductOrderFailed(getErrors(err?.response?.data || err))));
    }
}

// Make Land Request order
export const orderLandRequest = (data) => {
    return (dispatch) => {
        dispatch(makeProductOrderStart());

        const URL = '/buyer/order/rent/request/land';

        return axios
        .post(URL, data)
        .then(response => {
            if (!response) throw new Error("No Response");

            const responseData = getResData(response.data);

            if (!responseData.success) throw new Error(getErrors(responseData));

            return dispatch(makeProductOrderSuccess(responseData?.data));
        })
        .catch(err => dispatch(makeProductOrderFailed(getErrors(err?.response?.data || err))));
    }
}

// Make Lorry request order
export const orderLorryRequest = (data) => {
    
    return (dispatch) => {
        dispatch(makeProductOrderStart());

        const URL = '/buyer/order/rent/request/lorry';

        return axios
        .post(URL, data)
        .then(response => {
            if (!response) throw new Error("No Response");

            const responseData = getResData(response.data);

            if (!responseData.success) throw new Error(getErrors(responseData));

            return dispatch(makeProductOrderSuccess(responseData?.data));
        })
        .catch(err => dispatch(makeProductOrderFailed(getErrors(err?.response?.data || err))));
    }
}

export const getLorryTotalPriceStart = () => {
    return {
        type: actionTypes.GET_LORRY_TOTAL_PRICE_START,
    };
};

export const getLorryTotalPriceSuccess = (lorryTotalPrice) => {
    return {
        type: actionTypes.GET_LORRY_TOTAL_PRICE_SUCCESS,
        lorryTotalPrice,
    };
};

export const getLorryTotalPriceFailed = (error) => {
    return {
        type: actionTypes.GET_LORRY_TOTAL_PRICE_FAIL,
        error,
    };
};

// Get Lorry Total Price
export const getLorryTotalPrice = (rentFrom, rentTo, rentRequestId) => {
    return (dispatch) => {
        dispatch(getLorryTotalPriceStart());

        let url = `/buyer/rent/request/lorry/price`;

        const params = {
            rentFrom,
            rentTo,
            rentRequestId,
        };

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                dispatch(getLorryTotalPriceSuccess(data));
            })
            .catch((err) =>
                dispatch(getLorryTotalPriceFailed(getErrors(err?.response?.data || err)))
            );
    };
};

//Get Shipping Details
export const getShippingDetailsStart = () => {
    return {
        type: actionTypes.GET_SHIPPING_DETAILS_START,
    };
};

export const getShippingDetailsSuccess = (shippingDetails) => {
    return {
        type: actionTypes.GET_SHIPPING_DETAILS_SUCCESS,
        shippingDetails,
    };
};

export const getShippingDetailsFailed = (error) => {
    return {
        type: actionTypes.GET_SHIPPING_DETAILS_FAIL,
        error,
    };
};

// Get shipping details
export const getShippingDetails = () => {
    return (dispatch) => {
        dispatch(getShippingDetailsStart());

        return axios
            .get('/buyer/shipping/details')
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                dispatch(getShippingDetailsSuccess(data));
            })
            .catch((err) =>
                dispatch(getShippingDetailsFailed(getErrors(err?.response?.data || err)))
            );
    };
};


export const getLandTotalPriceTemp = (rentFrom, rentTo, rentRequestId) => {
    
        let url = `/buyer/rent/request/land/price`;

        const params = {
            rentFrom,
            rentTo,
            rentRequestId,
        };

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);
               
                if (!data.success) throw new Error(getErrors(data));

                return data;
            })
            .catch((err) =>{
            
                return err;
            }
            );

};

export const getLorryTotalPriceTemp = (rentFrom, rentTo, rentRequestId) => {
    
    let url = `/buyer/rent/request/lorry/price`;

    const params = {
        rentFrom,
        rentTo,
        rentRequestId,
    };

    if (Object.keys(params).length > 0) {
        url += "?" + new URLSearchParams(params).toString();
    }

    return axios
        .get(url)
        .then((response) => {
            if (!response) throw new Error("No Response");

            const data = getResData(response.data);
           
            if (!data.success) throw new Error(getErrors(data));
            
            return data;
        })
        .catch((err) =>{
        
            return err;
        }
        );

};

// Get Order summary pdf / print receipt.

export const getOrderReceipt = (orderNumber) => {

    const URL = `/buyer/receipt/${orderNumber}`;

    return axios
        .get(URL, { responseType: 'blob' } )
        .then(response => {

            if (!response) throw new Error("No Response");

            return response.data;
        })
        .catch(err => getErrors(err?.response?.data || err));
}

// Get Terms and conditions data

const getTermsAndConditionsStart = () => {
    return {
        type : actionTypes.SET_TERMS_AND_CONDITIONS_START,
    }
}

const getTermsAndConditionsSuccess = (data) => {
    return {
        type : actionTypes.SET_TERMS_AND_CONDITIONS_SUCCESS,
        payload: data
    }
}

const getTermsAndConditionsFailed = (error) => {
    return {
        type : actionTypes.SET_TERMS_AND_CONDITIONS_FAILED,
        payload: error
    }
}

export const getTermsAndConditions = () => {
    return (dispatch) => {
        dispatch(getTermsAndConditionsStart());

        const URL = '/terms/and/conditions';

        return axios
        .get(URL)
        .then(response => {
            if(!response) throw new Error("No Response from Get Terms And Condition API");

            const data = getResData(response.data);

            if (!data.success) throw new Error(getErrors(data));

            if(data.success) dispatch(getTermsAndConditionsSuccess(data?.data));

            if(!data.success) throw new Error("Response Data is not success from Terms and Condition API, Please Try Again later!")
        })
        .catch(err => {
            console.log("Custom Error : ", err);
            dispatch(getTermsAndConditionsFailed(getErrors(err?.response?.data || err)))
        })
    }
}