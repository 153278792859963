import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getProfile } from "./store/actions/profile";
import { getBuyerSubscriptionEnableStatus, getIsSubscriptionExist, getSellerSubscriptionEnableStatus } from "./store/actions/subscription";
import { isAuthorized } from "../src/shared/auth";

const RoleMiddleware = ({ component: Component, allowedRoles, userRole, ...rest }) => {
    
    const dispatch = useDispatch();

    const [isAuthenticated, setIsAuthenticated] = useState(null);
    
    const { profile, subscription } = useSelector((state) => ({
        profile: state?.profile?.profile,
        subscription: state?.subscription,
    }));

    async function  getUserSubscriptionEnableService () {
        if(isAuthenticated) await dispatch(getSellerSubscriptionEnableStatus());
        if(isAuthenticated) await dispatch(getBuyerSubscriptionEnableStatus());
    }

    async function getIsSubscriptionExistCall () {
        await dispatch(getIsSubscriptionExist());
    }

    useEffect(()=> {    
        const authorized = isAuthorized();

        if(authorized) setIsAuthenticated(authorized);
        if(userRole !== "NOT_AUTHORIZED" && authorized) {
            dispatch(getProfile());
        }
    }, []);

    useEffect(() => {
        getUserSubscriptionEnableService();
    },[isAuthenticated])

    useEffect(() => {
        if ((subscription?.seller?.sellerSubscriptionEnable && profile?.type === "SELLER" )|| (subscription?.buyer?.buyerSubscriptionEnable && profile?.type === "BUYER")) {
            getIsSubscriptionExistCall();
        }
    }, [subscription?.seller?.sellerSubscriptionEnable, subscription?.buyer?.buyerSubscriptionEnable])

    return (
        <Route
            {...rest}
            render={(props) => {
                if (allowedRoles.includes(userRole)) {
                    return <Component {...props} />;
                } else if(userRole === "NOT_AUTHORIZED" && localStorage.getItem("m-farm-user-token") && subscription?.isSubscribed === false){
                    return <Redirect to={process.env.PUBLIC_URL + "/subscription"} />;
                } else if (userRole === "NOT_AUTHORIZED" && !localStorage.getItem("m-farm-user-token")) {
                    // Handle direct URL access when user is not authorized and not logged in
                    return <Redirect to={process.env.PUBLIC_URL + "/auth"} />;
                }
            }}
        />
    );
};

export default RoleMiddleware;
