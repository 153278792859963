import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTermsAndConditions } from "../store/actions/order";

const TermsAndConditions = () => {
  const dispatch = useDispatch();

  const { termsAndCondition, error } = useSelector((state) => ({
    termsAndCondition: state?.orders?.termsAndConditions,
    error: state?.orders?.error,
  }));

  useEffect(() => {
    dispatch(getTermsAndConditions());
  },[])
  
  return (
    <div className="max-w-[90vw] md:max-w-[620px]">
      <p className="whitespace-pre-line">
        { termsAndCondition ? termsAndCondition: error ? error : null }
      </p>
    </div>
  );
};

export default TermsAndConditions;
