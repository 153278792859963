import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/Layout";
import Table from "../../../components/Tables/Table";
import SearchForm from "../../../components/Forms/SearchForm";
import Img from "../../../assets/image-placeholder.png";
import { getProfile } from "../../../store/actions/profile";
import {
  getOrderHistoryLorry,
  getOrderHistoryImage,
  getOrderStatus,
  changeOrderLorryStatus,
  changeOrderLorryStatusFailed,
} from "../../../store/actions/orderHistoryLorry";
import Select from "react-select";
import Modal from '../../../components/Modal/Modal';
import MoreInfo from "../../../components/MoreInfo";
import authCheck from "../../../shared/authCheck";
import { formatDate } from "../../../shared/utility";

const OrderHistoryLorry = () => {
  const dispatch = useDispatch();

  const [selectedOrder, setSelectedOrder] = useState(null);
  // const [selectedStatus, setSelectedStatus] = useState(null);
  const [updateList, setUpdateList] = useState(true);
  const [firstItemIndex, setFirstItemIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState(false);
  const [lorryAcceptanceStatus, setLorryAcceptanceStatus] = useState({});
  const [searchValue, setSearchValue] = useState(null);
  const [orderHistoryImages, setOrderHistoryImages] = useState({});

  const { profile, orderHistoryLorry, orderHistoryLorryStatus } = useSelector(
    state => ({
      profile: state?.profile?.profile,
      orderHistoryLorry: state?.orderHistoryLorry?.orderHistoryLorry,
      orderHistoryLorryStatus: state?.orderHistoryLorry?.orderHistoryLorryStatus,
    })
  );

  useEffect(() => {
    if (updateList && profile) {
      if(searchValue){
        dispatch(getOrderHistoryLorry(firstItemIndex, 5, profile?.type, searchValue));
      }else{
        dispatch(getOrderHistoryLorry(firstItemIndex, 5, profile?.type));
      }
      if(profile.type==='SELLER'){
        dispatch(getOrderStatus())
      }
      setUpdateList(false);
    }

    if (!profile) {
      dispatch(getProfile());
    }

    if (orderHistoryLorry) {
      getImages(orderHistoryLorry.data);
    }
  }, [dispatch, firstItemIndex, updateList, profile ,orderHistoryLorry, searchValue]);

  // Get Images
  const getImages = async data => {
    data.map(async e => {
      if (orderHistoryImages[e.id] == undefined && e.imageIdList) {
        const res = await getOrderHistoryImage(e.imageIdList[0]);
        if (res?.response?.status === 404) {
          setOrderHistoryImages(prevState => ({...prevState, [e.id]: null}))
        } else {
          const base64Sting = 'data:image/jpeg;base64,'+ res;
          setOrderHistoryImages(prevState => ({...prevState, [e.id]: base64Sting}))
        }
      }
    });
  };

  //Handle Search
  const handleSearchSubmit = values => {
    dispatch(getOrderHistoryLorry(0, 5, profile?.type, values.search));
    setFirstItemIndex(0)
    setCurrentPage(1)
  };

  // handle status change
  const handleStatusSubmit = async id => {
    
    for(let statusId in lorryAcceptanceStatus){
      if(statusId == id){
        const res = await dispatch(changeOrderLorryStatus(statusId, lorryAcceptanceStatus[statusId].label));
        if (res.type === "CHANGE_ORDER_LORRY_STATUS_SUCCESS") {
          setUpdateList(true);
          // setSelectedStatus(null);
        } else if (res.type === "CHANGE_ORDER_LORRY_STATUS_FAIL") {
          setTimeout(() => {
            dispatch(changeOrderLorryStatusFailed(null));
            setUpdateList(true);
          }, 5000);
        }
      }
    }
  };

  // Handle More Info
  const handleMoreInfo = (info) => {
    setSelectedOrder(info);
    setIsMoreInfoModalOpen(true); 
  }

  const orderPlaceDateFormat = (dateString) => {
    if (dateString) {
      
      const dateAndTime = dateString.split(" ");
      const getDate = dateAndTime[0].split("-");
      const day = parseInt(getDate[0]);
      const month = parseInt(getDate[1]) - 1  ;
      const year = parseInt(getDate[2]);

      const getTime = dateAndTime[1].split(":")
      const hours = parseInt(getTime[0]);
      const minutes = parseInt(getTime[1]);
      const date = new Date(year, month, day, hours, minutes);
      return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })},${date.getFullYear()}`;
    }
  }

  const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        display: 'flex',
         width: '10rem',
        height:"3rem",
        borderRadius: '0.375rem', // Equivalent to 6px (rounded-md)
        background: '#F5F5F5',
        padding: '11px 17px', // Equivalent to 13px 25px (py-[13px] px-[25px])
        border: 'none',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', // Equivalent to shadow-sm
        outline: 'none', // Equivalent to outline-none
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
          ? '#50A060'
          : state.isFocused
              ? '#f0f0f0'
              : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
          backgroundColor: state.isSelected ? '#50A060' : '#f0f0f0',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: '0px',
      color: '#333',
    }),
    input: (provided) => ({
      ...provided,
      padding: '0px',
      margin: '0px', // Remove the padding from the input
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0', // Remove the padding from the value container
    }),
  };

  const tableData = {
    header: [],
    body:
      orderHistoryLorry &&
      orderHistoryLorry?.data?.map((row, index) => { 
        // update status in and keep its track for update status on click
        const updateStatus = (selectedOption) => {
          const newObj = {};
          newObj[row.id] = selectedOption;
          setLorryAcceptanceStatus((prev) => ({...prev, ...newObj }))
        }
        return (
        <React.Fragment key={index}>
          <td className="py-[20px] px-[20px]">
            <div className="flex justify-between gap-[13px] overflow-x-auto overflow-y-hidden">
              <div className={`flex min-w-[20rem] max-w-[20rem]`}>
                <img 
                  src= {
                    orderHistoryImages[row.id] ? 
                    orderHistoryImages[row.id] : 
                    Img
                  }
                  alt="order-image"
                  className="w-16 h-16 rounded"
                />
                <div className="ml-2 text-left truncate">
                  <p className="font-medium px-3 py-2 truncate">{row.name}</p>
                  <p className="text-main pb-2 px-3 text-xs truncate">
                    &#8358; {row.price + " / " + row.pricePeriod}
                  </p>
                </div>
              </div>
              <div className="whitespace-nowrap text-center mr-5 min-w-[10rem] max-w-[10rem]">
                <p className="font-medium pb-2">
                  {Math.ceil(
                    (new Date(row.rentTo) - new Date(row.rentFrom)) /
                      (1000 * 60 * 60 * 24)
                  )  + 1 }{" "}
                  Day
                </p>
                <p className="text-xs text-[#747474] ">
                  {formatDate(row.rentFrom) + " - " + formatDate(row.rentTo) }
                </p>
              </div>
              <div className={`flex justify-between gap-3 whitespace-nowrap text-sm ${profile.type==='BUYER' ? "min-w-[5rem] md:min-w-[15rem]" : "min-w-[15rem]"} mr-5 pr-5`}> 
                  <div>
                    <p className="text-[#898989] font-semibold pb-2">Order Id</p>
                    <p className={`font-semibold ${profile.type==='BUYER' ? 'pointer-events-none' : 'cursor-pointer hover:text-main'}`} onClick={()=> handleMoreInfo(row)}>{row.orderNumber}</p>
                  </div>
                  <div>
                    <p className="text-[#898989] font-semibold pb-2">Date Placed</p>
                    <p className="font-semibold">{orderPlaceDateFormat(row.date)}</p>
                  </div>
                  <div>
                    <p className="text-[#898989] font-semibold pb-2">Status</p>
                    <p className="font-semibold">{row.rentStatus}</p>
                  </div>
              </div>
              <div className="text-right min-w-[5rem] mx-2">
              <p className="text-[#898989] font-semibold pb-1 whitespace-nowrap">Total Amount</p>
                <p className="text-main pb-2 text-xl font-semibold">&#8358; {row.amount}</p>
              </div>
              <div className={`flex gap-2 justify-between sm:gap-[1rem] ${profile.type==='BUYER' && 'hidden'}`}>              
              {/* <div className={profile.type==='BUYER' && 'hidden'}> */}
                <Select
                  onChange={updateStatus}
                  isDisabled={row.rentStatus!=='Waiting'}
                  styles={selectStyles}
                  options={orderHistoryLorryStatus?.data?.map((e,i) => { return { value: i+1, label: e } })}
                  menuPosition="fixed"
                />
              {/* </div>
              <div className={profile.type==='BUYER' && 'hidden'}> */}
                <button 
                  type="submit"
                  disabled={row.rentStatus!=='Waiting'}
                  className={`w-[10rem] rounded-[10px] h-12 font-medium text-white max-h-[4rem] ${row.rentStatus!=='Waiting' ? 'bg-slate-300' : 'bg-main'}`}
                  onClick={()=>handleStatusSubmit(row.id)}
                  >Update</button>
              {/* </div> */}
            </div>
            </div>
          </td>
        </React.Fragment>
        )}
      ),
    className: "grid grid-cols[1fr] items-start",
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <>
      <Modal
        isOpen={selectedOrder && isMoreInfoModalOpen }
        onClose={() => { 
          setIsMoreInfoModalOpen(false)
          setSelectedOrder(null) 
        }}
        title="More Info"
      >
        <MoreInfo 
          data={selectedOrder}
          type="LORRY"
        />
      </Modal>
      <Layout>
        <main className="py-[110px]">
          <div className="mb-[30px] flex flex-col sm:flex-row items-center justify-between gap-3 px-[10px] sm:px-[40px]">
            <h1 className="text-[24px] font-semibold">Order History Lorry</h1>
            <SearchForm setSearchValue={setSearchValue} onSubmit={values => handleSearchSubmit(values)} setUpdateList={setUpdateList} />
          </div>
          {orderHistoryLorry?.data?.length > 0 ? (
            <Table
              data={orderHistoryLorry?.data}
              total={orderHistoryLorry?.total}
              setFirstIndex={setFirstItemIndex}
              onUpdateList={value => setUpdateList(value)}
              tableData={tableData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            <h2 className="px-[40px]">No Order History found</h2>
          )}
        </main>
      </Layout>
    </>
  );
};

export default authCheck(OrderHistoryLorry);
