import { ErrorMessage, Field, Form, Formik } from "formik";
import ProfilePageInput from "../ProfileFormikInput";
import { updateProfile } from "../../store/actions/profile";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useState } from "react";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .trim()
    .max(100, "First Name size must be between 1 and 100"),
  lastName: Yup.string()
    .required("Last Name is required")
    .trim()
    .max(100, "Last Name size must be between 1 and 100"),
});

const ChangeProfileDataForm = ({
  changePassword,
  profile,
  toggleChangePassword,
}) => {
  const dispatch = useDispatch();
  const [firstNameDisabled, setFirstNameDisabled] = useState(true);
  const [lastNameDisabled, setLastNameDisabled] = useState(true);

  return (
    <Formik
      initialValues={{
        firstName: profile?.firstName ? profile?.firstName : "",
        lastName: profile?.lastName ? profile?.lastName : "",
        phoneNumber: profile?.msisdn ? profile?.msisdn : "",
        password: "11111111",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const data = {
          firstName: values?.firstName,
          lastName: values?.lastName,
        };
        await dispatch(updateProfile(data));
        setFirstNameDisabled(true);
        setLastNameDisabled(true);
      }}
    >
      {({ isSubmitting, errors, touched, values, setFieldValue }) => {
        return (
          <Form className="">
            <div className="mb-[16px]">
              <ProfilePageInput
                name="firstName"
                placeholder="Enter your first name"
                label="First Name"
                error={errors?.firstName && touched?.firstName}
                onCancel={() => setFieldValue("firstName", profile?.firstName)}
                disabled={firstNameDisabled}
                setDisabled={setFirstNameDisabled}
              />
            </div>
            <div className="mb-[16px]">
              <ProfilePageInput
                name="lastName"
                placeholder="Enter your last name"
                label="Last Name"
                error={errors?.lastName && touched?.lastName}
                onCancel={() => setFieldValue("lastName", profile?.lastName)}
                disabled={lastNameDisabled}
                setDisabled={setLastNameDisabled}
              />
            </div>
            <ProfilePageInput
              name="phoneNumber"
              placeholder="Enter your phone number"
              label="Phone Number"
              hideEdit={true}
              disabled={true}
            />
            {!changePassword && (
              <div className="mt-[16px]">
                <label htmlFor="password" className="text-[#848484] mb-[8px]">
                  Password
                </label>
                <div
                  className={`flex items-center justify-between border-b pb-[16px]`}
                >
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Enter your password"
                    className="w-full outline-none bg-transparent text-[20px] font-semibold"
                    disabled={true}
                  />
                  <button
                    type="button"
                    onClick={toggleChangePassword}
                    className="ml-2 text-main"
                  >
                    Change
                  </button>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-[#FF0000] mt-1"
                />
              </div>
            )}
            <button
              type="submit"
              className="bg-main text-white px-[30px] py-[16px] rounded-[10px] uppercase mt-[30px]"
              disabled={isSubmitting}
            >
              Update
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangeProfileDataForm;
