import * as actionTypes from "./types";
import axios from "../../axios-api";
import { getErrors, getResData } from "../../shared/utility";

// getTips
export const getTipsStart = () => {
    return {
        type: actionTypes.GET_TIPS_START,
    };
};

export const getTipsSuccess = (tips) => {
    return {
        type: actionTypes.GET_TIPS_SUCCESS,
        tips,
    };
};

export const getTipsFailed = (error) => {
    return {
        type: actionTypes.GET_TIPS_FAIL,
        error,
    };
};

export const getTips = (start, length, content) => {
    return (dispatch) => {
        dispatch(getTipsStart());

        let url = '/user/tip';

        const params = {};

        if (start !== undefined) {
            params.start = start;
        }

        if (length !== undefined) {
            params.length = length;
        }

        if (content !== undefined) {
            params.content = content;
        }

        if (Object.keys(params).length > 0) {
            url += '?' + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getTipsSuccess(data));
            })
            .catch((err) => dispatch(getTipsFailed(getErrors(err?.response?.data || err))));
    };
};