import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/Layout";
import Table from "../../../components/Tables/Table";
import SearchForm from "../../../components/Forms/SearchForm";
import DiagnosisForm from "../../../components/Forms/DiagnosisForm";
import Modal from "../../../components/Modal/Modal";
import Img from "../../../assets/image.svg";
import authCheck from "../../../shared/authCheck"

import {
  getDiagnosisRequest,
  createDiagnosisRequest,
  createDiagnosisRequestFailed,
  getDiagnosisImageRequest,
} from "../../../store/actions/diagnosis";

const DiagnosisRequest = () => {
  const dispatch = useDispatch();

  // const [diagnosisImg, setDiagnosisImg] = useState();
  const [diagnosis, setDiagnosis] = useState();
  const [updateList, setUpdateList] = useState(true);
  const [firstItemIndex, setFirstItemIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddDiagnosisModalOpen, setIsAddDiagnosisModalOpen] = useState(false);
  const [diagnosisImages, setDiagnosisImage] = useState({});
  const [searchValue, setSearchValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const { diagnosisRequests } = useSelector(state => ({
    diagnosisRequests: state?.diagnosisRequest?.diagnosisRequests,
  }));

  useEffect(() => {
    if (updateList) {
      if(searchValue){
        dispatch(getDiagnosisRequest(firstItemIndex, 5, searchValue));
      }else{
        dispatch(getDiagnosisRequest(firstItemIndex, 5));
      }
      setUpdateList(false);
    }

    if (diagnosisRequests) {
      getImages(diagnosisRequests?.data);
    }
  }, [dispatch, firstItemIndex, updateList, diagnosisRequests, searchValue]);

  // Get Images
  const getImages = async data => {
    data.map(async (e) => {
      if(diagnosisImages[e.id] == undefined && e.hasImage){
        const res = await getDiagnosisImageRequest(e.id);
        const base64Sting = 'data:image/jpeg;base64,'+ res;
        setDiagnosisImage(prevState =>({...prevState, [e.id]: base64Sting}))
      }
    })
  };

  //Handle Search
  const handleSearchSubmit = values => {
    dispatch(getDiagnosisRequest(0, 5, values.search));
    setFirstItemIndex(0)
    setCurrentPage(1)
  };

  //Handle Add Diagnosis
  const handleAddDiagnosis = async values => {
    setLoading(true)
    const res = await dispatch(
      createDiagnosisRequest(values)
    );
    if (res.type === "CREATE_DIAGNOSIS_REQUEST_SUCCESS") {
      setUpdateList(true);
      setIsAddDiagnosisModalOpen(false);
      setLoading(false)
    } else if (res.type === "CREATE_DIAGNOSIS_REQUEST_FAIL") {
      setTimeout(() => {
        dispatch(createDiagnosisRequestFailed(null));
        setUpdateList(true);
        setIsAddDiagnosisModalOpen(false);
        setLoading(false)
      }, 5000);
    }
  };

  const tableData = {
    header: [],
    body:
      diagnosisRequests &&
      diagnosisRequests?.data?.map((row, index) => (
        <React.Fragment key={index}>
          <td className="sm:px-[20px] px-[0px] py-[20px]">
            <div className="flex sm:flex-row">
              <div className="min-w-max mb-2">
                <img
                  src={
                    diagnosisImages[row.id] 
                    ? diagnosisImages[row.id] 
                    : Img
                  }
                  alt="diagnosis image"
                  className="h-24 w-24 object-cover m-auto rounded"
                />
              </div>
              <div className="my-auto px-2">
                <p className="text-lg font-semibold">{row.question}</p>
                <p>{row.answer}</p>
              </div>
            </div>
          </td>
        </React.Fragment>
      )),
    className: "grid grid-cols-[12fr] gap-x-[20px] items-start sm:px-[20px] px-[10px]",
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <>
      <Modal
        isOpen={isAddDiagnosisModalOpen}
        onClose={() => setIsAddDiagnosisModalOpen(false)}
        title="Create Diagnosis Request"
      >
        <DiagnosisForm
          onSubmit={values => handleAddDiagnosis(values)}
          onUpdateList={value => setUpdateList(value)}
          onClose={() => setIsAddDiagnosisModalOpen(false)}
          loading = {loading}
        />
      </Modal>
      <Layout>
        <main className="py-[110px]">
          <div className="flex items-center justify-between sm:px-[40px] px-[10px] pb-[30px] flex-col sm:flex-row">
            <div className="flex justify-between items-center">
            <h1 className="text-[24px] font-semibold">Diagnosis Request</h1>
            <button
                type="button"
                onClick={() => {
                  setIsAddDiagnosisModalOpen(true);
                }}
                className="rounded-[10px] bg-main px-[32px] py-[17px] text-white ml-3 block sm:hidden whitespace-nowrap"
              >
                + CREATE
              </button> 
            </div>
           
            <div className="flex items-center">
              <div className="mt-4">
              <SearchForm setSearchValue={setSearchValue} onSubmit={values => handleSearchSubmit(values)} setUpdateList={setUpdateList} />
              </div>
              <button
                type="button"
                onClick={() => {
                  setIsAddDiagnosisModalOpen(true);
                }}
                className="rounded-[10px] bg-main w-[8.6rem] h-[3.2rem] text-white ml-3 hidden sm:block whitespace-nowrap"
              >
                + CREATE
              </button>
            </div>
          </div>
          {diagnosisRequests?.data?.length > 0 ? (
            <Table
              data={diagnosisRequests?.data}
              total={diagnosisRequests?.total}
              setFirstIndex={setFirstItemIndex}
              onUpdateList={value => setUpdateList(value)}
              tableData={tableData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            <h2 className="px-[40px]">No Diagnosis Request found</h2>
          )}
        </main>
      </Layout>
    </>
  );
};

export default authCheck(DiagnosisRequest);
