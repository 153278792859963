import { useEffect, useState, useRef } from "react";
import WeatherWidget from "../../components/WeatherWidget"
import authCheck from "../../shared/authCheck"
import Layout from "../../components/Layout";

const WeatherInfo = ({ weatherData }) => {
    const weather = weatherData.weather[0];
    const temperature = weatherData.main.temp;
    const feelsLike = weatherData.main.feels_like;
    const tempMin = weatherData.main.temp_min;
    const tempMax = weatherData.main.temp_max;
    const humidity = weatherData.main.humidity;
    const pressure = weatherData.main.pressure;
    const windSpeed = weatherData.wind.speed;
    const windDegree = weatherData.wind.deg;
    const windGust = weatherData.wind.gust;
    const visibility = weatherData.visibility;
    const cloudiness = weatherData.clouds.all;
    const dateTime = weatherData.dt_txt;

    const description = weather.description.toLowerCase();
    const capitalizedDescription =
        description.charAt(0).toUpperCase() + description.slice(1);

    return (
        <div className="py-[40px]">
            <b>Weather Information</b>
            <p>
                The current temperature is between {tempMin}°C and {tempMax}°C. It feels
                like {feelsLike}°C. Humidity level is {humidity}%, and atmospheric pressure is {pressure} hPa.
                Wind speed is {windSpeed} m/s, coming from {windDegree}°. There might be
                gusts up to {windGust} m/s. Visibility is {visibility} meters, and cloudiness is {cloudiness}%.
                {capitalizedDescription}.
            </p>
            <br />
            <p>This weather data was recorded on {dateTime}.</p>
        </div>
    );
};


const SearchComponent = ({ onSearch }) => {
    const [city, setCity] = useState('');
    const [cityItem, setCityItem] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [error, setError] = useState("")

    const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
    const dropdownRef = useRef(null);

    const handleSearch = async () => {
        if (city.trim() !== '') {
            try {
                const response = await fetch(
                    `https://api.openweathermap.org/geo/1.0/direct?q=${city}&limit=5&appid=${apiKey}`
                );

                if (response.ok) {
                    const data = await response.json();
                    setSuggestions(data);
                    setIsDropdownOpen(true);
                } else {
                    console.log('Error fetching city data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    useEffect(() => {
        handleSearch();
    }, [city]);

    const handleOptionClick = (item) => {
        setCity(item.name);
        setCityItem(item);
        setIsDropdownOpen(false);
    };

    const handleSearchClick = () => {
        if (cityItem) {
            onSearch(cityItem);
            setError("")
        } else {
            setError("Please select a location from the list")
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="w-full md:w-auto mt-[110px]">
            <div className="w-full md:w-auto flex gap-x-[20px]">
                <div className="w-full md:w-auto relative">
                    <input
                        type="search"
                        placeholder="Search here"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="rounded-lg outline-none bg-gray-200 p-4 w-full"
                    />
                    <div ref={dropdownRef} className="absolute top-[56px] mt-[0px] bg-white border rounded-lg shadow-lg z-[15]">
                        {isDropdownOpen &&
                            suggestions.map((item) => (
                                <div
                                    key={item.lat}
                                    className="cursor-pointer py-1 hover:bg-gray-100 py-2 px-4 "
                                    onClick={() => handleOptionClick(item)}
                                >
                                    <p>{item.name}</p>
                                    <span className="text-[12px] text-[#777]">{item?.state} {item?.country}</span>
                                </div>
                            ))}
                    </div>

                </div>
                <button
                    className="bg-main text-white py-2 px-4 rounded-lg"
                    onClick={handleSearchClick}
                >
                    Search
                </button>
            </div>
            <p className="text-[#FF0000] text-[14px]">{error}</p>
        </div>
    );
};


const WeatherPage = () => {
    const [city, setCity] = useState('');
    const [weatherData, setWeatherData] = useState(null);

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[]);

    return <Layout>
        <main className="container mx-auto px-12 md:px-48">
            <div className="grid md:flex md:justify-between mt-[45px] mb-[30px] items-center">
                <h1 className="text-[18px] md:text-[42px] font-semibold mr-[20px]">Weather</h1>
                <SearchComponent onSearch={(city) => setCity(city)} />
            </div>
            <div className="bg-white shadow-table p-[20px] md:p-[40px] rounded-[10px] my-4">
                <h2 className="text-[18px] md:text-[24px] font-semibold mb-[13px]">Today’s Weather Updates</h2>
                <WeatherWidget city={city} setWeather={setWeatherData} />
            </div>
            {weatherData && <WeatherInfo weatherData={weatherData} />}
        </main>
    </Layout>
}

export default authCheck(WeatherPage)