import React from 'react';

import CloseIcon from "../../assets/close.svg"

import './Modal.scss';

const Modal = ({ isOpen, onClose, children, title }) => {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <>
            {isOpen && (
                <div className="modal-overlay" onClick={handleOverlayClick}>
                    <div className="modal-container min-w-[100vw] sm:min-w-0 sm:max-w-[50%]">
                        <div className="grid justify-items-end mb-[6px]">
                            <button className="modal-close" onClick={onClose}>
                                <img src={CloseIcon} alt="Close" />
                            </button>
                        </div>
                        <h2 className="text-center text-[24px] font-semibold mb-[30px] mt-[6px]">{title}</h2>

                        <div className="modal-content">{children}</div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
