import react, { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Layout from "../../components/Layout";
import { Field, Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  makeProductOrderFailed,
  getShippingDetails,
  orderCartItems,
  orderLorryRequest,
  orderSellRequest,
  orderLandRequest,
} from "../../store/actions/order";
import { useState } from "react";
import authCheck from "../../shared/authCheck";
import "../../App.scss";
import momoLogo from "../../assets/momo.png";
import CloseIcon from "../../assets/close.svg";
import NigeriaCurrency from "../../assets/NigeriaCurrency";
import { addToCart, getCartDetails } from "../../store/actions/cart";
import Modal from "../../components/Modal/Modal";
import TermsAndConditions from "../../components/TermsAndConditions";
import { getErrors } from "../../shared/utility";
import OrderConfirmed from "../../components/OrderConfirmed";
import Spinner from "../../components/Spinner";

let nameRegex = /^[a-zA-Z ]*$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .trim()
    .matches(nameRegex, "First Name must contain only alphabet characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .trim()
    .matches(nameRegex, "Last Name must contain only alphabet characters"),
  street: Yup.string().required("Street is required").trim(),
  area: Yup.string()
    .required("Area is required")
    .trim()
    .min(1, "Area size must be between 1 and 255")
    .max(255, "Area size must be between 1 and 255"),
  city: Yup.string()
    .required("City is required")
    .trim()
    .min(1, "City size must be between 1 and 100")
    .max(100, "City size must be between 1 and 100"),
  note: Yup.string().optional().trim().max(800),
});

const Order = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  let setFieldV;
  let resetFormikForm;

  const { CartDetails, shippingDetails } = useSelector((state) => ({
    CartDetails: state?.cart?.CartDetails,
    shippingDetails: state?.orders?.shippingDetails,
  }));

  const [orderStatus, setOrderStatus] = useState(null);
  const [checkbox, setCheckBox] = useState(false); // if saveAddress is true call API to save address on submitform;
  const [isTermsAndConditionChecked, setisTermsAndConditionChecked] = useState(false);
  const [landSubtotal, setLandSubtoal] = useState(0);
  const [lorrySubtotal, setLorrySubtoal] = useState(0);
  const [productSubtotal, setProductSubtoal] = useState(0);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState();
  const [mtnNumber, setMtnNumber] = useState("");
  const [total, setTotal] = useState(0);
  const [laoding, setLoading] = useState(false);
  const [mtnFieldError, setMtnFieldError] = useState("");
  const [orderNumber, setOrderNumber] = useState(null);
  const [orderAmount, setOrderAmount] = useState(null);
  const [isOrderConfirmed, setIsOrderConfirmed] = useState(false);
  const [orderItems, setOrderItems] = useState(null);
  const [orderSummaryType, setOrderSummaryType] = useState(null);

  const CustomInputSelectComponent = () => {
    const handleOnChange = () => {
      setCheckBox(!checkbox);
    };
    return (
      <input
        className="checkbox"
        checked={checkbox}
        onChange={handleOnChange}
        type="checkbox"
      />
    );
  };

  const updateCart = async () => {
    const cart = await dispatch(getCartDetails());
    setOrderSummaryType("CART");
    setOrderItems(cart.CartDetails)
  }

  //update order type and its details
  useEffect(()=>{

    const items = {
      product : [],
      land : [],
      lorry : [],
    }

    const localData = location?.data?.item;
    // check and updated order summary for buy now, rent now and cart
    switch (location?.data?.type) {
      case "PRODUCT":
        items.product.push({
          amount : location?.data?.quantity,
          data: {
            categoryId : localData?.categoryId,
            categoryName : localData?.categoryName,
            date : localData?.date,
            description : localData?.description,
            hasImage: localData?.hasImage,
            sellRequestId: localData?.id,
            location: localData?.location,
            msisdn: localData?.msisdn,
            name: localData?.name,
            price: localData?.price,
          }
        })
        setOrderItems(items);
        setOrderSummaryType("PRODUCT");
      break;

      case "LAND":
        items.land.push({
          amount: location?.data?.quantity,
          data: {
            firstName: "",
            lastName: "",
            notes: null,
            rentFrom: location?.data?.rentFrom?.split("T")[0],
            rentTo: location?.data?.rentTo?.split("T")[0],
            rentRequestId: localData?.id,
            land: {
              date: localData?.date,
              description: localData?.description,
              imageIdList: localData?.imageIdList,
              location: localData?.location,
              measurement: localData?.measurement,
              msisdn: localData?.msisdn,
              name: localData?.name,
              plantation: localData?.plantation,
              price: localData?.price,
              pricePeriod: localData?.pricePeriod,
              uuid: localData?.uuid,
            }
          }
        })
        setOrderItems(items);
        setOrderSummaryType("LAND");
      break;

      case "LORRY":
        items.lorry.push({
          amount: location?.data?.quantity,
          data: {
            firstName: "",
            lastName: "",
            notes: null,
            rentFrom: location?.data?.rentFrom,
            rentTo: location?.data?.rentTo,
            rentRequestId: localData?.id,
            lorry: {
              brandName: localData?.brandName,
              date: localData?.date,
              horsePower: localData?.horsePower,
              imageIdList: localData?.imageIdList,
              location: localData?.location,
              msisdn: localData?.msisdn,
              name: localData?.name,
              price: localData?.price,
              pricePeriod: localData?.pricePeriod,
              uuid: localData?.uuid,
              yearOfManufacture: localData?.yearOfManufacture
            }
          }
        })
        setOrderItems(items);
        setOrderSummaryType("LORRY");
      break;

      default:
        updateCart();
       
      break;
    }

  },[]);

  useEffect(() => {
    let pdTotal = 0;
    let landTotal = 0;
    let lorryTotal = 0;
    let total = 0;
    orderItems?.product?.length > 0 &&
      orderItems.product.forEach((e) => {
        pdTotal += (e.data.price * e.amount);
        total += (e.data.price * e.amount);
        // setProductSubtoal((prev) => prev + e.data.price * e.amount);
        // setTotal((prev) => prev + e.data.price * e.amount);
      });

    orderItems?.land?.length > 0 &&
      orderItems.land.forEach((e) => {
        landTotal += e.amount;
        total += e.amount;
        // setLandSubtoal((prev) => prev + e.amount);
        // setTotal((prev) => prev + e.amount);
      });

    orderItems?.lorry?.length > 0 &&
      orderItems.lorry.forEach((e) => {
        lorryTotal += e.amount;
        total += e.amount;
        // setLorrySubtoal((prev) => prev + e.amount);
        // setTotal((prev) => prev + e.amount);
      });

      setProductSubtoal(pdTotal);
      setLandSubtoal(landTotal);
      setLorrySubtoal(lorryTotal);
      setTotal(total);
  }, [CartDetails, orderItems]);

  useEffect(() => {
    dispatch(getShippingDetails());
  }, []);

  // update field values.
  useEffect(() => {
    if (setFieldV && shippingDetails) {
      setFieldV("firstName", shippingDetails?.data?.firstName);
      setFieldV("lastName", shippingDetails?.data?.lastName);
      setFieldV("street", shippingDetails?.data?.street);
      setFieldV("area", shippingDetails?.data?.area);
      setFieldV("city", shippingDetails?.data?.city);
    }
  }, [shippingDetails, setFieldV]);

  const formatDate = (dateString) => {
    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Extract year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const formatOrderDate = (dateString) => {
    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Extract year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  };

  useEffect(() => {
    const pattern = /^\+(234|0)([789]\d{9})$/;
    if (mtnNumber !== "" && !pattern.test(mtnNumber)) {
      setMtnFieldError("Mobile number is invalid");
    } else {
      setMtnFieldError("");
    }
  }, [mtnNumber]);

  const handleChangeMtnNumber = (e) => {
    setMtnNumber(e.target.value);
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <>
      <Modal
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        title="Terms & Conditions"
      >
        <TermsAndConditions />
      </Modal>
      <Layout>
        <section className="container px-1 md:mx-[auto] mt-[110px]">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              street: "",
              area: "",
              city: "",
              note: "",
              saveAddress: checkbox,
            }}
            validationSchema={validationSchema}
            onSubmit={ async (values) => {
              let landItems = [];
              let lorryItems = [];
              let productItems = [];
              setLoading(true);

              orderItems.land.forEach((e) => {
                landItems.push({
                  rentFrom: formatDate(e.data.rentFrom),
                  rentRequestId: e.data.land.id,
                  rentTo: formatDate(e.data.rentTo),
                });
              });

              orderItems.lorry.forEach((e) => {
                lorryItems.push({
                  rentFrom: formatDate(e.data.rentFrom),
                  rentRequestId: e.data.lorry.id,
                  rentTo: formatDate(e.data.rentTo),
                });
              });

              orderItems.product.forEach((e) => {
                productItems.push({
                  quantity: e.amount,
                  sellRequestId: e.data.id,
                });
              });
              
              //swithc case check for order type and call API accordingly
              let res ;
              switch (orderSummaryType) {

                case "PRODUCT":
                  let sellItemRequestBody = {
                    paymentMsisdn: mtnNumber,
                    quantity: orderItems.product[0].amount,
                    saveShippingDetails: checkbox,
                    sellRequestId: orderItems.product[0].data.sellRequestId,
                    shippingDetails: {
                      area: values.area,
                      city: values.city,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      notes: values.note,
                      street: values.street,
                    },
                  };

                  res = await dispatch(orderSellRequest(sellItemRequestBody));
                  break;

                case "LAND": 
                  let landItemRequestBody = {
                    paymentMsisdn: mtnNumber,
                    rentFrom: orderItems.land[0]?.data?.rentFrom,
                    rentRequestId: orderItems.land[0]?.data?.rentRequestId,
                    rentTo:orderItems.land[0]?.data?.rentTo,
                    saveShippingDetails: checkbox,
                    shippingDetails: {
                      area: values.area,
                      city: values.city,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      notes: values.note,
                      street: values.street,
                    },
                  };

                  res = await dispatch(orderLandRequest(landItemRequestBody));
                  break;
                  
                  case "LORRY":
                    let lorryItemRequestBody = {
                      paymentMsisdn: mtnNumber,
                      rentFrom: orderItems.lorry[0]?.data?.rentFrom,
                      rentRequestId: orderItems.lorry[0]?.data?.rentRequestId,
                      rentTo:orderItems.lorry[0]?.data?.rentTo,
                      saveShippingDetails: checkbox,
                      shippingDetails: {
                        area: values.area,
                        city: values.city,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        notes: values.note,
                        street: values.street,
                      },
                    };

                    res = await dispatch(orderLorryRequest(lorryItemRequestBody));
                  break;

                  case "CART":
                    let cartRequestBody = {
                      paymentMsisdn: mtnNumber,
                      rentRequestLandItems: landItems,
                      rentRequestLorryItems: lorryItems,
                      saveShippingDetails: checkbox,
                      sellRequestItems: productItems,
                      shippingDetails: {
                        area: values.area,
                        city: values.city,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        notes: values.note,
                        street: values.street,
                      },
                    };

                    res = await dispatch(orderCartItems(cartRequestBody));
                  break;
                
                default:
                  throw new Error ("Order summary type is not defined");
              }

              // if order is success
              
              if (res.type === "MAKE_PRODUCT_ORDER_SUCCESS") {
                setOrderStatus({
                  result: "Order placed successfully",
                  code: 1,
                });
                setOrderNumber(res?.order?.orderNumber);
                setOrderAmount(res?.order?.amount);
                setIsOrderConfirmed(true);
                dispatch(getCartDetails());
                resetFormikForm();
                setLoading(false);
              } else if (res.type === "MAKE_PRODUCT_ORDER_FAIL") {
                const errors = getErrors(res);
                setOrderStatus({ result: errors, code: 0 });
                setTimeout(() => {
                  dispatch(makeProductOrderFailed(null));
                }, 5000);
                setLoading(false);
              }
            }}
          >
            {({
              isSubmitting,
              errors,
              touched,
              handleSubmit,
              values,
              setFieldValue,
              resetForm,
            }) => {
              setFieldV = setFieldValue;
              resetFormikForm = resetForm;
              return (
                <div className="grid md:grid-cols-2 my-[40px] gap-y-[20px] gap-x-[100px] md:px-6">
                  <div className="flex flex-col">
                    <h2 className="text-[32px] font-semibold">
                      Shipping Details
                    </h2>
                    <div className="flex-grow">
                      <Form className="mt-[32px] h-[80%] gap-[10px] grid grid-gap-y-5">
                        <div className="flex items-center">
                          <Field
                            as={CustomInputSelectComponent}
                            name="toggle"
                          />
                          <span className="ml-3">
                            Save this address for later use
                          </span>
                        </div>
                        <div className="grid grid-cols-2 gap-[16px]">
                          <FormikInput
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            errors={errors}
                            touched={touched}
                          />
                          <FormikInput
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <FormikInput
                          type="text"
                          name="street"
                          placeholder="House No. / Street Name*"
                          errors={errors}
                          touched={touched}
                        />
                        <FormikInput
                          type="text"
                          name="area"
                          placeholder="Area, Society, etc"
                          errors={errors}
                          touched={touched}
                        />
                        <FormikInput
                          type="text"
                          name="city"
                          placeholder="Town / City*"
                          errors={errors}
                          touched={touched}
                        />
                        <FormikInput
                          type="textarea"
                          name="note"
                          placeholder="Order Note"
                          errors={errors}
                          touched={touched}
                        />
                      </Form>
                    </div>
                  </div>
                  {isOrderConfirmed ? (
                    <OrderConfirmed
                      orderNumber={orderNumber}
                      orderAmount={orderAmount}
                    />
                  ) : (
                    <div className="bg-[#FBFFF4] rounded-[10px] relative">
                      {isSubmitting && (
                        <div className="z-[5] absolute w-full h-full bg-[#cdcdcd80] flex items-center justify-center rounded-[10px]">
                          <Spinner />
                        </div>
                      )}
                      <h2 className="text-[32px] font-semibold px-[35px] text-main my-[10px]">
                        Order Details
                      </h2>

                      {orderItems?.product.length > 0 && (
                        <>
                          <p className="border-b-[2px] border-[#898989]"></p>
                          <div className="px-[35px]">
                            <div className="text-[#898989] font-semibold flex justify-between pb-[10px] mt-[16px]">
                              <span>Product</span>
                              <span>Subtotal</span>
                            </div>
                            {orderItems?.product.length > 0 &&
                              orderItems?.product.map((e) => {
                                return (
                                  <>
                                    <div className="font-semibold flex justify-between pb-[10px] mt-[16px]">
                                      <span>
                                        {e.data.name}* {e.amount}
                                      </span>
                                      <span className="flex gap-1 items-center">
                                        <NigeriaCurrency color="black" />
                                        {e.data.price * e.amount}
                                      </span>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                          <div className="px-[35px] py-5 border-b-[2px] border-[#898989] font-semibold flex justify-between">
                            <span className="text-[#898989]">Subtotal</span>
                            <span className="flex gap-1 items-center text-[#898989]">
                              <NigeriaCurrency color="#898989" />
                              {productSubtotal}
                            </span>
                          </div>
                        </>
                      )}

                      {orderItems?.lorry.length > 0 && (
                        <>
                          <div className="px-[35px]">
                            <div className="text-[#898989] font-semibold flex justify-between pb-[10px] mt-[16px]">
                              <span>Lorry</span>
                              <span>Subtotal</span>
                            </div>
                            {orderItems?.lorry.length > 0 &&
                              orderItems?.lorry.map((e) => {
                                return (
                                  <>
                                    <div className="font-semibold flex justify-between pb-[10px] mt-[16px]">
                                      <div className="flex flex-col gap-1">
                                        <span>{e.data.lorry.name}</span>
                                        <span className="font-light text-xs pl-8">{(e.data.rentFrom === e.data.rentTo) ? `Rent day: ${formatOrderDate(e.data.rentFrom)}` : `Rent Duration: ${formatOrderDate(e.data.rentFrom)} to ${formatOrderDate(e.data.rentTo)}`}</span>
                                      </div>
                                      <span className="flex gap-1 items-center">
                                        <NigeriaCurrency color="black" />
                                        {e.amount}
                                      </span>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                          <div className="px-[35px] py-5 border-b-[2px] border-[#898989] font-semibold flex justify-between">
                            <span className="text-[#898989]">Subtotal</span>
                            <span className="flex gap-1 items-center text-[#898989]">
                              <NigeriaCurrency color="#898989" />
                              {lorrySubtotal}
                            </span>
                          </div>
                        </>
                      )}

                      {orderItems?.land.length > 0 && (
                        <>
                          <div className="px-[35px]">
                            <div className="text-[#898989] font-semibold flex justify-between pb-[10px] mt-[16px]">
                              <span>Land</span>
                              <span>Subtotal</span>
                            </div>
                            {orderItems?.land.length > 0 &&
                              orderItems?.land.map((e) => {
                                return (
                                  <>
                                    <div className="font-semibold flex justify-between pb-[10px] mt-[16px]">
                                      <div className="flex flex-col gap-1">
                                        <span>{e.data.land.name}</span>
                                        <span className="font-light text-xs pl-8">{(e.data.rentFrom === e.data.rentTo) ? `Rent day: ${formatOrderDate(e.data.rentFrom)}` : `Rent Duration: ${formatOrderDate(e.data.rentFrom)} to ${formatOrderDate(e.data.rentTo)}`}</span>
                                      </div>
                                      <span className="flex gap-1 items-center">
                                        <NigeriaCurrency color="black" />
                                        {e.amount}
                                      </span>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                          <div className="px-[35px] py-5 border-b-[2px] border-[#898989] font-semibold flex justify-between">
                            <span className="text-[#898989]">Subtotal</span>
                            <span className="flex gap-1 items-center text-[#898989]">
                              <NigeriaCurrency color="#898989" />
                              {landSubtotal}
                            </span>
                          </div>
                        </>
                      )}

                      <div className="px-[35px] py-3 border-b-[2px] border-[#898989] font-semibold flex justify-between">
                        <span>Total</span>
                        <span className="flex gap-1 items-center">
                          <NigeriaCurrency color="black" />
                          {total}
                        </span>
                      </div>
                      <div className="flex flex-col my-6 px-[35px]">
                        <div className="flex justify-between items-center font-semibold">
                          <div className="flex gap-5">
                            <input
                              type="radio"
                              checked={true}
                              className="checkbox"
                            />
                            <img
                              src={momoLogo}
                              alt="momo"
                              className="h-[20px] w-[20px]"
                            />
                            <p className="">MTN MoMo Pay</p>
                          </div>
                          <div className="">
                            <p className=" flex gap-1 items-center text-main">
                              <NigeriaCurrency color="#50A060" />
                              {total}
                            </p>
                          </div>
                        </div>
                        <div className="my-3 flex flex-col gap-2">
                          <p>
                            Please confirm the MTN phone number that you will
                            use to pay for your order.
                          </p>
                          <input
                            type="text"
                            placeholder="Enter Phone No."
                            className=" pl-3 h-[3rem] w-[15rem] rounded-md"
                            value={mtnNumber}
                            onChange={handleChangeMtnNumber}
                          />
                          <p className="text-[#FF0000]">{mtnFieldError}</p>
                          <p>Request will be sent to this number.</p>
                        </div>
                        <div className="flex gap-2 ">
                          <input
                            onChange={() =>
                              setisTermsAndConditionChecked(
                                !isTermsAndConditionChecked
                              )
                            }
                            className="checkbox mt-[2px]"
                            type="checkbox"
                          />
                          <span>
                            I have read and agree to the website{" "}
                            <button
                              type="button"
                              className="text-main cursor-pointer"
                              onClick={() => setIsTermsModalOpen(true)}
                            >
                              terms & conditions
                            </button>
                            *
                          </span>
                        </div>
                      </div>
                      <div className="px-[35px] mb-[20px] grid">
                        <button
                          type="button"
                          onClick={() => {
                            handleSubmit(values);
                          }}
                          disabled={
                            mtnNumber.length === 0 ||
                            mtnNumber.length !== 14 ||
                            !isTermsAndConditionChecked ||
                            laoding
                          }
                          className={`bg-main rounded-[10px] text-white text-[18px] font-medium text-center flex justify-center py-[13px] ${
                            mtnNumber.length === 0 ||
                            mtnNumber.length !== 14 ||
                            !isTermsAndConditionChecked ||
                            laoding
                              ? "opacity-60"
                              : "opacity-100"
                          }`}
                        >
                          PAY NOW
                        </button>
                        <p
                          className={`mt-2 ${
                            orderStatus?.code === 1
                              ? "text-main"
                              : "text-[#FF0000]"
                          }`}
                        >
                          {orderStatus?.result}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          </Formik>
        </section>
      </Layout>
    </>
  );
};

export default authCheck(Order);
