import { useCallback, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useHistory, useParams } from "react-router-dom";
import {
  getHomeSellRequestImage,
  getSelectedtItemById,
} from "../../store/actions/homeRentSell";
import authCheck from "../../shared/authCheck";

import LocationIcon from "../../assets/location-pin.svg";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../store/actions/cart";
import defaultImage from "../../assets/image-placeholder.png";

import PhoneIcon from "../../assets/telephone-call.svg";
import { isAuthorized } from "../../shared/auth";
import { getProfile } from "../../store/actions/profile";

const ProductDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const param = useParams();

  const [product, setProduct] = useState(null);
  const [image, setImage] = useState(null);
  const [amount, setAmount] = useState(1);
  const [showMsisdn, setShowMsisdn] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const [showAddToCartSuccessText, setShowAddToCartSucessText] = useState(false);

  const { selectedProduct, CartDetails, profile } = useSelector((state) => ({
    selectedProduct: state?.homeRentSell?.selectedProduct,
    CartDetails: state?.cart?.CartDetails,
    profile: state?.profile?.profile,
  }));

  const fetchImages = useCallback(async () => {
    if (selectedProduct) {
      const res = await getHomeSellRequestImage(selectedProduct?.id);
      setImage(res);
    }
  }, [product, selectedProduct]);

  // fetch product details
  useEffect(() => {
    const productId = param?.id;
    dispatch(getSelectedtItemById(productId, "PRODUCT"));

    if (!profile) {
      dispatch(getProfile());
    }
  }, []);

  useEffect(() => {
    setProduct(selectedProduct);
    fetchImages();
  }, [selectedProduct]);

  const handleAddToCart = () => {
    const authorized = isAuthorized();
    if (authorized && product?.id) {
      const isItemAlreadyAvailableInCart = CartDetails?.product?.reduce(
        (acc, item) => {
          return item.data.sellRequestId === product?.id || acc;
        },
        false
      );

      if (isItemAlreadyAvailableInCart) {
        setStatusMessage("Item already present in cart");
        setShowAddToCartSucessText(true);
      } else {
        const req = { type: "product", id: product.id, quantity: amount };
        dispatch(addToCart(req));
        setStatusMessage("Item added to cart");
        setShowAddToCartSucessText(true);
      }
      setTimeout(() => {
        setStatusMessage(null);
        setShowAddToCartSucessText(false);
      }, 3000);
    } else if (!authorized) {
      history.push(`${process.env.PUBLIC_URL}/auth`);
    } else {
      throw new Error("Product ID not found");
    }
  };

  const handleBuyNow = () => {
    const buyItem = {
      type: "PRODUCT",
      item: selectedProduct,
      quantity: amount,
    };

    history.push({
      pathname: `${process.env.PUBLIC_URL}/order`,
      data: buyItem,
    });
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <>
      <Layout>
        {showAddToCartSuccessText && (
          <div className="fixed top-20 right-14 bg-slate-100 p-4 rounded">
            <p
              className={`${
                statusMessage === "Item added to cart"
                  ? "text-green-500"
                  : "text-[#FF0000]"
              } ${showAddToCartSuccessText ? "visible" : "invisible"}`}
            >
              {statusMessage}
            </p>
          </div>
        )}
        <section className="container mx-[auto] mt-[110px] mb-[60px]">
          <div className="grid md:grid-cols-2 items-center gap-x-[70px] px-6">
            <div>
              {image ? (
                <div
                  title="Change Profile Image"
                  className="w-[100%] h-[250px] md:h-[675px] rounded-[10px]"
                  style={{
                    backgroundImage: `url(data:image/jpeg;base64,${image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
              ) : (
                <img
                  src={defaultImage}
                  className="w-[100%] h-[250px] md:h-[675px] rounded-[10px]"
                />
              )}
            </div>
            <div>
              <h2 className="text-[28px] md:text-[42px] font-semibold">
                {product?.name}
              </h2>
              <div className="mt-[10px] text-[16px] md:text-[20px] flex text-[#AAAAAA]">
                {product?.categoryName}
              </div>
              <p className="text-[18px] md:text-[24px] text-[#50A060] mt-[10px] md:mt-[36px]">
                &#8358; {product?.price}
              </p>
              <div className="mt-[10px] md:mt-[16px]  text-[16px] md:text-[20px] flex">
                <img src={LocationIcon} alt="" className="mr-[12px]" />
                <span>{product?.location}</span>
              </div>
              <div
                className={`text-[16px] md:text-[20px] flex items-center transition-opacity duration-500 ease-in-out ${
                  showMsisdn
                    ? "opacity-100 mt-[10px] md:mt-[16px]"
                    : "invisible opacity-0 h-[0px]"
                }`}
              >
                <img src={PhoneIcon} alt="" className="mr-[12px] h-[20px]" />
                <span>{product?.msisdn}</span>
              </div>
              <p className="mt-[20px] text-[18px] md:text-[20px]">
                {product?.description}
              </p>

              {profile?.type !== "SELLER" && (
                <>
                  {" "}
                  <div className="flex gap-x-[30px] items-center mt-[22px]">
                    <button
                      type="button"
                      onClick={() =>
                        amount > 1 ? setAmount(amount - 1) : setAmount(1)
                      }
                      className="bg-[#50A060] rounded-[10px] w-[62px] h-[62px] text-white text-[32px]"
                    >
                      -
                    </button>
                    {amount}
                    <button
                      type="button"
                      onClick={() => setAmount(amount + 1)}
                      className="bg-[#50A060] rounded-[10px] w-[62px] h-[62px] text-white text-[32px]"
                    >
                      +
                    </button>
                  </div>
                  <div className="grid gap-[16px] lg:grid-cols-[1fr_1fr_62px] mt-[36px] md:mb-[35px] justify-items-center">
                    <a
                      onClick={() => handleBuyNow()}
                      className="cursor-pointer block py-[17px] w-full max-w-[330px] bg-[#50A060] rounded-[10px] text-white text-[18px] font-medium text-center"
                    >
                      BUY NOW
                    </a>
                    <a
                      onClick={() => handleAddToCart()}
                      className="cursor-pointer block py-[17px] w-full max-w-[330px] bg-[#50A060] rounded-[10px] text-white text-[18px] font-medium text-center"
                    >
                      ADD TO CART
                    </a>
                    <button
                      type="button"
                      onClick={() => setShowMsisdn(!showMsisdn)}
                      className="bg-[#F5FFE1] rounded-[10px] w-full max-w-[330px] lg:max-w-[62px] h-[62px] flex justify-center items-center px-[15px] gap-[12px]"
                    >
                      <img
                        src={PhoneIcon}
                        alt="phone number"
                        className="w-[32px] h-[32px]"
                      />
                      <p className="text-main lg:hidden uppercase">
                        {showMsisdn ? "hide Phone Number" : "show phone number"}
                      </p>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default ProductDetails;
