import React from 'react';
import NigeriaCurrency from '../assets/NigeriaCurrency';
import { useHistory } from "react-router-dom";
import { getOrderReceipt } from '../store/actions/order';

function OrderConfirmed({orderNumber, orderAmount}) {
    const history = useHistory();

    const handleContinueShopping = () => {
        history.push(`${process.env.PUBLIC_URL}/`);
    }

    const handlePrintReceipt = async () => {
        let getReceipt = null;

        try {
            getReceipt = await getOrderReceipt(orderNumber);
        } catch(err) {
            console.log('Error downloading PDF:', err);
        }   

        if(getReceipt) {
            const blob = new Blob([getReceipt], { type: 'application/pdf' });

              const reader = new FileReader();

              reader.readAsDataURL(blob);
              
              reader.addEventListener(
                "load",
                () => {
                  // download pdf file
                    const download = document.createElement('a');
                    download.href = reader.result;
                    download.download = 'order_receipt.pdf';
                    download.click();
                },
                false,
              );
        }
    }

  return (
    <div className='bg-[#FBFFF4] rounded-[10px] w-full'>
        <div className=''>
            {/* top */}
            <div className='px-7 pt-10'>
                <div className='text-xl lg:text-3xl font-semibold mb-5'>
                    <p>
                        Order 
                        <span className='text-main px-2'>{orderNumber}</span>
                        Confirmed
                    </p>
                </div>
                <div className='flex justify-between items-center font-semibold'>
                    <p className='text-lg lg:text-2xl'>Thank you for your order.</p>
                    <button
                        className='text-main'
                        type='button'
                        onClick={handlePrintReceipt}
                    >
                        Print Receipt
                    </button>
                </div>  
            </div>
            <hr className='h-px my-6 border-1 bg-black' />
            {/* bottom payment info */}
            <div className='px-7'>
                <div className='font-semibold text-2xl'>
                    <p>Payment Information</p>
                </div>
                <hr className='h-px my-4 border-0 bg-black' />
                <div className='flex items-center justify-between'>
                    <p>MTN MoMo Pay</p>
                    <div className='flex items-center gap-1 font-semibold whitespace-nowrap'>
                        <p className='mr-2'>Total :</p> 
                        <NigeriaCurrency />
                        {orderAmount}        
                    </div>
                </div>
                <div className='mt-10 text-base text-main font-semibold w-3/4'>
                    <p>Done, Your online payment has been successfully processed. Thank you for your order.</p>
                </div>
                <div className='font-semibold underline mt-20 mb-36 flex items-center justify-center text-main'>
                    <button 
                        className='text-sm'
                        onClick={handleContinueShopping}
                    >
                        CONTINUE SHOPPING
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OrderConfirmed