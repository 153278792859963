import React from 'react';

const PaginationButton = ({ onClick, children, active }) => {
  const buttonClasses = `rounded-[5px] w-[32px] h-[32px] ${
    active ? 'bg-main text-white' : 'border border-main'
  }`;

  return (
    <button className={buttonClasses} onClick={onClick}>
      {children}
    </button>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const renderPaginationButtons = () => {
    const paginationButtons = [];

    // First page button
    paginationButtons.push(
      <PaginationButton
        key={1}
        onClick={() => onPageChange(1)}
        active={currentPage === 1}
      >
        1
      </PaginationButton>
    );

    // Ellipsis (...) before middle pages
    if (currentPage > 3) {
      paginationButtons.push(<span key="ellipsis1">...</span>);
    }

    // Middle pages
    for (
      let page = Math.max(2, currentPage - 1);
      page <= Math.min(totalPages - 1, currentPage + 1);
      page++
    ) {
      paginationButtons.push(
        <PaginationButton
          key={page}
          onClick={() => onPageChange(page)}
          active={currentPage === page}
        >
          {page}
        </PaginationButton>
      );
    }

    // Ellipsis (...) after middle pages
    if (currentPage < totalPages - 2) {
      paginationButtons.push(<span key="ellipsis2">...</span>);
    }

    if (totalPages !== 1) {
      // Last page button
      paginationButtons.push(
        <PaginationButton
          key={totalPages}
          onClick={() => onPageChange(totalPages)}
          active={currentPage === totalPages}
        >
          {totalPages}
        </PaginationButton>
      );
    }

    return paginationButtons;
  };

    return (
        <div>
            <div className="flex gap-x-[8px]">{renderPaginationButtons()}</div>
        </div>
    );
};

export default Pagination;