import Header from "../../components/Header";
import LandingPageSellerBanner from "./LandingPageSellerBanner";
import LandingPageSellerSubBanner from "./LandingPageSellerSubBanner";
import GettingStartedSeller from "./GettingStartedSeller";
import SubFooter from "../Home/SubFooter";
import FooterContainer from "../Home/FooterContainer";
import EffectiveSeller from "./EffectiveSeller";
import SellerRights from "./SellerRights";
import { useEffect } from "react";

const LandingPageSeller = () => {

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <main>
    <Header />
    <LandingPageSellerBanner />
    <LandingPageSellerSubBanner />
    <SellerRights />
    <EffectiveSeller />
    <GettingStartedSeller />
    <SubFooter />
    <FooterContainer />
  </main>
  );
};

export default LandingPageSeller;
