import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHomeRentLandRequests } from "../../../../store/actions/homeRentSell";
import AboutCard from "../AboutCard";
import RentCardContainer from "../MachineRentContainer/RentCardContainer";
import ArrowButtonContainer from "../ProductCardContainer/ArrowButtonContainer";
import { useRef } from "react";

function LandRentContainer(props) {
  const { cardData } = props;

  const [visibleCards, setVisibleCards] = useState(5);
  const [totalCards, setTotalCards] = useState(null);
  const sliderRef = useRef(null);

  const { rentLandRequests } = useSelector((state) => ({
    rentLandRequests: state?.homeRentSell?.rentLandRequests,
  }));

  const dispatch = useDispatch();

  function getLandRequest (start, length) {
    dispatch(getHomeRentLandRequests(start, length))
  }


  const handleLeftArrowButton = () => {
    sliderRef.current.slickPrev();
  };

  const handleRightArrowButton = () => {
    sliderRef.current.slickNext();
    if(rentLandRequests?.data?.length + 5 <= totalCards ){
      getLandRequest(rentLandRequests.data.length, rentLandRequests.data.length + 5)
    }
  };

  useEffect(()=>{
    if(rentLandRequests?.total) setTotalCards(rentLandRequests?.total)
    if(rentLandRequests?.data) setVisibleCards(rentLandRequests.data)

  },[rentLandRequests]);

  useEffect(() => {
    getLandRequest(0,10);
  }, []);

  return (
    <section className="py-24">
      <AboutCard cardData={cardData} />
      <RentCardContainer visibleCards={visibleCards} sliderRef={sliderRef} type="land" />
      <ArrowButtonContainer handleLeft={handleLeftArrowButton} handleRight={handleRightArrowButton} />
    </section>
  );
}

export default LandRentContainer;
