import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/Layout";
import Table from "../../../components/Tables/Table";
import moment from "moment";
import SearchForm from "../../../components/Forms/SearchForm";
import UpArrow from '../../../assets/uparrow.svg'
import DownArrow from '../../../assets/downarrow.svg'
import authCheck from "../../../shared/authCheck"
import { getTips } from "../../../store/actions/tips";

const Tips = () => {
  const dispatch = useDispatch();

  const [expand, setExpand] = useState({});
  const [updateList, setUpdateList] = useState(true);
  const [firstItemIndex, setFirstItemIndex] = useState(0);
  const [searchValue, setSearchValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { tips } = useSelector(state => ({
    tips: state?.tips?.tips,
  }));

  useEffect(() => {
    if (updateList) {
      if(searchValue){
        dispatch(getTips(firstItemIndex, 5, searchValue));
      }else{
        dispatch(getTips(firstItemIndex, 5));
      }
      setUpdateList(false);
    }
  }, [dispatch, firstItemIndex, updateList, searchValue]);

  //Handle Search
  const handleSearchSubmit = values => {
    dispatch(getTips(0, 5, values.search));
    setFirstItemIndex(0)
    setCurrentPage(1)
  };

  const tableData = {
    header: [],
    body:
      tips &&
      tips?.data?.map((row, index) => (
        <React.Fragment key={index}>
          <td className="py-[20px] px-[20px]">
            <div>
              <p
                className={
                  expand && expand[row.id]
                    ? "font-medium mb-2"
                    : "font-medium mb-2 max-h-[70px] text-ellipsis overflow-hidden"
                }
              >
                {row.content}
              </p>
              <div className="flex justify-between">
                <p className="text-main">
                  {row.categoryName} |{" "}
                  {moment(row.triggerDate, "DD-MM-yyyy hh:mm:ss").format(
                    "DD-MM-yyyy"
                  )}{" "}
                </p>
                <button
                  onClick={() => {
                    setExpand({
                      ...expand,
                      [row.id]: expand.hasOwnProperty(row.id)
                        ? !expand[row.id]
                        : true,
                    });
                  }}
                  className="text-main"
                >
                  {expand.hasOwnProperty(row.id)
                    ? (expand[row.id]
                      ? <img className="w-5" src={DownArrow} alt="" />
                      :<img className="w-5" src={UpArrow} alt="" />
                    )
                    : <img className="w-5" src={DownArrow} alt="" />}
                </button>
              </div>
            </div>
          </td>
        </React.Fragment>
      )),
    className: "gap-x-[40px] items-start px-[40px]",
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <>
      <Layout>
        <main className="py-[110px]">
          <div className="mb-[30px] md:flex items-center justify-between px-[40px]">
            <h1 className="text-[18px] md:text-[24px] font-semibold mb-[20px] md:my-[0px] mr-[20px]">Tips</h1>
            <SearchForm setSearchValue={setSearchValue} onSubmit={values => handleSearchSubmit(values)}  setUpdateList={setUpdateList} />
          </div>
          {tips?.data?.length > 0 ? (
            <Table
              data={tips?.data}
              total={tips?.total}
              setFirstIndex={setFirstItemIndex}
              onUpdateList={value => setUpdateList(value)}
              tableData={tableData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            <h2 className="px-[40px]">No Tips found</h2>
          )}
        </main>
      </Layout>
    </>
  );
};

export default authCheck(Tips);
