import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

const authCheck = (WrappedComponent) => {

    return class extends Component {
        isAuthenticated = () => {
          return localStorage.getItem('m-farm-user-token') !== null;
        };
    
        render() {
          if (this.isAuthenticated()) {
            // If authenticated, render the wrapped component
            return <WrappedComponent {...this.props} />;
          } else {
            // If not authenticated, redirect to the login page
            // dispatch(getProfile())
            return <Redirect to={`${process.env.PUBLIC_URL}/auth`} />;
          }
        }
      };
}

export default authCheck