import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";

import UploadImage from '../UploadFileInput/UploadImage';
import FormikInput from '../FormikInput';
import { getCategories } from '../../store/actions/categories';
import { getSellRequestImage } from '../../store/actions/sellRequests';
import { getBuyRequestImage } from '../../store/actions/buyRequest';
import Spinner from '../../shared/Spinner';

const validationSchema = Yup.object().shape({
    categoryId: Yup.mixed().required('Category is required'),
    description: Yup.string()
                    .required('Description is required')
                    .trim()
                    .min(1,"Description size must be between 1 and 2000")
                    .max(2000, "Description size must be between 1 and 2000"),
    imageBase64: Yup.string()
                    .required('Image is required')
                    .test('fileSize', 'File size is too large, it should be less than 1mb', 
                        (value) => (((value.length * 3) / 4) <= (1024 * 1024))),
    location: Yup.string()
                    .required('Location is required')
                    .trim()
                    .min(1, "Location size must be between 1 and 100")
                    .max(100, "Location size must be between 1 and 100"),
    name: Yup.string()
                    .required('Name is required')
                    .trim()
                    .min(1, "Name size must be between 1 and 100")
                    .max(100, "Name size must be between 1 and 100"),
    price: Yup.number()
                    .required('Price is required')
                    .min(1, "Price is not valid")
                    .max(9999999999, "Price is not valid"),
});

const SellRequestForm = ({ onSubmit, sellRequest, onClose, type, loading }) => {
    const dispatch = useDispatch()

    const { categories } = useSelector((state) => ({
        categories: state?.categories?.categories,
    }));

    const [image, setImage] = useState()

    const fetchImage = async () => {
        let res;
        if(type==='SELLREQUEST'){
            res = await getSellRequestImage(sellRequest?.id)
        }else if(type==='BUYREQUEST'){
            res = await getBuyRequestImage(sellRequest?.id)
        }
        setImage(res)
    }

    useEffect(() => {
        if (sellRequest?.hasImage) {
            fetchImage()
        }
    }, [sellRequest?.hasImage])

    useEffect(() => {
        dispatch(getCategories())
    }, [dispatch])

    const categoryOptions = categories?.data?.map((category) => { return { value: category?.id, label: category?.name } })

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            width: '100%',
            borderRadius: '0.375rem', // Equivalent to 6px (rounded-md)
            background: '#F5F5F5',
            padding: '11px 17px', // Equivalent to 13px 25px (py-[13px] px-[25px])
            border: 'none',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', // Equivalent to shadow-sm
            outline: 'none', // Equivalent to outline-none
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#50A060'
                : state.isFocused
                    ? '#f0f0f0'
                    : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: state.isSelected ? '#50A060' : '#f0f0f0',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            padding: '0px',
            color: '#333',
        }),
        input: (provided) => ({
            ...provided,
            padding: '0px',
            margin: '0px', // Remove the padding from the input
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0', // Remove the padding from the value container
        }),
    };

    return (
        <div>
            <Formik
                initialValues={{
                    categoryId: sellRequest?.categoryId ? sellRequest?.categoryId : '',
                    description: sellRequest?.description ? sellRequest?.description : '',
                    imageBase64: image ? image : '',
                    location: sellRequest?.location ? sellRequest?.location : '',
                    name: sellRequest?.name ? sellRequest?.name : '',
                    price: sellRequest?.price ? sellRequest?.price : '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => onSubmit({ ...values, imageBase64: values?.imageBase64 ? values?.imageBase64 : image })}
            >
                {({ errors, touched, handleBlur, setFieldValue, values }) => (
                    <Form>
                        <div className="mb-4 w-[98%]">
                            <div className="mb-2">
                                <div className="mb-2">
                                    <UploadImage
                                        value={image}
                                        placeholder="Choose Image"
                                        name="imageBase64"
                                        onChange={(imageBase64) => {
                                            setFieldValue('imageBase64', imageBase64);
                                        }}
                                        variant='image'
                                    />
                                </div>
                                <Select
                                    name="categoryId"
                                    options={categoryOptions}
                                    value={categoryOptions?.find(
                                        (option) => option.value === values.categoryId
                                    )}
                                    placeholder="Select a category"
                                    onChange={(selectedOption) => {
                                        setFieldValue('categoryId', selectedOption?.value);
                                    }}
                                    onBlur={handleBlur}
                                    styles={selectStyles}
                                    className={
                                        errors?.categoryId && touched?.categoryId
                                            ? 'border-red-500'
                                            : ''
                                    }
                                />
                                {touched.categoryId && errors.categoryId && (
                                    <div className="mt-1 text-sm text-[#FF0000]">
                                        {errors.categoryId}
                                    </div>
                                )}

                                <div className="my-2">
                                    <FormikInput
                                        placeholder="Name"
                                        name="name"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                </div>
                                <div className="mb-2">
                                    <FormikInput
                                        placeholder="Description"
                                        name="description"
                                        type="textarea"
                                        errors={errors}
                                        touched={touched}
                                    />
                                </div>
                                <div className="mb-2">
                                    <FormikInput
                                        placeholder="Location"
                                        name="location"
                                        type="text"
                                        errors={errors}
                                        touched={touched}
                                    />
                                </div>
                                <div className="mb-2">
                                    <FormikInput
                                        placeholder="Price"
                                        name="price"
                                        type="number"
                                        errors={errors}
                                        touched={touched}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-evenly gap-3">
                            <button
                                disabled ={loading}
                                type="submit"
                                className={`flex gap-1 justify-center items-center rounded-[10px] bg-main  w-44 h-12 font-medium text-white ${loading ? "opacity-60" : "opacity-100"}`}
                            >
                                {loading && <Spinner />}
                                Save
                            </button>
                            <button
                                type="button"
                                className="rounded-[10px]  w-44 h-12 text-[#969696]"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SellRequestForm;
