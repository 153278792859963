import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import Logo from "../../assets/LogoLogin.svg";
import "./Auth.scss";
import LoginForm from "../../components/Forms/LoginForm";
import RegisterForm from "../../components/Forms/RegisterForm";
import OtpForm from "../../components/Forms/OtpForm/OtpForm";


const AuthToggle = ({ onToggle, authState }) => {
  
  const [active, setActive] = useState(authState);
  const handleToggle = (type) => {
    setActive(type);
    onToggle(type);
  };

  return (
    <div className="flex bg-[#F5F5F5] rounded-lg mb-[41px]">
      <div
        className={`flex-1 text-center px-2 py-[16px] cursor-pointer ${
          active === "login"
            ? "bg-main text-white rounded-lg"
            : "bg-transparent text-[#898989]"
        }`}
        onClick={() => handleToggle("login")}
      >
        Login
      </div>
      <div
        className={`flex-1 text-center px-2 py-[16px] cursor-pointer ${
          active === "register"
            ? "bg-main text-white rounded-lg"
            : "bg-transparent text-[#898989]"
        }`}
        onClick={() => handleToggle("register")}
      >
        Register
      </div>
    </div>
  );
};

const Auth = () => {
  const location = useLocation();
  const authState = location.state || "login";

  const [authType, setAuthType] = useState(authState);

  const [logedMsisdn, setLoggedMsisdn] = useState("");

  const { profile } = useSelector((state) => ({
    profile: state?.profile?.profile,
  }));

  const handleToggleAuthType = (type) => {
    setAuthType(type);
  };

  const renderForm = {
    login: <LoginForm setLoggedMsisdn={setLoggedMsisdn} />,
    register: <RegisterForm setLoggedMsisdn={setLoggedMsisdn} />,
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <div className="min-h-screen bg-cover bg-center flex flex-col items-center justify-center auth-bg-image">
      <Link to={process.env.PUBLIC_URL}>
        <img className="mx-auto mb-[40px]" src={Logo} alt="Logo" />
      </Link>
      <div className="w-[90%] max-w-md py-[50px] sm:px-[45px] px-[10px] bg-white p-8 rounded-[10px] shadow">
        {!logedMsisdn && <AuthToggle onToggle={handleToggleAuthType}  authState={authState}/>}
        {!logedMsisdn ? (
          renderForm[authType]
        ) : (
          <OtpForm
            type={authType}
            msisdn={logedMsisdn}
            onBackSignIn={() => {
              setLoggedMsisdn("");
              setAuthType("login");
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Auth;
