import Layout from "../../components/Layout";
import authCheck from "../../shared/authCheck"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getDashboardData } from "../../store/actions/dashboard";
import NavSellRequestIcon from "../../assets/navigation/nav-sell-request";
import NavBuyRequestIcon from "../../assets/navigation/nav-buy-request";
import NavRentRequestIcon from "../../assets/navigation/nav-rent-request";
import { getTips } from "../../store/actions/tips";
import WeatherWidget from "../../components/WeatherWidget";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Modal from "../../components/Modal/Modal";

const DashboardStatsCard = ({ icon, label, stats, color, containerClassname }) => {
    return (
        <div className={`bg-white shadow-table p-[20px] md:p-[40px] rounded-[10px] ${containerClassname}`}>
            <div className="grid grid-cols-[40px_auto] gap-x-[16px] items-center ">
                {icon}
                <h2 className={`${color} text-[18px] 3xl:text-[24px]`}>{label}</h2>
            </div>
            <p className="mt-[34px] text-[32px] md:text-[64px] font-semibold">{stats}</p>
        </div>
    )
}

const Dashboard = () => {
    const dispatch = useDispatch();

    const [isSellerSubscriptionEnable, setIsSellerSubscriptionEnable] = useState(null);
  

    const { dashboardData, tips, subscription, profile } = useSelector((state) => ({
        dashboardData: state.dashboard.dashboardData,
        tips: state.tips.tips,
        subscription: state?.subscription,
        profile: state?.profile?.profile,
    }));

    useEffect(() => {
        dispatch(getDashboardData());
        dispatch(getTips(0, 5))
    }, [dispatch]);

    useEffect(() => {
        if(subscription?.seller?.sellerSubscriptionEnable !== null  && subscription?.seller?.sellerSubscriptionEnable === false && subscription?.seller?.sellerSubscriptionEnableCount === 1 && profile?.type === "SELLER" ){
          setIsSellerSubscriptionEnable(false);
        }
      },[subscription, profile])

      useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[]);

    return <Layout>
        <Modal
        isOpen={isSellerSubscriptionEnable === false}
        onClose={() => {
          setIsSellerSubscriptionEnable(true);
        }}
        title="🎉 Free Subscription Alert 🎉"
      >
        <div className="p-5">
          <p>Subscription services for Seller are now available for FREE!</p>
        </div>
      </Modal>
        <main className="py-[110px]">
            <div className="flex justify-between items-center px-[40px] pb-[30px]">
                <h1 className="text-[18px] md:text-[24px] font-semibold">Dashboard</h1>
            </div>
            <div className="px-[40px] grid lg:grid-cols-3 gap-x-[60px] gap-y-[50px]">
                <DashboardStatsCard icon={<NavSellRequestIcon color="#0073DD" className="w-[40px] h-[40px]" />} label="Sell Requests" color="text-[#0073DD]" stats={dashboardData?.sellRequestCount || 0} />
                <DashboardStatsCard icon={<NavBuyRequestIcon color="#A0507B" className="w-[40px] h-[40px]" />} label="Buy Requests" color="text-[#A0507B]" stats={dashboardData?.buyRequestCount || 0} />
                <DashboardStatsCard icon={<NavRentRequestIcon color="#399C96" className="w-[40px] h-[40px]" />} label="Rent Request" color="text-[#399C96]" stats={dashboardData?.rentRequestCount || 0} />

                <div className="bg-white shadow-table p-[20px] md:p-[40px] rounded-[10px] lg:col-span-3 3xl:col-span-2 h-[491px]">
                    <h2 className="text-[18px] md:text-[24px] font-semibold mb-[14px]">Tips</h2>
                    <div className="grid h-ful-50px">
                        <div className="overflow-y-auto">
                            {tips?.data?.map(tip => {
                                return (
                                    <div className="p-[25px] rounded-[10px] bg-[#F6F6F6] mb-[20px]">
                                        <p className="line-clamp-3 text-ellipsis max-h-[72px] w-full overflow-hidden font-semibold mb-[10px]">{tip?.content}</p>
                                        <p className="text-main">{tip?.categoryName} &nbsp; | &nbsp; {tip?.triggerDate}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="bg-white shadow-table p-[20px] md:p-[40px] rounded-[10px] lg:col-span-3 3xl:col-span-1">
                    <h2 className="text-[18px] md:text-[24px] font-semibold mb-[14px]">Today’s Weather Updates</h2>
                    <Link to={process.env.PUBLIC_URL + "/" + "weather"} ><WeatherWidget isDashboard={true} /></Link>
                </div>
            </div>
        </main>
    </Layout>
}

export default authCheck(Dashboard)