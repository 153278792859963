import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";

const initialState = {
  error: null,
  loading: false,
  rentLandRequests: null,
  createRes: null,
  editRes: null,
  deleteRes: null,
  pricePeriods: null,
};

// get price periods requests
const getPricePeriodsStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getPricePeriodsSuccess = (state, action) => {
  return updateObject(state, {
    pricePeriods: action.pricePeriods,
    error: null,
    loading: false,
  });
};

const getPricePeriodsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

// get rentLand requests
const getRentLandRequestsStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getRentLandRequestsSuccess = (state, action) => {
  return updateObject(state, {
    rentLandRequests: action.rentLandRequests,
    error: null,
    loading: false,
  });
};

const getRentLandRequestsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    rentLandRequests: null,
  });
};

// create rentLand request
const createRentLandRequestStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const createRentLandRequestSuccess = (state, action) => {
  return updateObject(state, {
    createRes: action.createRes,
    error: null,
    loading: false,
  });
};

const createRentLandRequestFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    createRes: null,
  });
};

// edit rentLand request
const editRentLandRequestStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const editRentLandRequestSuccess = (state, action) => {
  return updateObject(state, {
    editRes: action.editRes,
    error: null,
    loading: false,
  });
};

const editRentLandRequestFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    editRes: null,
  });
};

// delete rentLand request
const deleteRentLandRequestStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const deleteRentLandRequestSuccess = (state, action) => {
  return updateObject(state, {
    deleteRes: action.deleteRes,
    error: null,
    loading: false,
  });
};

const deleteRentLandRequestFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    deleteRes: null,
  });
};

// add rentLand request image
const createRentLandRequestImageStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const createRentLandRequestImageSuccess = (state, action) => {
  return updateObject(state, {
    addImageRes: action.addImageRes,
    error: null,
    loading: false,
  });
};

const createRentLandRequestImageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    addImageRes: null,
  });
};

// delete rentLand request image
const deleteRentLandRequestImageStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const deleteRentLandRequestImageSuccess = (state, action) => {
  return updateObject(state, {
    deleteImageRes: action.deleteImageRes,
    error: null,
    loading: false,
  });
};

const deleteRentLandRequestImageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    deleteImageRes: null,
  });
};

const rentLandRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    // get rentLand requests actions
    case actionTypes.GET_RENT_LAND_REQUESTS_START:
      return getRentLandRequestsStart(state, action);
    case actionTypes.GET_RENT_LAND_REQUESTS_SUCCESS:
      return getRentLandRequestsSuccess(state, action);
    case actionTypes.GET_RENT_LAND_REQUESTS_FAIL:
      return getRentLandRequestsFail(state, action);
    // create rentLand request actions
    case actionTypes.CREATE_RENT_LAND_REQUEST_START:
      return createRentLandRequestStart(state, action);
    case actionTypes.CREATE_RENT_LAND_REQUEST_SUCCESS:
      return createRentLandRequestSuccess(state, action);
    case actionTypes.CREATE_RENT_LAND_REQUEST_FAIL:
      return createRentLandRequestFail(state, action);
    // edit rentLand request actions
    case actionTypes.EDIT_RENT_LAND_REQUEST_START:
      return editRentLandRequestStart(state, action);
    case actionTypes.EDIT_RENT_LAND_REQUEST_SUCCESS:
      return editRentLandRequestSuccess(state, action);
    case actionTypes.EDIT_RENT_LAND_REQUEST_FAIL:
      return editRentLandRequestFail(state, action);
    // delete rentLand request actions
    case actionTypes.DELETE_RENT_LAND_REQUEST_START:
      return deleteRentLandRequestStart(state, action);
    case actionTypes.DELETE_RENT_LAND_REQUEST_SUCCESS:
      return deleteRentLandRequestSuccess(state, action);
    case actionTypes.DELETE_RENT_LAND_REQUEST_FAIL:
      return deleteRentLandRequestFail(state, action);
    case actionTypes.GET_PRICE_PERIODS_START:
      return getPricePeriodsStart(state, action);
    case actionTypes.GET_PRICE_PERIODS_SUCCESS:
      return getPricePeriodsSuccess(state, action);
    case actionTypes.GET_PRICE_PERIODS_FAIL:
      return getPricePeriodsFail(state, action);
    // create rentLand Image
    case actionTypes.CREATE_RENT_LAND_REQUEST_IMAGE_START:
      return createRentLandRequestImageStart(state, action);
    case actionTypes.CREATE_RENT_LAND_REQUEST_IMAGE_SUCCESS:
      return createRentLandRequestImageSuccess(state, action);
    case actionTypes.CREATE_RENT_LAND_REQUEST_IMAGE_FAIL:
      return createRentLandRequestImageFail(state, action);
    // DELETE rentLand Image
    case actionTypes.DELETE_RENT_LAND_REQUEST_IMAGE_START:
      return deleteRentLandRequestImageStart(state, action);
    case actionTypes.DELETE_RENT_LAND_REQUEST_IMAGE_SUCCESS:
      return deleteRentLandRequestImageSuccess(state, action);
    case actionTypes.DELETE_RENT_LAND_REQUEST_IMAGE_FAIL:
      return deleteRentLandRequestImageFail(state, action);
    default:
      return state;
  }
};

export default rentLandRequestReducer;
