import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";

const initialState = {
  token: null,
  signInRes: null,
  signUpRes: null,
  resendOtpRes: null,
  confirmOtpRes: null,
  error: null,
  loading: false,
};

// Logout
const signInLogout = (state, action) => {
  return updateObject(state, { token: null });
};

//Login
const signInStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const signInSuccess = (state, action) => {
  return updateObject(state, {
    signInRes: action.signInRes,
    error: null,
    loading: false,
  });
};

const signInFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

// Register
const signUpStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const signUpSuccess = (state, action) => {
  return updateObject(state, {
    signUpRes: action.signUpRes,
    error: null,
    loading: false,
  });
};

const signUpFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

// Resend OTP
const resendOtpStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const resendOtpSuccess = (state, action) => {
  return updateObject(state, {
    resendOtpRes: action.resendOtpRes,
    error: null,
    loading: false,
  });
};

const resendOtpFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

// Confirm OTP
const confirmOtpStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const confirmOtpSuccess = (state, action) => {
  return updateObject(state, {
    confirmOtpRes: action.confirmOtpRes,
    error: null,
    loading: false,
  });
};

const confirmOtpFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_LOGOUT:
      return signInLogout(state, action);
    case actionTypes.AUTH_LOGIN_START:
      return signInStart(state, action);
    case actionTypes.AUTH_LOGIN_SUCCESS:
      return signInSuccess(state, action);
    case actionTypes.AUTH_LOGIN_FAIL:
      return signInFail(state, action);
    case actionTypes.AUTH_REGISTER_START:
      return signUpStart(state, action);
    case actionTypes.AUTH_REGISTER_SUCCESS:
      return signUpSuccess(state, action);
    case actionTypes.AUTH_REGISTER_FAIL:
      return signUpFail(state, action);
    case actionTypes.AUTH_RESEND_OTP_START:
      return resendOtpStart(state, action);
    case actionTypes.AUTH_RESEND_OTP_SUCCESS:
      return resendOtpSuccess(state, action);
    case actionTypes.AUTH_RESEND_OTP_FAIL:
      return resendOtpFail(state, action);
    case actionTypes.AUTH_REGISTER_CONFIRM_START:
      return confirmOtpStart(state, action);
    case actionTypes.AUTH_REGISTER_CONFIRM_SUCCESS:
      return confirmOtpSuccess(state, action);
    case actionTypes.AUTH_REGISTER_CONFIRM_FAIL:
      return confirmOtpFail(state, action);
    default:
      return state;
  }
};

export default reducer;
