
import LogoIcon from "../assets/green-logo.svg"
import EmailIcon from "../assets/contact-us/email.svg"
import FacebookIcon from "../assets/contact-us/facebook.svg"
import TwitterIcon from "../assets/contact-us/twitter-x.svg"
import InstargamIcon from "../assets/contact-us/instagram.svg"
import WhatsappIcon from "../assets/contact-us/whatsapp.svg"
import PhoneIcon from "../assets/contact-us/phone.svg"
import { Link } from "react-router-dom/cjs/react-router-dom"

const ContactUs = () => {
    const whatsAppNumber = '+2348137372786';
    return (

        <section className="bg-[#212121] text-sm md:text-xl text-[#9E9E9E]">
            <div className="container container-footer px-0 py-[64px] mx-[auto] grid md:grid-cols-[1fr_1fr_1fr] justify-center md:justify-between items-start gap-[70px] md:gap-[236px]">
                <div className="grid gap-8 justify-items-center xl:justify-items-start">
                    <div className="text-white flex gap-[10px] items-center">
                        <img src={LogoIcon} alt="logo" className="w-[27.297px] h-[27.297px]" />
                        <p className="font-circular-500 text-[27.5px]">mFarm</p>
                    </div>
                    <address className="font-circular-450 text-base text-center xl:text-left"> No. 26, 3rd Avenue, Gwarinpa, Abuja</address>
                    <div className="flex gap-x-[24px] justify-start items-center">
                    <Link
                            to={`${process.env.PUBLIC_URL}/`}
                        >
                            <img src={TwitterIcon} alt="" className="w-6 h-6" />
                        </Link>
                        <Link
                            to={`${process.env.PUBLIC_URL}/`}
                        >
                            <img src={InstargamIcon} alt="" className="w-6 h-6" />
                        </Link>
                        <Link
                            to={`${process.env.PUBLIC_URL}/`}
                        >
                            <img src={FacebookIcon} alt="" className="w-6 h-6" />
                        </Link>
                    </div>
                </div>
                <div className="grid justify-items-center gap-[22px] xl:justify-items-stretch">
                    <p className="font-circular-500 text-base">Contact us</p>
                    <div className="flex items-center gap-3">
                        <img src={EmailIcon} alt="" className="w-6 h-6" />
                        <p className="font-circular-450 text-base">innoviimfarm@gmail.com</p>
                    </div>
                    <div className="flex items-center gap-3">
                        <img src={PhoneIcon} alt="" className="w-6 h-6" />
                        <p className="font-circular-450 text-base">{`${whatsAppNumber.substring(0,4)} ${whatsAppNumber.substring(4)}`}</p>
                    </div>
                </div>
                <div className="grid gap-[22px] justify-center xl:justify-end">
                    <p className="font-circular-500 text-base text-center sm:text-left">Chat us on WhatsApp</p>
                    <a
                        href={`https://wa.me/${whatsAppNumber}`}
                        target="_blank"
                        className="flex items-center justify-center gap-3 px-[14px] py-[10px] rounded-[10px] bg-white" rel="noreferrer">
                        <img src={WhatsappIcon} alt="" className="w-6 h-6" />
                        <p className="font-circular-450 text-base text-[#9E9E9E]">Chat on WhatsApp</p>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default ContactUs