import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";

const initialState = {
    profile: null,
    updatePasswordRes: null,
    updateProfileRes: null,
    profileImage: null,
    updateProfileImageRes: null,
    error: null,
    loading: false,
};

// updatePassword
const updatePasswordStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const updatePasswordSuccess = (state, action) => {
    return updateObject(state, {
        updatePasswordRes: action.updatePasswordRes,
        error: null,
        loading: false,
    });
};

const updatePasswordFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

// getProfile
const getProfileStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getProfileSuccess = (state, action) => {
    return updateObject(state, {
        profile: action.profile,
        error: null,
        loading: false,
    });
};

const getProfileFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        profile: null
    });
};

// updateProfile
const updateProfileStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const updateProfileSuccess = (state, action) => {
    return updateObject(state, {
        updateProfileRes: action.updateProfileRes,
        error: null,
        loading: false,
    });
};

const updateProfileFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

// getProfileImage
const getProfileImageStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getProfileImageSuccess = (state, action) => {
    return updateObject(state, {
        profileImage: action.profileImage,
        error: null,
        loading: false,
    });
};

const getProfileImageFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

// updateProfileImage
const updateProfileImageStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const updateProfileImageSuccess = (state, action) => {
    return updateObject(state, {
        updateProfileImageRes: action.updateProfileImageRes,
        error: null,
        loading: false,
    });
};

const updateProfileImageFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PROFILE_UPDATE_PASSWORD_START:
            return updatePasswordStart(state, action);
        case actionTypes.PROFILE_UPDATE_PASSWORD_SUCCESS:
            return updatePasswordSuccess(state, action);
        case actionTypes.PROFILE_UPDATE_PASSWORD_FAIL:
            return updatePasswordFail(state, action);
        case actionTypes.PROFILE_GET_USER_PROFILE_START:
            return getProfileStart(state, action);
        case actionTypes.PROFILE_GET_USER_PROFILE_SUCCESS:
            return getProfileSuccess(state, action);
        case actionTypes.PROFILE_GET_USER_PROFILE_FAIL:
            return getProfileFail(state, action);
        case actionTypes.PROFILE_UPDATE_USER_PROFILE_START:
            return updateProfileStart(state, action);
        case actionTypes.PROFILE_UPDATE_USER_PROFILE_SUCCESS:
            return updateProfileSuccess(state, action);
        case actionTypes.PROFILE_UPDATE_USER_PROFILE_FAIL:
            return updateProfileFail(state, action);
        case actionTypes.PROFILE_GET_PROFILE_IMAGE_START:
            return getProfileImageStart(state, action);
        case actionTypes.PROFILE_GET_PROFILE_IMAGE_SUCCESS:
            return getProfileImageSuccess(state, action);
        case actionTypes.PROFILE_GET_PROFILE_IMAGE_FAIL:
            return getProfileImageFail(state, action);
        case actionTypes.PROFILE_UPDATE_PROFILE_IMAGE_START:
            return updateProfileImageStart(state, action);
        case actionTypes.PROFILE_UPDATE_PROFILE_IMAGE_SUCCESS:
            return updateProfileImageSuccess(state, action);
        case actionTypes.PROFILE_UPDATE_PROFILE_IMAGE_FAIL:
            return updateProfileImageFail(state, action);
        default:
            return state;
    }
};

export default profileReducer;
