import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";

const initialState = {
    error: null,
    loading: false,
    tips: null,
};

//getTips
const getTipsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getTipsSuccess = (state, action) => {
    return updateObject(state, {
        tips: action.tips,
        error: null,
        loading: false,
    });
};

const getTipsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        tips: null,
    });
};

const tipReducer = (state = initialState, action) => {
    switch (action.type) {
        // get tips actions
        case actionTypes.GET_TIPS_START:
            return getTipsStart(state, action);
        case actionTypes.GET_TIPS_SUCCESS:
            return getTipsSuccess(state, action);
        case actionTypes.GET_TIPS_FAIL:
            return getTipsFail(state, action);
        default:
            return state;
    }
};

export default tipReducer;
