import { Link } from 'react-router-dom';
import NavDashboardIcon from '../assets/navigation/nav-dashboard';
import NavTipIcon from '../assets/navigation/nav-tips';
import NavSellRequestIcon from "../assets/navigation/nav-sell-request";
import NavBuyRequestIcon from "../assets/navigation/nav-buy-request"
import NavRentRequestIcon from "../assets/navigation/nav-rent-request";
import NavDiagnosisRequestIcon from "../assets/navigation/nav-diagnosis-request";
import NavOrderHistoryIcon from "../assets/navigation/nav-order-history"
import { useState } from 'react';
import SideBarMenu from '../assets/SideBarMenu';

const links = [
    {
        icon: <SideBarMenu color="#50A060" />,
        iconActive: <SideBarMenu color="#ffffff" />,
        label: "Menu",
    },
    {
        icon: <NavDashboardIcon color="#50A060" />,
        iconActive: <NavDashboardIcon color="#ffffff" />,
        path: "/dashboard",
        label: "Dashboard",
    },
    {
        icon: <NavSellRequestIcon color="#50A060" />,
        iconActive: <NavSellRequestIcon color="#ffffff" />,
        path: "/sell-request",
        label: "Sell Request",
    },
    {
        icon: <NavBuyRequestIcon color="#50A060" />,
        iconActive: <NavBuyRequestIcon color="#ffffff" />,
        path: "/buy-request",
        label: "Buy Request",
    },
    {
        icon: <NavRentRequestIcon color="#50A060" />,
        iconActive: <NavRentRequestIcon color="#ffffff" />,
        path: "/rent-request-land",
        label: "Rent Request Land",
    },
    {
        icon: <NavRentRequestIcon color="#50A060" />,
        iconActive: <NavRentRequestIcon color="#ffffff" />,
        path: "/rent-request-lorries",
        label: "Rent Request Lorries",
    },
    {
        icon: <NavTipIcon color="#50A060" />,
        iconActive: <NavTipIcon color="#ffffff" />,
        path: "/tips",
        label: "Tips",
    },
    {
        icon: <NavDiagnosisRequestIcon color="#50A060" />,
        iconActive: <NavDiagnosisRequestIcon color="#ffffff" />,
        path: "/diagnosis-request",
        label: "Diagnosis Request",
    },
    {
        icon: <NavOrderHistoryIcon color="#50A060" />,
        iconActive: <NavOrderHistoryIcon color="#ffffff" />,
        path: "/order-history-land",
        label: "Order History Land",
    },
    {
        icon: <NavOrderHistoryIcon color="#50A060" />,
        iconActive: <NavOrderHistoryIcon color="#ffffff" />,
        path: "/order-history-lorry",
        label: "Order History Lorry",
    },
    {
        icon: <NavOrderHistoryIcon color="#50A060" />,
        iconActive: <NavOrderHistoryIcon color="#ffffff" />,
        path: "/order-history-sell",
        label: "Order History Sell",
    }
]

const HideSideBar = ({setHideSideBar}) => {
    return (
        <div className="flex flex-col items-start bg-white w-fit pt-[20px] min-h-layout drop-shadow-sidebar">
            {links?.map((link, idx) => {
                const isActive = window.location.pathname === process.env.PUBLIC_URL + link.path;
                    return ( link.label === "Menu" ? ( 
                        <button 
                            key={idx}
                            type='button'
                            className={`flex items-center gap-x-[12px] py-[20px] px-[40px] w-full ${isActive ? "bg-main text-white" : ""}`}
                            onClick={()=>setHideSideBar(true)}
                        >
                            {isActive ? link.iconActive : link.icon}
                        </button>
                    ) : (
                        <Link key={idx} to={link.label === "Menu" ? null : `${process.env.PUBLIC_URL}${link.path}`} className={`flex items-center gap-x-[12px] py-[20px] px-[40px] w-full ${isActive ? "bg-main text-white" : ""}`}>
                            {isActive ? link.iconActive : link.icon}
                        </Link>
                    )
                );
            })}
        </div>
    )
}

const SidebarLink = ({setHideSideBar}) => {
    return (
        <div className="flex flex-col items-start bg-white w-[280px] pt-[20px] min-h-layout drop-shadow-sidebar">
            {links?.map((link, idx) => {
                const isActive = window.location.pathname === process.env.PUBLIC_URL + link.path;
                return ( link.label === "Menu" ? (
                    <button
                        key={idx}
                        type='button'
                        className= {`flex items-center gap-x-[12px] py-[20px] px-[40px] ${isActive ? "bg-main text-white" : ""}`}
                        onClick={()=>setHideSideBar(false)}
                    >
                        {isActive ? link.iconActive : link.icon}
                        {link.label}
                    </button>
                ) : ( 
                    <Link 
                        key={idx} 
                        to={`${process.env.PUBLIC_URL}${link.path}`} 
                        className={`flex items-center gap-x-[12px] py-[20px] px-[40px] w-full ${isActive ? "bg-main text-white" : ""}`}
                    >
                        {isActive ? link.iconActive : link.icon}
                        {link.label}
                    </Link>
                    )
                );
            })}
        </div>
    )
}

const Sidebar = () => {
    const [hideSidebar , setHideSideBar] = useState(true);
    
    return ( !hideSidebar ? <HideSideBar setHideSideBar={setHideSideBar}/> : <SidebarLink setHideSideBar={setHideSideBar}/> );
};

export default Sidebar;