import axios from "../../axios-api";
import * as actionTypes from "./types";
import { getErrors, getResData } from "../../shared/utility";
import { getProfile } from "./profile";

export const getNotificationsStart = () => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_START,
    };
};

export const getNotificationsSuccess = (notifications) => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
        notifications,
    };
};

export const getNotificationsFailed = (error) => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_FAIL,
        error,
    };
};

export const getNotifications = (start, length) => {
    return (dispatch) => {
        dispatch(getNotificationsStart());

        let url = "/user/notification";

        const params = {};

        if (start !== undefined) {
            params.start = start;
        }

        if (length !== undefined) {
            params.length = length;
        }

        if (Object.keys(params).length > 0) {
            url += "?" + new URLSearchParams(params).toString();
        }

        return axios
            .get(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));

                return dispatch(getNotificationsSuccess(data));
            })
            .catch((err) =>
                dispatch(getNotificationsFailed(getErrors(err?.response?.data || err)))
            );
    };
};

export const markAllAsReadStart = () => {
    return {
        type: actionTypes.MARK_ALL_AS_READ_START,
    };
};

export const markAllAsReadSuccess = (markAllAsReadRes) => {
    return {
        type: actionTypes.MARK_ALL_AS_READ_SUCCESS,
        markAllAsReadRes,
    };
};

export const markAllAsReadFailed = (error) => {
    return {
        type: actionTypes.MARK_ALL_AS_READ_FAIL,
        error,
    };
};

export const markAllAsRead = () => {
    return (dispatch) => {
        dispatch(markAllAsReadStart());

        let url = "/user/notification/mark/read";
        return axios
            .put(url)
            .then((response) => {
                if (!response) throw new Error("No Response");

                const data = getResData(response.data);

                if (!data.success) throw new Error(getErrors(data));
                dispatch(getProfile())
                return dispatch(markAllAsReadSuccess(data));
            })
            .catch((err) =>
                dispatch(markAllAsReadFailed(getErrors(err?.response?.data || err)))
            );
    };
};