import axios from 'axios';
import { logout } from "./store/actions/auth";
import store from './storage';

// Function to refresh access token using refreshToken
const refreshAccessToken = (refreshToken) => {
  return axios.post(`${window.globalConfig.serverUrl || process.env.REACT_APP_SERVER_URL}/token/refresh/user`, {refreshToken: refreshToken });
};

export const setInstanceAuth = (instance) => {
  instance.interceptors.request.use(function (config) {
    let token = localStorage.getItem('m-farm-user-token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
}

const instance = axios.create({
  baseURL: window.globalConfig.serverUrl || process.env.REACT_APP_SERVER_URL
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (accessToken) => {
  refreshSubscribers.forEach((callback) => callback(accessToken));
  refreshSubscribers = [];
};

instance.interceptors.response.use(
  async (response) => {
    if (response.status === 200 && response.data.errorCode === 108) {
      const refreshToken = localStorage.getItem('m-farm-user-refresh-token');

      if (!isRefreshing && refreshToken) {
        isRefreshing = true;
        try {
          const refreshResponse = await refreshAccessToken(refreshToken);

          const newAccessToken = refreshResponse?.data?.data?.accessToken;
          const newRefreshToken = refreshResponse?.data?.data?.refreshToken;

          if (newAccessToken) {
            localStorage.setItem('m-farm-user-token', newAccessToken);
            onRefreshed(newAccessToken);
          } 

          if(newRefreshToken) localStorage.setItem('m-farm-user-refresh-token', newRefreshToken);

          isRefreshing = false;
          return instance(response.config); // Retry the original request with the new access token
        } catch (refreshError) {
          isRefreshing = false;
          store.dispatch(logout());
          return Promise.reject(refreshError);
        }
      } else {
        return new Promise((resolve) => {
          refreshSubscribers.push((accessToken) => {
            response.config.headers.Authorization = 'Bearer ' + accessToken;
            resolve(instance(response.config)); // Retry the original request with the new access token
          });
        });
      }
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

setInstanceAuth(instance);

export default instance;
