import React from "react";
import banner from "../../assets/landing screen/Buyer landing page banner.svg";

const LandingPageBuyerBanner = () => {
  return (
    <main className="bg-[#FAF8F4] relative left-0 top-0 w-[99.5vw] px-1 md:px-0">
      <div className="flex flex-col items-center justify-center pt-[150px]">
        <div className="text-base text-[#675732] bg-[#D7CAAC] rounded-3xl inline-block py-2 px-4">
          <p className="font-circular-450">Agriculture. Fish Farming. Animal Farming.</p>
        </div>
        <div className="text-xl md:text-7xl text-center text-[#212121] m-6">
          <p className="font-circular-500">Freshness For MBuyers</p>
        </div>
        <div className="text-[#6B7280] text-xl font-normal text-center max-w-[42rem]">
          <p className="font-circular-450">
            mFarm offers our customers all agricultural produce fresh from the
            farm with clearly marked prices.
          </p>
        </div>
        <div className="w-[100%] px-[112px] mt-16 flex items-center justify-center">
          <img src={banner} alt="banner" className="min-w-[220%] sm:min-w-full" />
        </div>
      </div>
    </main>
  );
};

export default LandingPageBuyerBanner;
