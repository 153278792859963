import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import FormikInput from "../FormikInput";
import {
  getPricePeriods,
  getRentRequestImage,
} from "../../store/actions/rentLandRequest";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from "../ImageUploader";
import ImageUploaderEdit from "../ImageUploaderEdit";
import Spinner from '../../shared/Spinner'

const validationLandSchema = Yup.object().shape({
  plantation: Yup.string().required("Plantation is required").trim().max(400),
  description: Yup.string().required("Description is required").trim().max(400),
  location: Yup.string().required("Location is required").trim().min(1, "Location size must be between 1 and 100").max(100, "Location size must be between 1 and 100"),
  name: Yup.string().required("Name is required").trim().min(1, "Name size must be between 1 and 255").max(255, "Name size must be between 1 and 255"),
  price: Yup.number().required("Price is required").min(1, "Price is not valid").max(9999999999, "Price is not valid"),
  measurement: Yup.string().required("Measurement is required"),
  pricePeriod: Yup.string().required("Price Period is required"),
  imageBase64: Yup.array()
    .min(3, "At least 3 images are required")
    .max(10, "Maximum of 10 images allowed")
    .required("Images are required")
    .test('fileSize', 'One of the File size is too large, it should be less than 1mb', (imageArray) =>  imageArray.reduce((acc, image) => ((image.base64 ?  ((( image.base64.length * 3) / 4) <= (1024 * 1024 )) : (((image.length * 3) / 4) <= (1024 * 1024 )) ? acc : false)), true)),
});

const RentRequestLandForm = ({ onSubmit, rentRequest, onClose, loading }) => {
  const dispatch = useDispatch();
  let setFieldV;

  const { pricePeriods } = useSelector((state) => ({
    pricePeriods: state?.rentLandRequests?.pricePeriods,
  }));

  const [images, setImages] = useState("");

  const fetchImages = async () => {
    const imageIdList = rentRequest?.imageIdList || []; // Ensure imageIdList is an array

    const imagePromises = imageIdList.map(async (imageId) => {
      const res = await getRentRequestImage(imageId);
      return { id: imageId, base64: res };
    });

    const imageArray = await Promise.all(imagePromises);
    setImages(imageArray);
  };

  useEffect(() => {
    if (setFieldV) {
      setFieldV("imageBase64", images);
    }
  }, [images, setFieldV]);

  useEffect(() => {
    if (rentRequest?.imageIdList) {
      fetchImages();
    }
  }, [rentRequest?.imageIdList]);

  useEffect(() => {
    dispatch(getPricePeriods());
  }, [dispatch]);

  const pricePeriodsOptions = pricePeriods?.data?.map((pricePeriod) => {
    return { value: pricePeriod, label: pricePeriod };
  });

  const selectPriceStyles = {
    control: (provided, state) => ({
      ...provided,
      display: "flex",
      width: "100%",
      borderRadius: "0.375rem", // Equivalent to 6px (rounded-md)
      background: "#50A060",
      padding: "8px 17px", // Equivalent to 13px 25px (py-[13px] px-[25px])
      border: "none",
      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)", // Equivalent to shadow-sm
      outline: "none", // Equivalent to outline-none,
      color: "#fff",
      minWidth: "180px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#50A060"
        : state.isFocused
        ? "#f0f0f0"
        : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: state.isSelected ? "#50A060" : "#f0f0f0",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: "0px",
      color: "#fff",
    }),
    input: (provided) => ({
      ...provided,
      padding: "0px",
      margin: "0px", // Remove the padding from the input
      color: "#fff",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0", // Remove the padding from the value container
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#fff",
      "&:hover": {
        color: "#fff",
      },
    }),
  };

  return (
    <Formik
      initialValues={{
        description: rentRequest?.description ? rentRequest?.description : "",
        location: rentRequest?.location ? rentRequest?.location : "",
        name: rentRequest?.name ? rentRequest?.name : "",
        price: rentRequest?.price ? rentRequest?.price : "",
        plantation: rentRequest?.plantation ? rentRequest?.plantation : "",
        measurement: rentRequest?.measurement ? rentRequest?.measurement : "",
        pricePeriod: rentRequest?.pricePeriod ? rentRequest?.pricePeriod : "",
        imageBase64: "",
      }}
      validationSchema={validationLandSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, handleBlur, setFieldValue, values }) => {
        setFieldV = setFieldValue;
        return (
          <Form className="w-[98%]">
            <div className="mb-4 grid gap-y-[20px] w-[100%]">
              <div>
                {images ? (
                  <ImageUploaderEdit
                    images={values?.imageBase64}
                    setImages={(values) => {
                      setFieldValue("imageBase64", values);
                    }}
                  />
                ) : (
                  <ImageUploader
                    images={values?.imageBase64}
                    setImages={(values) => {
                      setFieldValue("imageBase64", values);
                    }}
                  />
                )}
                {touched.imageBase64 && errors.imageBase64 && (
                  <div className="mt-1 text-sm text-[#FF0000]">
                    {errors.imageBase64}
                  </div>
                )}
              </div>
              <FormikInput
                placeholder="Name"
                name="name"
                type="text"
                errors={errors}
                touched={touched}
              />
              <FormikInput
                placeholder="Land Measurement"
                name="measurement"
                type="text"
                errors={errors}
                touched={touched}
              />
              <FormikInput
                placeholder="Land Location"
                name="location"
                type="text"
                errors={errors}
                touched={touched}
              />
              <div className="flex justify-evenly sm:flex-row flex-col gap-3">
                <div>
                  <FormikInput
                    placeholder="Price"
                    name="price"
                    type="number"
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div>
                  <Select
                    name="pricePeriod"
                    options={pricePeriodsOptions}
                    value={pricePeriodsOptions?.find(
                      (option) => option.value === values.pricePeriod
                    )}
                    placeholder="Price Period"
                    onChange={(selectedOption) => {
                      setFieldValue("pricePeriod", selectedOption.value);
                    }}
                    handleBlur={handleBlur}
                    styles={selectPriceStyles}
                  />
                  {touched.pricePeriod && errors.pricePeriod && (
                    <div className="mt-1 text-sm text-[#FF0000]">
                      {errors.pricePeriod}
                    </div>
                  )}
                </div>
              </div>
              <FormikInput
                placeholder="Description of Land"
                name="description"
                type="textarea"
                errors={errors}
                touched={touched}
              />
              <FormikInput
                placeholder="What plantations can be grown?"
                name="plantation"
                type="textarea"
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="flex justify-evenly gap-3">
              <button
                disabled={loading}
                type="submit"
                className={`flex gap-1 justify-center items-center rounded-[10px] bg-main w-44 h-12 font-medium text-white ${loading ? "opacity-60" : "opacity-100"}`}
              >
                {loading && <Spinner />}
                Save
              </button>
              <button
                type="button"
                className="rounded-[10px] px-[66px] py-[12px] text-[#969696]"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RentRequestLandForm;
