import NavBuyRequestIcon from "../assets/navigation/nav-buy-request"
import NavDashboardIcon from "../assets/navigation/nav-dashboard"
import NavOrderHistoryIcon from "../assets/navigation/nav-order-history"
import NavRentRequestIcon from "../assets/navigation/nav-rent-request"
import NavSellRequestIcon from "../assets/navigation/nav-sell-request"
import NavTipIcon from "../assets/navigation/nav-tips"
import NavDiagnosisRequestIcon from "../assets/navigation/nav-diagnosis-request";

export const notAuthorisedLinks = [
    {
        label: "For Buyers",
        path: "/for-buyers"
    },
    {
        label: "For Sellers",
        path: "/for-sellers"
    },
]

export const buyerLinks = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Tips",
        path: "/tips"
    },
    {
        label: "Weather",
        path: "/weather"
    },
    {
        label: "Diagnostic",
        path: "/diagnosis-request"
    }
]

export const sellerLinks = [
    {
        icon: <NavDashboardIcon color="#50A060" />,
        iconActive: <NavDashboardIcon color="#ffffff" />,
        path: "/dashboard",
        label: "Dashboard",
    },
    {
        icon: <NavSellRequestIcon color="#50A060" />,
        iconActive: <NavSellRequestIcon color="#ffffff" />,
        path: "/sell-request",
        label: "Sell Request",
    },
    {
        icon: <NavBuyRequestIcon color="#50A060" />,
        iconActive: <NavBuyRequestIcon color="#ffffff" />,
        path: "/buy-request",
        label: "Buy Request",
    },
    {
        icon: <NavRentRequestIcon color="#50A060" />,
        iconActive: <NavRentRequestIcon color="#ffffff" />,
        path: "/rent-request-land",
        label: "Rent Request Land",
    },
    {
        icon: <NavRentRequestIcon color="#50A060" />,
        iconActive: <NavRentRequestIcon color="#ffffff" />,
        path: "/rent-request-lorries",
        label: "Rent Request Lorries",
    },
    {
        icon: <NavTipIcon color="#50A060" />,
        iconActive: <NavTipIcon color="#ffffff" />,
        path: "/tips",
        label: "Tips",
    },
    {
        icon: <NavDiagnosisRequestIcon color="#50A060" />,
        iconActive: <NavDiagnosisRequestIcon color="#ffffff" />,
        path: "/diagnosis-request",
        label: "Diagnosis Request",
    },
    {
        icon: <NavOrderHistoryIcon color="#50A060" />,
        iconActive: <NavOrderHistoryIcon color="#ffffff" />,
        path: "/order-history-land",
        label: "Order History Land",
    },
    {
        icon: <NavOrderHistoryIcon color="#50A060" />,
        iconActive: <NavOrderHistoryIcon color="#ffffff" />,
        path: "/order-history-lorry",
        label: "Order History Lorry",
    },
    {
        icon: <NavOrderHistoryIcon color="#50A060" />,
        iconActive: <NavOrderHistoryIcon color="#ffffff" />,
        path: "/order-history-sell",
        label: "Order History Sell",
    }
]