import React from "react";
import qualityIcon from "../../../assets/landing screen/quality-icon.svg";
import easyOrderIcon from "../../../assets/landing screen/easy-order-icon.svg";
import EfficientDeliveryIcon from "../../../assets/landing screen/efficient-delivery-icon.svg";
import valuesIcon from "../../../assets/landing screen/values-icon.svg";

const MfarmQualities = () => {

    return (
        <div className={Style.mainBox}>
            {list.map( item => (
                <div className={Style.contentBox} key={item.title}> 
                  <div className="flex flex-row items-center sm:items-start sm:flex-col gap-3"> 
                    <img className={Style.icon} src={item.icon} alt="icon" />
                    <p className={Style.title}>{item.title}</p>
                  </div>
                    <p className={Style.description} >{item.descriptions}</p>
                </div>
            ))}
        </div>
    );
};

const list = [
    {
      icon: qualityIcon,
      title: "Quality",
      descriptions:
        "We are committed to delivering the highest quality agricultural produce directly from the farm to your doorstep.",
    },
    {
      icon: easyOrderIcon,
      title: "Ease of Ordering",
      descriptions:
        "Ordering fresh produce has never been easier. With mFarm, you can conveniently browse our wide selection of farm-fresh items.",
    },
    {
      icon: EfficientDeliveryIcon,
      title: "Efficient Delivery",
      descriptions:
        "We understand the importance of timely delivery. mFarm simplifies the supply chain to ensure that your fresh produce reaches you when you need it.",
    },
    {
      icon: valuesIcon,
      title: "Values",
      descriptions:
        "Your satisfaction is our top priority. We are dedicated to meeting and exceeding your expectations with every order.",
    },
  ];

const Style = {
  mainBox: "flex items-center sm:items-start justify-center sm:justify-start flex-wrap gap-8 sm:w-[40rem]",
  contentBox: "flex flex-col min-w-[240px] w-[272px]",
  icon:"w-12 h-12 sm:mb-6",
  title :"font-circular-500 text-xl text-[#212121] text-center sm:mb-2",
  description :"font-circular-450 text-base text-[#6B7280]",
}

export default MfarmQualities;
