import React, { useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "./store/actions/profile";
import Auth from "./pages/Auth/Auth";
import Home from "./pages/Home/Home";
import Dashboard from "./pages/seller-renter/Dashboard";
import ProfilePage from "./pages/Profile/Profile";
import Tips from "./pages/buyer/Tips/Tips";
import OrderHistoryLand from "./pages/buyer/OrderHistoryLand/OrderHistoryLand";
import OrderHistoryLorry from "./pages/buyer/OrderHistoryLorry/OrderHistoryLorry";
import OrderHistorySell from "./pages/buyer/OrderHistorySell/OrderHistorySell";
import DiagnosisRequest from "./pages/buyer/DiagnosisRequest/DiagnosisRequest";
import SellRequestsPage from "./pages/seller-renter/SellRequestPage";
import BuyRequestsPage from "./pages/seller-renter/BuyRequestPage";
import RentRequestsLandPage from "./pages/seller-renter/RentRequestLandPage";
import RentRequestsLorriesPage from "./pages/seller-renter/RentRequestLorriesPage";
import WeatherPage from "./pages/buyer/WeatherPage";
import NotificationsPage from "./pages/Notification";
import AgricultureProductsPage from "./pages/buyer/AgricultureProducts";
import RentLandListPage from "./pages/buyer/RentLandList";
import RentLorryListPage from "./pages/buyer/RentLorryList";
import RentLandDetails from "./pages/buyer/RentLandDetails";
import RentLorryDetails from "./pages/buyer/RentLorryDetails";
import ProductDetails from "./pages/buyer/ProductDetails";
import Order from "./pages/buyer/Order";
import LandingPageBuyer from "./pages/LandingPageBuyer";
import LandingPageSeller from "./pages/LandingPageSeller";
import Subscription from "./pages/Auth/Subscription";

import "./App.scss";
import RoleMiddleware from "./RoleMiddleware";
import Cart from "./pages/buyer/Cart/Cart";
import { getCartDetails } from "./store/actions/cart";

const pages = [
    {
        path: "/",
        component: Home,
        allowedRoles: ["BUYER", "SELLER", "NOT_AUTHORIZED"]
    },
    {
        path: "/subscription",
        component: Subscription,
        allowedRoles: ["BUYER", "SELLER", "NOT_AUTHORIZED"]
    },
    {
        path: "/auth",
        component: Auth,
        allowedRoles: ["NOT_AUTHORIZED"]
    },
    {
        path: "/dashboard",
        component: Dashboard,
        allowedRoles: ["SELLER"]
    },
    {
        path: "/profile",
        component: ProfilePage,
        allowedRoles: ["BUYER", "SELLER"]
    },
    {
        path: "/tips",
        component: Tips,
        allowedRoles: ["BUYER", "SELLER"]
    },
    {
        path: "/diagnosis-request",
        component: DiagnosisRequest,
        allowedRoles: ["BUYER", "SELLER"]
    },
    {
        path: "/order-history-land",
        component: OrderHistoryLand,
        allowedRoles: ["BUYER", "SELLER"]
    },
    {
        path: "/order-history-lorry",
        component: OrderHistoryLorry,
        allowedRoles: ["BUYER", "SELLER"]
    },
    {
        path: "/order-history-sell",
        component: OrderHistorySell,
        allowedRoles: ["BUYER", "SELLER"]
    },
    {
        path: "/sell-request",
        component: SellRequestsPage,
        allowedRoles: ["SELLER"]
    },
    {
        path: "/buy-request",
        component: BuyRequestsPage,
        allowedRoles: ["SELLER"]
    },
    {
        path: "/rent-request-land",
        component: RentRequestsLandPage,
        allowedRoles: ["SELLER"]
    },
    {
        path: "/rent-request-lorries",
        component: RentRequestsLorriesPage,
        allowedRoles: ["SELLER"]
    },
    {
        path: "/weather",
        component: WeatherPage,
        allowedRoles: ["BUYER", "SELLER"]
    },
    {
        path: "/notifications",
        component: NotificationsPage,
        allowedRoles: ["BUYER", "SELLER"]
    },
    {
        path: "/products",
        component: AgricultureProductsPage,
        allowedRoles: ["BUYER"]
    },
    {
        path: "/land",
        component: RentLandListPage,
        allowedRoles: ["BUYER"]
    },
    {
        path: "/lorries",
        component: RentLorryListPage,
        allowedRoles: ["BUYER"]
    },
    {
        path: "/rent-land-details/:id",
        component: RentLandDetails,
        allowedRoles: ["BUYER","SELLER", "NOT_AUTHORIZED"]
    },
    {
        path: "/rent-lorry-details/:id",
        component: RentLorryDetails,
        allowedRoles: ["BUYER","SELLER", "NOT_AUTHORIZED"]
    },
    {
        path: "/product-details/:id",
        component: ProductDetails,
        allowedRoles: ["BUYER","SELLER", "NOT_AUTHORIZED"]
    },
    {
        path: "/order",
        component: Order,
        allowedRoles: ["BUYER"]
    },
    {
        path: "/cart",
        component: Cart,
        allowedRoles: ["BUYER"]
    },
    {
        path: "/for-buyers",
        component: LandingPageBuyer,
        allowedRoles: ["NOT_AUTHORIZED"]
    },
    {
        path: "/for-sellers",
        component: LandingPageSeller,
        allowedRoles: ["NOT_AUTHORIZED"]
    }
]

const App = () => {
    const token = localStorage.getItem('m-farm-user-token');
    const dispatch = useDispatch()
    const [userRole, setUserRole] = useState("NOT_AUTHORIZED")

    const { profile } = useSelector((state) => ({
        profile: state?.profile?.profile,
    }));

    useEffect(() => {
        if (token && !profile) {
            dispatch(getProfile())
        }
    }, [dispatch, token])

    useEffect(() => {
        if (profile && profile?.type) {
            setUserRole(profile?.type)
            if(profile?.type === "BUYER"){
                dispatch(getCartDetails())
            }
        } else {
            setUserRole("NOT_AUTHORIZED")
        }
    }, [profile])

    return (
        <Router>
            <Switch>
                {pages.map((pageItem, idx) => {
                    return <RoleMiddleware
                        key={idx}
                        exact
                        path={`${process.env.PUBLIC_URL}${pageItem.path}`}
                        component={pageItem.component}
                        allowedRoles={pageItem.allowedRoles}
                        userRole={userRole}
                    />
                })}
            </Switch>
        </Router>
    );
};

export default App;
