import * as actionTypes from "./types";
import axios from "../../axios-api";
import { getErrors, getResData } from "../../shared/utility";

// get order history
export const getOrderHistoryLandStart = () => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_LAND_START,
  };
};

export const getOrderHistoryLandSuccess = orderHistoryLand => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_LAND_SUCCESS,
    orderHistoryLand,
  };
};

export const getOrderHistoryLandFailed = error => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_LAND_FAIL,
    error,
  };
};

export const getOrderHistoryLand = (start, length, type, search) => {
  return dispatch => {
    dispatch(getOrderHistoryLandStart());

    let url;

    if (type === "SELLER") {
      url = "/seller/rent/request/land/order";
    } else if (type === "BUYER") {
      url = "/buyer/rent/request/land/order";
    }

    const params = {};

    if (start !== undefined) {
      params.start = start;
    }

    if (length !== undefined) {
      params.length = length;
    }

    if (search !== undefined) {
      params.name = search;
    }

    if (Object.keys(params).length > 0) {
      url += "?" + new URLSearchParams(params).toString();
    }

    return axios
      .get(url)
      .then(response => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(getOrderHistoryLandSuccess(data));
      })
      .catch(err =>
        dispatch(
          getOrderHistoryLandFailed(getErrors(err?.response?.data || err))
        )
      );
  };
};

// get Order Image
export const getOrderHistoryImageStart = () => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_IMAGE_START,
  };
};

export const getOrderHistoryImageSuccess = orderLandImage => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_IMAGE_SUCCESS,
    orderLandImage,
  };
};

export const getOrderHistoryImageFailed = error => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_IMAGE_FAIL,
    error,
  };
};

export const getOrderHistoryImage = id => {
  return axios
    .get(`/rent/request/land/image/${id}`, { responseType: "arraybuffer" })
    .then(response => {
      if (!response) throw new Error("No Response");

      const data = getResData(response.data);
      const base64String = arrayBufferToBase64(response.data);

      if (!data) throw new Error(getErrors(data));

      return base64String;
    })
    .catch(err => {
      return err;
    });
};

const arrayBufferToBase64 = buffer => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

// get order status
export const getOrderHistoryLandStatusStart = () => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_LAND_STATUS_START,
  };
};

export const getOrderHistoryLandStatusSuccess = orderHistoryLandStatus => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_LAND_STATUS_SUCCESS,
    orderHistoryLandStatus,
  };
};

export const getOrderHistoryLandStatusFailed = error => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_LAND_STATUS_FAIL,
    error,
  };
};

export const getOrderStatus = () => {
  return dispatch => {
    dispatch(getOrderHistoryLandStatusStart());

    let url = "/seller/rent/status";

    return axios
      .get(url)
      .then(response => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(getOrderHistoryLandStatusSuccess(data));
      })
      .catch(err =>
        dispatch(
          getOrderHistoryLandStatusFailed(getErrors(err?.response?.data || err))
        )
      );
  };
};

// change order status
export const changeOrderLandStatusStart = () => {
  return {
    type: actionTypes.CHANGE_ORDER_LAND_STATUS_START,
  };
};

export const changeOrderLandStatusSuccess = changeOrderStatus => {
  return {
    type: actionTypes.CHANGE_ORDER_LAND_STATUS_SUCCESS,
    changeOrderStatus,
  };
};

export const changeOrderLandStatusFailed = error => {
  return {
    type: actionTypes.CHANGE_ORDER_LAND_STATUS_FAIL,
    error,
  };
};

export const changeOrderLandStatus = (id, status) => {
  return dispatch => {
    dispatch(changeOrderLandStatusStart());

    let url = "/seller/rent/status/land";

    return axios
      .put(url, { id: id, status: status })
      .then(response => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(changeOrderLandStatusSuccess(data));
      })
      .catch(err =>
        dispatch(
          changeOrderLandStatusFailed(getErrors(err?.response?.data || err))
        )
      );
  };
};
