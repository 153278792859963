import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout"
import { getNotifications, markAllAsRead } from "../store/actions/notifications";
import { useEffect, useState } from "react";
import moment from "moment";
import Pagination from "../components/Pagination";

const NotificationsPage = () => {
    const dispatch = useDispatch();

    const { notifications } = useSelector((state) => ({
        notifications: state.notifications.notifications
    }));

    const itemsPerPage = 5;

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(notifications?.total / itemsPerPage);

    const lastItemIndex = currentPage * itemsPerPage;
    const firstItemIndex = lastItemIndex - itemsPerPage;

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        dispatch(getNotifications(firstItemIndex, itemsPerPage));
    }, [dispatch, firstItemIndex]);

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[]);

    return <Layout>
        <main className="px-[40px] py-[110px]">
            <div className="flex justify-between items-center  pb-[30px]">
                <h1 className="text-[24px] font-semibold">Notifications</h1>
                <button
                    type="button"
                    onClick={() => dispatch(markAllAsRead())}
                    className="text-[#2D623A] font-medium"
                >
                    Mark Read All
                </button>
            </div>
            {notifications?.data?.length > 0 ? ( 
                notifications?.data?.map((notification, idx) => {
                    return <div key={notification?.id}>
                        {idx !== 0 && <hr className="my-[16px]" />}
                        <p>{notification?.content}</p>
                        <p className="mt-[8px] text-[#848484] text-[14px]">{moment(notification?.date).format('h:mm A, MMMM D, YYYY')}</p>
                    </div>
                })
            ) : (
                <div className="text-xl">
                    <p className="mx-8">No Notification found</p>
                </div>
             )}
            {notifications?.data?.length > 0 && <div className="px-[40px] flex justify-end mt-[30px]">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    startIndex={firstItemIndex}
                    lastIndex={lastItemIndex}
                    onPageChange={handlePageChange}
                />
            </div>}
        </main>
    </Layout>
}

export default NotificationsPage