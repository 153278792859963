import * as actionTypes from "./types";
import axios from "../../axios-api";
import { getErrors, getResData } from "../../shared/utility";

// getTips
export const getOrderHistorySellStart = () => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_SELL_START,
  };
};

export const getOrderHistorySellSuccess = orderHistorySell => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_SELL_SUCCESS,
    orderHistorySell,
  };
};

export const getOrderHistorySellFailed = error => {
  return {
    type: actionTypes.GET_ORDER_HISTORY_SELL_FAIL,
    error,
  };
};

export const getOrderHistorySell = (start, length, type, search) => {
  return dispatch => {
    dispatch(getOrderHistorySellStart());

    let url;

    if(type==='SELLER'){
        url = "/seller/sell/request/order";
    }else if(type==='BUYER'){
        url = "/buyer/sell/request/order";
    }
    
    const params = {};

    if (start !== undefined) {
      params.start = start;
    }

    if (length !== undefined) {
      params.length = length;
    }

    if (search !== undefined) {
      params.name = search;
    }

    if (Object.keys(params).length > 0) {
      url += "?" + new URLSearchParams(params).toString();
    }

    return axios
      .get(url)
      .then(response => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(getOrderHistorySellSuccess(data));
      })
      .catch(err =>
        dispatch(
          getOrderHistorySellFailed(getErrors(err?.response?.data || err))
        )
      );
  };
};

// get Order Image
export const getOrderHistorySellImageStart = () => {
    return {
      type: actionTypes.GET_ORDER_HISTORY_SELL_IMAGE_START,
    };
  };
  
  export const getOrderHistorySellImageSuccess = (orderSellImage) => {
    return {
      type: actionTypes.GET_ORDER_HISTORY_SELL_IMAGE_SUCCESS,
      orderSellImage,
    };
  };
  
  export const getOrderHistorySellImageFailed = (error) => {
    return {
      type: actionTypes.GET_ORDER_HISTORY_SELL_IMAGE_FAIL,
      error,
    };
  };
  
  export const getOrderHistoryImage = (id) => { 
    return axios
      .get(`/sell/request/image/${id}`, {responseType: 'arraybuffer'})
      .then((response) => {
        if (!response) throw new Error('No Response');
  
        const data = getResData(response.data);
        const base64String = arrayBufferToBase64(response.data);
  
        if (!data) throw new Error(getErrors(data));
  
        return base64String;
      })
      .catch((err) => {
        return err
      });
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };


// get order status
export const getOrderHistorySellStatusStart = () => {
  return {
      type: actionTypes.GET_ORDER_HISTORY_SELL_STATUS_START,
  };
};

export const getOrderHistorySellStatusSuccess = (orderHistorySellStatus) => {
  return {
      type: actionTypes.GET_ORDER_HISTORY_SELL_STATUS_SUCCESS,
      orderHistorySellStatus,
  };
};

export const getOrderHistorySellStatusFailed = (error) => {
  return {
      type: actionTypes.GET_ORDER_HISTORY_SELL_STATUS_FAIL,
      error,
  };
};

export const getOrderStatus = () => {
  return (dispatch) => {
      dispatch(getOrderHistorySellStatusStart());

      let url = '/seller/delivery/status';

      return axios
          .get(url)
          .then((response) => {
              if (!response) throw new Error("No Response");

              const data = getResData(response.data);

              if (!data.success) throw new Error(getErrors(data));

              return dispatch(getOrderHistorySellStatusSuccess(data));
          })
          .catch((err) => dispatch(getOrderHistorySellStatusFailed(getErrors(err?.response?.data || err))));
  };
};

// change order status
export const changeOrderSellStatusStart = () => {
  return {
      type: actionTypes.CHANGE_ORDER_SELL_STATUS_START,
  };
};

export const changeOrderSellStatusSuccess = (changeOrderStatus) => {
  return {
      type: actionTypes.CHANGE_ORDER_SELL_STATUS_SUCCESS,
      changeOrderStatus,
  };
};

export const changeOrderSellStatusFailed = (error) => {
  return {
      type: actionTypes.CHANGE_ORDER_SELL_STATUS_FAIL,
      error,
  };
};

export const changeOrderSellStatus = (id, status) => {
  return (dispatch) => {
      dispatch(changeOrderSellStatusStart());

      let url = '/seller/delivery/status';

      return axios
          .put(url, {id: id, status: status})
          .then((response) => {
              if (!response) throw new Error("No Response");

              const data = getResData(response.data);

              if (!data.success) throw new Error(getErrors(data));

              return dispatch(changeOrderSellStatusSuccess(data));
          })
          .catch((err) => dispatch(changeOrderSellStatusFailed(getErrors(err?.response?.data || err))));
  };
};
  