import * as actionTypes from "./types";
import axios from "../../axios-api";
import { getErrors, getResData } from "../../shared/utility";

export const signInStart = () => {
  return {
    type: actionTypes.AUTH_LOGIN_START,
  };
};

export const signInSuccess = (signInRes) => {
  return {
    type: actionTypes.AUTH_LOGIN_SUCCESS,
    signInRes,
  };
};

export const signInFailed = (error) => {
  return {
    type: actionTypes.AUTH_LOGIN_FAIL,
    error,
  };
};

export const signIn = (authData) => {
  return (dispatch) => {
    dispatch(signInStart());

    return axios
      .post(`/sign-in`, authData)
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        localStorage.setItem("m-farm-user-token", data.data?.accessToken);
        localStorage.setItem(
          "m-farm-user-refresh-token",
          data.data?.refreshToken
        );
        return dispatch(signInSuccess(data.data));
      })
      .catch((err) => dispatch(signInFailed(getErrors(err))));
  };
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("m-farm-user-token");
  localStorage.removeItem("m-farm-user-refresh-token");
  localStorage.removeItem("role");

  dispatch({
    type: actionTypes.AUTH_LOGOUT,
  });
  
  dispatch({
    type: actionTypes.RESET_SUBSCRIPTION_STATE,
  })

};

// Register
export const signUpStart = () => {
  return {
    type: actionTypes.AUTH_REGISTER_START,
  };
};

export const signUpSuccess = (signUpRes) => {
  return {
    type: actionTypes.AUTH_REGISTER_SUCCESS,
    signUpRes,
  };
};

export const signUpFailed = (error) => {
  return {
    type: actionTypes.AUTH_REGISTER_FAIL,
    error,
  };
};

export const signUp = (authData) => {
  return (dispatch) => {
    dispatch(signUpStart());

    return axios
      .post(`/sign-up`, authData)
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(signUpSuccess(data.data));
      })
      .catch((err) => dispatch(signUpFailed(getErrors(err))));
  };
};

// Confirm otp
export const confirmOtpStart = () => {
  return {
    type: actionTypes.AUTH_REGISTER_CONFIRM_START,
  };
};

export const confirmOtpSuccess = (confirmOtpRes) => {
  return {
    type: actionTypes.AUTH_REGISTER_CONFIRM_SUCCESS,
    confirmOtpRes,
  };
};

export const confirmOtpFailed = (error) => {
  return {
    type: actionTypes.AUTH_REGISTER_CONFIRM_FAIL,
    error,
  };
};

export const confirmOtp = (authData) => {
  return (dispatch) => {
    dispatch(confirmOtpStart());

    return axios
      .post(`/sign-up/confirm`, authData)
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        localStorage.setItem("m-farm-user-token", data.data?.accessToken);
        localStorage.setItem(
          "m-farm-user-refresh-token",
          data.data?.refreshToken
        );
        return dispatch(confirmOtpSuccess(data.data));
      })
      .catch((err) => dispatch(confirmOtpFailed(getErrors(err))));
  };
};

// Resend OTP
export const resendOtpStart = () => {
  return {
    type: actionTypes.AUTH_RESEND_OTP_START,
  };
};

export const resendOtpSuccess = (resendOtpRes) => {
  return {
    type: actionTypes.AUTH_RESEND_OTP_SUCCESS,
    resendOtpRes,
  };
};

export const resendOtpFailed = (error) => {
  return {
    type: actionTypes.AUTH_RESEND_OTP_FAIL,
    error,
  };
};

export const resendOtp = (data) => {
  return (dispatch) => {
    dispatch(resendOtpStart());

    return axios
      .post(`/otp/resend`, data)
      .then((response) => {
        if (!response) throw new Error("No Response");

        const data = getResData(response.data);

        if (!data.success) throw new Error(getErrors(data));

        return dispatch(resendOtpSuccess(data.data));
      })
      .catch((err) => dispatch(resendOtpFailed(getErrors(err))));
  };
};
