import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authCheck from "../../shared/authCheck"

import Layout from "../../components/Layout"
import Modal from "../../components/Modal/Modal"

import EditIcon from '../../assets/edit.svg';
import DeleteIcon from '../../assets/delete.svg';

import ImagePlaceholderIcon from "../../assets/image-placeholder.png"
import SellRequestForm from "../../components/Forms/SellRequestForm";
import { createBuyRequest, deleteBuyRequest, editBuyRequest, getBuyRequestImage, getBuyRequests, getBuyRequestsFailed, updateBuyRequestImage } from "../../store/actions/buyRequest";
import Pagination from "../../components/Pagination";
import SearchForm from "../../components/Forms/SearchForm";

const TableRow = ({ buyRequest, handleEditBuyRequest, handleDeleteBuyRequest }) => {
    const { id, name, location, description, price, hasImage,status, rejectReason } = buyRequest
    const itemStatus = status.charAt(0).toUpperCase() + status.toLowerCase().slice(1)
    const [image, setImage] = useState()

    const fetchImage = async () => {
        const res = await getBuyRequestImage(id)
        setImage(res)
    }

    useEffect(() => {
        if (hasImage) {
            fetchImage()
        }
    }, [hasImage])

    return <div className="grid grid-cols-[150px_30rem_6rem_42px] md:grid-cols-[150px_6fr_5rem_100px] gap-x-[25px] p-[20px] items-center">
        {image ? <div
            className="w-[150px] h-[150px] m-auto rounded-[10px] block cursor-pointer"
            style={{
                backgroundImage: `url(data:image/jpeg;base64,${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
            }}
        /> : <img src={ImagePlaceholderIcon} alt="" />}

        <div className="flex justify-center items-start flex-col overflow-hidden gap-2">
            <p className="text-[22px] font-semibold w-full truncate">{name}</p>
            <p className="text-main mt-[6px] w-full truncate">{price}</p>
            <p className="text-[#565656] mt-[6px] w-full truncate">{description}</p>
            <p className="mt-[6px]">{location}</p>
        </div> 
        <div className="relative group" >
            <div className={`flex items-center absolute p-2 -top-[0] right-[104px] text-center text-[#FF0000] bg-white w-[30vw] h-fit rounded shadow-lg sibling-element opacity-0 invisible transition duration-300 ${ rejectReason ? "group-hover:opacity-100 group-hover:visible" : ""}`}>
                <p >
                    {rejectReason}
                </p>
            </div>
            <p className={`element-to-hover text-center py-3 
                        ${status=== "PENDING"?"text-blue-600" : 
                        (status === "REJECTED"?"text-[#FF0000] cursor-pointer" : 
                        "text-main")}`}>
                {itemStatus}
            </p>
        </div>
        <div className="flex items-center flex-col">
            <button
                type="button"
                onClick={handleEditBuyRequest}
                className="rounded-[5px] bg-[#285C9A] p-[8px]"
            >
                <img src={EditIcon} alt="edit" />
            </button>
            <button
                type="button"
                onClick={handleDeleteBuyRequest}
                className="rounded-[5px] bg-[#FF0000] p-[8px] mt-[22px]"
            >
                <img src={DeleteIcon} alt="delete" />
            </button>
        </div>
    </div>
}

const BuyRequestsPage = () => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [updateList, setUpdateList] = useState(true);
    const [searchvalue, setSearchValue] = useState(null)
    const [buyRequest, setBuyRequest] = useState();
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()

    const { buyRequests } = useSelector((state) => ({
        buyRequests: state?.buyRequests?.buyRequests,
    }))
    const itemsPerPage = 5;

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(buyRequests?.total / itemsPerPage);

    const lastItemIndex = currentPage * itemsPerPage;
    const firstItemIndex = lastItemIndex - itemsPerPage;

    const handlePageChange = (pageNumber) => {
        setUpdateList(true);
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        if (updateList) {
            if(searchvalue){
                dispatch(getBuyRequests(firstItemIndex, 5, searchvalue));
            }else{
                dispatch(getBuyRequests(firstItemIndex, 5));
            }
            setUpdateList(false);
        }
    }, [dispatch, firstItemIndex, updateList]);

    const handleEditBuyRequest = (id) => {
        const [buyRequest] = buyRequests?.data?.filter((buyRequest) => buyRequest.id === id);
        setBuyRequest(buyRequest);
        setIsEditModalOpen(true);
    };

    const handleDeleteBuyRequest = (id) => {
        const [buyRequest] = buyRequests?.data?.filter((buyRequest) => buyRequest.id === id);
        setBuyRequest(buyRequest);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteSubmit = async () => {
        await dispatch(deleteBuyRequest(buyRequest.id));
        setUpdateList(true);
        setIsDeleteModalOpen(false);
    };

    const handleCreateSubmit = async (values) => {
        setLoading(true)
        const res = await dispatch(createBuyRequest(values));
        if (res.type === 'CREATE_BUY_REQUEST_SUCCESS') {
            setUpdateList(true);
            setIsAddModalOpen(false);
            setLoading(false);
        } else if (res.type === 'CREATE_BUY_REQUEST_FAIL') {
            setTimeout(() => {
                dispatch(getBuyRequestsFailed(null));
                setUpdateList(true);
                setIsAddModalOpen(false);
                setLoading(false);
            }, 5000);
        }
    };

    const handleEditSubmit = async (values) => {
        setLoading(true);
        const res = await dispatch(editBuyRequest(buyRequest.id, values));
        const imageRes = await dispatch(updateBuyRequestImage({ id: buyRequest?.id, imageBase64: values?.imageBase64 }))
        if (res.type === 'EDIT_BUY_REQUEST_SUCCESS') {
            setUpdateList(true);
            setIsEditModalOpen(false);
            setBuyRequest(null);
            setLoading(false);
        } else if (res.type === 'EDIT_BUY_REQUEST_FAIL') {
            setTimeout(() => {
                dispatch(getBuyRequestsFailed(null));
                setUpdateList(true);
                setIsEditModalOpen(false);
                setBuyRequest(null);
                setLoading(false);
            }, 5000);
        }
    };

    const handleSearchSubmit = () => {
        setCurrentPage(1);
    }

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[]);

    return (
        <>
            <Modal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                title="Create Buy Request"
            >
                <SellRequestForm
                    onSubmit={handleCreateSubmit}
                    onClose={() => {
                        setIsAddModalOpen(false);
                    }}
                    loading = {loading}
                />
            </Modal>
            <Modal
                isOpen={isEditModalOpen && buyRequest}
                onClose={() => {
                    setIsEditModalOpen(false);
                    setBuyRequest(null)
                }}
                title="Edit Buy Request"
            >
                <SellRequestForm
                    sellRequest={buyRequest}
                    type="BUYREQUEST"
                    onSubmit={handleEditSubmit}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        setBuyRequest(null);
                    }}
                    loading = {loading}
                />
            </Modal>
            <Modal
                isOpen={isDeleteModalOpen && buyRequest}
                onClose={() => {
                    setIsDeleteModalOpen(false);
                    setBuyRequest(null);
                }}
                title={`Are you sure you want to delete this Buy Request?`}
            >
                <div className="flex justify-evenly sm:flex-row flex-col gap-1">
                    <button
                        type="button"
                        onClick={handleDeleteSubmit}
                        className="rounded-[10px] bg-[#ff0000] px-[66px] py-[12px] font-medium text-white"
                    >
                        Delete
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            setIsDeleteModalOpen(false);
                            setBuyRequest(null);
                        }}
                        className="rounded-[10px] px-[66px] py-[12px] text-[#969696]"
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
            <Layout>
                <main className="py-[110px]">
                    <div className="flex justify-between items-center sm:px-[40px] px-[10px] pb-[30px] flex-col sm:flex-row">
                        <div className="flex justify-between items-center gap-2">
                            <h1 className="text-[24px] font-semibold">Buy Request</h1>
                            <button
                                type="button"
                                onClick={() => {
                                    setIsAddModalOpen(true);
                                }}
                                className="rounded-[10px] bg-main w-[8.6rem] h-[3.6rem] text-white uppercase whitespace-nowrap block sm:hidden"
                            >   
                                + Create
                            </button>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="mt-4">
                            <SearchForm setSearchValue={setSearchValue} onSubmit={values => handleSearchSubmit(values)} setUpdateList={setUpdateList} />
                            </div>
                            <button
                            type="button"
                            onClick={() => {
                                setIsAddModalOpen(true);
                            }}
                            className="rounded-[10px] bg-main w-[8.6rem] h-[3.2rem] text-white uppercase whitespace-nowrap hidden sm:block"
                        >
                            + Create
                        </button>
                        </div>
                    </div>
                        {buyRequests?.data?.length > 0 ? ( 
                            <div className="px-[40px]">
                                <div className=" bg-white shadow-table rounded-[10px] overflow-x-auto">
                                    {buyRequests?.data?.map((buyRequestItem, idx) => {
                                        return (
                                            <>
                                                {idx !== 0 && <hr />}
                                                <TableRow
                                                    key={buyRequestItem?.id}
                                                    buyRequest={buyRequestItem}
                                                    handleEditBuyRequest={() => handleEditBuyRequest(buyRequestItem?.id)}
                                                    handleDeleteBuyRequest={() => handleDeleteBuyRequest(buyRequestItem?.id)}
                                                />
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        ) : ( 
                            <h2 className="px-[40px]">No Buy Requests found</h2>
                        )}
                    {buyRequests?.data?.length > 0 && 
                    <>
                        <div className={`mb-2 px-[40px] mt-[20px] flex justify-between ${buyRequests?.total && buyRequests?.total <= 0 && 'hidden'}`}>
                            <span className="text-sm text-[#A6A6A6] ml-5">
                                {firstItemIndex + 1} -{' '}
                                {lastItemIndex + 1 > buyRequests?.total ? buyRequests?.total : lastItemIndex} of {buyRequests?.total}
                            </span>
                            <div className="flex justify-end mr-5">
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    startIndex={firstItemIndex}
                                    lastIndex={lastItemIndex}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </>
                    }
                </main>
            </Layout>
        </>
    )
}

export default authCheck(BuyRequestsPage)