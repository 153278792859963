import React from "react";
import startEyeEmoji from "../../assets/landing screen/star-eye-emoji.svg";
import happyFaceEmoji from "../../assets/landing screen/happy-face-emoji.svg";
import heartEyeEmoji from "../../assets/landing screen/heart-eye-emoji.svg";
import bgLines from "../../assets/landing screen/green-lines-bg.png";

const EffectiveSeller = () => {
  return (
    <div className="bg-[#3DB83A] ">
      <div
        className="flex justify-between flex-col lg:flex-row items-center lg:items-start gap-16 py-[96px] px-2 sm:px-[112px] z-10"
        style={{
          backgroundImage: `url(${bgLines})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >

      {list.map((item, index) => (
          <div
          className="flex flex-1 flex-col gap-4 min-w-[320px] w-[385px] z-20"
          key={index}
          >
          <div className="inline-block p-3 m-auto rounded-xl border border-solid border-1 border-gray-300 bg-white">
            <img src={item.icon} alt="icon" className="w-[42px] h-[42px]" />
          </div>
          <div className="flex flex-col items-center text-center gap-2 text-white">
            <p className="font-circular-500 text-xl">{item.title}</p>
            <p className="text-base">{item.description}</p>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};
const list = [
  {
    icon: startEyeEmoji,
    title: "Quick and Easy Vendor Sign-Up",
    description:
      "Vendors can register with a user-friendly registration process that doesn't involve unnecessary complexity.",
  },
  {
    icon: happyFaceEmoji,
    title: "Effective Product Management",
    description:
      "Sellers have access to an intuitive dashboard that simplifies the management of their product listings.",
  },
  {
    icon: heartEyeEmoji,
    title: " Promotion of Goods",
    description:
      "Sellers can choose to feature their products, which can increase visibility and sales.",
  },
];
export default EffectiveSeller;
