import * as actionTypes from '../actions/types';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
    sellRequests: null,
    createRes: null,
    editRes: null,
    deleteRes: null,
};

// get sell requests
const getSellRequestsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getSellRequestsSuccess = (state, action) => {
    return updateObject(state, {
        sellRequests: action.sellRequests,
        error: null,
        loading: false,
    });
};

const getSellRequestsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        sellRequests: null,
    });
};

// create sell request
const createSellRequestStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const createSellRequestSuccess = (state, action) => {
    return updateObject(state, {
        createRes: action.createRes,
        error: null,
        loading: false,
    });
};

const createSellRequestFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        createRes: null,
    });
};

// edit sell request
const editSellRequestStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const editSellRequestSuccess = (state, action) => {
    return updateObject(state, {
        editRes: action.editRes,
        error: null,
        loading: false,
    });
};

const editSellRequestFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        editRes: null,
    });
};

// delete sell request
const deleteSellRequestStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const deleteSellRequestSuccess = (state, action) => {
    return updateObject(state, {
        deleteRes: action.deleteRes,
        error: null,
        loading: false,
    });
};

const deleteSellRequestFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        deleteRes: null,
    });
};

// updateSellRequestImage
const updateSellRequestImageStart = (state, action) => {
    return updateObject(state, { error: null, loading: true , sellRequests: null});
};

const updateSellRequestImageSuccess = (state, action) => {
    return updateObject(state, {
        updateSellRequestImageRes: action.updateSellRequestImageRes,
        error: null,
        loading: false,
    });
};

const updateSellRequestImageFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const sellRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        // get sell requests actions
        case actionTypes.GET_SELL_REQUESTS_START:
            return getSellRequestsStart(state, action);
        case actionTypes.GET_SELL_REQUESTS_SUCCESS:
            return getSellRequestsSuccess(state, action);
        case actionTypes.GET_SELL_REQUESTS_FAIL:
            return getSellRequestsFail(state, action);
        // create sell request actions
        case actionTypes.CREATE_SELL_REQUEST_START:
            return createSellRequestStart(state, action);
        case actionTypes.CREATE_SELL_REQUEST_SUCCESS:
            return createSellRequestSuccess(state, action);
        case actionTypes.CREATE_SELL_REQUEST_FAIL:
            return createSellRequestFail(state, action);
        // edit sell request actions
        case actionTypes.EDIT_SELL_REQUEST_START:
            return editSellRequestStart(state, action);
        case actionTypes.EDIT_SELL_REQUEST_SUCCESS:
            return editSellRequestSuccess(state, action);
        case actionTypes.EDIT_SELL_REQUEST_FAIL:
            return editSellRequestFail(state, action);
        // delete sell request actions
        case actionTypes.DELETE_SELL_REQUEST_START:
            return deleteSellRequestStart(state, action);
        case actionTypes.DELETE_SELL_REQUEST_SUCCESS:
            return deleteSellRequestSuccess(state, action);
        case actionTypes.DELETE_SELL_REQUEST_FAIL:
            return deleteSellRequestFail(state, action);
        case actionTypes.EDIT_SELL_REQUEST_IMAGE_START:
            return updateSellRequestImageStart(state, action);
        case actionTypes.EDIT_SELL_REQUEST_IMAGE_SUCCESS:
            return updateSellRequestImageSuccess(state, action);
        case actionTypes.EDIT_SELL_REQUEST_IMAGE_FAIL:
            return updateSellRequestImageFail(state, action);
        default:
            return state;
    }
};

export default sellRequestReducer;
