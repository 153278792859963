import React from 'react'
import fruitsVeggiIcon from '../../../assets/landing screen/fruits-veggi-icon.svg';
import dairyProductIcon from '../../../assets/landing screen/dairy-product-icon.svg';
import meatSeaFoodIcon from '../../../assets/landing screen/meat-seafoot-icon.svg';
import organicFoodIcon from '../../../assets/landing screen/organic-food-icon.svg';

const MfarmSellerQualities = () => {
    return (
        <div className={Style.mainBox}>
            {list.map( item => (
                <div className={Style.contentBox} key={item.title}> 
                  <div className="flex flex-row items-center sm:items-start sm:flex-col gap-3"> 
                    <img className={Style.icon} src={item.icon} alt="icon" />
                    <p className={Style.title}>{item.title}</p>
                  </div>
                    <p className={Style.description} >{item.descriptions}</p>
                </div>
            ))}
        </div>
    );
}

const list = [
    {
      icon: fruitsVeggiIcon,
      title: "Fruits and Vegetables",
      descriptions:
        "A variety of vegetables such as tomatoes, lettuce, apples, bananas, oranges and carrots.",
    },
    {
      icon: dairyProductIcon,
      title: "Dairy Products",
      descriptions:
        "Milk and dairy items like cheese, yogurt, and butter.",
    },
    {
      icon: meatSeaFoodIcon,
      title: "Meat & Seafood",
      descriptions:
        "Quality cuts of meat, including beef, pork, and poultry.",
    },
    {
      icon: organicFoodIcon,
      title: "Organic Food Products",
      descriptions:
        "Specialty organic items like honey and herbs. Organic dairy and meat products.",
    },
  ];

const Style = {
  mainBox: "flex items-center sm:items-start justify-center sm:justify-start  flex-wrap sm:w-[40rem]",
  contentBox: "flex flex-col min-w-[240px] w-[272px]  sm:mr-8 mb-12",
  icon:"w-12 h-12 sm:mb-6",
  title :"font-circular-500 text-xl text-[#212121] text-center sm:mb-2",
  description :"font-circular-450 text-base text-[#6B7280]",
}


export default MfarmSellerQualities
