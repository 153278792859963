import React from "react";

const AboutSeller = () => {
  return (
    <div className="flex flex-col max-w-[42rem]">
      <p className="font-circular-500 text-base text-[#3DB83A] mb-3">
        #1 Fresh Farm Producers{" "}
      </p>
      <p className="font-circular-500 text-3xl text-[#212121] mb-5">
        What can farmers sell on mFarm?
      </p>
      <p className="font-circular-450 text-xl text-[#6B7280]">
        Below mentioned are some generally consumed food products that farmers
        can sell on mFarm platform:
      </p>
    </div>
  );
};

export default AboutSeller;
