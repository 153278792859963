import LandingPageBuyerBanner from "./LandingPageBuyerBanner";
import Header from "../../components/Header";
import SubFooter from "../Home/SubFooter";
import FooterContainer from "../Home/FooterContainer";
import LandingPageBuyerSubBanner from "./LandingPageBuyerSubBanner";
import GettingStarted from "./GettingStarted";
import { useEffect } from "react";

const LandingPageBuyer = () => {

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <main>
    <Header />
    <LandingPageBuyerBanner />
    <LandingPageBuyerSubBanner />
    <GettingStarted />
    <SubFooter />
    <FooterContainer />
  </main>
  );
};

export default LandingPageBuyer;
