import store from "../storage";
import { logout } from "../store/actions/auth";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const isEmpty = (item) => {
  if (typeof item === "string") {
    if (item.length === 0) return true;
    if (item.length !== 0) return false;
  }
  if (!item || typeof item !== "object") return true;
  if (Array.isArray(item)) {
    return item.length > 0 ? false : true;
  }
  return !Object.keys(item).length;
};


export const getErrors = (err) => {
  let error = 'Something went wrong. Please try later';

  if (err) error = err;
  if (err.error) error = err.error;
  if (err.message) error = err.message;
  if (err?.response?.data?.message) error = err?.response?.data?.message;
  
  return error;
}

export const transformErrorsFromAPI = (err) => {
  const errors = {}

  if (typeof err === 'string') {
    errors['error'] = err;
    return errors;
  }

  for (const [key, value] of Object.entries(err)) {
    if (key === "error") {
      errors['error'] = value
    }
    else {
      errors[key.uncapitalize()] = value
    }
  }

  return errors
}

export const getResData = (res, inCatch = false) => {
  const responseData = {
    success: false,
    result: null,
    total: null,
    data: null,
    error: null,
  };

  if (inCatch) {
    if (res && typeof res === "object" && isEmpty(res)) {
      responseData.error = res.toString();
    } else {
      responseData.error = res;
    }
    return responseData;
  }
  if (isEmpty(res)) return responseData;

  if (res.result === "error" && res.errorCode === 102) store.dispatch(logout())

  if (res.result === "error") {
    responseData.error = res.debugInfo || res.errorDesc;
    return responseData;
  }
  if (res.data && res.data.result === "error") {
    responseData.error = res.data.debugInfo || res.data.errorDesc;
    return responseData;
  }

  if (res.result === 'ok') {
    responseData.success = true;
    responseData.result = res.result;
    responseData.data = res.data;
    if (res.total) {
      responseData.total = res.total;
    }
    return responseData;
  }
  if (res.data && res.data.data && res.data.result) {
    responseData.success = true;
    responseData.result = res.data.result;
    responseData.data = res.data.data;
    if (res.data.total) {
      responseData.total = res.data.total;
    }
    return responseData;
  }
  return responseData;
};

export const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const getTotalAmount = (price,pricePeriod, rentFrom, rentTo) => {

  let totalPrice = null;
  const from = new Date(rentFrom);
  const to = new Date(rentTo);
  
  if (pricePeriod === 'Day') {
    const daysDifference = Math.ceil((to - from + 1) / (1000 * 60 * 60 * 24));
    totalPrice += price * daysDifference;
  } else if (pricePeriod === 'Month') {
    const monthsDifference = (to.getFullYear() - from.getFullYear()) * 12 +  (to.getMonth() - from.getMonth()) + 1;
    totalPrice += price * monthsDifference;
  } 

  return totalPrice;
}

export const formatDate = (dateString) => {
  // Create a new Date object from the given string
  const date = new Date(dateString);

  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const formatPrice = (price) =>{

  const priceString = price.toString();
  const reversedString = [...priceString].reverse().join("");
  let newPrice = "";

  for ( let c = 0 ; c < reversedString.length ; c++ ) {
    newPrice += reversedString[c];
    if((c+1) % 3 === 0 && (c+1) < reversedString.length ) newPrice += ",";
  }

  return [...newPrice].reverse().join("");
}