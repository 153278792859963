import * as actionTypes from '../actions/types';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
    buyRequests: null,
    createRes: null,
    editRes: null,
    deleteRes: null,
};

// get buy requests
const getBuyRequestsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getBuyRequestsSuccess = (state, action) => {
    return updateObject(state, {
        buyRequests: action.buyRequests,
        error: null,
        loading: false,
    });
};

const getBuyRequestsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        buyRequests: null,
    });
};

// create buy request
const createBuyRequestStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const createBuyRequestSuccess = (state, action) => {
    return updateObject(state, {
        createRes: action.createRes,
        error: null,
        loading: false,
    });
};

const createBuyRequestFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        createRes: null,
    });
};

// edit buy request
const editBuyRequestStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const editBuyRequestSuccess = (state, action) => {
    return updateObject(state, {
        editRes: action.editRes,
        error: null,
        loading: false,
    });
};

const editBuyRequestFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        editRes: null,
    });
};

// delete buy request
const deleteBuyRequestStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const deleteBuyRequestSuccess = (state, action) => {
    return updateObject(state, {
        deleteRes: action.deleteRes,
        error: null,
        loading: false,
    });
};

const deleteBuyRequestFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        deleteRes: null,
    });
};

// updateBuyRequestImage
const updateBuyRequestImageStart = (state, action) => {
    return updateObject(state, { error: null, loading: true, buyRequests: null });
};

const updateBuyRequestImageSuccess = (state, action) => {
    return updateObject(state, {
        updateBuyRequestImageRes: action.updateBuyRequestImageRes,
        error: null,
        loading: false,
    });
};

const updateBuyRequestImageFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const buyRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        // get buy requests actions
        case actionTypes.GET_BUY_REQUESTS_START:
            return getBuyRequestsStart(state, action);
        case actionTypes.GET_BUY_REQUESTS_SUCCESS:
            return getBuyRequestsSuccess(state, action);
        case actionTypes.GET_BUY_REQUESTS_FAIL:
            return getBuyRequestsFail(state, action);
        // create buy request actions
        case actionTypes.CREATE_BUY_REQUEST_START:
            return createBuyRequestStart(state, action);
        case actionTypes.CREATE_BUY_REQUEST_SUCCESS:
            return createBuyRequestSuccess(state, action);
        case actionTypes.CREATE_BUY_REQUEST_FAIL:
            return createBuyRequestFail(state, action);
        // edit buy request actions
        case actionTypes.EDIT_BUY_REQUEST_START:
            return editBuyRequestStart(state, action);
        case actionTypes.EDIT_BUY_REQUEST_SUCCESS:
            return editBuyRequestSuccess(state, action);
        case actionTypes.EDIT_BUY_REQUEST_FAIL:
            return editBuyRequestFail(state, action);
        // delete buy request actions
        case actionTypes.DELETE_BUY_REQUEST_START:
            return deleteBuyRequestStart(state, action);
        case actionTypes.DELETE_BUY_REQUEST_SUCCESS:
            return deleteBuyRequestSuccess(state, action);
        case actionTypes.DELETE_BUY_REQUEST_FAIL:
            return deleteBuyRequestFail(state, action);
        case actionTypes.EDIT_BUY_REQUEST_IMAGE_START:
            return updateBuyRequestImageStart(state, action);
        case actionTypes.EDIT_BUY_REQUEST_IMAGE_SUCCESS:
            return updateBuyRequestImageSuccess(state, action);
        case actionTypes.EDIT_BUY_REQUEST_IMAGE_FAIL:
            return updateBuyRequestImageFail(state, action);
        default:
            return state;
    }
};

export default buyRequestReducer;
