// actions.js
import axios from "../../axios-api";
import * as actionTypes from "./types";
import { arrayBufferToBase64, getErrors, getResData, getTotalAmount } from "../../shared/utility";

export const getCartDetailsStart = () => {
    return {
        type: actionTypes.GET_CART_DETAILS_START,
    };
};

export const getCartDetailsSuccess = (CartDetails) => {
    return {
        type: actionTypes.GET_CART_DETAILS_SUCCESS,
        CartDetails,
    };
};

export const getCartDetailsFailed = (error) => {
    return {
        type: actionTypes.GET_CART_DETAILS_FAIL,
        error,
    };
};

export const getCartDetails = () => {
    return (dispatch) => {
        dispatch(getCartDetailsStart());

         return axios.get('/buyer/shopping/cart')
         .then((response) => {
            if (!response) throw new Error("No Response");

            // why getResData ??
            // let data = getResData(response.data);
            // if (!data.success) throw new Error(getErrors(data));

            const product = response?.data?.data?.sellRequestItems.map(item => {
                const productItem = {
                    amount: item.quantity,
                    data: {
                    categoryId : item.categoryId,
                    categoryName : item.categoryName,
                    date : item.date ,
                    description : item.description ,
                    hasImage : item.hasImage ,
                    sellRequestId : item.sellRequestId ,
                    id: item.id,
                    location : item.location ,
                    msisdn : item.msisdn ,
                    name : item.name ,
                    price : item.price ,
                    status : item. status
                    }
                  }
                  return productItem;
            });
            
            const lorry = response?.data?.data?.rentRequestLorryItems?.map(item => {
                const totalAmount = getTotalAmount(item.price,item.pricePeriod, item.rentFrom, item.rentTo);
                if(!totalAmount){
                    // handle error
                    console.log("totalAmount is null")
                }
                const lorryItem = {
                    amount: totalAmount ? totalAmount : 0,
                    data :{
                      firstName : "",
                      lastName : "",
                      notes : null,
                      rentFrom : item.rentFrom,
                      rentRequestId : item.rentRequestId,
                      rentTo : item.rentTo,
                      lorry :{
                        brandName : item.brandName,
                        date : item.date,
                        horsePower : item.horsePower,
                        id : item.id,
                        location : item.location,
                        msisdn : item.msisdn,
                        name : item.name,
                        price : item.price,
                        pricePeriod : item.pricePeriod,
                        status : item.status,
                        uuid : item.uuid,
                        yearOfManufacture : item.yearOfManufacture,
                        imageIdList : item.imageIdList,
                        available: item?.available,
                      }
                    }
                }
                return lorryItem;
            });

            const land = response?.data?.data?.rentRequestLandItems?.map(item => {
                const totalAmount = getTotalAmount(item.price,item.pricePeriod, item.rentFrom, item.rentTo);
                if(!totalAmount){
                    // handle error
                    console.log("totalAmount is null")
                }
                const landItem = {
                    amount: totalAmount ? totalAmount : 0,
                    data : {
                      firstName : "",
                      lastName : "",
                      notes : null,
                      rentFrom : item.rentFrom,
                      rentRequestId : item.rentRequestId,
                      rentTo : item.rentTo,
                      land : {
                        date : item.date,
                        description : item.description,
                        id : item.id,
                        location : item.location,
                        measurement : item.measurement,
                        msisdn : item.msisdn,
                        name : item.name,
                        plantation : item.plantation,
                        price : item.price,
                        pricePeriod : item.pricePeriod,
                        uuid : item.uuid,
                        imageIdList :  item.imageIdList,
                        available: item?.available,
                      }
                    }
                  }
                  return landItem;
            });

            const cart = { product: product ? product : [], lorry: lorry ? lorry : [], land: land ? land : []}

            return dispatch(getCartDetailsSuccess(cart));
        })
        .catch(err => {
            getCartDetailsFailed(getErrors(err?.response?.data || err))
        })
    };
};

export const addToCartstart = () => {
    return {
        type: actionTypes.ADD_TO_CART_START,
    };
};

export const addToCartsuccess = () => {
    return {
        type: actionTypes.ADD_TO_CART_SUCCESS,
    };
};

export const addToCartFailed = (error) => {
    return {
        type: actionTypes.ADD_TO_CART_FAILED,
        error,
    };
};

export const addToCart = (data, setIsPeriodAvailableError) => {

    return (dispatch) => {
        dispatch(addToCartstart());

        let URL = null;
        let reqBody = null;

        switch (data.type) {
            case "product" :
                URL = "/buyer/shopping/cart/sell/request";
                reqBody = {
                    quantity : data.quantity,
                    sellRequestId : data.id,
                }
                break;
            case "lorry" :
                URL = "/buyer/shopping/cart/rent/request/lorry";
                reqBody = {
                    rentFrom : data.rentFrom,
                    rentRequestId : data.id,
                    rentTo : data.rentTo,
                }
                break;
            case "land" :
                URL = "/buyer/shopping/cart/rent/request/land";
                reqBody = {
                    rentFrom : data.rentFrom,
                    rentRequestId : data.id,
                    rentTo : data.rentTo,
                }
                break;
            default :
                // set error
                // setIsPeriodAvailableError(true);
                dispatch(addToCartFailed("Item type not found"))
                return;
        }
        
        if(!URL){
            dispatch(addToCartFailed("End Point not Found"))
            throw new Error("End Point not found")
        }

        return axios.post(URL, reqBody)
        .then((response) => {
            if (!response) throw new Error("No Response");

            const data = getResData(response.data);

            if (!data.success) throw new Error(getErrors(data));
            dispatch(getCartDetails());
            return dispatch(addToCartsuccess());

        })
        .catch(err => {
            const error = getErrors(err?.response?.data || err);
            if(error === "Period is not available"){
                setIsPeriodAvailableError(true)
            }
            dispatch(addToCartFailed(error))
        })
    };
};

export const editCartDetailstart = () => {
    return {
        type: actionTypes.EDIT_CART_DETAIL_START,
    };
};

export const editCartDetailsuccess = (editRes) => {
    return {
        type: actionTypes.EDIT_CART_DETAIL_SUCCESS,
        editRes,
    };
};

export const editCartDetailsFailed = (error) => {
    return {
        type: actionTypes.EDIT_CART_DETAIL_FAIL,
        error,
    };
};

export const editCartDetails = (data, setIsPeriodAvailableError) => {
    return (dispatch) => {
        dispatch(editCartDetailstart());

        let URL = null;
        let reqBody = data;

        switch (data.type){
            case "PRODUCT" :
                URL = "/buyer/shopping/cart/sell/request";
                reqBody = {quantity: data.quantity, id: data.id}
                break;
            case "LAND" :
                URL = "/buyer/shopping/cart/rent/request/land";
                reqBody = {
                    rentFrom : data.rentFrom,
                    id : data.id,
                    rentTo : data.rentTo,
                }
                break;
            case "LORRY" :
                URL = "/buyer/shopping/cart/rent/request/lorry";
                reqBody ={
                    rentFrom : data.rentFrom,
                    id : data.id,
                    rentTo : data.rentTo,
                }
                break;
            default:
                //set error
                setIsPeriodAvailableError(true)
                dispatch(editCartDetailsFailed("Item type not found"))
                throw new Error("Item type not found")
                return;
        }

        if(!URL){
            dispatch(editCartDetailsFailed("End Point not Found"))
            throw new Error("End Point not found")
        }

        return axios
        .put(URL, reqBody)
        .then(response => {
            if(!response) throw new Error("NO response from Edit cart Item API")

            const data = getResData(response.data);

            if(!data.success) throw new Error (getErrors(data));

            dispatch(getCartDetails());
            return dispatch(editCartDetailsuccess(data));
        })
        .catch(err => dispatch(editCartDetailsFailed(getErrors(err?.response?.data || err))))
    };
};

export const deleteCartItemstart = () => {
    return {
        type: actionTypes.DELETE_CART_ITEM_START,
    };
};

export const deleteCartItemsuccess = (deleteRes) => {
    return {
        type: actionTypes.DELETE_CART_ITEM_SUCCESS,
        deleteRes,
    };
};

export const deleteCartItemFailed = (error) => {
    return {
        type: actionTypes.DELETE_CART_ITEM_FAILED,
        error,
    };
};

export const deleteCartItem = (item, setIsPeriodAvailableError) => {

    return (dispatch) => {
        dispatch(deleteCartItemstart());

        let URL = null;

        switch (item.type) {
            case "PRODUCT" :
                URL = `/buyer/shopping/cart/sell/request/${item.id}`;
                break;
            case "LORRY" :
                URL = `/buyer/shopping/cart/rent/request/lorry/${item.id}`;
                break;
            case "LAND" :
                URL = `/buyer/shopping/cart/rent/request/land/${item.id}`;
                break;
            default :
                // set error
                setIsPeriodAvailableError(true);
                dispatch(deleteCartItemFailed("Item type not found"))
                throw new Error("Item type not found")
                return;
        }

        if(!URL){
            dispatch(deleteCartItemFailed("End Point not Found"))
            throw new Error("End Point not found")
        }

        return axios.delete(URL)
        .then((response) => {
            if(!response) throw new Error ("NO response from delete cart Item API");

            const data = getResData(response.data);

            if (!data.success) throw new Error(getErrors(data));

            dispatch(deleteCartItemsuccess());
            dispatch(getCartDetails());
            return dispatch(addToCartsuccess());

        })
        .catch((err) => deleteCartItemFailed(getErrors(err?.response?.data || err)));
}
}

// export const deleteBuyRequestFailed = (error) => {
//     return {
//         type: actionTypes.DELETE_CART_DETAIL_FAIL,
//         error,
//     };
// };

// export const deleteBuyRequest = (id) => {
//     return (dispatch) => {
//         dispatch(deleteCartDetailstart());

//         return axios
//             .delete(`/seller/buy/request/${id}`)
//             .then((response) => {
//                 if (!response) throw new Error("No Response");

//                 const data = getResData(response.data);

//                 if (!data.success) throw new Error(getErrors(data));

//                 return dispatch(deleteCartDetailsuccess(data));
//             })
//             .catch((err) =>
//                 dispatch(deleteBuyRequestFailed(getErrors(err?.response?.data || err)))
//             );
//     };
// };

// export const getBuyRequestImage = (id) => {
//     return axios
//         .get(`/seller/buy/request/image/${id}`, {
//             responseType: 'arraybuffer',// Tell Axios to treat the response as an array buffer
//             headers: {
//                 'Content-Type': 'application/json',
//             }
//         })
//         .then((response) => {
//             if (!response) throw new Error("No Response");

//             const data = response.data;
//             const base64String = arrayBufferToBase64(data);

//             return base64String;
//         })
//         .catch((err) => getErrors(err));
// };

// // updateBuyRequestImage
// export const updateBuyRequestImageStart = () => {
//     return {
//         type: actionTypes.EDIT_CART_DETAIL_IMAGE_START,
//     };
// };

// export const updateBuyRequestImageSuccess = (updateBuyRequestImageRes) => {
//     return {
//         type: actionTypes.EDIT_CART_DETAIL_IMAGE_SUCCESS,
//         updateBuyRequestImageRes,
//     };
// };

// export const updateBuyRequestImageFailed = (error) => {
//     return {
//         type: actionTypes.EDIT_CART_DETAIL_IMAGE_FAIL,
//         error,
//     };
// };

// export const updateBuyRequestImage = (data) => {
//     return (dispatch) => {
//         dispatch(updateBuyRequestImageStart());

//         return axios
//             .put(`/seller/buy/request/image`, data)
//             .then(async (response) => {
//                 if (!response) throw new Error("No Response");

//                 const data = getResData(response.data);

//                 if (!data.success) throw new Error(getErrors(data));

//                 return await dispatch(updateBuyRequestImageSuccess(data.data));
//             })
//             .catch((err) => dispatch(updateBuyRequestImageFailed(getErrors(err))));
//     };
// };
