import * as actionTypes from '../actions/types';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
    CartDetails: {
        product: [],
        lorry: [],
        land: []
    }
};

// get buy requests
const getCartDetailsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getCartDetailsSuccess = (state, action) => {
    return updateObject(state, {
        CartDetails: action.CartDetails,
        error: null,
        loading: false,
    });
};

const getCartDetailsFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

// create buy request
const addToCartstart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const addToCartsuccess = (state, action) => {
    return updateObject(state, {
        loading: false
    });
};

const addToCartFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
}


// edit buy request
// const editBuyRequestStart = (state, action) => {
//     return updateObject(state, { error: null, loading: true });
// };

// const editBuyRequestSuccess = (state, action) => {
//     return updateObject(state, {
//         editRes: action.editRes,
//         error: null,
//         loading: false,
//     });
// };

// const editBuyRequestFail = (state, action) => {
//     return updateObject(state, {
//         error: action.error,
//         loading: false,
//         editRes: null,
//     });
// };

// // delete buy request
// const deleteBuyRequestStart = (state, action) => {
//     return updateObject(state, { error: null, loading: true });
// };

// const deleteBuyRequestSuccess = (state, action) => {
//     return updateObject(state, {
//         deleteRes: action.deleteRes,
//         error: null,
//         loading: false,
//     });
// };

// const deleteBuyRequestFail = (state, action) => {
//     return updateObject(state, {
//         error: action.error,
//         loading: false,
//         deleteRes: null,
//     });
// };

// // updateBuyRequestImage
// const updateBuyRequestImageStart = (state, action) => {
//     return updateObject(state, { error: null, loading: true, buyRequests: null });
// };

// const updateBuyRequestImageSuccess = (state, action) => {
//     return updateObject(state, {
//         updateBuyRequestImageRes: action.updateBuyRequestImageRes,
//         error: null,
//         loading: false,
//     });
// };

// const updateBuyRequestImageFail = (state, action) => {
//     return updateObject(state, {
//         error: action.error,
//         loading: false,
//     });
// };

const buyRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        // get buy requests actions
        case actionTypes.GET_CART_DETAILS_START:
            return getCartDetailsStart(state, action);
        case actionTypes.GET_CART_DETAILS_SUCCESS:
            return getCartDetailsSuccess(state, action);
        case actionTypes.GET_CART_DETAILS_FAIL:
            return getCartDetailsFailed(state, action);
        // create buy request actions
        case actionTypes.ADD_TO_CART_START:
            return addToCartstart(state, action);
        case actionTypes.ADD_TO_CART_SUCCESS:
            return addToCartsuccess(state, action);
        case actionTypes.ADD_TO_CART_FAILED:
            return addToCartFailed(state, action);
        // edit buy request actions
        // case actionTypes.EDIT_BUY_REQUEST_START:
        //     return editBuyRequestStart(state, action);
        // case actionTypes.EDIT_BUY_REQUEST_SUCCESS:
        //     return editBuyRequestSuccess(state, action);
        // case actionTypes.EDIT_BUY_REQUEST_FAIL:
        //     return editBuyRequestFail(state, action);
        // // delete buy request actions
        // case actionTypes.DELETE_BUY_REQUEST_START:
        //     return deleteBuyRequestStart(state, action);
        // case actionTypes.DELETE_BUY_REQUEST_SUCCESS:
        //     return deleteBuyRequestSuccess(state, action);
        // case actionTypes.DELETE_BUY_REQUEST_FAIL:
        //     return deleteBuyRequestFail(state, action);
        // case actionTypes.EDIT_BUY_REQUEST_IMAGE_START:
        //     return updateBuyRequestImageStart(state, action);
        // case actionTypes.EDIT_BUY_REQUEST_IMAGE_SUCCESS:
        //     return updateBuyRequestImageSuccess(state, action);
        // case actionTypes.EDIT_BUY_REQUEST_IMAGE_FAIL:
        //     return updateBuyRequestImageFail(state, action);
        default:
            return state;
    }
};

export default buyRequestReducer;
