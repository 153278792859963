import * as actionTypes from '../actions/types';
import { updateObject } from '../../shared/utility';

const initialState = {
  error: null,
  loading: false,
  diagnosisRequest: null,
  diagnosisImageRequests: null,
  diagnosisImage: null
};

//get Diagnosis history
const getDiagnosisRequestStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getDiagnosisRequestSuccess = (state, action) => {
  return updateObject(state, {
    diagnosisRequests: action.diagnosisRequests,
    error: null,
    loading: false,
  });
};

const getDiagnosisRequestFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    diagnosisRequests: null,
  });
};

//get Diagnosis image requests
const getDiagnosisImageRequestStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getDiagnosisImageRequestSuccess = (state, action) => {
  return updateObject(state, {
    diagnosisImageRequests: action.diagnosisImageRequests,
    error: null,
    loading: false,
  });
};

const getDiagnosisImageRequestFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    diagnosisImageRequests: null,
  });
};

//Create Diagnosis Request info
const createDiagnosisRequestStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const createDiagnosisRequestSuccess = (state, action) => {
  return updateObject(state, {
    diagnosisRequest: action.diagnosisRequest,
    error: null,
    loading: false,
  });
};

const createDiagnosisRequestFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    diagnosisRequest: null,
  });
};

const updateImageStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const updateImageSuccess = (state, action) => {
  return updateObject(state, {
    diagnosisImage: action.diagnosisImage,
    error: null,
    loading: false,
  });
};

const updateImageFailed = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    diagnosisImage: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Get diagnosis request actions
    case actionTypes.GET_DIAGNOSIS_REQUEST_START:
      return getDiagnosisRequestStart(state, action);
    case actionTypes.GET_DIAGNOSIS_REQUEST_SUCCESS:
      return getDiagnosisRequestSuccess(state, action);
    case actionTypes.GET_DIAGNOSIS_REQUEST_FAIL:
      return getDiagnosisRequestFailed(state, action);
    // Get diagnosis request actions
    case actionTypes.GET_DIAGNOSIS_IMAGE_REQUEST_START:
      return getDiagnosisImageRequestStart(state, action);
    case actionTypes.GET_DIAGNOSIS_IMAGE_REQUEST_SUCCESS:
      return getDiagnosisImageRequestSuccess(state, action);
    case actionTypes.GET_DIAGNOSIS_IMAGE_REQUEST_FAIL:
      return getDiagnosisImageRequestFailed(state, action);
    // Create diagnosis request
    case actionTypes.CREATE_DIAGNOSIS_REQUEST_START:
      return createDiagnosisRequestStart(state, action);
    case actionTypes.CREATE_DIAGNOSIS_REQUEST_SUCCESS:
      return createDiagnosisRequestSuccess(state, action);
    case actionTypes.CREATE_DIAGNOSIS_REQUEST_FAIL:
      return createDiagnosisRequestFailed(state, action);
    // diagnosis request Image
    case actionTypes.UPDATE_IMAGE_START:
      return updateImageStart(state, action);
    case actionTypes.UPDATE_IMAGE_SUCCESS:
      return updateImageSuccess(state, action);
    case actionTypes.UPDATE_IMAGE_FAIL:
      return updateImageFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
