import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useParams } from "react-router-dom";
import {
  getHomeRentLandRequestImage,
  getSelectedtItemById,
} from "../../store/actions/homeRentSell";
import { useHistory } from "react-router-dom";

import LocationIcon from "../../assets/location-pin.svg";
import MeasurementIcon from "../../assets/measuring.svg";
import Modal from "../../components/Modal/Modal";
import RentLandForm from "../../components/Forms/RentLandForm";
import defaultImage from "../../assets/image-placeholder.png";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../store/actions/cart";
import ErrorModal from "../../shared/ErrorModal";
import PhoneIcon from "../../assets/telephone-call.svg";
import { getTotalAmount } from "../../shared/utility";
import { isAuthorized } from "../../shared/auth";
import { getProfile } from "../../store/actions/profile";

const RentLandDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const param = useParams();

  const [rentItem, setRentItem] = useState(null);
  const [images, setImages] = useState(null);
  const [activeImage, setActiveImage] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStatusModal, setIsStatusModal] = useState(false);
  const [modalText, setModalText] = useState(false);
  const [modalTextColor, setModalTextColor] = useState(false);
  const [showMsisdn, setShowMsisdn] = useState(false);
  const [itemsNotAvailableList, setItemsNotAvailableList] = useState(null);
  const [isPeriodAvailableError, setIsPeriodAvailableError] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const [showAddToCartSuccessText, setShowAddToCartSucessText] =
    useState(false);
  const [isBuyNowEnable, setIsBuyNowEnable] = useState(false);

  const { CartDetails, cart, selectedProduct, profile } = useSelector((state) => ({
    selectedProduct: state?.homeRentSell?.selectedProduct,
    CartDetails: state?.cart?.CartDetails,
    cart: state?.cart,
    profile: state?.profile?.profile,
  }));

  const fetchImages = async () => {
    try {
      let imagePromises = null;
      if (rentItem)
        imagePromises = rentItem?.imageIdList?.map(async (id) => {
          return await getHomeRentLandRequestImage(id);
        });

      if (imagePromises) {
        const images = await Promise.all(imagePromises);
        setActiveImage(images[0]);
        setImages(images);
      }
    } catch (error) {
      // console.error("Error fetching images:", error);
    }
  };

  // fetch product details
  useEffect(() => {
    const landId = param?.id;
    dispatch(getSelectedtItemById(landId, "LAND"));
    fetchImages();

    if (!profile) {
      dispatch(getProfile());
    }
  }, []);

  useEffect(() => {
    setRentItem(selectedProduct);
  }, [selectedProduct]);

  // useEffect(() => {
  //   if (location.data) {
  //     setRentItem(location.data.rentItem);
  //   }
  // }, []);

  useEffect(() => {
    if (rentItem?.imageIdList?.length > 0) {
      fetchImages();
    }
  }, [rentItem?.imageIdList]);

  useEffect(() => {
    if (!itemsNotAvailableList && cart?.error) {
      setIsStatusModal(true);
      setModalText(cart.error);
      setModalTextColor("text-[#FF0000]");
      setTimeout(() => {
        setIsStatusModal(false);
        setModalText("");
        setModalTextColor("");
      }, 3000);
    }
  }, [cart]);

  const handleSubmit = async (values) => {
    const rentFrom =
      values?.type === "period"
        ? formatDate(values?.period?.startDate) + "T00:00:00"
        : formatDate(values?.date) + "T00:00:00";
    const rentTo =
      values?.type === "period"
        ? formatDate(values?.period?.endDate) + "T23:59:59"
        : formatDate(values?.date) + "T23:59:59";

    const authorized = isAuthorized();

    if (authorized && rentItem?.id) {
      let data = {
        type: "land",
        rentFrom: rentFrom,
        id: rentItem.id,
        rentTo: rentTo,
      };

      // check if buy now is not enable and also item is already present in cart or not, if item is present in cart dont add again
      if (!isBuyNowEnable) {
        const isItemAlreadyAvailableInCart = CartDetails?.land?.reduce(
          (acc, item) => {
            return item.data.rentRequestId === rentItem?.id || acc;
          },
          false
        );

        if (isItemAlreadyAvailableInCart) {
          setStatusMessage("Item already present in cart");
          setShowAddToCartSucessText(true);
        } else {
          dispatch(addToCart(data, setIsPeriodAvailableError));
          setStatusMessage("Item added to cart");
          setShowAddToCartSucessText(true);
        }
      } else {
        // buy perticular item

        const rentItems = {
          type: "LAND",
          item: rentItem,
          rentFrom: rentFrom,
          rentTo: rentTo,
          quantity: getTotalAmount(
            rentItem.price,
            rentItem.pricePeriod,
            rentFrom,
            rentTo
          ),
        };

        history.push({
          pathname: `${process.env.PUBLIC_URL}/order`,
          data: rentItems,
        });
      }

      setTimeout(() => {
        setStatusMessage(null);
        setShowAddToCartSucessText(false);
        setIsBuyNowEnable(false);
      }, 3000);
    } else if (!authorized) {
      history.push(`${process.env.PUBLIC_URL}/auth`);
    } else {
      throw new Error("Rent ID not found");
    }
    setIsModalOpen(false);
  };

  const formatDate = (dateString) => {
    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Extract year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsBuyNowEnable(false);
          setIsModalOpen(false);
        }}
        title="Select Rent Duration"
      >
        <RentLandForm
          type="LAND"
          landId={rentItem?.id}
          onSubmit={(values) => handleSubmit(values)}
          onClose={() => {
            setIsBuyNowEnable(false);
            setIsModalOpen(false);
          }}
        />
      </Modal>
      <Layout>
        {showAddToCartSuccessText && (
          <div className="fixed top-20 right-14 bg-slate-100 p-4 rounded">
            <p
              className={`${
                statusMessage === "Item added to cart"
                  ? "text-green-500"
                  : "text-[#FF0000]"
              } ${showAddToCartSuccessText ? "visible" : "invisible"}`}
            >
              {statusMessage}
            </p>
          </div>
        )}
        <section className="container mx-[auto] mt-[110px] mb-[60px]">
          {isStatusModal && (
            <ErrorModal text={modalText} textColor={modalTextColor} />
          )}
          <div className="grid md:grid-cols-2 items-center gap-x-[70px] px-6">
            <div>
              {activeImage ? (
                <div
                  title="Change Profile Image"
                  className="w-[100%] h-[250px] md:h-[675px] rounded-[10px]"
                  style={{
                    backgroundImage: `url(data:image/jpeg;base64,${activeImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
              ) : (
                <img
                  src={defaultImage}
                  className="w-[100%] h-[250px] md:h-[675px] rounded-[10px]"
                />
              )}
            </div>

            <div>
              <h2 className="text-[28px] md:text-[42px] font-semibold">
                {rentItem?.name}
              </h2>
              <p className="text-[18px] md:text-[24px] text-main mt-[10px] md:mt-[36px]">
                {rentItem?.price} / {rentItem?.pricePeriod}
              </p>
              <div className="mt-[10px] md:mt-[16px] text-[16px] md:text-[20px] flex">
                <img src={MeasurementIcon} alt="" className="mr-[12px]" />
                <span>{rentItem?.measurement}</span>
              </div>
              <div className="mt-[10px] md:mt-[16px] text-[16px] md:text-[20px] flex">
                <img src={LocationIcon} alt="" className="mr-[12px]" />
                <span>{rentItem?.location}</span>
              </div>
              <div
                className={`text-[16px] md:text-[20px] flex items-center transition-opacity duration-500 ease-in-out ${
                  showMsisdn
                    ? "opacity-100 mt-[10px] md:mt-[16px]"
                    : "invisible opacity-0 h-[0px]"
                }`}
              >
                <img src={PhoneIcon} alt="" className="mr-[12px] h-[20px]" />
                <span>{rentItem?.msisdn}</span>
              </div>
              <p className="mt-[14px] md:mt-[20px] text-[16px] md:text-[20px]">
                {rentItem?.description}
              </p>
              {profile?.type !== "SELLER" && (
                <div className="grid gap-[16px] lg:grid-cols-[1fr_1fr_62px] mt-[36px] mb-[35px] justify-items-center items-start content-start">
                  <button
                    type="button"
                    onClick={() => {
                      const authorized = isAuthorized();
                      if (authorized) {
                        setIsBuyNowEnable(true);
                        setIsModalOpen(true);
                      } else {
                        history.push(`${process.env.PUBLIC_URL}/auth`);
                      }
                    }}
                    className="py-[17px] justify-self-center w-full max-w-[330px] bg-main rounded-[10px] text-white text-[18px] font-medium text-center"
                  >
                    RENT NOW
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      const authorized = isAuthorized();
                      if (authorized) {
                        setIsModalOpen(true);
                      } else {
                        history.push(`${process.env.PUBLIC_URL}/auth`);
                      }
                    }}
                    className="py-[17px] justify-self-center w-full max-w-[330px] bg-[#50A060] rounded-[10px] text-white text-[18px] font-medium text-center"
                  >
                    ADD TO CART
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setShowMsisdn(!showMsisdn);
                    }}
                    className="bg-[#F5FFE1] rounded-[10px] w-full max-w-[330px] lg:max-w-[62px] h-[62px] flex justify-center items-center px-[15px] gap-[12px]"
                  >
                    <img
                      src={PhoneIcon}
                      alt="phone number"
                      className="w-[32px] h-[32px]"
                    />
                    <p className="text-main lg:hidden uppercase">
                      {showMsisdn ? "hide Phone Number" : "show phone number"}
                    </p>
                  </button>
                </div>
              )}
            </div>
            <div className="grid justify-items-center mt-[30px] w-[100%] overflow-x-auto">
              <div className={`flex no-wrap w-max gap-[30px]`}>
                {images?.map((image) => (
                  <button
                    type="button"
                    onClick={() => setActiveImage(image)}
                    className="w-[100px] h-[100px] rounded-[10px]"
                    style={{
                      backgroundImage: `url(data:image/jpeg;base64,${image})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default RentLandDetails;
