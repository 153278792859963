import React from 'react'

function UpdateProfileModal({onConfirm, onCancle, error}) {
  return (
    <div>
        <p className='my-10 px-8'>Are you sure want to update Profile?</p>
        {error && <p className='text-[#FF0000]'>File size is too large, it should be less than 1mb</p>}
        <div className="flex justify-evenly gap-3">
            <button
                className="rounded-[10px]  w-44 h-12 text-[#969696]"
                onClick={onCancle}
            >
                Cancle
            </button>
            <button
                className="flex gap-1 justify-center items-center rounded-[10px] bg-main  w-44 h-12 font-medium text-white"
                onClick={onConfirm}
            >
                
                Save
            </button>
        </div>
    </div>
  )
}

export default UpdateProfileModal