import React from 'react'
import ContactUs from '../../components/ContactUs'
import Footer from '../../components/Footer'

function FooterContainer() {
  return (
    <footer className='bg-[#212121] px-2 sm:px-[32px]'>
      <ContactUs />
      <Footer />
    </footer>
  )
}

export default FooterContainer
