import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import FormikInput from "../../components/FormikInput";
import { signIn } from "../../store/actions/auth";
import { transformErrorsFromAPI } from "../../shared/utility";
import { getProfile } from "../../store/actions/profile";
import ButtonSpinner from "../ButtonSpinner";

const loginValidationSchema = Yup.object().shape({
  msisdn: Yup.string().required("Mobile Number is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password size must be between 6 and 20")
    .max(20, "Password size must be between 6 and 20"),
});

const LoginForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { profile, loading } = useSelector((state) => ({
    profile: state?.profile?.profile,
    loading: state?.auth?.loading,
  }))

  useEffect(()=>{

    if(profile?.type === "SELLER") {
      history.push(`${process.env.PUBLIC_URL}/dashboard`)
    }else if (profile?.type === "BUYER") {
      history.push(`${process.env.PUBLIC_URL}/`);
    } else if (profile?.subscribed === false){
      history.push(`${process.env.PUBLIC_URL}/subscription`);
    }

  },[profile])

  return (
    <Formik
      initialValues={{ msisdn: "", password: "" }}
      validationSchema={loginValidationSchema}
      onSubmit={async (values, { setErrors, resetForm }) => {
        const res = await dispatch(signIn(values));
        if (!res) {
          alert("Something went wrong");
        }
        if (res && res.error) {
          setErrors(transformErrorsFromAPI(res.error));
          return;
        }
        if (res.signInRes.accessToken && !profile) {
          dispatch(getProfile());
        }
        return;
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="mb-[30px]">
            <FormikInput
              name="msisdn"
              placeholder="Mobile Number"
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="mb-[40px]">
            <FormikInput
              name="password"
              type="password"
              placeholder="Password"
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="grid items-center justify-center">
            <button
              type="submit"
              disabled={loading}
              className={`flex items-center justify-center gap-2 rounded-[10px] bg-main w-[16rem] h-[3rem] uppercase text-white focus:outline-none ${loading ? "opacity-60": "opacity-100"}`}
            >
              {loading && 
                  <ButtonSpinner />
                } 
              Login
            </button>
          </div>
          {(errors.password || errors.error || errors.msisdn) && (
            <p className="text-[#FF0000] text-center pt-3">
              {errors.password
                ? errors.password
                : errors.error
                ? errors.error
                : errors.msisdn
                ? errors.msisdn
                : ""}
            </p>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
