import React from "react";
import { Provider, useDispatch, useSelector } from "react-redux";

import "./OtpForm.scss";
import { useRef, useEffect } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { isArray } from "lodash";
import * as Yup from "yup";
import { confirmOtp, resendOtp } from "../../../store/actions/auth";
import { getProfile } from "../../../store/actions/profile";
import { getErrors } from "../../../shared/utility";
import { useState } from "react";
import { getBuyerSubscriptionEnableStatus, getIsSubscriptionExist, getSellerSubscriptionEnableStatus } from "../../../store/actions/subscription";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ButtonSpinner from "../../ButtonSpinner";

const useMountEffect = (fun) => useEffect(fun, []);

const OtpInput = (props) => {

  const { setIsEnable } = props;
  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };

  const [ref0, setRef0] = UseFocus();
  const [ref1, setRef1] = UseFocus();
  const [ref2, setRef2] = UseFocus();
  const [ref3, setRef3] = UseFocus();
  const [ref4, setRef4] = UseFocus();
  const [ref5, setRef5] = UseFocus();

  useMountEffect(setRef0);
  useEffect(() => {
    if (typeof props.value === "object") {
      if (props.value.length < 6) setIsEnable(false);
      const enableButton = Object.values(props.value).reduce(
        (accumulator, currentValue, index) => {
          if (currentValue?.length === 0) {
            return false;
          } else if (currentValue === undefined) {
            return false;
          } else if (index === 5) {
            return true;
          }
          return accumulator;
        },
        false
      );
      setIsEnable(enableButton);
    }
  }, [props.value]);

  function valueChange (event, ref) {
    if(event.code === "Backspace"){
      return !props.value[ref] ? (ref === 1 ? setRef0() : (ref === 2 ? setRef1(): (ref === 3 ? setRef2() : (ref === 4 ? setRef3() : (ref === 5 ? setRef4() : null))))): null;
    }else if(event.code === "ArrowLeft"){
      return (ref === 1 ? setRef0() : (ref === 2 ? setRef1(): (ref === 3 ? setRef2() : (ref === 4 ? setRef3() : (ref === 5 ? setRef4() : null)))));
    } else if (event.keyCode >= 48 && event.keyCode <= 57){
      return props.value[ref] ? (ref === 0 ? setRef1() : (ref === 1 ? setRef2() : (ref === 2 ? setRef3(): (ref === 3 ? setRef4() : (ref === 4 ? setRef5() : (ref === 5 ? setRef5() : null)))))): null;
    }else if(event.code === "ArrowRight"){
      return (ref === 0 ? setRef1() : (ref === 1 ? setRef2() : (ref === 2 ? setRef3(): (ref === 3 ? setRef4() : (ref === 4 ? setRef5() : (ref === 5 ? setRef5() : null))))));
    }
  }

  return (
    <FieldArray
      name="code"
      render={() => (
        <div className="input-block otp-input-block">
          <div className="otp-wrapper">
            <div className="otp-wrapper-fields">
              <Field
                name={`${props.name}.${0}`}
                maxLength="1"
                innerRef={ref0}
                onKeyUp={(event) => valueChange(event,0)}
                className="otp-input"
              />
              <Field
                name={`${props.name}.${1}`}
                maxLength="1"
                innerRef={ref1}
                onKeyUp={(event) => valueChange(event,1)}
                className="otp-input"
              />
              <Field
                name={`${props.name}.${2}`}
                maxLength="1"
                innerRef={ref2}
                onKeyUp={(event) => valueChange(event, 2)}
                className="otp-input"
              />
              <Field
                name={`${props.name}.${3}`}
                maxLength="1"
                innerRef={ref3}
                onKeyUp={(event) => valueChange(event, 3)}
                className="otp-input"
              />
              <Field
                name={`${props.name}.${4}`}
                maxLength="1"
                innerRef={ref4}
                onKeyUp={(event) => valueChange(event, 4)}
                className="otp-input"
              />
              <Field
                name={`${props.name}.${5}`}
                maxLength="1"
                innerRef={ref5}
                onKeyUp={(event) => valueChange(event, 5)}
                className="otp-input"
              />
            </div>
          </div>
          {props.error && props.touched && (
            <p className="text-[#FF0000] text-center">
              {isArray(props.error) ? props.error[0] : props.error}
            </p>
          )}
        </div>
      )}
    />
  );
};

const otpValSchema = Yup.object().shape({
  code: Yup.array()
    .of(
      Yup.number()
        .min(0, "Code should be a number")
        .max(9, "Code should be a number")
        .typeError("Code should be a number")
    )
    .required("OTP is required")
    .nullable(),
  msisdn: Yup.string().required("Number is required"),
});

const OtpForm = ({ type, msisdn, onBackSignIn }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isEnable, setIsEnable] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [subscriptionUpdate, setSubscriptionUpdate] = useState(false);

  const { subscription, loading, profile } = useSelector((state) => ({
    subscription: state?.subscription,
    loading: state?.auth?.loading,
    profile: state?.profile?.profile, 
  }))

  const handleResetOtp = async () => {

    const otpRes = await dispatch(resendOtp({ msisdn: msisdn }));

    if (otpRes?.type === "AUTH_RESEND_OTP_FAIL" && otpRes?.error) {
      setOtpError(otpRes?.error);
    } else if (otpRes?.type === "AUTH_RESEND_OTP_SUCCESS") {
      if (!isResendDisabled) {
        setTimer(30);
        setIsResendDisabled(true);
      }
      setOtpError("");
    }
  };

  async function getUserSubscriptionEnableService () {
   await dispatch(getSellerSubscriptionEnableStatus());
    await dispatch(getBuyerSubscriptionEnableStatus());
  }

  useEffect(() => {
    let interval;

    if (timer > 0 && isResendDisabled) {
      interval = setInterval(() => {
        setTimer(prevState => prevState - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      setIsResendDisabled(false);
    }

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [timer, isResendDisabled]);

  // check seller and buyer subscription service enable
  useEffect(()=>{
    getUserSubscriptionEnableService();
  },[profile])

  // is service is enable check for subscription
  useEffect(() => {
    if ((subscription?.seller?.sellerSubscriptionEnable && profile?.subscribed === false ) || 
        (subscription?.buyer?.buyerSubscriptionEnable && profile?.subscribed === false ) ||
        (subscription?.seller?.sellerSubscriptionEnable && profile?.type === "SELLER") || 
        (subscription?.buyer?.buyerSubscriptionEnable && profile?.type === "BUYER" )) {
            dispatch(getIsSubscriptionExist());
    } else if (subscription?.seller?.sellerSubscriptionEnable === false && profile?.type === "SELLER" ) {
      history.push(`${process.env.PUBLIC_URL}/dashboard`);
    } else if (subscription?.buyer?.buyerSubscriptionEnable === false && profile?.type === "BUYER" ) {
      history.push(`${process.env.PUBLIC_URL}/`);
    }

}, [subscription?.seller?.sellerSubscriptionEnable, subscription?.buyer?.buyerSubscriptionEnable, profile])

  useEffect(()=>{

    if (subscription?.isSubscribed === false) {
      history.push(`${process.env.PUBLIC_URL}/subscription`);
    } else  if (subscription?.isSubscribed && profile?.type === "SELLER") {
      history.push(`${process.env.PUBLIC_URL}/dashboard`);
    } else if (subscription?.isSubscribed && profile?.type === "BUYER") {
      history.push(`${process.env.PUBLIC_URL}/`);
    }
  },[subscription?.isSubscribed])

  return (
    <>
      <h2 className="text-[24px] font-semibold text-center">
        OTP Verification
      </h2>
      <h2 className="text-center text-[#565656] mb-[20px]">
        Please enter OTP here
      </h2>
      <Formik
        initialValues={{ code: "", msisdn: msisdn }}
        validationSchema={otpValSchema}
        onSubmit={async (values, { setErrors, resetForm }) => {
          const data = {
            code: values.code.join(""),
            msisdn: values.msisdn,
          };
          const res = await dispatch(confirmOtp(data));

          if (!res) {
            alert("Something went wrong");
          }
          if (res && res.error) {
            setErrors({ code: getErrors(res.error) });
            return;
          }
          if (res?.type === "AUTH_REGISTER_SUCCESS" && !profile) {
            dispatch(getProfile());
          }else if(res?.type === "AUTH_REGISTER_CONFIRM_SUCCESS" && !profile){
            dispatch(getProfile());
          }
        }}
      >
        {({ errors, touched, values, setFieldValue }) => {
          return (
            <Form className="grid justify-items-center">
              <OtpInput
                error={errors.code || otpError}
                touched={touched.code}
                name="code"
                value={values.code}
                setIsEnable={setIsEnable}
              />
              <button
                disabled={isResendDisabled}
                className={`mt-[26px] rounded-[10px] bg-main w-[14rem] h-[3rem] uppercase text-white focus:outline-none whitespace-nowrap ${
                  isResendDisabled > 0 ? "opacity-60" : "opacity-100"
                }`}
                type="button"
                onClick={handleResetOtp}
              >
                {isResendDisabled ? 'Resend OTP in '+ timer : 'Resend'}
              </button>
              <div className="grid px-14">
              <button
                type="submit"
                disabled={loading || !isEnable}
                className={`flex items-center justify-center gap-2 mt-[26px] rounded-[10px] bg-main w-[14rem] h-[3rem] uppercase text-white focus:outline-none whitespace-nowrap ${
                  loading || !isEnable ? "opacity-60" : "opacity-100"
                }`}
              >
                {loading && <ButtonSpinner />}
                {type === "register" ? "register now" : "login"}
              </button>
              </div>
              <button
                type="button"
                className="mt-[26px] text-main text-center"
                onClick={() => {
                  onBackSignIn();
                  setOtpError("");
                }}
              >
                Back to Login/Registration
              </button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default OtpForm;
