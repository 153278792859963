import React from 'react'
import ProductCardContainer from './ProductCardContainer'
import MachineRentContainer from './MachineRentContainer'
import LandRentContainer from './LandRentContainer'

function ProductAndRentContainer({ setStatusMessage, setShowAddToCartSuccessText }) {
    const ItemCardDetails = [
        {
            title: "Products",
            subTitle: "Agriculture Products",
            description: "Bridging our local farms in Nigeria closer to people, and building meaningful connections between both!",
            buttonText:"View All Products"
        },
        {
            title: "Lorry on Rent",
            subTitle: "Machine on Rent",
            description: "Bridging our local farms in Nigeria closer to people, and building meaningful connections between both!",
            buttonText:"View All Products"
        },
        {
            title: "Land on Rent",
            subTitle: "Agriculture Land Rent",
            description: "Bridging our local farms in Nigeria closer to people, and building meaningful connections between both!",
            buttonText:"View All Products"
        },
    
    ]
  return (
    <main className='px-2 sm:px-16'>
        <ProductCardContainer cardData={ItemCardDetails[0]} setStatusMessage={setStatusMessage} setShowAddToCartSuccessText={setShowAddToCartSuccessText} />
        <MachineRentContainer cardData={ItemCardDetails[1]} />
        <LandRentContainer cardData={ItemCardDetails[2]} />
    </main>
  )
}

export default ProductAndRentContainer
