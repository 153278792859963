const NavBuyRequestIcon = ({ color, className }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="Frame" clipPath="url(#clip0_44_3919)">
                <g id="Group">
                    <path id="Vector" d="M6.10837 19.1464C6.20359 19.5687 6.07663 20.0053 5.77332 20.3105L4.66239 21.4285L0 16.7437L1.11799 15.6257C1.42482 15.3169 1.86214 15.1892 2.28887 15.2921H2.2924C2.51459 15.3453 2.71914 15.4624 2.8849 15.6257L5.77332 18.5324C5.93908 18.6992 6.05194 18.9051 6.10484 19.1322C6.10837 19.1393 6.10837 19.1429 6.10837 19.1464Z" fill={color} />
                    <path id="Vector_2" d="M7.09229 1V11.0013C7.24746 10.9907 7.40617 10.9871 7.56135 10.9871C8.3584 10.9871 9.13429 11.1398 9.86785 11.4414C9.89959 11.4556 9.93486 11.4627 9.96308 11.4627H15.4155C15.9903 11.4627 16.5264 11.7325 16.8755 12.1654L18.2369 10.7494V1H7.09229ZM15.5107 3.88541V4.83302C15.5107 6.39817 14.2446 7.67229 12.6893 7.67229C11.9345 7.67229 11.2257 7.37772 10.6931 6.84181C10.1606 6.30589 9.86785 5.59253 9.86785 4.83302V3.88541C9.60687 3.88541 9.39879 3.67247 9.39879 3.41338C9.39879 3.15075 9.6104 2.94135 9.86785 2.94135H10.8095C11.0705 2.94135 11.2786 3.1543 11.2786 3.41338C11.2786 3.67602 11.067 3.88541 10.8095 3.88541V4.83302C10.8095 5.33699 11.0035 5.81257 11.3597 6.17103C11.7159 6.52949 12.1885 6.72469 12.6893 6.72469C13.7261 6.72469 14.569 5.87645 14.569 4.83302V3.88541C14.3081 3.88541 14.1 3.67247 14.1 3.41338C14.1 3.15075 14.3116 2.94135 14.569 2.94135H15.5107C15.7717 2.94135 15.9797 3.1543 15.9797 3.41338C15.9797 3.67602 15.7681 3.88541 15.5107 3.88541Z" fill={color} />
                    <path id="Vector_3" d="M21.665 9.80526C21.27 9.4681 20.674 9.52134 20.339 9.91883C20.3319 9.92593 20.3249 9.93303 20.3178 9.94368L19.1011 11.2107L18.9812 11.3349L17.2813 13.1024C17.2918 13.184 17.2989 13.2692 17.2989 13.3508C17.2989 14.3942 16.456 15.2425 15.4191 15.2425H11.3281C11.0671 15.2425 10.859 15.0295 10.859 14.7704C10.859 14.5078 11.0706 14.2984 11.3281 14.2984H15.4191C15.9376 14.2984 16.3608 13.8725 16.3608 13.3508C16.3608 13.2621 16.3467 13.1698 16.322 13.0846C16.2056 12.6836 15.8353 12.4032 15.4191 12.4032H9.9632C9.81155 12.4032 9.6599 12.3748 9.5153 12.3145C8.89459 12.0589 8.23861 11.9312 7.565 11.9312C7.27227 11.9312 6.98308 11.9525 6.70094 11.9915C6.69741 11.9915 6.69388 11.9915 6.69388 11.9915H6.69036C5.0363 12.2364 3.75256 13.145 3.03662 14.5681C3.22707 14.6711 3.39988 14.7988 3.55506 14.955L6.44348 17.8617C6.55281 17.9717 6.64803 18.0924 6.73268 18.2202L6.94076 18.0179C7.5262 17.45 8.29856 17.1341 9.11325 17.1341H15.3027C16.1562 17.1341 16.9674 16.8076 17.581 16.2114L21.7814 11.1362C22.1129 10.7387 22.0636 10.1424 21.665 9.80526ZM9.69517 15.0224C9.51177 15.207 9.212 15.207 9.02861 15.0224C8.84521 14.8379 8.84521 14.5362 9.02861 14.3517C9.212 14.1671 9.50825 14.1671 9.69517 14.3517C9.87856 14.5398 9.87856 14.8379 9.69517 15.0224Z" fill={color} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_44_3919">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default NavBuyRequestIcon
