import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../../components/Layout";
import Delete from "../../../assets/delete-bin.svg";
import Calender from "../../../assets/calendar-color.svg";
import Modal from "../../../components/Modal/Modal";
import RentLandForm from "../../../components/Forms/RentLandForm";
import Table from "../../../components/Tables/Table";
import { useDispatch, useSelector } from "react-redux";
import {deleteCartItem, editCartDetails, getCartDetails } from "../../../store/actions/cart";
import { useHistory } from "react-router-dom";
import {
  getHomeRentLandRequestImage,
  getHomeRentLorriesRequestImage,
  getHomeSellRequestImage,
} from "../../../store/actions/homeRentSell";
import ErrorModal from "../../../shared/ErrorModal";
import { formatDate } from "../../../shared/utility";

const ProductRow = ({
  row,
  handleProductQantityChange,
  handleDelete,
}) => {
  const [images, setImages] = useState({});
  const [quantity, setQuantity] = useState(row.amount);

  const fetchImage = useCallback(async () => {
    const res = await getHomeSellRequestImage(row?.data?.sellRequestId);
    setImages(prevState => ({...prevState, [row?.data?.sellRequestId] : res}));
  }, [row]);

  useEffect(() => {
    if ( images[row?.data?.sellRequestId] === undefined && row?.data?.hasImage) {
      fetchImage();
    }
  }, [row, fetchImage]);

  return (
    <React.Fragment>
      <td className="py-[20px] flex">
        <div
          htmlFor="imageBase64"
          className="w-16 h-16 min-w-[4rem] rounded-[10px]"
          style={{
            backgroundImage: `url(data:image/jpeg;base64,${images[row?.data?.sellRequestId]})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        <div className="flex">
          <div>
            <p className="font-semibold text-lg px-3 pt-2">{row.data.name}</p>
            <p className="pb-2 px-3 text-sm">
              <span className="font-semibold">Weight: </span>
              {row.amount} Kg
            </p>
          </div>
        </div>
      </td>
      <td>
        <div className="flex justify-between">
          <p className="py-10">&#8358; {row.data.price}</p>
        </div>
      </td>
      <td>
        <div className="flex justify-between py-3">
          <div className="flex gap-x-[30px] items-center mt-[22px]">
            <button
              type="button"
              onClick={() => {
                setQuantity(prev => prev - 1)
                handleProductQantityChange({ id: row.data.id, quantity: (quantity - 1), type: "PRODUCT"} )
              }}
              disabled={quantity <= 1}
              className={`bg-[#50A060] rounded-[10px] w-[30px] h-[30px] text-white text-[22px] ${quantity <= 1? "opacity-60" : "opacity-100"}`}
            >
              -
            </button>
            {quantity}
            <button
              type="button"
              onClick={() => {
                setQuantity(prev => prev + 1)
                handleProductQantityChange({ id: row.data.id, quantity: (quantity + 1), type: "PRODUCT"} )
              }}
              className="bg-[#50A060] rounded-[10px] w-[30px] h-[30px] text-white text-[22px]"
            >
              +
            </button>
          </div>
        </div>
      </td>
      <td>
        <div className="flex justify-between py-8 px-5">
          <img
            src={Delete}
            alt="order"
            className="w-6 min-w-[1.5rem] h-8 rounded cursor-pointer"
            onClick={() => handleDelete({ id:row.data.id , type: "PRODUCT"})}
          />
        </div>
      </td>
      <td>
        <div className="flex justify-between">
          <p className="py-10">&#8358; {row.amount * row.data.price}</p>
        </div>
      </td>
    </React.Fragment>
  );
};

const LorryRaw = ({
  row,
  setIsLorryModalOpen,
  setRentItem,
  handleDelete,
}) => {
  const [images, setImages] = useState({});

  const fetchImage = useCallback(async () => {
    const res = await getHomeRentLorriesRequestImage(
      row?.data?.lorry?.imageIdList[0]
    );
    setImages(prevState => ({...prevState, [row?.data?.lorry?.imageIdList[0]] : res}));
  }, [row]);

  useEffect(() => {
    if (images[row?.data?.lorry?.imageIdList[0]] === undefined && row?.data?.lorry?.imageIdList?.length > 0) {
      fetchImage();
    }
  }, [row, fetchImage]);

  return (
    <React.Fragment>
      <td className="py-[20px] flex">
        <div
          htmlFor="imageBase64"
          className="w-16 h-16 min-w-[4rem] rounded-[10px]"
          style={{
            backgroundImage: `url(data:image/jpeg;base64,${images[row?.data?.lorry?.imageIdList[0]]})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        <div className="flex">
          <div>
            <p className="font-semibold text-lg px-3 pt-2">
              {row.data.lorry.name}
            </p>
            <p className="pb-2 px-3 text-sm">
              <span className="font-semibold">Horsepower: </span>
              {row.data.lorry.horsepower} BHP
            </p>
          </div>
        </div>
      </td>
      <td>
        <div className="flex justify-between">
          <p className="py-10">
            &#8358; {row.data.lorry.price} / {row.data.lorry.pricePeriod}
          </p>
        </div>
      </td>
      <td>
        <div className="flex flex-col justify-between pb-10 pt-6">
          <p className={`text-xs text-[#FF0000] ${row.data.lorry.available ? "invisible" : "visible"}`}>Period Not Available </p>
          <p className={`${row.data.lorry.available ? "text-black" : "text-[#FF0000]"}`}>
            {formatDate(row.data.rentFrom) +
              " - " +
              formatDate(row.data.rentTo)}
          </p>
          <div
            className="flex cursor-pointer"
            onClick={() => {
              setRentItem(row);
              setTimeout(()=>{
                setIsLorryModalOpen(true);
              },100)
            }}
          >
            <img
              src={Calender}
              alt="calender"
              className="w-6 h-6 rounded pr-2"
            />
            <p className="text-sm pt-1 hover:text-[#50A060]">Change Duration</p>
          </div>
        </div>
      </td>
      <td>
        <div className="flex justify-between py-8 px-5">
          <img
            src={Delete}
            alt="order"
            className="w-6 min-w-[1.5rem] h-6 rounded cursor-pointer"
            onClick={() => handleDelete({ id:row.data.lorry.id , type: "LORRY"})}
          />
        </div>
      </td>
      <td>
        <div className="flex justify-between">
          <p className="py-10">&#8358; {row.amount}</p>
        </div>
      </td>
    </React.Fragment>
  );
};

const LandRow = ({
  row,
  setIsLandModalOpen,
  setRentItem,
  handleDelete,
}) => {
  const [images, setImages] = useState({});

  const fetchImage = useCallback(async () => {
    const res = await getHomeRentLandRequestImage(
      row?.data?.land?.imageIdList[0]
    );
    setImages(prevState => ({...prevState, [row?.data?.land?.imageIdList[0]]: res}));
  }, [row]);

  useEffect(() => {
    if (images[row?.data?.land?.imageIdList[0]] === undefined && row?.data?.land?.imageIdList?.length > 0) {
      fetchImage();
    }
  }, [row, fetchImage]);

  if (!row?.data?.land) {
    return null;
  }

  return (
    <React.Fragment>
      <td className="py-[20px] flex">
        <div
          htmlFor="imageBase64"
          className="w-16 h-16 rounded-[10px]"
          style={{
            backgroundImage: `url(data:image/jpeg;base64,${images[row?.data?.land?.imageIdList[0]]})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        <div className="flex">
          <div>
            <p className="font-semibold text-lg px-3 pt-2">
              {row.data.land.name}
            </p>
            <p className="pb-2 px-3 text-sm">
              <span className="font-semibold">Measurement: </span>
              {row.data.land.measurement}
            </p>
          </div>
        </div>
      </td>
      <td> 
        <div className="flex justify-between">
          <p className="py-10">&#8358; {row.data.land.price} / {row.data.land.pricePeriod}</p>
        </div>
      </td>
      <td>
        <div className="flex justify-between py-3">
          <div className="flex flex-col justify-between pb-7 pt-4">
          <p className={`text-xs text-[#FF0000] ${row.data.land.available ? "invisible" : "visible"}`}>Period Not Available </p>
          <p className={`${row.data.land.available ? "text-black" : "text-[#FF0000]"}`}>
            {formatDate(row.data.rentFrom) +
                " - " +
                formatDate(row.data.rentTo)}
            </p>
            <div
              className="flex"
              onClick={() => {
                setRentItem(row);
                setTimeout(()=>{
                setIsLandModalOpen(true);
              },100)
            }}
            >
              <img
                src={Calender}
                alt="calender"
                className="w-6 h-6 rounded pr-2"
              />
              <p className="text-sm pt-1 hover:text-[#50A060]">
                Change Duration
              </p>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="flex justify-between py-8 px-5">
          <img
            src={Delete}
            alt="order"
            className="w-6 min-w-[1.5rem] h-6 rounded cursor-pointer"
            onClick={() => handleDelete({ id:row.data.land.id , type: "LAND"})}
          />
        </div>
      </td>
      <td>
        <div className="flex justify-between">
          <p className="py-10">&#8358; {row.amount}</p>
        </div>
      </td>
    </React.Fragment>
  );
};

const Cart = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [updateList, setUpdateList] = useState(true);
  const [amount, setAmount] = useState({});
  const [isLandModalOpen, setIsLandModalOpen] = useState(false);
  const [isLorryModalOpen, setIsLorryModalOpen] = useState(false);
  const [rentItem, setRentItem] = useState(null);
  const [landSubtotal, setLandSubtoal] = useState(0);
  const [lorrySubtotal, setLorrySubtoal] = useState(0);
  const [productSubtotal, setProductSubtoal] = useState(0);
  const [total, setTotal] = useState(0);
  const [isError, setIsError] = useState(false);
  const [itemsNotAvailableList, setItemsNotAvailableList] = useState(null);
  const [isPeriodAvailableError, setIsPeriodAvailableError] = useState(false);
  const [isAllItemAvailable, setIsAllItemAvailable] = useState(true);

  const { CartDetails, cart } = useSelector((state) => ({
    CartDetails: state?.cart?.CartDetails,
    cart: state?.cart,
  }));

  useEffect(() => {
    dispatch(getCartDetails());
  },[])

  useEffect(() => {

    // find amount of product when quantity changes and update respective state
        if (CartDetails?.product?.length > 0) {
      CartDetails.product.forEach((element) => {
        let prev = amount;
        prev[element.data.id] = element.amount;
        setAmount(prev);
      });
    }

    // find amount of all product items and update respective state
    let pdSubTotal = 0;
    if (CartDetails?.product?.length > 0) {
      pdSubTotal = CartDetails.product.reduce((acc, e) => {
        return acc + e.data.price * e.amount;
      }, 0);
      setProductSubtoal((prev) => pdSubTotal);
    }

     // find amount of all land items and update respective state
    let landSbTotal = 0;
    if (CartDetails?.land?.length > 0) {
      landSbTotal = CartDetails.land.reduce((acc, e) => {
        return acc + e.amount;
      }, 0);
      setLandSubtoal((prev) => landSbTotal);
    }

     // find amount of all lorry items and update respective state
    let lorrySbTotal = 0;
    if (CartDetails?.lorry?.length > 0) {
      lorrySbTotal = CartDetails.lorry.reduce((acc, e) => {
        return acc + e.amount;
      }, 0);
      setLorrySubtoal((prev) => lorrySbTotal);
    }

    // set total amount including all products, Lands and Lorry
    setTotal((prev) => pdSubTotal + landSbTotal + lorrySbTotal);

    // set error if there is any error in cart
    if(cart?.error){
      setIsError(true);
      setTimeout(()=>{setIsError(false)},5000)
    }

    // if any items selected period is not available then show it to user
    const land = CartDetails?.land?.filter((item => {
      if(!item?.data?.land?.available) return item;
    }))

    const lorry = CartDetails?.lorry?.filter((item => {
      if(!item.data.lorry.available) return item;
    }))

    if(land.length > 0 || lorry.length > 0){
      if(land.length > 0 && lorry.length > 0) setItemsNotAvailableList({land: land, lorry: lorry});
      else if(land.length > 0) setItemsNotAvailableList({land: land});
      else if(lorry.length > 0) setItemsNotAvailableList({land: land, lorry: lorry});
      setIsPeriodAvailableError(true)
    }else{
      setItemsNotAvailableList(null);
    }

    // check if all items available

    const isLandAvailable = CartDetails?.land?.reduce((acc, item) => acc && item.data.land.available, true)

    const isLorryAvailable = CartDetails?.lorry?.reduce((acc, item) => acc && item.data.lorry.available, true)

    if(!isLandAvailable || !isLorryAvailable){
        setIsAllItemAvailable(false)
    }else{
      setIsAllItemAvailable(true)
    }

  }, [CartDetails, cart]);

  const formatRentDate = (dateString) => {
    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Extract year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const handleSubmit = (values, type) => {

    const data = {
      id:values.id,
      rentFrom :values?.type === "period"
      ? formatRentDate(values?.period?.startDate) + "T00:00:00"
      : formatRentDate(values?.date) + "T00:00:00",
      rentTo:values?.type === "period"
      ? formatRentDate(values?.period?.endDate) + "T23:59:59"
      : formatRentDate(values?.date) + "T23:59:59",
      type:type
    }

    dispatch(editCartDetails(data, setIsPeriodAvailableError))
    setRentItem(null);
    if(type === "LAND")setIsLandModalOpen(false)
    if(type === "LORRY")setIsLorryModalOpen(false)
  };

  const handleProductQantityChange = (item) => {
    dispatch(editCartDetails(item, setIsPeriodAvailableError))
  };

  const handleDelete = (item) => {
    dispatch(deleteCartItem(item, setIsPeriodAvailableError));
  };

  const handleCheckout = () => {
    history.push(`${process.env.PUBLIC_URL}/order`);
  };

  const tableDataProduct = {
    header: ["Product", "Price", "Quantity", "Remove", "Total"],
    body:
      CartDetails?.hasOwnProperty("product") &&
      CartDetails?.product?.map((row, index) => (
        <ProductRow
          key={index}
          row={row}
          handleProductQantityChange={handleProductQantityChange}
          handleDelete={handleDelete}
        />
      )),
    className:
      "grid grid-cols-[8fr_2fr_4fr_2fr_2fr] gap-x-[40px] items-start px-[40px]",
  };

  const tableDataLorryRent = {
    header: ["Description", "Price", "Duration", "Remove", "Total"],
    body:
      CartDetails?.hasOwnProperty("lorry") &&
      CartDetails?.lorry?.map((row, index) => (
        <LorryRaw
          key={index}
          row={row}
          setIsLorryModalOpen={setIsLorryModalOpen}
          setRentItem={setRentItem}
          handleDelete={handleDelete}
        />
      )),
    className:
      "grid grid-cols-[8fr_2fr_4fr_2fr_2fr] gap-x-[40px] items-start px-[40px]",
  };

  const tableDataLandRent = {
    header: ["Description", "Price", "Duration", "Remove", "Total"],
    body:
      CartDetails?.hasOwnProperty("land") &&
      CartDetails?.land?.map((row, index) => (
        <LandRow
          row={row}
          setIsLandModalOpen={setIsLandModalOpen}
          setRentItem={setRentItem}
          handleDelete={handleDelete}
        />
      )),
    className:
      "grid grid-cols-[8fr_2fr_4fr_2fr_2fr] gap-x-[40px] items-start px-[40px]",
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[]);

  return (
    <>
      <Modal
        isOpen={isLandModalOpen}
        onClose={() => {
          setIsLandModalOpen(false);
        }}
        title="Select Rent Duration"
      >
        <RentLandForm
          type="LAND"
          landId={rentItem?.data?.rentRequestId}
          onSubmit={(values) => handleSubmit(values, "LAND")}
          onClose={() => {
            setIsLandModalOpen(false);
          }}
          itemDbId = {rentItem?.data?.land?.id}
        />
      </Modal>
      <Modal
        isOpen={isLorryModalOpen}
        onClose={() => {
          setIsLorryModalOpen(false);
        }}
        title="Select Rent Duration"
      >
        <RentLandForm
          type="LORRY"
          landId={rentItem?.data?.rentRequestId}
          onSubmit={(values) => handleSubmit(values, "LORRY")}
          onClose={() => {
            setIsLorryModalOpen(false);
          }}
          itemDbId = {rentItem?.data?.lorry?.id}
        />
      </Modal>
      <Layout>
        <main className="pb-[40px] mt-[110px]">
          {isError && <ErrorModal text={cart?.error} textColor={"text-[#FF0000]"} />}
          <div className="mb-[30px] flex items-center justify-between px-[40px]">
            <h1 className="text-[24px] font-semibold">Shopping Cart</h1>
          </div>
          {CartDetails?.product?.length > 0 ||
          CartDetails?.lorry?.length > 0 ||
          CartDetails?.land?.length > 0 ? (
            <div>
              {CartDetails?.product?.length > 0 && (
                <div>
                  <div className="mb-[30px] flex items-center justify-between px-[40px]">
                    <h1 className="text-[18px] text-main font-semibold">
                      Product
                    </h1>
                  </div>
                  {CartDetails?.product?.length > 0 ? (
                    <Table
                      data={CartDetails?.product}
                      total={CartDetails?.product?.length}
                      noPagination={true}
                      onUpdateList={(value) => setUpdateList(value)}
                      tableData={tableDataProduct}
                    />
                  ) : (
                    <h2 className="px-[40px]">Product Cart is empty</h2>
                  )}
                  <div className="flex justify-end gap-3 px-[40px] py-6">
                    <div className=" md:w-1/3 lg:pl-8 whitespace-nowrap">
                      <p>
                        Total Items{" "}
                        <span className="font-semibold">
                          {CartDetails?.product?.length}
                        </span>
                      </p>
                    </div>
                    <div className="text-right">
                      <p>
                        Subtotal{" "}
                        <span className="font-semibold">
                          {" "}
                          &#8358; {productSubtotal}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {CartDetails?.lorry?.length > 0 && (
                <div>
                  <div className="mb-[30px] flex items-center justify-between px-[40px]">
                    <h1 className="text-[18px] text-main font-semibold">
                      Machine / Lorry On Rent
                    </h1>
                  </div>
                  {CartDetails?.lorry?.length > 0 ? (
                    <Table
                      data={CartDetails?.lorry}
                      total={CartDetails?.lorry?.length}
                      noPagination={true}
                      onUpdateList={(value) => setUpdateList(value)}
                      tableData={tableDataLorryRent}
                    />
                  ) : (
                    <h2 className="px-[40px]">
                      Machine ? Lorry Rent Cart is empty
                    </h2>
                  )}
                  <div className="flex justify-end  px-[40px] py-6">
                    <div className="w-1/3 lg:pl-8 whitespace-nowrap">
                      <p>
                        Total Items{" "}
                        <span className="font-semibold">
                          {CartDetails?.lorry?.length}
                        </span>
                      </p>
                    </div>
                    <div className="text-right">
                      <p>
                        Subtotal{" "}
                        <span className="font-semibold">
                          {" "}
                          &#8358; {lorrySubtotal}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {CartDetails?.land?.length > 0 && (
                <div>
                  <div className="mb-[30px] flex items-center justify-between px-[40px]">
                    <h1 className="text-[18px] text-main font-semibold">
                      Land On Rent
                    </h1>
                  </div>
                  {CartDetails?.land?.length > 0 ? (
                    <Table
                      data={CartDetails?.land}
                      total={CartDetails?.land?.length}
                      noPagination={true}
                      onUpdateList={(value) => setUpdateList(value)}
                      tableData={tableDataLandRent}
                    />
                  ) : (
                    <h2 className="px-[40px]">Land Rent Cart is empty</h2>
                  )}
                  <div className="flex justify-end  px-[40px] py-6">
                    <div className="w-1/3 lg:pl-8 whitespace-nowrap">
                      <p>
                        Total Items{" "}
                        <span className="font-semibold">
                          {CartDetails?.land?.length}
                        </span>
                      </p>
                    </div>
                    <div className="text-right w-1/3">
                      <p>
                        Subtotal{" "}
                        <span className="font-semibold">
                          {" "}
                          &#8358; {landSubtotal}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-end  px-[40px] py-6">
                <div className="text-right">
                  <p className="text-xl">
                    <span className="text-main">Grand Subtotal </span>
                    <span className="font-semibold"> &#8358; {total}</span>
                  </p>
                  <p className="text-sm">
                    Taxes and shipping calculated at checkout
                  </p>
                  <button
                    onClick={handleCheckout}
                    disabled={!isAllItemAvailable || total === 0}
                    className={`block py-[17px] justify-self-center w-full max-w-[330px] bg-main rounded-[10px] mt-[36px] text-white text-[18px] font-medium text-center mb-[35px] ${
                      !isAllItemAvailable || total === 0 ? "opacity-70" : "opacity-100"
                    }`}
                  >
                    Check Out
                  </button>
                  {total === 0 && (
                    <p className="text-[#FF0000] text-center">
                      Items quantity should be atleast 1
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <h2 className="p-[42.7px] text-center text-[48px]">
              Your Cart is empty Add Items to checkout Cart.
            </h2>
          )}
        </main>
      </Layout>
    </>
  );
};

export default Cart;
