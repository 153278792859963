import React from 'react'

const AboutBuyer = () => {
  return (
    <div className='flex flex-col max-w-[48rem]'>
      <p className='font-circular-500 text-base text-[#3DB83A] mb-3'>#1 Fresh Farm Producers </p>
      <p className='font-circular-500 text-3xl text-[#212121] mb-5'>Why buy from mFarm?</p>
      <p className='font-circular-450 text-xl text-[#6B7280]'>We simplifies the supply chain and put quality into the delivery of your fresh produce to your desired location</p>
    </div>
  )
}

export default AboutBuyer
