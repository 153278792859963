import React from 'react'
import SellerRightsHeader from './SellerRightsHeader'
import SellerRightsContent from './SellerRightsContent'

const SellerRights = () => {
  return (
    <main className='py-24 px-2 sm:px-[112px] flex flex-col gap-16 bg-[#F9FAFB]'>
        <SellerRightsHeader />
        <SellerRightsContent />
    </main>
  )
}

export default SellerRights
