import React from 'react'
import { useState } from 'react'

function ErrorModal({text, textColor}) {
  return (
        <div className={`fixed h-10rem p-4 z-50 right-10 top-20 rounded-md shadow-md  ${textColor} bg-slate-200 transition ease-in-out duration-3000`}>
            <p className='m-auto'>{text}</p>
        </div>
  )
}

export default ErrorModal