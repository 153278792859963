import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikInput from '../FormikInput';
import CloseIcon from "../../assets/close.svg"
// import SearchIcon from '../../assets/search.svg';

const validationSchema = Yup.object().shape({
    search: Yup.string()
});

const SearchForm = ({ setSearchValue, onSubmit, setUpdateList }) => {
 
  const [isSearchFocused, setIsSearchFocused] = useState(false)

  const handleClearSearch = (setFieldValue) => () => {
    setIsSearchFocused(false);
    setFieldValue('search', '');
    setSearchValue((prev) => null)
    setUpdateList(true)
  }

  const handleSubmit = (values) => {
    setSearchValue(values.search)
    onSubmit(values)
    setUpdateList(true)
  }

  const clearIconStyle = {
    position : "absolute",
    top: "0.8rem",
    right:"5%",
    cursor:"pointer"
  }
  return (
    <div style={{position:"relative"}}>
      <Formik
        initialValues={{
            search: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit} 
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => {
          return (
            <Form>
              <div className="mb-4 w-[100%]">
                <div className="mb-2 relative">
                  <FormikInput
                    name="search"
                    type="text"
                    placeholder="Search here"
                    errors={errors}
                    touched={touched}
                    setIsSearchFocused={setIsSearchFocused}
                  />
                  {
                  isSearchFocused && 
                    <img src={CloseIcon} alt="clear" style={clearIconStyle} onClick={handleClearSearch(setFieldValue)}  />
                  }
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SearchForm;
