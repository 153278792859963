import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProfile, getProfileImage, updateProfileImage } from "../../store/actions/profile";

import Layout from "../../components/Layout"
import ChangePasswordForm from "../../components/Forms/ChangePasswordForm";
import ChangeProfileDataForm from "../../components/Forms/ChangeProfileDataForm";
import defaultProfileImg from "../../assets/default_profile_icon.png";
import Modal from "../../components/Modal/Modal";
import UpdateProfileModal from "../../components/UpdateProfileModal";


const ProfilePage = () => {
    const dispatch = useDispatch()

    const [changePassword, setChangePassword] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [isUpdateProfileModalOpen, setIsUpdateProfileModalOpen] = useState(false);
    const [isPasswordChanged, setIsPasswordChange] = useState(false);
    const [error, setError] = useState(false);

    const toggleChangePassword = () => {
        setChangePassword(!changePassword)
    };

    const { profile, profileImage } = useSelector((state) => ({
        profile: state?.profile?.profile,
        profileImage: state?.profile?.profileImage
    }));

    const handleUpdateProfile = () => {

        const isImage = /^image\//.test(userProfile?.type);
        if(isImage){
            if (userProfile) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result.replace(/^data:image\/(.*);base64,/, '');
                    if (((base64String.length * 3) / 4) <= (1024 * 1024)) {
                        dispatch(updateProfileImage({ imageBase64: base64String }));
                        handleCloseModal();
                    } else {
                        setError(true);
                    }
                };
                reader.readAsDataURL(userProfile);
            }
        }else{
            alert("Selected file is not an image.");
        }
    }

    const handleCloseModal = () => {
        document.getElementById("imageBase64").value = "";
        setUserProfile(null);
        setIsUpdateProfileModalOpen(false)
        setError(false);
    }
    
    useEffect(() => {
        if (!profile) {
            dispatch(getProfile())
        }
    }, [profile, dispatch])

    useEffect(() => {
        if (profile?.hasImage) {
            dispatch(getProfileImage())
        }
    }, [profile, dispatch])

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[]);

    return(
        <>
            <Modal
             isOpen={isUpdateProfileModalOpen}
            onClose={handleCloseModal} 
            title ="Update Profile!"
             >
                <UpdateProfileModal
                    onConfirm={handleUpdateProfile}
                    onCancle={handleCloseModal}
                    error={error}
                />
            </Modal>
        <Layout>
        <main className="py-[110px] px-[40px]">
            { isPasswordChanged && <div className="fixed top-20 right-14 text-main bg-slate-100 p-4 rounded">
                <p>Your Password has been changed</p>
            </div>}
            <div className="flex justify-between items-center mb-[30px]">
                <h1 className="text-[24px] font-semibold">Profile</h1>
            </div>
            <div className="w-full bg-white shadow-table rounded-[10px] p-[20px] md:p-[40px]">
                {!profileImage &&
                    <label
                        htmlFor="imageBase64"
                        title="Add Profile Image"
                        className="w-[120px] h-[120px] rounded-[50%] bg-[#aaaaaa] mb-[35px] text-white text-center flex items-center justify-center cursor-pointer">
                        <img src={defaultProfileImg} alt="default-img"/>
                    </label>
                }
                {profileImage &&
                    <>
                        <label
                            title="Change Profile Image"
                            htmlFor="imageBase64"
                            className="w-[120px] h-[120px] rounded-[100%] block  mb-[35px] cursor-pointer"
                            style={{
                                backgroundImage: `url(data:image/jpeg;base64,${profileImage})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center"
                            }}
                        />
                    </>
                }
                <input
                    id="imageBase64"
                    type="file"
                    className="hidden"
                    accept=".jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp" 
                    onChange={(event) => {
                        const file = event.target.files[0];
                        setUserProfile(file);
                        setIsUpdateProfileModalOpen(true);
                    }}
                />
                {profile &&
                    <ChangeProfileDataForm
                        profile={profile}
                        changePassword={changePassword}
                        toggleChangePassword={toggleChangePassword}
                    />
                }
                {changePassword && (
                    <ChangePasswordForm
                        toggleChangePassword={toggleChangePassword}
                        setIsPasswordChange={setIsPasswordChange}
                    />
                )}
            </div>
        </main>
        </Layout>
    </>
    )
}

export default ProfilePage;