import * as actionTypes from '../actions/types';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
    sellRequests: null,
    rentLandRequests: null,
    rentLorriesRequests: null,
    selectedProduct: null,
};

// get sell requests
const getHomeSellRequestsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getHomeSellRequestsSuccess = (state, action) => {
    return updateObject(state, {
        sellRequests: updateObject(state.sellRequests,action.sellRequests),
        error: null,
        loading: false,
    });
};

const getHomeSellRequestsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        sellRequests: null,
    });
};

// get rentLand requests
const getHomeRentLandRequestsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getHomeRentLandRequestsSuccess = (state, action) => {
    return updateObject(state, {
        rentLandRequests: action.rentLandRequests,
        error: null,
        loading: false,
    });
};

const getHomeRentLandRequestsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        rentLandRequests: null,
    });
};

// get rentLorries requests
const getHomeRentLorriesRequestsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getHomeRentLorriesRequestsSuccess = (state, action) => {
    return updateObject(state, {
        rentLorriesRequests: action.rentLorriesRequests,
        error: null,
        loading: false,
    });
};

const getHomeRentLorriesRequestsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        rentLorriesRequests: null,
    });
};

const setSelectedProductStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
    });
}

const setSelectedProductSuccess = (state, action) => {
    return updateObject(state, {
        selectedProduct: action.selectedProduct,
        error: null,
        loading: false,
    });
};

const setSelectedProductFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        selectedProduct: null,
    });
}

const homeSellRentRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        // get sell requests actions
        case actionTypes.HOME_GET_SELL_REQUESTS_START:
            return getHomeSellRequestsStart(state, action);
        case actionTypes.HOME_GET_SELL_REQUESTS_SUCCESS:
            return getHomeSellRequestsSuccess(state, action);
        case actionTypes.HOME_GET_SELL_REQUESTS_FAIL:
            return getHomeSellRequestsFail(state, action);
        // get rentLand requests actions
        case actionTypes.HOME_GET_RENT_REQUESTS_LAND_START:
            return getHomeRentLandRequestsStart(state, action);
        case actionTypes.HOME_GET_RENT_REQUESTS_LAND_SUCCESS:
            return getHomeRentLandRequestsSuccess(state, action);
        case actionTypes.HOME_GET_RENT_REQUESTS_LAND_FAIL:
            return getHomeRentLandRequestsFail(state, action);
        // get rentLorries requests actions
        case actionTypes.HOME_GET_RENT_REQUESTS_LORRY_START:
            return getHomeRentLorriesRequestsStart(state, action);
        case actionTypes.HOME_GET_RENT_REQUESTS_LORRY_SUCCESS:
            return getHomeRentLorriesRequestsSuccess(state, action);
        case actionTypes.HOME_GET_RENT_REQUESTS_LORRY_FAIL:
            return getHomeRentLorriesRequestsFail(state, action);

        // Selected Product
        case actionTypes.SET_SELECTED_PRODUCT_START:
            return setSelectedProductStart(state, action);
        case actionTypes.SET_SELECTED_PRODUCT:
            return setSelectedProductSuccess(state, action);
        case actionTypes.SET_SELECTED_PRODUCT_FAIL:
            return setSelectedProductFail(state, action);

        default:
            return state;
    }
};

export default homeSellRentRequestReducer;