import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { getProfile } from "../store/actions/profile";
import { useEffect } from "react";
import { useWindowWidth } from "../shared/hooks";
import ContactUs from "./ContactUs";
import FooterContainer from "../pages/Home/FooterContainer";

const Layout = ({ children }) => {
    const windowWidth = useWindowWidth();
    const dispatch = useDispatch()

    const { profile } = useSelector((state) => ({
        profile: state?.profile?.profile,
    }));

    useEffect(() => {
        if (!profile) {
            dispatch(getProfile())
        }
    }, [profile, dispatch])

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header showLinks={profile?.type !== "SELLER"} />
            <div className={`h-layout ${profile?.type === "SELLER" && windowWidth > 768 ? "flex" : ""} justify-stretch`}>

                {profile?.type === "SELLER" && windowWidth > 768 ? <Sidebar /> : null}
                <div className="overflow-auto w-full">{children}</div>
            </div>
            {profile?.type === "BUYER" && <>
                    <FooterContainer />
                </>
                }
        </div>
    );
}

export default Layout;