import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";

const initialState = {
    error: null,
    loading: false,
    notifications: null,
    markAllAsReadRes: null,
};

//getNotifications
const getNotificationsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getNotificationsSuccess = (state, action) => {
    return updateObject(state, {
        notifications: action.notifications,
        error: null,
        loading: false,
    });
};

const getNotificationsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        notifications: null,
    });
};

//markAllAsRead
const markAllAsReadStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const markAllAsReadSuccess = (state, action) => {
    return updateObject(state, {
        markAllAsReadRes: action.markAllAsReadRes,
        error: null,
        loading: false,
    });
};

const markAllAsReadFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        markAllAsReadRes: null,
    });
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        // get notifications actions
        case actionTypes.GET_NOTIFICATIONS_START:
            return getNotificationsStart(state, action);
        case actionTypes.GET_NOTIFICATIONS_SUCCESS:
            return getNotificationsSuccess(state, action);
        case actionTypes.GET_NOTIFICATIONS_FAIL:
            return getNotificationsFail(state, action);
        // markAllAsRead actions
        case actionTypes.MARK_ALL_AS_READ_START:
            return markAllAsReadStart(state, action);
        case actionTypes.MARK_ALL_AS_READ_SUCCESS:
            return markAllAsReadSuccess(state, action);
        case actionTypes.MARK_ALL_AS_READ_FAIL:
            return markAllAsReadFail(state, action);
        default:
            return state;
    }
};

export default notificationReducer;
