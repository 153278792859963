import * as actionTypes from "../actions/types";
import { updateObject } from "../../shared/utility";

const initialState = {
    error: null,
    loading: false,
    categories: null,
};

//getCategoriess
const getCategoriessStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const getCategoriessSuccess = (state, action) => {
    return updateObject(state, {
        categories: action.categories,
        error: null,
        loading: false,
    });
};

const getCategoriessFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        categories: null,
    });
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        // get categories actions
        case actionTypes.GET_CATEGORIES_START:
            return getCategoriessStart(state, action);
        case actionTypes.GET_CATEGORIES_SUCCESS:
            return getCategoriessSuccess(state, action);
        case actionTypes.GET_CATEGORIES_FAIL:
            return getCategoriessFail(state, action);
        default:
            return state;
    }
};

export default categoryReducer;
