const NavDashboardIcon = ({ color }) => {
  return (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="home (15) 1" clipPath="url(#clip0_44_3996)">
      <path id="Vector" d="M9.01392 0.83088L0.774863 9.15701C0.529168 9.40519 0.334268 9.69992 0.20131 10.0243C0.0683514 10.3487 -5.6098e-05 10.6965 3.45171e-08 11.0477V20.0538C3.45171e-08 20.57 0.202766 21.065 0.563692 21.43C0.924619 21.795 1.41414 22 1.92457 22H6.83712C7.34755 22 7.83707 21.795 8.198 21.43C8.55892 21.065 8.76169 20.57 8.76169 20.0538V17.2794C8.76169 17.1955 8.77805 17.1123 8.80984 17.0347C8.84163 16.9572 8.88822 16.8867 8.94695 16.8273C9.00569 16.768 9.07541 16.7209 9.15214 16.6888C9.22887 16.6567 9.3111 16.6402 9.39413 16.6403H12.6059C12.6889 16.6402 12.7711 16.6567 12.8479 16.6888C12.9246 16.7209 12.9943 16.768 13.053 16.8273C13.1118 16.8867 13.1584 16.9572 13.1902 17.0347C13.2219 17.1123 13.2383 17.1955 13.2383 17.2794V20.0538C13.2383 20.57 13.4411 21.065 13.802 21.43C14.1629 21.795 14.6525 22 15.1629 22H20.0754C20.5859 22 21.0754 21.795 21.4363 21.43C21.7972 21.065 22 20.57 22 20.0538V11.0477C22.0001 10.6965 21.9316 10.3487 21.7987 10.0243C21.6657 9.69992 21.4708 9.40519 21.2251 9.15701L12.9828 0.83088C12.4564 0.298861 11.7426 0 10.9983 0C10.2541 0 9.54033 0.298861 9.01392 0.83088Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_44_3996">
        <rect width="22" height="22" fill={color} />
      </clipPath>
    </defs>
  </svg>)
}

export default NavDashboardIcon
